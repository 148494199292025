import store from "../../store/index";

export default {
  faultControl(DOMdoc) {
    //@ToDo better check to prevent push
    let returnCode = true;
    
    let elemCollection = DOMdoc.querySelectorAll("input, select");
    let elementArray = Array.from(elemCollection);
    elementArray.forEach((element) => {
      if (!this.singleFault(element)) {
        returnCode = false;
      }
    });
    return returnCode;
  },

  singleFault(element) {
    let returnCode = true;
    if (element.hasAttributes()) {
      if (element.value == "" && this.getRequired(element.attributes)) {
        element.classList.add("border-danger");
        returnCode = false;
      } else {
        element.classList.remove("border-danger");
      }
    }
    return returnCode;
  },

  getRequired(attributes) {
    for (const attr of attributes) {
      if (attr.name == "required") {
        return true;
      }
    }
    return false;
  },
  checkIndividual(){
    let getType = store.getters["RefObj/getObjectType"]
    let getId = store.getters["RefObj/getObjectId"]
    let ObjTypArray = store.getters["RefObj/getObjectIdType_model"]
    for (let i = 0; i < ObjTypArray.length; i++){
      if (getType(i) == "Individual" && getId(i) !== undefined && getId(i).trim() !== ''){
        return true
      }
    }
    return false
  },
  checkAtLeastOne(){
    // let getType = store.getters["RefObj/getObjectType"]
    let getId = store.getters["RefObj/getObjectId"]
    let ObjTypArray = store.getters["RefObj/getObjectIdType_model"]
    for (let i = 0; i < ObjTypArray.length; i++){
      if (getId(i) !== undefined && getId(i).trim() !== ''){
        return true
      }
    }
    return false
  },

  
};
