const FILENAME = "original_file_name";
const ORGID = "OrganizationId";
const ORGNAME = "Organization Name";
const ORGOFFICIALNAME = "Organization Official Name";
const DOCTITLE = "Document Title";
const REVISION = "Revision";
const DOCID = "ZVSP Document Number";
const LANG = "Language";
const SUMMARY = "Summary";
const KEYWORDS = "Keywords";
const PROID = "basf:ProjectID";
const PO = "basf:purchase order number";
const TAG = "basf:engineering tag number";
const DESC = "Description";
const EQUINR = "basf:equipment number";
const IECID = "instance of object uri (IEC 61406 ID)";
const PRODTYPE = "product type";
const ECLASS = "ECLASS number";
const REFDES = "basf:Referenced Designation";
const SOCONF = "vendor sales order confirmation number";
const MANUFACT = "Manufacturer";
// const MANUFACTPRODCODE = "Manufacturer Product Code";
const MANUFACTSERIAL = "Manufacturer Serial Number";
const IPCLASSIFICATION = "IP Classification"
const NUMBEROFPDFPAGES = "Number of PDF pages"

export const ZVSDefinitions = {
  FILENAME,
  NUMBEROFPDFPAGES,
  ORGID,
  ORGNAME,
  ORGOFFICIALNAME,
  DOCTITLE,
  REVISION,
  DOCID,
  LANG,
  SUMMARY,
  KEYWORDS,
  PROID,
  PO,
  TAG,
  DESC,
  EQUINR,
  IECID,
  PRODTYPE,
  ECLASS,
  REFDES,
  SOCONF,
  MANUFACT,
  // MANUFACTPRODCODE,
  MANUFACTSERIAL,
  IPCLASSIFICATION,
};
export const ZVSMandatory = [
  FILENAME,
  NUMBEROFPDFPAGES,
  ORGID,
  ORGNAME,
  ORGOFFICIALNAME,
  DOCTITLE,
  REVISION,
  DOCID,
  LANG,
  // SUMMARY,
  // KEYWORDS,
  PROID,
  PO,
  TAG,
  MANUFACT,
  PRODTYPE,
];
// Attention! order matters!
export const ZVSRefObjTypes = [
  PROID,
  PO,
  PRODTYPE,
  IECID,
  ECLASS,
  TAG,
  EQUINR,
  REFDES,
  MANUFACTSERIAL,  
  SOCONF,
]
export const ZVSOptional = [
  // PROID,
  // PO,
  // TAG,
  // DESC,
  // EQUINR,
  // IECID,
  // PRODTYPE,
  // ECLASS,
  // REFDES,
  // SOCONF,
  // MANUFACT,
  // MANUFACTSERIAL,
]
