export default {
  namespaced: true,

  state() {
    return {
      DocumentIdType_model: [
        // {
        //   UserId: undefined,
        //   GroupId: undefined,
        //   DomainId: undefined,
        //     // "BIG_00-0000-000-PM-010-010 Site and Plant Structure and Eng Doc Codes",
        //   IsPrimary: true,
        //   DocumentDomainId: undefined,
        //     // "BIG_00-0000-000-PM-010-010 Site and Plant Structure and Eng Doc Codes",
        //   DocumentId: undefined,
        // },
      ],
      userChooseTemplate: 0,
    };
  },

  getters: {
    getDocumentIdTypeInstance: (state) => (index) => {
      return state.DocumentIdType_model[index];
    },
    getDocumentId: (state) => (index) => {
      return state.DocumentIdType_model[index].DocumentId;
    },
    getDocumentIdType(state) {
      return state.DocumentIdType_model;
    },
    getDomainId: (state) => (index) => {
      return state.DocumentIdType_model[index].DomainId;
    },
    getIsPrimary: (state) => (index) => {
      return state.DocumentIdType_model[index].IsPrimary;
    },
    getDocumentDomainId: (state) => (index) => {
      return state.DocumentIdType_model[index].DocumentDomainId;
    },
    getDocumentIdTypeId: (state) => (index) => {
      return state.DocumentIdType_model[index].id;
    },

    getUserChooseTemplate(state) {
      return state.userChooseTemplate;
    },
  },

  mutations: {
    addDocumentIdType(state) {
      state.DocumentIdType_model.push({
        UserId: undefined,
        GroupId: undefined,
        DomainId: undefined,
        IsPrimary: false,
        DocumentDomainId: undefined,
        DocumentId: undefined,
        id: undefined,
      });
    },
    setDocumentId(state, payload) {
      state.DocumentIdType_model[payload.index].DocumentId = payload.DocumentId;
    },
    setDocumentIdType(state, payload) {
      state.DocumentIdType_model[payload.index] = payload.DocumentIdTyp;
    },
    setDomainId(state, payload) {
      state.DocumentIdType_model[payload.index].DomainId = payload.DomainId;
    },
    setIsPrimary(state, payload) {
      state.DocumentIdType_model[payload.index].IsPrimary = payload.IsPrimary;
    },
    setDocumentDomainId(state, payload) {
      state.DocumentIdType_model[payload.index].DocumentDomainId =
        payload.DocumentDomainId;
    },

    setUserChooseTemplate(state, payload) {
      state.userChooseTemplate = payload;
    },
    removeDocumentIdType(state, index){
      state.DocumentIdType_model.splice(index, 1)
    },
  },

  actions: {
    addDocumentIdType(context) {
      context.commit("addDocumentIdType");
    },
    setDocumentId(context, payload) {
      context.commit("setDocumentId", payload);
    },
    setDocumentIdType(context, payload) {
      context.commit("setDocumentIdType", payload);
    },
    setDomainId(context, payload) {
      context.commit("setDomainId", payload);
    },
    setIsPrimary(context, payload) {
      context.commit("setIsPrimary", payload);
    },
    setDocumentDomainId(context, payload) {
      context.commit("setDocumentDomainId", payload);
    },
    setUserChooseTemplate(context, payload) {
      context.commit("setUserChooseTemplate", payload);
    },
    removeDocumentIdType(context, index){
      context.commit("removeDocumentIdType", index)
    }
  },
};
