<template>
  <div>
    <div class="card myaccordion" id="accordion">
      <h3 class="card-header" v-if="!getIsGeneralDocu">
        {{ $t("headerRefObjId") }}
      </h3>
      <h3 class="card-header" v-else>{{ $t("headerGeneralRefObjId") }}</h3>
      <div class="card-body" id="accbody">
        <form action="" id="ObjectType">
          <div v-for="(item, index) in getObjectIdType_model" :key="item">
            <div v-if="!getIsGeneralDocu">
              <button
                v-if="index == 0"
                type="button"
                class="d-flex btn btn-outline-primary collapsed mb-2 fullwidth"
                data-toggle="collapse"
                data-target=".multi-collapse"
                aria-expanded="true"
                aria-controls="objId0 objId1 objId2"
                id="accbutton1"
                @click="toggleIcon"
              >
                <span style="font-size: 1.2rem">
                  {{ $t("toogleHeaderMandatory") }}
                  <i :class="image1" style="margin-left: 1.5rem"></i>
                </span>
              </button>
              <button
                v-if="index == 3"
                type="button"
                class="d-flex btn btn-outline-primary mb-2 mt-2 fullwidth"
                data-toggle="collapse"
                data-target=".multi-collapse"
                aria-expanded="false"
                aria-controls="objId3 objId4 objId5 objId6 objId7 objId8"
                @click="toggleIcon"
              >
                <span style="font-size: 1.2rem">
                  {{ $t("toogleHeaderOptional") }}
                  <i :class="image2" style="margin-left: 1.5rem"></i>
                </span>
              </button>
            </div>
            <button
              v-if="index == getObjIdLengthOptData + 1"
              type="button"
              class="btn btn-link"
            >
              {{ $t("additionalData") }}
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="additionalData"
                  data-body="additionalDataBody"
                  @click.prevent="showmodal"
                ></i
              ></a>
            </button>
            <div class="card" :id="'objId' + index">
              <div
                :class="getShowClass(index)"
                :id="'objId' + index"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div class="row mb-3">
                    <!--  -->
                    <label
                      for="objectid"
                      class="col-xl-3 col-form-label"
                      v-if="index > getObjIdLengthOptData || getIsGeneralDocu"
                      >{{ $t("objIdInfoTitle") }}:<span
                        v-if="getIsGeneralDocu && index < 3"
                      >
                      </span
                      ><span v-else>* </span>
                      <a href="#"
                        ><i
                          class="bi bi-info-circle"
                          data-toggle="modal"
                          data-target="#infoModal"
                          data-title="objIdInfoTitle"
                          data-body="objIdInfoBody"
                          @click.prevent="showmodal"
                        ></i></a
                    ></label>
                    <label for="objectid" class="col-xl-3 col-form-label" v-else
                      >{{ getRefType(index) }}:<span v-if="index < 3">* </span>
                      <a href="#"
                        ><i
                          class="bi bi-info-circle"
                          data-toggle="modal"
                          data-target="#infoModal"
                          :data-title="'objIdRefTypeInfoTitle' + index"
                          :data-body="'objIdRefTypeInfoBody' + index"
                          @click.prevent="showmodal"
                        ></i
                      ></a>
                    </label>
                    <div class="col-xl-5">
                      <input
                        :disabled="getDisabled(index, getObjectType(index), true)"
                        type="text"
                        class="form-control"
                        id="objectid"
                        :placeholder="
                          getIsGeneralDocu && index == 0
                            ? 'www.myorg.com/documents/equi1234/xyzid'
                            : ''
                        "
                        :value="getObjectId(index)"
                        @change="setObjId(index, $event)"
                        :required="
                          (index < 3 && !getIsGeneralDocu) ||
                          index > getObjIdLengthOptData
                        "
                        @focusout="
                          if (
                            index != 3 &&
                            index != 1 &&
                            index != 4 &&
                            !getIsGeneralDocu
                          ) {
                            singleFault($event.target);
                          } else if (!getIsGeneralDocu) {
                            validateAttributeValue(index, 'url', $event.target);
                          } else if (getIsGeneralDocu && index == 0) {
                            validateAttributeValue(3, 'url', $event.target);
                          }
                        "
                        @keydown.enter.prevent
                        
                      />
                    </div>
                    <!-- </div> -->
                    <!-- <div v-if="index > 8"> -->
                    <!-- <div class="row mb-3"> -->
                    <label for="ObjectType" class="col-xl-2 col-form-label"
                      >{{ $t("objIdObjectTypeInfoTitle") }}
                      <a href="#"
                        ><i
                          class="bi bi-info-circle"
                          data-toggle="modal"
                          data-target="#infoModal"
                          data-title="objIdObjectTypeInfoTitle"
                          data-body="objIdObjectTypeInfoBody"
                          @click.prevent="showmodal"
                        ></i></a
                    ></label>
                    <div class="col-xl-2">
                      <select
                        class="form-select"
                        id="ObjType"
                        :disabled="getDisabled(index, getObjectType(index))"
                        :value="getObjectType(index)"
                        @change="setObjType(index, $event)"
                        @focusout="singleFault($event.target)"
                        required
                      >
                        <option value="Individual">Individual</option>
                        <option value="Type">Type</option>
                      </select>
                    </div>
                    <!-- </div> -->
                  </div>

                  <div v-if="index > getObjIdLengthOptData || getIsGeneralDocu">
                    <div class="row mb-3">
                      <label for="RefType" class="col-xl-3 col-form-label"
                        >{{ $t("objIdRefTypeInfoTitle") }}: <span>* </span>
                        <a href="#"
                          ><i
                            class="bi bi-info-circle"
                            data-toggle="modal"
                            data-target="#infoModal"
                            data-title="objIdRefTypeInfoTitle"
                            data-body="objIdRefTypeInfoBody"
                            @click.prevent="showmodal"
                          ></i></a
                      ></label>
                      <div class="col-xl-4">
                        <input
                          type="text"
                          class="form-control"
                          :disabled="getDisabled(index, getObjectType(index))"
                          id="RefType"
                          :value="getRefType(index)"
                          @change="setRefTypeLocal(index, $event)"
                          required
                          @focusout="singleFault($event.target)"
                          @keydown.enter.prevent
                        />
                      </div>
                      <div
                        class="col-xl-4 mt-2"
                        v-if="
                          (!getDisabled(index, getObjectType(index)) ||
                          (index === 3 && !getIsGeneralDocu)) && getObjectType(index) == 'Individual'
                        "
                      >
                        <div class="form-check">
                          <label
                            for="IsGloballyBiUnique"
                            class="form-check-label"
                            >{{ $t("objIdBiuniqueInfoTitle") }}:
                            <a href="#"
                              ><i
                                class="bi bi-info-circle"
                                data-toggle="modal"
                                data-target="#infoModal"
                                data-title="objIdBiuniqueInfoTitle"
                                data-body="objIdBiuniqueInfoBody"
                                @click.prevent="showmodal"
                              ></i></a
                          ></label>
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="IsGloballyBiUnique"
                            :disabled="getDisabled(index, getObjectType(index))"
                            :checked="getIsGlobalBiUnique(index)"
                            @change="
                              setIsGloBiUniq(index, !getIsGlobalBiUnique(index))
                            "
                            @keydown.enter.prevent
                          />
                        </div>
                      </div>
                      <div class="col xl-1 mt-2" v-if="index > 2">
                        <button
                          class="btn btn-outline-danger"
                          style="width: 3rem"
                          @click.prevent="removeObjectIdType(index)"
                        >
                          <i class="bi-x-circle" style="font-size: 1rem"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col gy-2">
              <button
                class="btn btn-outline-primary"
                style="width: 8rem"
                @click.prevent="addObjectIdType()"
              >
                <i class="bi-plus-circle" style="font-size: 1.5rem"></i>
              </button>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-8 align-left mt-2">
              <go-back-util />
            </div>
            <div class="col-sm-4 align-right mt-2">
              <button
                class="btn btn-success bi-caret-right"
                @click.prevent="pushToOptData"
              >
                {{ $t("next Step") }}
              </button>
            </div>
          </div>
        </form>
      </div>
      <modal-utils />
    </div>

    <!-- Example Accordion -->

    <!-- End Example -->
  </div>
</template>
<style scoped>
.fullwidth {
  width: 100%;
}

.myaccordion .btn-outline-primary:hover,
.myaccordion .btn-outline-primary:focus {
  text-decoration: none;
}
</style>

<script>
import GoBackUtil from "../utils/GoBackUtil.vue";
import { mapActions, mapGetters } from "vuex";
import Fault from "../utils/Fault";
import i18n from "@/i18n";
import ModalUtils from "../utils/ModalsUtils.vue";

export default {
  name: "ObjectIdCom",
  components: {
    GoBackUtil,
    ModalUtils,
  },
  data() {
    return {
      image1: "bi bi-caret-up-square",
      image2: "bi bi-caret-down-square",
      // lengthOptData: 9,
      fail: [],
    };
  },
  computed: {
    ...mapGetters("RefObj", [
      "getObjectType",
      "getRefType",
      "getIsGlobalBiUnique",
      "getObjectId",
      "getReferencedObject_model",
      "getObjectIdType_model",
    ]),
    ...mapGetters([
      "getIsInstance",
      "getIsGeneralDocu",
      "getObjIdLengthOptData",
      "getIsContainer"
    ]),
  },
  methods: {
    ...mapActions("RefObj", [
      "setObjectType",
      "setRefType",
      "setIsGlobalBiUnique",
      "setObjectId",
      "addObjectIdType",
      "removeObjectIdType",
      "setEmptyObjectIdType",
    ]),
    ...mapActions("ActSteps", ["setRefObjOptData", "setRefObjParty"]),
    ...mapActions(["setObjIdLengthOptData"]),

    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },

    validateAttributeValue(index, attribute, ele) {
      this.fail = [];
      let value = ele.value;
      if (value.trim() !== "" && index == 3) {
        if (!value.includes(".")) {
          this.fail.push(i18n.global.t("faultDomain", [attribute]));
        }

        if (!value.includes("/")) {
          this.fail.push(i18n.global.t("faultPath", [attribute]));
        }

        const domain = value.split("://").pop().split("/")[0];

        if (domain.toLowerCase() !== domain) {
          this.fail.push(i18n.global.t("faultDomainUpper"));
        }

        if (value.includes("xn--")) {
          this.fail.push(i18n.global.t("faultPunyCode"));
        }

        if (value.length > 255) {
          this.fail.push(i18n.global.t("faultUrlLength"));
        }

        const invalidCharactersRegex = /[^A-Za-z0-9#,$&'()*+\-.\/~\[\]=?:;!_@]/;
        const match = value.match(invalidCharactersRegex);

        if (match) {
          this.fail.push(
            i18n.global.t("faultInvalidCharURL", [match.index + 1])
          );
        }
      }
      if (index == 1) {
        const invalidPoCharRegex = /[^0-9]/;
        const match = value.match(invalidPoCharRegex);
        if (match) {
          this.fail.push(i18n.global.t("faultPoNumbers"));
        }
      }
      if (index == 4 && value.trim() !== "") {
        const invalidEclassCharRegex = /[^0-9]/;
        const match = value.replace(/\s/g, "").match(invalidEclassCharRegex);
        if (match) {
          this.fail.push(i18n.global.t("faultEclassNumbers"));
        }
        if (value.replace(/\s/g, "").length != 8) {
          this.fail.push(i18n.global.t("faultEclassLength"));
        }
      }
      //  currently not implemented
      // if (!isValidDomain(domain)) {
      //   fail(`The ${attribute} domain is not registered.`);
      // }
      if (this.fail.length > 0) {
        ele.classList.add("border-danger");
        this.deactivateProgress();
        this.pushAlert();
      } else ele.classList.remove("border-danger");
    },
    pushAlert() {
      if (this.fail.length > 0) {
        let Message = "";
        this.fail.forEach(function (item) {
          Message = Message + item + "\n";
        });
        alert(Message);
      }
    },
    singleFault(ele) {
      return Fault.singleFault(ele);
    },
    checkIndividual() {
      return Fault.checkIndividual();
    },
    checkAtLeastOne() {
      this.fail = this.spliceFail("faultAtLeastOneEntry")
      if (Fault.checkAtLeastOne()) {
          return true;
        } else {
          this.deactivateProgress();
          this.fail.push(i18n.global.t("faultAtLeastOneEntry"));
          // alert(i18n.global.t("faultAtLeastOneEntry"));
          return false;
        }
    },
    spliceFail(fault){
      let newFail = []
      for (let i = 0; i < this.fail.length; i++){
        if (this.fail[i] == !i18n.global.t(fault)){
          newFail.push(this.fail[i])
        }
      }
      return newFail
      // 
    },
    toggleIcon() {
      let tempImage = this.image1;
      this.image1 = this.image2;
      this.image2 = tempImage;
    },
    getShowClass(index) {
      if (index < 3) {
        return "collapse show multi-collapse";
      } else if (index <= this.getObjIdLengthOptData) {
        return "collapse multi-collapse";
      } else return "";
    },
    // || (this.getIsGeneralDocu && objType === "Individual")
    getDisabled(index, objType = "Individual", isInput=false) {
      var returnValue = false
      if((!this.getIsContainer || !this.getIsInstance) && (objType == "Individual" || objType == "IndividualInvert")){
        returnValue = true
      }
      if (index <= this.getObjIdLengthOptData  && !isInput) { // && objType === "Individual"
        returnValue = true
      } // else return false;
      return returnValue
    },
    checkInstance() {
      if (!this.getIsContainer){
        if (Fault.checkIndividual()) {
          this.deactivateProgress();
          alert(i18n.global.t("faultNoIndividual"));
          return false;
        }
      }
      if (this.getIsInstance) {
        if (Fault.checkIndividual()) {
          return true;
        } else {
          this.deactivateProgress();
          alert(i18n.global.t("faultIndividual"));
          return false;
        }
      } else return true;
    },
    pushToOptData() {
      if (
        this.checkAtLeastOne() &&
        this.fail.length == 0 &&
        Fault.faultControl(document) &&
        this.checkInstance()
        // 
      ) {
        this.$store.dispatch("ActSteps/setRefObjParty", true);
        
        if (this.getIsContainer && this.getIsInstance){
          this.$router.push("/metadata/refobject/optional-data");
          this.$store.dispatch("ActSteps/setRefObjOptData", true);
        }else{
          this.$router.push("/metadata/refobject/party");
        }    
      } else if (this.fail.length > 0) {
        this.pushAlert();
      } else {
        this.deactivateProgress();
        alert(i18n.global.t("faultMandatory"));
      }
    },
    deactivateProgress() {
      this.$store.dispatch("ActSteps/setRefObjParty", false);
      this.$store.dispatch("ActSteps/setRefObjOptData", false);
    },
    setObjId(index, event) {
      var payload = {
        index: index,
        value: event.target.value,
      };
      this.$store.dispatch("RefObj/setObjectId", payload);
    },
    setObjType(index, event) {
      var payload = {
        index: index,
        value: event.target.value,
      };
      if (event.target.value === "Type") {
        let payload1 = {
          index: index,
          value: false,
        };
        this.$store.dispatch("RefObj/setIsGlobalBiUnique", payload1);
      }
      if (
        (this.getRefType(index) === "instance of object uri (IEC 61406 ID)" ||
          this.getRefType(index) === "instance of object uri" ||
          this.getRefType(index) === "S-GTIN") &&
        event.target.value === "Individual"
      ) {
        let payload2 = {
          index: index,
          value: true,
        };
        this.$store.dispatch("RefObj/setIsGlobalBiUnique", payload2);
      }
      this.$store.dispatch("RefObj/setObjectType", payload);
    },
    setRefTypeLocal(index, event) {
      var payload = {
        index: index,
        value: event.target.value,
      };
      this.$store.dispatch("RefObj/setRefType", payload);
      let payload2 = {};
      if (
        (event.target.value === "instance of object uri (IEC 61406 ID)" ||
          event.target.value === "instance of object uri" ||
          event.target.value === "S-GTIN") &&
        this.getObjectType(index) === "Individual"
      ) {
        payload2 = {
          index: index,
          value: true,
        };
        this.$store.dispatch("RefObj/setIsGlobalBiUnique", payload2);
      }
    },

    setIsGloBiUniq(index, uniquness) {
      let payload = {
        index: index,
        value: uniquness,
      };
      this.$store.dispatch("RefObj/setIsGlobalBiUnique", payload);
    },
  },
  activated() {
    if (this.getIsGeneralDocu && this.getObjIdLengthOptData !== 2) {
      this.$store.dispatch("setObjIdLengthOptData", 2);
      this.$store.dispatch("RefObj/setEmptyObjectIdType");
    }
  },

};
</script>
