<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>{{$t("headerDocDesc")}}:</h4>
      </div>
      <form action="" id="DocDescriptionDocVer">
        <div class="card-body">
          <div
            v-for="(descript, descIndex) in getDocumentDescription_model"
            :key="descript"
          >
            <div class="card-text border rounded mt-2 border-dark">
              <div class="row mb-3">
                <label for="doctitle" class="col-sm-3 col-form-label"
                  >{{$t("docVersionDocTitleInfoTitle")}}:*
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="docVersionDocTitleInfoTitle"
                      data-body="docVersionDocTitleInfoBody"
                      @click.prevent="showmodal"
                    ></i></a
                ></label>
                <div class="col-sm-9">
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="doctitle"
                    :value="getDocumentDescriptionTitle(descIndex)"
                    @input="setDocDesTitle(descIndex, $event)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  />
                </div>
              </div>
              <div class="row mb-3">
                <label for="subtitle" class="col-sm-3 col-form-label"
                  >{{$t("docVersionDocSubTitleInfoTitle")}}:
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="docVersionDocSubTitleInfoTitle"
                      data-body="docVersionDocSubTitleInfoBody"
                      @click.prevent="showmodal"
                    ></i></a
                ></label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    id="subtitle"
                    :value="getDocumentDescriptionSubTitle(descIndex)"
                    @input="setDocDesSubTitle(descIndex, $event)"
                    @keydown.enter.prevent
                  />
                </div>
              </div>
              <div class="row mb-3">
                <label for="summary" class="col-sm-3 col-form-label"
                  >{{$t("docVersionDocSummaryInfoTitle")}}:*
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="docVersionDocSummaryInfoTitle"
                      data-body="docVersionDocSummaryInfoBody"
                      @click.prevent="showmodal"
                    ></i></a
                ></label>
                <div class="col-sm-9">
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="summary"
                    :value="getDocumentDescriptionSummary(descIndex)"
                    @input="setDocDesSummary(descIndex, $event)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  />
                </div>
              </div>
              <div class="card border rounded mt-2 border-dark">
                <div class="card-body">
                  <div
                    v-for="(item, keyWindex) in getKeyWordsType_model(
                      descIndex
                    )"
                    :key="keyWindex"
                  >
                    <div class="row mb-3">
                      <label for="keyword" class="col-sm-3 col-form-label"
                        >{{$t("docVersionDocKeyWordInfoTitle")}}:*
                        <a href="#"
                          ><i
                            class="bi bi-info-circle"
                            data-toggle="modal"
                            data-target="#infoModal"
                            data-title="docVersionDocKeyWordInfoTitle"
                            data-body="docVersionDocKeyWordInfoBody"
                            @click.prevent="showmodal"
                          ></i></a
                      ></label>
                      <div class="col">
                        <input
                          required
                          type="text"
                          class="form-control"
                          id="keyword"
                          :value="getKeyWords(descIndex, keyWindex)"
                          @input="
                            setDocDesKeyWord(descIndex, keyWindex, $event)
                          "
                          @focusout="singleFault($event.target)"
                          @keydown.enter.prevent
                        />
                      </div>
                      <div class="col-sm-2 mt-2 mt-sm-0" v-if="keyWindex > 0">
                        <button
                          class="btn btn-outline-danger"
                          style="width: 3rem"
                          @click.prevent="removeKWord(descIndex, keyWindex)"
                        >
                          <i class="bi-x-circle" style="font-size: 1rem"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col sm-2">
                    <button
                      class="btn btn-outline-primary"
                      style="width: 5rem"
                      @click.prevent="addKeyWord(descIndex)"
                    >
                      <i class="bi-plus-circle" style="font-size: 1rem"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <label class="col-sm-3 col-form-label" for="doclanguage">
                  {{$t("docVersionDocLangInfoTitle")}}:*
                </label>
                <div class="col-sm-4">
                  <select
                    required
                    class="form-control"
                    id="doclanguage"
                    :disabled="descIndex < getPartyType4LanguagesArray.length"
                    :value="getDocumentDescriptionLanguage(descIndex)"
                    @input="setDocDesLang(descIndex, $event)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  >
                    <option
                      v-for="item in getLanguage()"
                      :key="item[0]"
                      :value="item[0]"
                    >
                      {{ item[0] }} - {{ item[1] }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-3">
                <div
                  class="col xxl-1 mt-3 mt-xxl-0"
                  v-if="descIndex >= getPartyType4LanguagesArray.length"
                >
                  <button
                    class="btn btn-outline-danger"
                    style="width: 5rem"
                    @click.prevent="removeDocDesType(descIndex)"
                  >
                    <i class="bi-x-circle" style="font-size: 1rem"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col gy-2">
              <button
                class="btn btn-outline-primary"
                style="width: 8rem"
                @click.prevent="addDocumentDescription"
              >
                <i class="bi-plus-circle" style="font-size: 1.5rem"></i>
              </button>
            </div>

            <div class="row mb-3">
              <div class="col-sm-9 align-left mt-2">
                <go-back-util />
              </div>
              <div class="col-sm-3 align-right mt-2">
                <button
                  class="btn btn-success bi-caret-right"
                  @click.prevent="pushToLifeCycle"
                >
                  {{$t("next Step")}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <modal-utils />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Fault from "../utils/Fault";
import LangCodes from "../utils/LangCodes";
import i18n from "@/i18n";
import ModalUtils from "../utils/ModalsUtils.vue";
import GoBackUtil from "../utils/GoBackUtil.vue";

export default {
  name: "DocDescriptionDocVerCom",
  components: {
    GoBackUtil,
    ModalUtils,
  },

  computed: {
    ...mapGetters("DocVer", [
      "getKeyWordsType_model",
      "getDocumentDescription_model",
      "getDocumentDescriptionLanguage",
      "getDocumentDescriptionSummary",
      "getDocumentDescriptionTitle",
      "getDocumentDescriptionSubTitle",
      "getKeyWords",
      "getPartyType4LanguagesArray",
    ]),
    ...mapGetters(["getIsGeneralDocu"]),
  },
  methods: {
    ...mapActions("DocVer", [
      "setDocumentDescriptionLanguage",
      "setDocumentDescriptionSummary",
      "setDocumentDescriptionTitle",
      "setDocumentDescriptionSubTitle",
      "setDocumentDescriptionKeyWords",
      "addDocumentDescription",
      "addKeyWord",
      "removeKeyWord",
      "removeDocumentDescription",
    ]),
    ...mapActions("ActSteps", ["setDocVersionLifeCycle"]),

    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },

    singleFault(ele) {
      return Fault.singleFault(ele);
    },
    getLanguage() {
      return LangCodes.getLang();
    },
    pushToLifeCycle() {
      if (Fault.faultControl(document)) {
        this.$store.dispatch("ActSteps/setDocVersionLifeCycle", true);
        this.$router.push("/metadata/docversion/lifecycle/");
      } else alert(i18n.global.t("faultMandatory"));
    },
    removeDocDesType(descIndex) {
      this.$store.dispatch("DocVer/removeDocumentDescription", descIndex);
    },
    removeKWord(descIndex, keyWindex) {
      let payload = {
        descIndex: descIndex,
        keyWindex: keyWindex,
      };
      this.$store.dispatch("DocVer/removeKeyWord", payload);
    },
    setDocDesLang(descIndex, event) {
      let payload = {
        descIndex: descIndex,
        Language: event.target.value,
      };
      this.$store.dispatch("DocVer/setDocumentDescriptionLanguage", payload);
    },
    setDocDesTitle(descIndex, event) {
      let payload = {
        descIndex: descIndex,
        Title: event.target.value,
      };
      this.$store.dispatch("DocVer/setDocumentDescriptionTitle", payload);
    },
    setDocDesSubTitle(descIndex, event) {
      let payload = {
        descIndex: descIndex,
        SubTitle: event.target.value,
      };
      this.$store.dispatch("DocVer/setDocumentDescriptionSubTitle", payload);
    },
    setDocDesSummary(descIndex, event) {
      let payload = {
        descIndex: descIndex,
        Summary: event.target.value,
      };
      this.$store.dispatch("DocVer/setDocumentDescriptionSummary", payload);
    },
    setDocDesKeyWord(descIndex, keyWindex, event) {
      let payload = {
        descIndex: descIndex,
        KeyWord: event.target.value,
        keyWindex: keyWindex,
      };
      this.$store.dispatch("DocVer/setDocumentDescriptionKeyWords", payload);
    },
  },
};
</script>
<style scoped>
.card-text {
  padding: 1em;
}
</style>
