<template>
  <div class="page-classification">
    <div class="row">
      <div class="col-lg-12 gy-3 text-center">
        <h1 :class="getIsGeneralDocu ? 'hGen' : 'hZVS' ">{{$t("step3")}}</h1>
      </div>
      <metadata-navigation-com></metadata-navigation-com>
    </div>
    <div class="row mt-3">
      <div class="col-lg-8" >
          <classification-data-com/>
      </div>
      <div class="col-lg-4">
          <file-selection-com/>       
      </div>
      
    </div>

  </div>
</template>

<script>
import MetadataNavigationCom from '../../components/MetadataNavigationCom.vue';
import FileSelectionCom from '../../components/filerelated/FileSelectionCom.vue'
import ClassificationDataCom from '../../components/classification/ClassificationDataCom.vue'
import { mapGetters } from 'vuex';

export default {
    name: "ClassificationView",
    components: {
        MetadataNavigationCom,
        ClassificationDataCom,
        FileSelectionCom,
    },
    computed: {
    ...mapGetters(["getIsGeneralDocu"])
  },
   mounted(){
//     const [empty, cat1, cat2, cat3] = new String(this.$route.path).split('/')
//     utag.view({
//     "page_name" : this.$route.path,
//     "page_language" : this.$i18n.locale,
//     "page_identifier" : "general_docu: " + this.getIsGeneralDocu,
//     "page_category" : cat1,                         
//     "page_subcategory" : cat2,
//     "page_sub_subcategory": cat3,
//     "page_type" : "",                                      
//     "page_country" : "global"

// });
  }

}
</script>
