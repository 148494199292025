<template>
  <span
    class="position-absolute top-0 translate-middle badge rounded-pill bg-success"
  >
    <i class="bi-check" style="font-size: 1rem"></i>
    <span class="visually-hidden">all fields filled</span>
  </span>
</template>

<script>
export default {
  name: "BadgeSuccessUtil",
};
</script>

<style scoped>
.badge {
  opacity: 90%;
}
</style>
