<template>
  <div>
    <nav>
      <router-link to="/metadata/docversion/documentversionid/" :class="{ rldisabled: !getDocVersionParty }">
        <span>
          {{$t("routeDocVerParty")}}
        </span></router-link
      >

      <router-link to="/metadata/docversion/documentdescription/" :class="{ rldisabled: !getDocVersionDocDesc }">
        <span>
          {{$t("routeDocDesc")}}
        </span></router-link
      >

      <router-link to="/metadata/docversion/lifecycle/" :class="{ rldisabled: !getDocVersionLifeCycle }">
        <span>
          {{$t("routeDocLifeCycle")}}
        </span></router-link
      >

      <!-- <router-link
              to="/metadata/docversion/relationship/"
              class="nav-link border rounded"
              >Document Relationship</router-link
            >

            <router-link
              to="/docversion/documentId/"
              class="nav-link border rounded"
              >Digital File</router-link
            > -->
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "SideMenuDocVersionCom",
  computed: {
    ...mapGetters("ActSteps", ["getDocVersionParty", "getDocVersionDocDesc", "getDocVersionLifeCycle"])
  },
};
</script>

<style scoped src="@/assets/process_steps.css"></style>
