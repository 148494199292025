<template>
  <div class="d-flex justify-content-center vertical-center">
    <!-- ATTENTION!!!! change for production -->
    <!-- href="https://test.vdi2770-editor.basf.com/api/v1/login/azuread-tenant-oauth2" -->
    <!--  -->
    <a
      
      :href="getBaseURL()"
      class="btn btn-outline-primary btn-lg"
      >Single Sign On BASF VDI2770-Editor</a
    >
    <!-- <button class="btn btn-outline-primary btn-lg" @click="getBaseURL">Single Sign On BASF VDI2770-Editor</button> -->
  </div>
</template>

<script>
// import {EventBus} from "../main";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SingleSO",
  mounted() {
    if (this.$route.query.tempaccess){
      this.routeLoaded()
    }
  },
  // watch: {
  //   $route() {
  //     console.log("Watch")
  //     if (!this.getIsAuthenticated) {
  //       this.$nextTick(this.routeLoaded);
  //     }
  //   },
  // },
  computed: {
    ...mapGetters(["getToken", "getUser"]),
  },
  methods: {
    ...mapActions(["setToken", "setUser"]),
    getBaseURL(){
      // return 'http://localhost:8000/api/v1/login/azuread-tenant-oauth2'
      return axios.defaults.baseURL + '/api/v1/login/azuread-tenant-oauth2'
    },
    async routeLoaded() {
      axios.defaults.headers.common["Authorization"] = "";
      axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
      axios.defaults.xsrfCookieName = "csrftoken";
      if (this.getToken == "" && this.$route.query.tempaccess) {
        await axios
          .post("api/v1/complete/authaccess/", {
            tempaccess: this.$route.query.tempaccess,
            user: this.$route.query.user,
          })
          .then((response) => {
            try {
              const token = response.data.token;
              this.$store.dispatch("setToken", token);
              axios.defaults.headers.common["Authorization"] =
                "Token " + this.getToken;
              this.$store.dispatch("setUser", response.data.user);
            } catch (error) {
              alert("Authentication failed");
            }
          })
          .catch((error) => {
            alert(error);
          });
        this.$router.push("/");
      }
    },
  },
};
</script>
<style scoped>
.vertical-center {
  margin: 0;
  position: absolute;
  top: 40%;
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
}
</style>
