export default {
  namespaced: true,
  state() {
    return {
      fileUpload: false,
      fileSelect: false,
      orgData: false,
      orgMetaData: false,
      orgDocDomain: false,
      classification: false,
      refObject: false,
      refObjectObjId: false,
      refObjOptData: false,
      refObjParty: false,
      refObjDescr: false,
      docVersion: false,
      docVersionParty: false,
      docVersionDocDesc: false,
      docVersionLifeCycle: false,
    };
  },

  getters: {
    getFileUpload(state) {
      return state.fileUpload;
    },
    getFileSelect(state) {
      return state.fileSelect;
    },
    getOrgData(state) {
      return state.orgData;
    },
    getOrgMetaData(state) {
      return state.orgMetaData;
    },
    getOrgDocDomain(state) {
      return state.orgDocDomain;
    },
    getClassification(state) {
      return state.classification;
    },
    getRefObject(state) {
      return state.refObject;
    },
    getRefObjObjId(state) {
      return state.refObjectObjId;
    },
    getRefObjOptData(state) {
      return state.refObjOptData;
    },
    getRefObjParty(state) {
      return state.refObjParty;
    },
    getRefObjDesc(state) {
      return state.refObjDescr;
    },
    getDocVersion(state) {
      return state.docVersion;
    },
    getDocVersionParty(state) {
      return state.docVersionParty;
    },
    getDocVersionDocDesc(state) {
      return state.getDocVersionDocDesc;
    },
    getDocVersionLifeCycle(state) {
      return state.docVersionLifeCycle;
    },
  },

  mutations: {
    setFileUpload(state, payload) {
      state.fileUpload = payload;
    },
    setFileSelect(state, payload) {
      state.fileSelect = payload;
    },
    setOrgData(state, payload) {
      state.orgData = payload;
    },
    setOrgMetaData(state, payload) {
      state.orgMetaData = payload;
    },
    setOrgDocDomain(state, payload) {
      state.orgDocDomain = payload;
    },
    setClassification(state, payload) {
      state.classification = payload;
    },
    setRefObject(state, payload) {
      state.refObject = payload;
    },
    setRefObjObjId(state, payload) {
      state.refObjectObjId = payload;
    },
    setRefObjOptData(state, payload) {
      state.refObjOptData = payload;
    },
    setRefObjParty(state, payload) {
      state.refObjParty = payload;
    },
    setRefObjDesc(state, payload) {
      state.refObjDescr = payload;
    },
    setDocVersion(state, payload) {
      state.docVersion = payload;
    },
    setDocVersionParty(state, payload) {
      state.docVersionParty = payload;
    },
    setDocVersionDocDesc(state, payload) {
      state.getDocVersionDocDesc = payload;
    },
    setDocVersionLifeCycle(state, payload) {
      state.docVersionLifeCycle = payload;
    },
  },

  actions: {
    setFileUpload(context, payload) {
        context.commit("setFileUpload", payload);
      },
      setFileSelect(context, payload) {
        context.commit("setFileSelect", payload);
      },
      setOrgData(context, payload) {
        context.commit("setOrgData", payload);
      },
      setOrgMetaData(context, payload) {
        context.commit("setOrgMetaData", payload);
      },
      setOrgDocDomain(context, payload) {
        context.commit("setOrgDocDomain", payload);
      },
      setClassification(context, payload) {
        context.commit("setClassification", payload);
      },
      setRefObject(context, payload) {
        context.commit("setRefObject", payload);
      },
      setRefObjObjId(context, payload) {
        context.commit("setRefObjObjId", payload);
      },
      setRefObjOptData(context, payload) {
        context.commit("setRefObjOptData", payload);
      },
      setRefObjParty(context, payload) {
        context.commit("setRefObjParty", payload);
      },
      setRefObjDesc(context, payload) {
        context.commit("setRefObjDesc", payload);
      },
      setDocVersion(context, payload) {
        context.commit("setDocVersion", payload);
      },
      setDocVersionParty(context, payload) {
        context.commit("setDocVersionParty", payload);
      },
      setDocVersionDocDesc(context, payload) {
        context.commit("setDocVersionDocDesc", payload);
      },
      setDocVersionLifeCycle(context, payload) {
        context.commit("setDocVersionLifeCycle", payload);
      },
  },
};
