<template>
  <div>
    <div class="card">
      <h3 class="card-header">
        {{ $t("headerClassData") }}:
        <a href="#"
          ><i
            class="bi bi-info-circle"
            data-toggle="modal"
            data-target="#infoModal"
            data-title="classSysHeaderInfoTitle"
            :data-body="
              getIsGeneralDocu
                ? 'classSysGeneralHeaderInfoBody'
                : 'classSysHeaderInfoBody'
            "
            @click.prevent="showmodal"
          ></i
        ></a>
      </h3>
      <form id="formClassification" action="" @submit.prevent>
        <div class="card-body">
          <div
            v-for="(classSystem, docindex) in getDocumentClassification_model"
            :key="docindex"
          >
            <div class="card-text border rounded mt-2 border-dark">
              <div class="row mb-3">
                <label
                  name="ClassificationSystemLabel"
                  for="ClassificationSystem"
                  class="col-lg-3 col-form-label"
                  >{{ $t("ClassificationSystem") }}:*
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="classSysInfoTitle"
                      data-body="classSysInfoBody"
                      @click.prevent="showmodal"
                    ></i></a
                ></label>
                <div class="col-lg-9">
                  <input
                    required
                    list="datalistClassification"
                    class="form-select"
                    :id="'ClassificationSystem' + docindex"
                    :disabled="getDisabled(docindex)"
                    :value="
                      getDocumentClassificationClassificationSystem(docindex)
                    "
                    @input.prevent="
                      setClassification(docindex, $event.target.value)
                    "
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  />
                  <!-- Value Fixed to BIG Specifikation -->
                  <datalist id="datalistClassification">
                    <option
                      v-for="(item, index) in classificationProposals"
                      :key="index"
                    >
                      {{ item.ClassificationSystem }}
                    </option>
                  </datalist>
                </div>
              </div>
              <div class="row mb-3">
                <label for="ClassId" class="col-lg-3 col-form-label"
                  >{{ $t("classIdInfoTitle") }}:*
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="classIdInfoTitle"
                      data-body="classIdInfoBody"
                      @click.prevent="showmodal"
                    ></i></a
                ></label>

                <div
                  class="col-lg-9"
                  v-if="
                    getDocumentClassificationClassificationSystem(docindex) ==
                      'BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types' ||
                    getDocumentClassificationClassificationSystem(docindex) ==
                      'VDI2770:2018' ||
                    getDocumentClassificationClassificationSystem(docindex) ==
                      'Documentation Checklist CIM Database structure'
                  "
                >
                  <select
                    required
                    list="datalistClassId"
                    class="form-select"
                    :id="'ClassId' + docindex"
                    :disabled="getDisableClassId(docindex)"
                    placeholder="{{$t('search')}}"
                    :value="getDocumentClassificationClassId(docindex)"
                    @input.prevent="setClassId(docindex, $event.target.value)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  >
                    <!-- classIdProposals[docindex]" -->
                    <option
                      v-for="item in getClassNamePerLanguage(docindex)"
                      :key="item"
                      :value="item.ClassId"
                    >
                      {{ item.ClassId }} - {{ item.classname }}
                    </option>
                  </select>
                </div>
                <!-- <div class="col-lg-9" v-if="docindex > 0"> -->
                <!-- <div
                  class="col-lg-9"
                  v-if="
                    getDocumentClassificationClassificationSystem(docindex) !=
                      'BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types' &&
                    getDocumentClassificationClassificationSystem(docindex) !=
                      'VDI2770:2018'
                  "
                > -->
                <div class="col-lg-9" v-else>
                  <input
                    required
                    list="datalistClassId"
                    class="form-select"
                    :id="'ClassId' + docindex"
                    :disabled="getDisableClassId(docindex)"
                    placeholder="Type to search..."
                    :value="getDocumentClassificationClassId(docindex)"
                    @input.prevent="setClassId(docindex, $event.target.value)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  />
                  <datalist id="datalistClassId">
                    <option value="" selected></option>
                    <!-- classIdProposals[docindex]"  -->
                    <option
                      v-for="item in getClassNamePerLanguage(docindex)"
                      :key="item"
                      :value="item.ClassId"
                    >
                      {{ item.ClassId }} - {{ item.classname }}
                    </option>
                  </datalist>
                  <!-- </select> -->
                </div>
                <!-- <div class="col-lg-9" v-else> -->
              </div>

              <div class="card">
                <div class="card-body">
                  <div
                    class="row mb-3"
                    v-for="(item, classindex) in getClassNameType_model(
                      docindex
                    )"
                    :key="item"
                  >
                    <label for="ClassName" class="col-xxl-3 col-form-label"
                      >{{ $t("classNameInfoTitle") }}:*
                      <a href="#"
                        ><i
                          class="bi bi-info-circle"
                          data-toggle="modal"
                          data-target="#infoModal"
                          data-title="classNameInfoTitle"
                          data-body="classNameInfoBody"
                          @click.prevent="showmodal"
                        ></i></a
                    ></label>
                    <!-- Start here -->
                    <div
                      class="col-xxl-4"
                      v-if="
                        getDocumentClassificationClassificationSystem(
                          docindex
                        ) ==
                          'BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types' ||
                        getDocumentClassificationClassificationSystem(
                          docindex
                        ) == 'VDI2770:2018' ||
                        getDocumentClassificationClassificationSystem(
                          docindex
                        ) == 'Documentation Checklist CIM Database structure'
                      "
                    >
                      <select
                        required
                        list="classNameList"
                        class="form-select"
                        id="ClassName"
                        placeholder="{{$t('search')}}"
                        :disabled="getDisabled(docindex)"
                        :value="getClassNameTypeClassname(docindex, classindex)"
                        @input.prevent="
                          setClassName(
                            docindex,
                            classindex,
                            $event.target.value
                          )
                        "
                        @focusout="singleFault($event.target)"
                        @keydown.enter.prevent
                      >
                        <!-- changed (docindex)-->
                        <option
                          v-for="item in classNameIdProposals[docindex]"
                          :key="item"
                          :value="item.ClassName"
                        >
                          {{ item.ClassName }}
                        </option>
                      </select>
                    </div>

                    <div class="col-xxl-4" v-else>
                      <input
                        required
                        list="classNameList"
                        class="form-select"
                        id="ClassName"
                        placeholder="Type to search..."
                        :disabled="getDisabled(docindex)"
                        :value="getClassNameTypeClassname(docindex, classindex)"
                        @input.prevent="
                          setClassName(
                            docindex,
                            classindex,
                            $event.target.value
                          )
                        "
                        @focusout="singleFault($event.target)"
                        @keydown.enter.prevent
                      />

                      <datalist id="classNameList">
                        <!-- changed (docindex)-->
                        <option
                          v-for="item in classNameIdProposals[docindex]"
                          :key="item"
                          :value="item.ClassName"
                        >
                          {{ item.ClassName }}
                        </option>
                      </datalist>
                    </div>

                    <label for="ClassLang" class="col-xxl-2 col-form-label"
                      >{{ $t("classLangInfoTitle") }}:*
                      <a href="#"
                        ><i
                          class="bi bi-info-circle"
                          data-toggle="modal"
                          data-target="#infoModal"
                          data-title="classLangInfoTitle"
                          data-body="classLangInfoBody"
                          @click.prevent="showmodal"
                        ></i></a
                    ></label>
                    <div class="col-xxl-3">
                      <select
                        required
                        class="form-select"
                        id="ClassLang"
                        :disabled="getDisabled(docindex)"
                        :value="getClassNameTypeLanguage(docindex, classindex)"
                        @input.prevent="
                          setClassLanguage(classindex, docindex, $event)
                        "
                        @focusout="singleFault($event.target)"
                        @keydown.enter.prevent
                      >
                        <option
                          v-for="item in getLanguage()"
                          :key="item[0]"
                          :value="item[0]"
                        >
                          {{ item[0] }} - {{ item[1] }}
                        </option>
                      </select>
                    </div>

                    <div
                      class="col xxl-1 mt-3 mt-xxl-0"
                      v-if="classindex >= 1 && docindex > 1"
                    >
                      <button
                        class="btn btn-outline-danger"
                        style="width: 3rem"
                        @click.prevent="
                          removeClassNameType(docindex, classindex)
                        "
                      >
                        <i class="bi-x-circle" style="font-size: 1rem"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col xxl-1 mt-3 mt-xxl-0" v-if="docindex >= 2">
                    <button
                      class="btn btn-outline-primary"
                      style="width: 3rem"
                      @click.prevent="addClassNameType(docindex)"
                    >
                      <i class="bi-plus-circle" style="font-size: 1rem"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8 gy-2">
              <button
                class="btn btn-outline-primary"
                style="width: 6.5rem"
                @click.prevent="addClassSystem"
              >
                <i class="bi-plus-circle" style="font-size: 1.5rem"></i>
              </button>
            </div>
            <div
              class="col-lg-4 gy-2"
              v-if="
                getDocumentClassification_model.length > 3 ||
                (!getIsTpuDocumentation &&
                  getDocumentClassification_model.length > 2) ||
                (getIsGeneralDocu && getDocumentClassification_model.length > 1)
              "
            >
              <button
                class="btn btn-outline-danger"
                style="width: 4rem"
                @click.prevent="removeClassSystem"
              >
                <i class="bi-x-circle" style="font-size: 1.5rem"></i>
              </button>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-8 align-left mt-3">
              <go-back-util />
            </div>
            <div class="col-sm-4 align-right mt-3">
              <button
                class="btn btn-success bi-caret-right"
                @click.prevent="pushToRefObject"
              >
                <!--   -->
                {{ $t("next Step") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <modal-utils />
  </div>
</template>

<script>
// $(function () {
//   $('[data-toggle="tooltip"]').tooltip();
// });

import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import GoBackUtil from "../utils/GoBackUtil.vue";
import Fault from "../utils/Fault";
import LangCodes from "../utils/LangCodes";
import i18n from "@/i18n";
import ModalUtils from "../utils/ModalsUtils.vue";

export default {
  name: "ClassificationDataCom",
  data() {
    return {
      fault: "",
      classificationProposals: [
        {
          id: "",
          ClassificationSystem:
            "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types",
        },
      ],
      //@Changed zu Liste von Listen geändert
      classIdProposals: [
        [
          {
            id: "",
            ClassificationSystem: "",
            ClassId: "",
            // @ Test
            classname: "",
            language: "",
          },
        ],
      ],
      // changed []
      classNameIdProposals: [
        [
          {
            id: "",
            ClassId: "",
            language: "",
            ClassName: "",
          },
        ],
      ],
      // theClassLang: ""
      relatedClassIdObject: [
        {
          id: "",
          fromClassId_id: "",
          toClassId_id: "",
          ClassId: "",
        },
      ],
    };
  },
  components: {
    GoBackUtil,
    ModalUtils,
  },
  computed: {
    ...mapGetters("Classification", [
      "getClassNameType_model",
      "getClassNameTypeLanguage",
      "getClassNameTypeClassname",
      "getDocumentClassification_model",
      "getDocumentClassificationClassificationSystem",
      "getDocumentClassificationClassId",
    ]),
    ...mapGetters([
      "getNextRound",
      "getIsTpuDocumentation",
      "getIsGeneralDocu",
    ]),
    ...mapGetters("DocId", ["getDocumentId"]),
    getComputedLanguage() {
      return this.theClassLang;
    },
  },
  methods: {
    ...mapActions("Classification", [
      "setClassNameType",
      "setClassNameTypeLanguage",
      "setClassNameTypeClassname",
      "setDocumentClassification",
      "setDocumentClassificationClassificationSystem",
      "setDocumentClassificationClassId",
      "setDocumentClassificationClassNameId",
      "addDocumentClassification",
      "addClassNameType",
      "removeDocumentClassification",
      "removeClassNameType",
    ]),
    ...mapActions("ActSteps", [
      "setRefObject",
      "setRefObjObjId",
      "setRefObjOptData",
      "setRefObjParty",
      "setRefObjDesc",
      "setDocVersion",
      "setDocVersionParty",
      "setClassification"
    ]),
    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button triggering the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },
    singleFault(ele) {
      return Fault.singleFault(ele);
    },
    getLanguage() {
      return LangCodes.getLang();
    },

    onFocusClassId(index) {
      let classsystem =
        this.getDocumentClassificationClassificationSystem(index);
      this.getClassIds(classsystem, index);
    },
    onFocusClassName(docindex) {
      let classid = this.getDocumentClassificationClassId(docindex);
      this.getClassNames(classid, docindex);
    },
    getClassNamePerLanguage(docindex) {
      if (this.classIdProposals[docindex]) {
        var classNamePerLanguage = this.classIdProposals[docindex].filter(
          (a) => a.language == this.$i18n.locale.substring(0, 2)
        );
        if (!classNamePerLanguage || classNamePerLanguage.length == 0) {
          classNamePerLanguage = this.classIdProposals[docindex];
        }
        return classNamePerLanguage;
      } else return [];
    },

    async addClassMappings(docindex) {
      if (
        this.getDocumentClassification_model.length < 2 &&
        !this.getIsGeneralDocu
      ) {
        this.addDocumentClassification();
      }
      if (!this.getIsGeneralDocu) {
        let payloadClassSystem = {
          index: 1,
          ClassificationSystem: "VDI2770:2018",
        };
        this.setDocumentClassificationClassificationSystem(payloadClassSystem);
        this.setClassId(1, this.relatedClassIdObject[0].ClassId);
      }
      if (
        this.getIsTpuDocumentation &&
        this.getDocumentClassification_model.length < 3 &&
        !this.getIsGeneralDocu
      ) {
        await this.addDocumentClassification();
        var newIndex = 2;
        let payloadClassSystem = {
          index: newIndex,
          ClassificationSystem:
            "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types",
        };
        await this.setDocumentClassificationClassificationSystem(payloadClassSystem);
        this.classificationProposals[newIndex].ClassificationSystem =
          this.getDocumentClassificationClassificationSystem(newIndex);
        await this.setClassification(
          newIndex,
          this.getDocumentClassificationClassificationSystem(newIndex)
          // "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types"
        );
        await this.setClassIdProposal(newIndex);
      }
    },

    getDisabled(index) {
      if (
        index == 0 ||
        (index == 1 && !this.getIsGeneralDocu) ||
        (this.getIsTpuDocumentation && index == 2)
      ) {
        return true;
      } else if (index + 1 < this.getDocumentClassification_model.length) {
        return true;
      } else return false;
    },
    getDisableClassId(index) {
      if (index === 0 && !this.getIsGeneralDocu) {
        //&& this.getDocumentClassification_model.length < 3) {
        return false;
      }
      if (index === 1 && !this.getIsGeneralDocu) {
        return true;
      } else if (index + 1 < this.getDocumentClassification_model.length) {
        return true;
      } else return false;
    },

    pushToRefObject() {
      if (Fault.faultControl(document)) {
        this.setClassification(
          0,
          this.getDocumentClassificationClassificationSystem(0)
          // "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types"
        );
        if (!this.getNextRound) {
          this.$router.push("/metadata/refobject/objectid");
        } else {
          this.$store.dispatch("ActSteps/setRefObject", true);
          this.$store.dispatch("ActSteps/setRefObjOptData", true);
          this.$store.dispatch("ActSteps/setRefObjParty", true);
          this.$store.dispatch("ActSteps/setRefObjDesc", true);
          this.$store.dispatch("ActSteps/setDocVersionParty", true);
          this.$store.dispatch("ActSteps/setDocVersion", true);
          this.$router.push("/metadata/docversion/documentversionid/");
        }
        this.$store.dispatch("ActSteps/setRefObject", true);
        this.$store.dispatch("ActSteps/setRefObjObjId", true);
      } else alert(i18n.global.t("faultMandatory"));
    },

    async getClassIds(ClassSystem, index) {
      if (
        this.classificationProposals.find(
          (c) => c.ClassificationSystem === ClassSystem
        )
      ) {
        await axios
          .get("api/v1/classid/?ClassificationSystem=" + ClassSystem)
          .then((resp) => {
            this.classIdProposals[index] = resp.data;
          })
          .catch((error) => {
            alert(error);
          });
      } else {
        this.classIdProposals[index] = [
          {
            id: "",
            ClassificationSystem: "",
            ClassId: "",
            classname: "",
            language: "",
          },
        ];
      }
    },

    async getClassNames(ClassId, index) {
      try {
        let relatedclassNameId = this.classIdProposals[index].find(
          (a) => a.ClassId == ClassId
        ).id;
        await axios
          .get("api/v1/classname/?classnameId=" + relatedclassNameId)
          .then((resp) => {
            // changed [index]
            this.classNameIdProposals[index] = resp.data;
          })
          .catch((error) => {
            alert(error);
          });
      } catch (error) {
        // changed [index]
        this.classNameIdProposals[index] = [
          {
            id: "",
            ClassId: "",
            language: "",
            ClassName: "",
            language: "",
          },
        ];
      }
    },

    async getClassMapping(ClassId, index) {
      try {
        let relatedclassId = this.classIdProposals[index].find(
          (a) => a.ClassId == ClassId
        ).id;
        await axios
          .get("api/v1/classmapping/?fromClassId=" + relatedclassId)
          .then((resp) => {
            this.relatedClassIdObject = resp.data;
            this.addClassMappings(index);
          })
          .catch((error) => {
            alert(error);
          });
      } catch (error) {
        this.relatedClassIdObject = [
          {
            id: "",
            fromClassId_id: "",
            toClassId_id: "",
            ClassId: "",
          },
        ];
        this.addClassMappings(index);
      }
    },

    getLanguages(docindex, classindex) {
      var defaultObj = { language: "" };
      // changed [docindex]
      defaultObj = this.classNameIdProposals[docindex].find(
        (a) =>
          a.ClassName == this.getClassNameTypeClassname(docindex, classindex)
      ) || { language: "" };
      return defaultObj;
    },

    addClassSystem() {
      this.$store.dispatch("Classification/addDocumentClassification");
    },
    removeClassSystem() {
      this.$store.dispatch("Classification/removeDocumentClassification");
    },
    addClassNameType(docindex) {
      this.$store.dispatch("Classification/addClassNameType", docindex);
    },
    removeClassNameType(docindex, classindex) {
      const payload = {
        docindex: docindex,
        classindex: classindex,
      };
      this.$store.dispatch("Classification/removeClassNameType", payload);
    },
    async setClassification(index, event) {
      if (index >= 0) {
        let payload = {
          index: index,
          ClassificationSystem: event,
        };
        this.$store.dispatch(
          "Classification/setDocumentClassificationClassificationSystem",
          payload
        );
        await this.getClassIds(event, index);
      }
    },
    async setClassId(index, e) {
      if (index >= 0) {
        let payload = {
          index: index,
          ClassId: e,
        };
        this.$store.dispatch(
          "Classification/setDocumentClassificationClassId",
          payload
        );
        await this.getClassNames(e, index);
        if (true) {
          if (index === 0 || (index === 2 && this.getIsTpuDocumentation)) {
            let theClassName = this.classNameIdProposals[index].find(
              (a) => a.language == "en"
            );
            this.setClassName(index, 0, theClassName.ClassName);
            if (index === 0 && !this.getIsGeneralDocu) {
              this.getClassMapping(e, index);
            }
          }
          (async () => {
            if (
              (index === 1 && !this.getIsGeneralDocu) ||
              (index == 0 && this.getIsGeneralDocu)
            ) {
              if (index === 1) {
                await this.getClassSystems();
                await this.getClassIds("VDI2770:2018", index);
                await this.getClassNames(e, index);
              }
              for (
                let i = 0;
                i < this.classNameIdProposals[index].length;
                i++
              ) {
                if (
                  this.getClassNameType_model(index).length <
                  this.classNameIdProposals[index].length
                ) {
                  this.addClassNameType(index);
                }
                this.setClassName(
                  index,
                  i,
                  this.classNameIdProposals[index][i].ClassName
                );
              }
            }
          })();
        }
      }
    },
    setClassName(docindex, classindex, value) {
      const payload = {
        docindex: docindex,
        classindex: classindex,
        classname: value,
      };
      this.$store.dispatch("Classification/setClassNameTypeClassname", payload);
      const classpayload = {
        docindex: docindex,
        classindex: classindex,
        language: this.getLanguages(docindex, classindex).language,
      };
      this.$store.dispatch(
        "Classification/setClassNameTypeLanguage",
        classpayload
      );
    },
    setClassLanguage(classindex, docindex, event) {
      const payload = {
        docindex: docindex,
        classindex: classindex,
        language: event.target.value,
      };
      this.$store.dispatch("Classification/setClassNameTypeLanguage", payload);
    },

    async getClassSystems() {
      await axios
        .get("api/v1/ClassificationSystem/")
        .then((resp) => {
          this.classificationProposals = resp.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    async setClassIdProposal(index) {
      let value = this.getDocumentId(0);
      const pdfVersionRegex =
        /(BIG_)(\w){2}(-)\D{1}\d{3}(-)\D{1}\d{2}(-VD)(-)\D{1}\d{3}/;
      const match = value.match(pdfVersionRegex);
      try {
        if (match) {
          var lastIndex = match[0].lastIndexOf("-");
          await this.setClassId(index, match[0].substring(lastIndex + 1));
        }
      } catch (error) {
        let payload = {
          index: index,
          ClassId: undefined,
        };
        this.$store.dispatch(
          "Classification/setDocumentClassificationClassId",
          payload
        );
        alert(
          i18n.global.t("faultInvalidClassId", [
            match[0].substring(lastIndex + 1),
          ])
        );
        this.$store.dispatch("ActSteps/setClassification", false);
        this.$router.push("/metadata/defaultsettings/documentId/")
      }
    },
  },

  async created() {
    await this.getClassSystems();
    if (this.getIsTpuDocumentation) {
      this.classificationProposals[0].ClassificationSystem =
        "Documentation Checklist CIM Database structure";
      // this.getDocumentClassificationClassificationSystem(0);
      this.setClassification(
        0,
        // this.getDocumentClassificationClassificationSystem(0)
        "Documentation Checklist CIM Database structure"
      );
    } else if (
      this.getDocumentClassificationClassificationSystem(0) == undefined &&
      !this.getIsGeneralDocu
    ) {
      this.classificationProposals[0].ClassificationSystem =
        "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types";
      // this.getDocumentClassificationClassificationSystem(0);
      await this.setClassification(
        0,
        // this.getDocumentClassificationClassificationSystem(0)
        "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types"
      );
      // unfortunately wwe have to do it twice because hooks are not called or data not available
      this.setClassIdProposal(0);
    } else {
      this.classificationProposals[0].ClassificationSystem = "VDI2770:2018";
      // this.getDocumentClassificationClassificationSystem(0);
      this.setClassification(
        0,
        // this.getDocumentClassificationClassificationSystem(0)
        "VDI2770:2018"
      );
    }
  },
  async activated() {
    if (
      !this.getIsTpuDocumentation &&
      !this.getIsGeneralDocu &&
      this.getDocumentClassificationClassificationSystem(0) != undefined &&
      (this.getDocumentClassificationClassId(0) == undefined ||
        this.getDocumentClassificationClassId(0) == "")
    ) {
      this.setClassIdProposal(0);
    }
  },
};
</script>

<style scoped>
.card-text {
  padding: 1em;
}
</style>
