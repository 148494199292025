export default {
  namespaced: true,
  state() {
    return {
      viewerFiles: [
          {
            uniqueId: 1,
            loadedFile: "No loaded",
            fileType: "ohne",
            xmlBlobData: undefined,
             numPages: "500",
             size: 26500
          },
      ],
      showListOfPdfs: true,
      selectedFileId: undefined,
      expertMode: false,
    };
  },
  getters: {
    getExpertMode(state){
      return state.expertMode
    },
    getSelectedFileId(state){
      return state.selectedFileId
    },
    getFileByUniqueID: (state) => (uniqueId) => {
      try {
        let fileObj = state.viewerFiles.find(
          (item) => item.uniqueId == uniqueId
        );
        return fileObj.loadedFile;
      } catch (error) {
        return undefined;
      }
    },
    getXmlBlobByUniqueID: (state) => (uniqueId) => {
      try {
        let fileObj = state.viewerFiles.find(
          (item) => item.uniqueId == uniqueId
        );
        return fileObj.xmlBlobData;
      } catch (error) {
        return undefined;
      }
    },
    getViewerFileObjByUniqueID: (state) => (uniqueId) => {
      try {
        return state.viewerFiles.find((item) => item.uniqueId == uniqueId);
      } catch (error) {
        return undefined;
      }
    },
    getViewerFileObjsByType: (state) => (fileType) => {
      try {
        return state.viewerFiles.some((item) => item.fileType == fileType);
      } catch (error) {
        return undefined;
      }
    },
    getViewerFilesArray(state) {
      return state.viewerFiles;
    },
    getShowListOfPdfs(state){
      return state.showListOfPdfs
    }
  },

  mutations: {
    setExpertMode(state){
      state.expertMode = !state.expertMode
    },
    setSelectedFileId(state, payload){
      state.selectedFileId = payload
    },
    addViewerFileObj(state, payload) {
      state.viewerFiles.push({
        uniqueId: payload.uniqueId,
        loadedFile: payload.loadedFile,
        fileType: payload.fileType,
        xmlBlobData: payload.xmlBlobData,
        numPages: payload.numPages,
        size: payload.size,
      });
    },
    deleteViewerFiles(state) {
      state.viewerFiles = [];
    },
    toggleShowListOfPdfsState(state){
      state.showListOfPdfs = !state.showListOfPdfs
    },
    setShowListOfPdfsState(state, payload){
      state.showListOfPdfs = payload
    },

  },
  actions: {
    setExpertMode(context){
      context.commit("setExpertMode")
    },
    setSelectedFileId(context, payload){
      context.commit("setSelectedFileId", payload)
    },
    addViewerFileObj(context, payload) {
      context.commit("addViewerFileObj", payload);
    },
    deleteViewerFiles(context) {
      context.commit("deleteViewerFiles");
    },
    toggleShowListOfPdfsState(context){
      context.commit("toggleShowListOfPdfsState")
    },
    setShowListOfPdfsState(context, payload){
      context.commit("setShowListOfPdfsState", payload)
    }
  },
};
