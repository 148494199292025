import store from "..";

export default {
  namespaced: true,
  state() {
    return {
      PartyType_model: {
        GroupId: "",
        OrganizationId: undefined,
        OrganizationName: undefined,
        OrganizationOfficialName: undefined,
        Responsible: "Responsible",
        UserId: "",
        id: undefined,
      },
      userChooseTemplate: 0,
    };
  },
  mutations: {
    clearID(state){
      state.PartyType_model.id = undefined
    },
    setPartyType(state, DataObject) {
      state.PartyType_model = DataObject;
    },
    setOrganizationId(state, OrgId) {
      state.PartyType_model.OrganizationId = OrgId;
    },
    setOrganizationName(state, payload) {
      state.PartyType_model.OrganizationName = payload;
    },
    setOrganizationOfficialName(state, payload) {
      state.PartyType_model.OrganizationOfficialName = payload;
    },
    setResponsible(state, payload) {
      state.PartyType_model.Responsible = payload;
    },
    setUserChooseTemplate(state, action) {
      state.userChooseTemplate = action;
    },
  },
  getters: {
    getPartyType_model(state) {
      return state.PartyType_model;
    },
    getOrganizationId(state) {
      return state.PartyType_model.OrganizationId;
    },
    getResponsible(state) {
      return state.PartyType_model.Responsible;
    },
    getOrganizationName(state) {
      return state.PartyType_model.OrganizationName;
    },
    getOrganizationOfficialName(state) {
      return state.PartyType_model.OrganizationOfficialName;
    },
    getUserChooseTemplate(state) {
      return state.userChooseTemplate;
    },
    getPartyTempId(state) {
      return state.PartyType_model.id;
    },
  },
  actions: {
    clearID(context){
      context.commit("clearID")
    },
    setPartyType(context, payload) {
      context.commit("setPartyType", payload);
    },
    setOrganizationId(context, payload) {
      context.commit("setOrganizationId", payload);
    },
    setOrganizationName(context, payload) {
      context.commit("setOrganizationName", payload);
    },
    setOrganizationOfficialName(context, payload) {
      context.commit("setOrganizationOfficialName", payload);
    },
    setResponsible(context, payload) {
      context.commit("setResponsible", payload);
    },
    setUserChooseTemplate(context, payload) {
      context.commit("setUserChooseTemplate", payload);
    },
  },
};
