<template>
  <div class="page-file-select">
    <div class="row">
      <div class="col-lg-12 gy-3 text-center">
        <h1 :class="getIsGeneralDocu ? 'hGen' : 'hZVS'">
          {{ $t("Step 1: Files") }}
        </h1>
      </div>
      <metadata-navigation-com></metadata-navigation-com>
    </div>
    <div class="row mb-5">
      <side-menu-files-com />
    </div>
    <div class="row mt-3">
      <div
        class="col-lg-12"
        v-if="$route.path == '/metadata/files' && getIsContainer"
      >
        <div class="card">
          <div class="card-header">
            <h5>
              {{ $t("cardHeaderFileView") }}
              <!-- <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="thisTitle"
                  data-body="thisBody"
                  @click.prevent="showmodal"
                ></i
              ></a> -->
            </h5>
          </div>
          <div class="card-body">
            <div
              class="text-center d-flex align-items-center justify-content-around"
            >
              <!-- "getTypeStatus()" -->
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="model"
                  name="typeselection1"
                  :checked="getTypeStatus()"
                  @click="setInstance(false)"
                  @keydown.enter.prevent
                />
                <label class="form-check-label" for="model"
                  >{{ $t("model") }}
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="model"
                      data-body="modelInfoBody"
                      @click.prevent="showmodal"
                    ></i
                  ></a>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="instance"
                  name="typeselection2"
                  :checked="getIsInstance"
                  @click="setInstance(true)"
                  @keydown.enter.prevent
                />
                <label class="form-check-label" for="instance"
                  >{{ $t("instance") }}
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="instance"
                      data-body="instanceInfoBody"
                      @click.prevent="showmodal"
                    ></i
                  ></a>
                </label>
              </div>
            </div>
          </div>
          <div v-if="!getIsGeneralDocu">
            <div class="card-header">
              <h5>
                {{ $t("TPU-question") }}
              </h5>
            </div>
            <div class="card-body">
              <div
                class="text-center d-flex align-items-center justify-content-around"
              >
                <div
                  class="form-check form-switch ms-2"
                  id="flexSwitchCheckTPUSwitch"
                >
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckTPU"
                    id="flexSwitchCheckTPUInput"
                    >{{ $t("TPU-Plant") }}
                    <a href="#"
                      ><i
                        class="bi bi-info-circle"
                        data-toggle="modal"
                        data-target="#infoModal"
                        data-title="TPU-Plant"
                        data-body="TPUInfoBody"
                        @click.prevent="showmodal"
                      ></i></a
                  ></label>

                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckTPU"
                    :checked="getIsTpuDocumentation"
                    @click="activateTpuDocumentation"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="getIsInstance !== undefined">
            <div class="card-header">
              <h5
                v-if="
                  getDocumentationName == '' ||
                  getDocumentationName == undefined
                "
              >
                {{ $t("documentationNameHeader") }}
              </h5>
              <h4 v-else></h4>
            </div>
            <div class="card-body">
              <form action="" id="documentationNameForm">
                <div class="row mb-3">
                  <label
                    for="documentationName"
                    class="col-lg-3 col-form-label"
                  >
                    {{ $t("documentationName") }}
                    <a href="#"
                      ><i
                        class="bi bi-info-circle"
                        data-toggle="modal"
                        data-target="#infoModal"
                        data-title="documentationName"
                        data-body="documentationNameHelpBody"
                        @click.prevent="showmodal"
                      ></i
                    ></a>
                  </label>
                  <!-- :value="getDocumentationName" -->
                  <div class="col-lg-9">
                    <input
                      required
                      type="text"
                      class="form-control"
                      id="documentationName"
                      :value="getDocumentationName"
                      @change="setDocumentationName($event.target.value)"
                      @focusout="checkDocumentationName($event.target)"
                      @keydown.enter.prevent
                    />
                  </div>
                </div>
                <!-- <div class="row mb-3" v-if="getDocumentationName == undefined"> -->
                <div class="row mb-3" v-if="getIsContainer">
                  <div class="col-sm-8 align-left mt-2">
                    <go-back-util />
                  </div>
                  <div class="col-sm-4 align-right mt-2">
                    <button
                      class="btn btn-success bi-caret-right"
                      @click.prevent="setDocName"
                    >
                      {{ $t("next Step") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
        <div
          class="row mb-3"
          v-if="$route.path == '/metadata/files' && !getIsContainer"
        >
          <div class="col-sm-8 align-left mt-2">
            <go-back-util />
          </div>
          <div class="col-sm-4 align-right mt-2">
            <button
              v-if="
                getDocumentationName !== undefined &&
                getDocumentationName.trim() !== ''
              "
              class="btn btn-success bi-caret-right"
              @click.prevent="pushToFileUpload"
            >
              {{ $t("next Step") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal-utils />
  </div>
</template>

<script>
import SideMenuFilesCom from "../../components/filerelated/SideMenuFilesCom.vue";
import MetadataNavigationCom from "../../components/MetadataNavigationCom.vue";
import GoBackUtil from "../../components/utils/GoBackUtil.vue";
// import FileModals from "../../components/filerelated/FileModals.vue";
import { mapActions, mapGetters } from "vuex";
import Fault from "../../components/utils/Fault";
import ModalUtils from "../../components/utils/ModalsUtils.vue";
import i18n from "@/i18n";

export default {
  name: "FileView",
  components: {
    MetadataNavigationCom,
    SideMenuFilesCom,
    GoBackUtil,
    // FileModals,
    ModalUtils,
  },
  data() {
    return {
      docName: undefined,
      fail: [],
    };
  },
  computed: {
    ...mapGetters([
      "getIsContainer",
      "getDocumentationName",
      "getIsInstance",
      "getIsTpuDocumentation",
      "getIsGeneralDocu",
    ]),
    ...mapGetters("Classification", [
      "getDocumentClassificationClassificationSystem",
    ]),
  },
  methods: {
    checkDocumentationName(ele) {
      this.fail = [];
      const faultCode = this.singleFault(ele);
      // check BIG Spec
      let value = ele.value;
      const invalidCharactersRegex = /[^-A-Za-z0-9_]/;
      const match = value.match(invalidCharactersRegex);
      if (value.length > 150) {
        this.fail.push(i18n.global.t("faultDocNameToLong"));
        ele.classList.add("border-danger");
      }
      if (match) {
        this.fail.push(i18n.global.t("faultInvalidCharDoc", [match.index + 1]));
        ele.classList.add("border-danger");
      }
      if (this.fail.length == 0) {
        ele.classList.remove("border-danger");
      }
      this.pushAlert();
    },
    pushAlert() {
      if (this.fail.length > 0) {
        let Message = "";
        this.fail.forEach(function (item) {
          Message = Message + item + "\n";
        });
        alert(Message);
      }
    },
    activateTpuDocumentation() {
      this.$store.dispatch("setIsTpuDocumentation");
      let payload = {
          index: 0,
          value: "QGIC.025334"
        }
      if (!this.getIsTpuDocumentation) {
        payload.value = ''
      }
      this.$store.dispatch("RefObj/setObjectId", payload);
    },
    pushToFileUpload() {
      this.$store.dispatch("ActSteps/setFileUpload", true);
      this.$router.push("/metadata/files/fileupload");
    },
    singleFault(ele) {
      return Fault.singleFault(ele);
    },

    setDocName() {
      var theDocName = document.getElementById("documentationName").value;
      if (this.fail.length > 0) {
        this.pushAlert();
      } else if (!Fault.faultControl(document)) {
        this.fail.push(i18n.global.t("faultDocumentationName"));
        this.pushAlert();
      } else {
        this.$store.dispatch("ActSteps/setFileUpload", true);
        this.$store.dispatch("setDocumentationName", theDocName);
        this.$router.push("/metadata/files/fileupload");
      }
    },

    ...mapActions([
      "setDocumentationName",
      "setIsInstance",
      "setIsTpuDocumentation",
    ]),
    ...mapActions("ActSteps", ["setFileUpload"]),
    ...mapActions("RefObj", ["setObjectId"]),
    ...mapActions("Classification", [
      "setDocumentClassificationClassificationSystem",
    ]),
    getTypeStatus() {
      if (this.getIsInstance == undefined) {
        return false;
      } else return !this.getIsInstance;
    },

    setInstance(boolValue) {
      this.$store.dispatch("setIsInstance", boolValue);
    },
    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },
  },
  mounted(){
//     const [empty, cat1, cat2, cat3] = new String(this.$route.path).split('/')
//     utag.view({
//     "page_name" : this.$route.path,
//     "page_language" : this.$i18n.locale,
//     "page_identifier" : "general_docu: " + this.getIsGeneralDocu,
//     "page_category" : cat1,                         
//     "page_subcategory" : cat2,
//     "page_sub_subcategory": cat3,
//     "page_type" : "",                                      
//     "page_country" : "global"

// });
  }
};
</script>

<style></style>
