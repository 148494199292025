export default {
  namespaced: true,
  state() {
    return {
      DocumentClassification_model: [
        {
          // id: "",
          ClassificationSystem: undefined,
          // "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types",
          ClassId: undefined,
          // classNameId: "",
          ClassNameType_model: [
            {
              // id: "",
              language: undefined,
              // "en",
              classname: undefined,
            },
          ],
        },
      ],
    };
  },
  getters: {
    getClassNameType_model: (state) => (index) => {
      return state.DocumentClassification_model[index].ClassNameType_model;
    },
    // getClassNameTypeId: (state) => (docindex, classindex) => {
    //   return state.DocumentClassification_model[docindex].ClassNameType_model[
    //     classindex
    //   ].id;
    // },
    getClassNameTypeLanguage: (state) => (docindex, classindex) => {
      return state.DocumentClassification_model[docindex].ClassNameType_model[
        classindex
      ].language;
    },
    getClassNameTypeClassname: (state) => (docindex, classindex) => {
      return state.DocumentClassification_model[docindex].ClassNameType_model[
        classindex
      ].classname;
    },
    getDocumentClassification_model(state) {
      return state.DocumentClassification_model;
    },
    getDocumentClassificationClassificationSystem: (state) => (index) => {
      return state.DocumentClassification_model[index].ClassificationSystem;
    },
    getDocumentClassificationClassId: (state) => (index) => {
      return state.DocumentClassification_model[index].ClassId;
    },
  },

  mutations: {
    // payload must have form of: {classindex: Number, docindex: Number, classNameType_model: {ClassNameType_model}}
    setClassNameType(state, payload) {
      state.DocumentClassification_model[payload.docindex].ClassNameType_model[
        payload.classindex
      ] = payload.classNameType_model;
    },
    // payload must have form of: {classindex: Number, docindex: Number, language: String}
    setClassNameTypeLanguage(state, payload) {
      state.DocumentClassification_model[payload.docindex].ClassNameType_model[
        payload.classindex
      ].language = payload.language;
    },
    // payload must have form of: {classindex: Number, docindex: Number, classname: String}
    setClassNameTypeClassname(state, payload) {
      state.DocumentClassification_model[payload.docindex].ClassNameType_model[
        payload.classindex
      ].classname = payload.classname;
    },
    // payload must have form of: {index: Number, documentClassification_model_type: {DocumentClassification_model_type}}
    setDocumentClassification(state, payload) {
      state.DocumentClassification_model[payload.index] =
        payload.documentClassification_model_type;
    },
    // payload must have form of: {index: Number, ClassificationSystem: String}
    setDocumentClassificationClassificationSystem(state, payload) {
      state.DocumentClassification_model[payload.index].ClassificationSystem =
        payload.ClassificationSystem;
    },
    // payload must have form of: {index: Number, ClassId: String}
    setDocumentClassificationClassId(state, payload) {
      state.DocumentClassification_model[payload.index].ClassId =
        payload.ClassId;
    },
    // payload must have form of: {index: Number, classNameId: Number}
    // setDocumentClassificationClassNameId(state, payload) {
    //   state.DocumentClassification_model[payload.index].classNameId =
    //     payload.classNameId;
    // },
    addDocumentClassification(state) {
      state.DocumentClassification_model.push({
        // id: "",
        ClassificationSystem: "",
        ClassId: "",
        // classNameId: "",
        ClassNameType_model: [
          {
            // id: "",
            language: "",
            classname: "",
          },
        ],
      });
    },
    addClassNameType(state, docindex) {
      state.DocumentClassification_model[docindex].ClassNameType_model.push({
        // id: "",
        language: "",
        classname: "",
      });
    },
    removeDocumentClassification(state) {
      state.DocumentClassification_model.splice(-1, 1);
    },
    removeClassNameType(state, payload) {
      state.DocumentClassification_model[
        payload.docindex
      ].ClassNameType_model.splice(payload.classindex, 1);
    },

    clearStore(state, classSystem){
      state.DocumentClassification_model = [
        {
          // id: "",
          // ClassificationSystem: "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types",
          ClassificationSystem: classSystem,
          ClassId: undefined,
          // classNameId: "",
          ClassNameType_model: [
            {
              // id: "",
              language: "en",
              classname: undefined,
            },
          ],
        },
      ];
    },
  },
  actions: {
    clearStore(context, classSystem){
      context.commit("clearStore", classSystem)
    },
    // payload must have form of: {index: Number, classNameType_model: {ClassNameType_model}}
    setClassNameType(context, payload) {
      context.commit("setClassNameType", payload);
    },
    // payload must have form of: {index: Number, language: String}
    setClassNameTypeLanguage(context, payload) {
      context.commit("setClassNameTypeLanguage", payload);
    },
    // payload must have form of: {index: Number, classname: String}
    setClassNameTypeClassname(context, payload) {
      context.commit("setClassNameTypeClassname", payload);
    },
    // payload must have form of: {index: Number, documentClassification_model: {DocumentClassification_model}}
    setDocumentClassification(context, payload) {
      context.commit("setDocumentClassification", payload);
    },
    // payload must have form of: {index: Number, ClassificationSystem: String}
    setDocumentClassificationClassificationSystem(context, payload) {
      context.commit("setDocumentClassificationClassificationSystem", payload);
    },
    // payload must have form of: {index: Number, ClassId: String}
    setDocumentClassificationClassId(context, payload) {
      context.commit("setDocumentClassificationClassId", payload);
    },
    // payload must have form of: {index: Number, classNameId: Number}
    // setDocumentClassificationClassNameId(context, payload) {
    //   context.commit("setDocumentClassificationClassNameId", payload);
    // },
    addDocumentClassification(context) {
      context.commit("addDocumentClassification");
    },
    addClassNameType(context, payload) {
      context.commit("addClassNameType", payload);
    },
    removeDocumentClassification(context) {
      context.commit("removeDocumentClassification");
    },
    removeClassNameType(context, payload) {
      context.commit("removeClassNameType", payload);
    },
  },
};
