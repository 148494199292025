<template>
  <div>
    <router-view v-slot="{ Component, route  }">
      <transition name="fade">
      <keep-alive>
        
        <component :is="Component" :key="route.path"/>
        
      </keep-alive>
      </transition>
    </router-view>
  </div>
  
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import i18n from "@/i18n";
export default {
  name: "KeepMeAliveView",
  computed:{
    ...mapGetters(["getEditing", "getToken"])
  },
  methods: {
    ...mapActions(["setEditing", "setIsPackageUnit"])
  },

  beforeRouteLeave(to, from, next) {
    if (!this.getEditing) {
      if (!window.confirm(i18n.global.t("wantToLeave"))) {
        return;
      }
    }
    const token = this.getToken;
    this.$store.reset();
    this.$store.dispatch("setToken", token);
    this.$store.dispatch("setEditing", false)
    next();
  },
};
</script>
<style>
.rldisabled {
  opacity: 0.3;
  pointer-events: none;
}
/* .wrapper {
  width: 100%;
  min-height: 100vh;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}

.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}

.scale-slide-enter-from {
  left: -100%;
}

.scale-slide-enter-to {
  left: 0%;
}

.scale-slide-leave-from {
  transform: scale(1);
}

.scale-slide-leave-to {
  transform: scale(0.8);
} */
</style>
