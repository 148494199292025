<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>
          Document Relationships:
          <a href="#"
            ><i
              class="bi bi-info-circle"
              data-toggle="modal"
              data-target="#infoModal"
              data-title="thisTitle"
              data-body="thisBody"
              @click.prevent="showmodal"
            ></i
          ></a>
        </h4>
      </div>
      <div class="card-body">
        <form action="" id="docRelationship">
          <div class="row mb-3">
            <label for="documentId" class="col-sm-3 col-form-label"
              >Document Id:
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="thisTitle"
                  data-body="thisBody"
                  @click.prevent="showmodal"
                ></i></a
            ></label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                id="documentId"
                :value="getDocumentIdType10_modelDocumentId"
                @input="
                  setDocumentIdType10_modelDocumentId($event.target.value)
                "
                @keydown.enter.prevent
              />
            </div>
          </div>
          <div class="row mb-3">
            <label for="domainId" class="col-sm-3 col-form-label"
              >Domain Id:
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="thisTitle"
                  data-body="thisBody"
                  @click.prevent="showmodal"
                ></i></a
            ></label>
            <div class="col-sm-9">
              <input
                type="url"
                class="form-control"
                id="domainId"
                :value="getDocumentIdType10_modelDomainId"
                @input="setDocumentIdType10_modelDomainId($event.target.value)"
                @keydown.enter.prevent
              />
            </div>
          </div>
          <div class="row mb-3">
            <label for="versionId" class="col-sm-3 col-form-label"
              >Document Version Id:
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="thisTitle"
                  data-body="thisBody"
                  @click.prevent="showmodal"
                ></i></a
            ></label>
            <div class="col-sm-9">
              <input
                type="url"
                class="form-control"
                id="versionId"
                :value="getDocumentRelationship_modelDocumentVersionId"
                @input="
                  setDocumentRelationship_modelDocumentVersionId(
                    $event.target.value
                  )
                "
                @keydown.enter.prevent
              />
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <!-- v-for div -->
              <div
                v-for="(desc, index) in getDescriptionType11_model"
                :key="index"
              >
                <div class="row mb-3">
                  <label for="Description" class="col-sm-3 col-form-label"
                    >Description:
                    <a href="#"
                      ><i
                        class="bi bi-info-circle"
                        data-toggle="modal"
                        data-target="#infoModal"
                        data-title="thisTitle"
                        data-body="thisBody"
                        @click.prevent="showmodal"
                      ></i></a
                  ></label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      id="Description"
                      :value="getDescriptionType11Description(index)"
                      @input="setDesc11Desc(index, $event)"
                      @keydown.enter.prevent
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="desclanguage">
                    Language:
                  </label>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                      id="desclanguage"
                      :value="getDescriptionType11Language(index)"
                      @input="setDesc11Lang(index, $event)"
                      @keydown.enter.prevent
                    />
                  </div>
                  <div class="col-sm-2 mt-2 mt-sm-0" v-if="index > 0">
                    <button
                      class="btn btn-outline-danger"
                      style="width: 3rem"
                      @click.prevent="removeDescriptionType11(index)"
                    >
                      <i class="bi-x-circle" style="font-size: 1rem"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- ------------------ -->
              <div class="row mb-3">
                <div class="col sm-2">
                  <button
                    class="btn btn-outline-primary"
                    style="width: 4rem"
                    @click.prevent="addDescriptionType11"
                  >
                    <i class="bi-plus-circle" style="font-size: 1rem"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3 mt-3">
            <label class="col-sm-3 col-form-label" for="type"> Type: </label>
            <div class="col-sm-3">
              <select
                name="type"
                id="type"
                class="form-select"
                :value="getDocumentRelationship_modelType"
                @input="setDocumentRelationship_modelType($event.target.value)"
                @keydown.enter.prevent
              >
                <option value="RefersTo">Refers to</option>
                <option value="BasedOn">Based On</option>
                <option value="Affecting">Affecting</option>
                <option value="TranslationOf">Translation of</option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-9 align-left mt-2">
              <go-back-util />
            </div>
            <!-- @ToDo v-if einfügen, je nachdem ob ein weiterer Durchlauf nötig ist oder Download -->
            <div class="col-sm-3 align-right mt-2">
              <button
                class="btn btn-success bi-caret-right"
                @click.prevent="pushToSelectFiles"
              >
                create Documentation
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal-utils />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoBackUtil from "../utils/GoBackUtil.vue";
import i18n from "@/i18n";
import ModalUtils from "../utils/ModalsUtils.vue";

export default {
  name: "DocRelationshipDocVerCom",
  components: {
    GoBackUtil,
    ModalUtils,
  },
  data() {
    return {
      xmlString: "",
      jsonObj: {
        getDescriptionType11_model: {
          Language: "en",
          Description: "TestDesc",
          id: "",
        },
      },
    };
  },
  methods: {
    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },

    pushToSelectFiles() {
      this.$router.push("/metadata/docversion/xmlpreview/");
    },
    setDesc11Lang(index, event) {
      let payload = {
        index: index,
        language: event.target.value,
      };
      this.$store.dispatch("DocVer/setDescriptionType11Language", payload);
    },
    setDesc11Desc(index, event) {
      let payload = {
        index: index,
        description: event.target.value,
      };
      this.$store.dispatch("DocVer/setDescriptionType11Description", payload);
    },

    ...mapActions("DocVer", [
      "addDescriptionType11",
      "removeDescriptionType11",
      "setDescriptionType11Description",
      "setDescriptionType11Language",
      "setDocumentRelationship_modelDocumentVersionId",
      "setDocumentRelationship_modelType",
      "setDocumentIdType10_modelDocumentId",
      "setDocumentIdType10_modelDomainId",
    ]),
  },
  computed: {
    ...mapGetters("DocVer", [
      "getDescriptionType11_model",
      "getDescriptionType11Description",
      "getDescriptionType11Language",
      "getDocumentRelationship_model",
      "getDocumentRelationship_modelDocumentVersionId",
      "getDocumentRelationship_modelType",
      "getDocumentIdType10_model",
      "getDocumentIdType10_modelDocumentId",
      "getDocumentIdType10_modelDomainId",
    ]),
    ...mapGetters("DocId", [
      "getDocumentDomainId",
      "getDomainId",
      "getDocumentId",
    ]),
    ...mapGetters(["getIsGeneralDocu"]),
  },
  created() {
    // @ToDo DocumentID -Vorschlag automatisch vorschlagen, entweder von Eingabe oder Document-Name. Entscheidung abwarten
    this.setDocumentIdType10_modelDomainId(this.getDomainId);
    this.setDocumentIdType10_modelDocumentId(this.getDocumentId);
  },
};
</script>

<style scoped>
.col-form-label {
  padding-top: 0rem;
}
.card-text {
  padding: 1em;
}
</style>
