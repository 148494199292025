<template>
  <div>
    <div class="row">
      <div class="row mt-3 text-primary">
        <h6 class="text-center">{{ $t("attentionRAMsize") }}</h6>
      </div>

      <div class="col-lg-6">
        <router-view></router-view>
      </div>
      <div class="col-lg-6 mt-3">
        <router-view v-slot="{ Component }" name="second">
            <component :is="Component" />
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "VdiViewer",
  components: {},
  methods: {
    ...mapActions(["setIsPackageUnit", "setToken", "setUser"]),
  },
  computed: {
    ...mapGetters(["getToken", "getUser"]),
  },

  created() {
    this.setIsPackageUnit(true);
  },
  unmounted() {
    this.setIsPackageUnit(false);
    const token = this.getToken;
    const user = this.getUser;
    this.$store.reset();
    this.$store.dispatch("setToken", token);
    this.$store.dispatch("setUser", user);
  },
   mounted(){
//     const [empty, cat1, cat2, cat3] = new String(this.$route.path).split('/')
//     utag.view({
//     "page_name" : this.$route.path,
//     "page_language" : this.$i18n.locale,
//     "page_identifier" : "general_docu: " + this.getIsGeneralDocu,
//     "page_category" : cat1,                         
//     "page_subcategory" : cat2,
//     "page_sub_subcategory": cat3,
//     "page_type" : "",                                      
//     "page_country" : "global"

// });
  }
};
</script>
