/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *   added current License Version by Peter Geiger 08/18/2023
 *   new License Version
 *   https://www.w3.org/copyright/software-license-2023/
 *
 *   File: treeview-navigation.js
 *   Desc: Tree item object for representing the state and user interactions for a
 *       tree widget for navigational links
 */

// 'use strict';
// removed class NavigationContentGenerator Peter Geiger 08/18/2023
// class NavigationContentGenerator {
//   constructor(siteURL, siteName) {
//     this.siteName = siteName;
//     this.siteURL = siteURL;
//     this.fillerTextSentences = [];

//     this.fillerTextSentences.push(
//       'The content on this page is associated with the <a href="$linkURL">$linkName</a> link for <a href="$siteURL">$siteName</a>.'
//     );
//  this.fillerTextSentences.push('The text content in this paragraph is filler text providing a detectable change of content when the <a href="$linkURL">$linkName</a> link is selected from the menu.  ');
//  this.fillerTextSentences.push('<a href="$siteURL">$siteName</a> doesn\'t really exist, but the use of an organizational name is useful to provide context for the <a href="$linkURL">$linkName</a> link.  ');
//  this.fillerTextSentences.push('Since $siteName doesn\'t exist there really is no real content associated with the <a href="$linkURL">$linkName</a> link.');
// }

//   renderParagraph(linkURL, linkName) {
//     var content = '';
//     this.fillerTextSentences.forEach(
//       (s) =>
//         (content += s
//           .replace('$siteName', this.siteName)
//           .replace('$siteURL', this.siteURL)
//           .replace('$linkName', linkName)
//           .replace('$linkURL', linkURL))
//     );
//     return content;
//   }
// }
import store from "../../store/index";
export default class TreeViewNavigation {
  
  constructor(node) {
    var linkURL, linkTitle;
    // Check whether node is a DOM element
    if (typeof node !== "object") {
      return;
    }

    document.body.addEventListener("focusin", this.onBodyFocusin.bind(this));
    document.body.addEventListener("mousedown", this.onBodyFocusin.bind(this));

    this.treeNode = node;
    this.navNode = node.parentElement;
// Peter Geiger function clearEventListener added 15.09.2023
    function clearEventListener(element) {
      // console.log(element)
      const clonedElement = element.cloneNode(true);
      element.replaceWith(clonedElement);
      return clonedElement;
    }

    this.treeitems = this.treeNode.querySelectorAll('[role="treeitem"]');
    for (let i = 0; i < this.treeitems.length; i++) {
      let ti = this.treeitems[i];
      // Peter Geiger added 15.09.2023 remove existing eventListener in case they are doubled
      ti = clearEventListener(ti);
      ti.addEventListener("keydown", this.onKeydown.bind(this));
      ti.addEventListener("click", this.onLinkClick.bind(this));
      // first tree item is in tab sequence of page
      if (i == 0) {
        ti.tabIndex = 0;
      } else {
        ti.tabIndex = -1;
      }
      var groupNode = this.getGroupNode(ti);
      if (groupNode) {
        var span = ti.querySelector("span.icon");
        span.addEventListener("click", this.onIconClick.bind(this));
      }
    }

    // Initial content for page
    // if (location.href.split('#').length > 1) {
    //   linkURL = location.href;
    //   linkTitle = getLinkNameFromURL(location.href);
    // } else {
    //   linkURL = location.href + '#home';
    //   linkTitle = 'Home';
    // }

    // this.contentGenerator = new NavigationContentGenerator(
    //   '#home',
    //   'Mythical University'
    // );
    // this.updateContent(linkURL, linkTitle, false);

    // function getLinkNameFromURL(url) {
    //   function capitalize(str) {
    //     return str.charAt(0).toUpperCase() + str.slice(1);
    //   }

    //   var name = url.split('#')[1];
    //   if (typeof name === 'string') {
    //     name = name.split('-').map(capitalize).join(' ');
    //   } else {
    //     name = 'Home';
    //   }
    //   return name;
    // }
  }
  
  // @Change updateContent muss das richtige in der zweiten Spalte liefern

  // updateContent(linkURL, linkName, moveFocus) {
  //   var h1Node, paraNodes;

  //   if (typeof moveFocus !== 'boolean') {
  //     moveFocus = true;
  //   }

  //   // Update content area
  //   h1Node = document.querySelector('.page .main h1');
  //   if (h1Node) {
  //     h1Node.textContent = linkName;
  //   }
  //   paraNodes = document.querySelectorAll('.page .main p');
  //   paraNodes.forEach(
  //     (p) =>
  //       (p.innerHTML = this.contentGenerator.renderParagraph(linkURL, linkName))
  //   );

  //   // move focus to the content region
  //   if (moveFocus && h1Node) {
  //     h1Node.tabIndex = -1;
  //     h1Node.focus();
  //   }

  //   // Update aria-current
  //   this.updateAriaCurrent(linkURL);
  // }

  getAriaCurrentURL() {
    let url = false;
    let node = this.treeNode.querySelector("[aria-current]");
    if (node) {
      url = node.href;
    }
    return url;
  }

  updateAriaCurrent(url) {
    if (typeof url !== "string") {
      url = this.getAriaCurrentURL();
    }

    this.treeitems.forEach((item) => {
      if (item.href === url) {
        item.setAttribute("aria-current", "page");
        // Make sure link is visible
        this.showTreeitem(item);
        this.setTabIndex(item);
      } else {
        item.removeAttribute("aria-current");
      }
    });
  }

  showTreeitem(treeitem) {
    var parentNode = this.getParentTreeitem(treeitem);

    while (parentNode) {
      parentNode.setAttribute("aria-expanded", "true");
      parentNode = this.getParentTreeitem(parentNode);
    }
  }

  setTabIndex(treeitem) {
    this.treeitems.forEach((item) => (item.tabIndex = -1));
    treeitem.tabIndex = 0;
  }

  getParentTreeitem(treeitem) {
    var node = treeitem.parentNode;

    if (node) {
      node = node.parentNode;
      if (node) {
        node = node.previousElementSibling;
        if (node && node.getAttribute("role") === "treeitem") {
          return node;
        }
      }
    }
    return false;
  }

  isVisible(treeitem) {
    var flag = true;
    if (this.isInSubtree(treeitem)) {
      treeitem = this.getParentTreeitem(treeitem);
      if (!treeitem || treeitem.getAttribute("aria-expanded") === "false") {
        return false;
      }
    }
    return flag;
  }

  isInSubtree(treeitem) {
    if (treeitem.parentNode && treeitem.parentNode.parentNode) {
      return treeitem.parentNode.parentNode.getAttribute("role") === "group";
    }
    return false;
  }

  isExpandable(treeitem) {
    return treeitem.hasAttribute("aria-expanded");
  }

  isExpanded(treeitem) {
    return treeitem.getAttribute("aria-expanded") === "true";
  }

  getGroupNode(treeitem) {
    var groupNode = false;
    var id = treeitem.getAttribute("aria-owns");
    if (id) {
      groupNode = document.getElementById(id);
    }
    return groupNode;
  }

  getVisibleTreeitems() {
    var items = [];
    for (var i = 0; i < this.treeitems.length; i++) {
      var ti = this.treeitems[i];
      if (this.isVisible(ti)) {
        items.push(ti);
      }
    }
    return items;
  }

  collapseTreeitem(treeitem) {
    if (treeitem.getAttribute("aria-owns")) {
      var groupNode = document.getElementById(
        treeitem.getAttribute("aria-owns")
      );
      if (groupNode) {
        treeitem.setAttribute("aria-expanded", "false");
      }
    }
  }

  expandTreeitem(treeitem) {
    if (treeitem.getAttribute("aria-owns")) {
      var groupNode = document.getElementById(
        treeitem.getAttribute("aria-owns")
      );
      if (groupNode) {
        treeitem.setAttribute("aria-expanded", "true");
      }
    }
  }

  expandAllSiblingTreeitems(treeitem) {
    var parentNode = treeitem.parentNode.parentNode;

    if (parentNode) {
      var siblingTreeitemNodes = parentNode.querySelectorAll(
        ":scope > li > a[aria-expanded]"
      );

      for (var i = 0; i < siblingTreeitemNodes.length; i++) {
        siblingTreeitemNodes[i].setAttribute("aria-expanded", "true");
      }
    }
  }

  setFocusToTreeitem(treeitem) {
    treeitem.focus();
  }

  setFocusToNextTreeitem(treeitem) {
    var visibleTreeitems = this.getVisibleTreeitems();
    var nextItem = false;

    for (var i = visibleTreeitems.length - 1; i >= 0; i--) {
      var ti = visibleTreeitems[i];
      if (ti === treeitem) {
        break;
      }
      nextItem = ti;
    }
    if (nextItem) {
      this.setFocusToTreeitem(nextItem);
    }
  }

  setFocusToPreviousTreeitem(treeitem) {
    var visibleTreeitems = this.getVisibleTreeitems();
    var prevItem = false;

    for (var i = 0; i < visibleTreeitems.length; i++) {
      var ti = visibleTreeitems[i];
      if (ti === treeitem) {
        break;
      }
      prevItem = ti;
    }

    if (prevItem) {
      this.setFocusToTreeitem(prevItem);
    }
  }

  setFocusToParentTreeitem(treeitem) {
    if (this.isInSubtree(treeitem)) {
      var ti = treeitem.parentNode.parentNode.previousElementSibling;
      this.setFocusToTreeitem(ti);
    }
  }

  setFocusByFirstCharacter(treeitem, char) {
    var start,
      i,
      ti,
      index = -1;
    var visibleTreeitems = this.getVisibleTreeitems();
    char = char.toLowerCase();

    // Get start index for search based on position of treeitem
    start = visibleTreeitems.indexOf(treeitem) + 1;
    if (start >= visibleTreeitems.length) {
      start = 0;
    }

    // Check remaining items in the tree
    for (i = start; i < visibleTreeitems.length; i++) {
      ti = visibleTreeitems[i];
      if (char === ti.textContent.trim()[0].toLowerCase()) {
        index = i;
        break;
      }
    }

    // If not found in remaining slots, check from beginning
    if (index === -1) {
      for (i = 0; i < start; i++) {
        ti = visibleTreeitems[i];
        if (char === ti.textContent.trim()[0].toLowerCase()) {
          index = i;
          break;
        }
      }
    }

    // If match was found...
    if (index > -1) {
      this.setFocusToTreeitem(visibleTreeitems[index]);
    }
  }

  // Event handlers

  onBodyFocusin(event) {
    var tgt = event.target;

    if (this.treeNode.contains(tgt)) {
      this.navNode.classList.add("focus");
    } else {
      this.navNode.classList.remove("focus");
      this.updateAriaCurrent();
    }
  }

  onIconClick(event) {
    var tgt = event.currentTarget;

    if (this.isExpanded(tgt.parentNode.parentNode)) {
      this.collapseTreeitem(tgt.parentNode.parentNode);
    } else {
      this.expandTreeitem(tgt.parentNode.parentNode);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  // @Change Test für klick event
  // wird benötigt
  onLinkClick(event) {
    // var tgt = event.currentTarget;
    // remove all colors when user click a link
    let allColoredRows =
            document.getElementsByClassName("row pdflistrow");
          for (let i = 0; i < allColoredRows.length; i++) {
            allColoredRows[i].removeAttribute("style");
          }
    let currentId = event.currentTarget.getAttribute("aria-owns");
    
    let elementToProcess = document.getElementById(currentId);
    // newElement == Null means this element is not expandable, must be file link
    switch (elementToProcess) {
      case null:
        // @insert Code for Metadata
        let getFileById = store.getters["ViewerStore/getViewerFileObjByUniqueID"]
        let selectedViewerFileRep = getFileById(event.currentTarget.getAttribute("id"))
        if (selectedViewerFileRep){
          if (selectedViewerFileRep.fileType == "application/pdf"){
            // URL.createObjectURL(selectedViewerFileRep.loadedFile)
            event.currentTarget.setAttribute("style", "color: #5cb85c !important")
            for (const a of document.querySelectorAll("a")) {
              if (a.textContent.includes(event.currentTarget.innerText)) {
                // a.setAttribute("style", "color: #5cb85c !important");
                a.setAttribute("class", "link-clicked");
              }
            }
            const data = window.URL.createObjectURL(selectedViewerFileRep.loadedFile);
            const pdfWindow = window.open();
            pdfWindow.location.href = data;
          } else {
            if (store.getters["ViewerStore/getShowListOfPdfs"]){
              store.dispatch("ViewerStore/setShowListOfPdfsState");
            }
            store.dispatch("ViewerStore/setSelectedFileId", event.currentTarget.getAttribute("id"))
            // add css class to visualize clicked link
            let classElements = document.getElementsByClassName("metadata-active");
            for (var i = 0; i < classElements.length; i++) {
              classElements[i].classList.remove('metadata-active');
           }
           event.target.parentElement.classList.add('metadata-active')
          //  event.currentTarget.setAttribute("style", "color: #5cb85c")
           event.currentTarget.setAttribute("class", "link-clicked")
          }
        }
        break;
      case elementToProcess.getAttribute("role") == "group"
        ? elementToProcess
        : -1:
        let ulList = this.getFurtherUl(elementToProcess);
        if (ulList.length > 0) {
          // nothing to color, list contains expandables; fall to the next case
          // alert("nothing to color")
        } else {
          
          this.colorFileBackgroundById(
            elementToProcess.getElementsByTagName("a")
          ); 
          break;
        }
      default:
      // alert("Don't know what to do with this click")
    }

    event.preventDefault();
    event.stopPropagation();
  }
  getFurtherUl(elementToProcess) {
    let uLlis = elementToProcess.getElementsByTagName("ul");
    return uLlis;
  }
  colorFileBackgroundById(arrayOfEleIdAttribute) {
    for (let i = 0; i < arrayOfEleIdAttribute.length; i++) {
      if (arrayOfEleIdAttribute[i].hasAttribute("id")) {
        let eleToStyle = document.getElementById(
          arrayOfEleIdAttribute[i].getAttribute("id") + "metaId"
        );
        if (eleToStyle != null) {
          eleToStyle.setAttribute("style", "background-color: #fde482");
        }
      }
    }
  }

  onKeydown(event) {
    var tgt = event.currentTarget,
      flag = false,
      key = event.key;

    function isPrintableCharacter(str) {
      return str.length === 1 && str.match(/\S/);
    }

    if (event.altKey || event.ctrlKey || event.metaKey) {
      return;
    }

    if (event.shift) {
      if (
        event.keyCode == this.keyCode.SPACE ||
        event.keyCode == this.keyCode.RETURN
      ) {
        event.stopPropagation();
      } else {
        if (isPrintableCharacter(key)) {
          if (key == "*") {
            this.expandAllSiblingTreeitems(tgt);
            flag = true;
          } else {
            this.setFocusByFirstCharacter(tgt, key);
          }
        }
      }
    } else {
      switch (key) {
        // NOTE: Return key is supported through the click event
        // @Change ersetzen oder auskommentieren, updateContent wird nicht benutzt
        case " ":
          // this.updateContent(tgt.href, tgt.textContent.trim());
          flag = true;
          break;

        case "Up":
        case "ArrowUp":
          this.setFocusToPreviousTreeitem(tgt);
          flag = true;
          break;

        case "Down":
        case "ArrowDown":
          this.setFocusToNextTreeitem(tgt);
          flag = true;
          break;

        case "Right":
        case "ArrowRight":
          if (this.isExpandable(tgt)) {
            if (this.isExpanded(tgt)) {
              this.setFocusToNextTreeitem(tgt);
            } else {
              this.expandTreeitem(tgt);
            }
          }
          flag = true;
          break;

        case "Left":
        case "ArrowLeft":
          if (this.isExpandable(tgt) && this.isExpanded(tgt)) {
            this.collapseTreeitem(tgt);
            flag = true;
          } else {
            if (this.isInSubtree(tgt)) {
              this.setFocusToParentTreeitem(tgt);
              flag = true;
            }
          }
          break;

        case "Home":
          this.setFocusToTreeitem(this.treeitems[0]);
          flag = true;
          break;

        case "End":
          var visibleTreeitems = this.getVisibleTreeitems();
          this.setFocusToTreeitem(
            visibleTreeitems[visibleTreeitems.length - 1]
          );
          flag = true;
          break;

        default:
          if (isPrintableCharacter(key)) {
            if (key == "*") {
              this.expandAllSiblingTreeitems(tgt);
              flag = true;
            } else {
              this.setFocusByFirstCharacter(tgt, key);
            }
          }
          break;
      }
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}

/**
 * ARIA Treeview example
 *
 * @function onload
 * @description  after page has loaded initialize all treeitems based on the role=treeitem
 */
//  const modalObj =document.getElementById('staticBackdrop');
// // window
// modalObj.addEventListener('show.bs.modal', function () {
//   var trees = document.querySelectorAll('nav [role="tree"]');
//   console.log("show.bs.modal event")
//   for (let i = 0; i < trees.length; i++) {
//     new TreeViewNavigation(trees[i]);
//   }
// });
