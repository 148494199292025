<template>
    <button class="btn btn-outline-secondary bi-caret-left-fill" @click.prevent="goBack" style="width: 6.5rem;">
        {{$t('go back')}}
    </button>
</template>

<script>
export default {
    methods: {
        goBack() {
            return this.$router.go(-1);
        }
    }
}
</script>