<template>
  <div class="home text-center gy-3">
    <h1>{{ $t("title") }}</h1>
    <div class="row pt-5 pb-4" id="capital">
      <div class="col-md-12">
        <div class="float-start text-start">
          <div class="float-end" style="max-width: 30%">
            <img
              src="@/assets/4590_Driving_Digital_Transformation.jpg"
              class="img-thumbnail shadow-sm rounded"
              alt="Digital Transformation"
            />
          </div>
          <h5>
            {{ $t("homeSubTitel") }}
          </h5>
          <p class="capitalize">
            {{ $t("homeParaOne") }}
          </p>
          <p class="capitalize">
            {{ $t("homeParaTwo") }}
          </p>
          <p class="capitalize">
            {{ $t("homeParaThree") }}
          </p>
        </div>
      </div>
    </div>
    <div class="d-grid gap-2 d-xl-block mx-auto">
      <button
        type="button"
        :class="getZJDocuButtonClass()"
        @click.prevent="setIsGeneralDocu(false)"
        v-html="$t('zhanjiangDocu')"
      >

      </button>
      <button
        type="button"
        :class="getGeneralDocuButtonClass()"
        @click.prevent="setIsGeneralDocu(true)"
        v-html="$t('generalDocu')"
      >

      </button>
    </div>
    <div class="row pb-4" v-if="getIsGeneralDocu !== undefined">
      <div class="card mx-auto d-block shadow pt-2 mt-3">
        <p></p>
        <img
          src="../assets/Bild8__Final.jpg"
          class="card-img-top"
          alt="Simple Dokument"
          style="max-height: 60%; max-width: 60%"
        />
        <div class="card-body">
          <p>
            <button
              class="btn btn-outline-secondary"
              data-toggle="modal"
              data-target="#learnMoreModal"
              data-title="titleLearMoreSimpleDocContainer"
              data-body="bodyLearMoreSimpleDocContainer"
              @click.prevent="showmodal"
            >
              {{ $t("learnMore") }}
            </button>
          </p>
          <h5 class="card-title">{{ $t("SimpleDocumentContainer") }}</h5>
          <p class="card-text"></p>
          <router-link
            to="/metadata/files/fileupload"
            class="btn btn-primary mb-3 mt-3"
            @click="setContState(false)"
            >{{ $t("createDocument") }}</router-link
          >
        </div>
      </div>
      <div class="card mx-auto d-block shadow pt-2 mt-3">
        <p></p>
        <img
          src="../assets/Bild9__Final.jpg"
          class="card-img-top"
          alt="Documentation Container"
          style="max-height: 100%; max-width: 100%"
        />
        <div class="card-body">
          <p>
            <button
              class="btn btn-outline-secondary"
              data-toggle="modal"
              data-target="#learnMoreModal"
              data-title="titleLearMoreDocContainer"
              data-body="bodyLearMoreDocContainer"
              @click.prevent="showmodal"
            >
              {{ $t("learnMore") }}
            </button>
          </p>
          <h5 class="card-title">{{ $t("documentationContainer") }}</h5>
          <p class="card-text"></p>
          <router-link
            to="/metadata/files"
            class="btn btn-primary mb-3 mt-3"
            @click="setContState(true)"
            >{{ $t("createFullDocumentation") }}</router-link
          >
        </div>
      </div>

      <div class="card mx-auto d-block shadow pt-2 mt-3" v-if="getIsGeneralDocu == true">
          <p></p>
          <img
            src="../assets/Bild10__Final.jpg"
            class="card-img-top"
            alt="Package Unit"
            style="max-height: 58%; max-width: 87%"
          />
          <div class="card-body">
            <p>
              <button class="btn btn-outline-secondary" data-toggle="modal" data-target="#learnMoreModal" data-title="homeSubTitel" data-body="homeParaOne"> {{ $t("learnMore") }}</button>
            </p>
            <h5 class="card-title">{{$t("PackageUnitDocumentation")}}</h5>
            <p class="card-text">
              
            </p>
            <router-link
              to="/metadata/files"
              class="btn btn-primary mb-3 mt-3"
              @click="setContState(true, true)"
              >{{$t("createPackageUnit")}}</router-link
            >
          </div>
        </div>

      <div class="card mx-auto d-block shadow pt-2 mt-3" v-if="getIsGeneralDocu == false">
          <p></p>
          <img
            src="../assets/Excel_bulk_creation.png"
            class="card-img-top mt-4"
            alt="Documentation Container"
            style="max-height: 100%; max-width: 100%"
          />
          <div class="card-body">
            <p>
              <button class="btn btn-outline-secondary " data-toggle="modal" data-target="#learnMoreModal" data-title="homeSubTitel" data-body="homeParaOne"> {{ $t("learnMore") }}</button>
            </p>
            <h5 class="card-title">
              {{$t("excelUpload")}}
            </h5>
            <p class="card-text">
              </p>
            <router-link
              to="/metadata/bulkupload"
              class="btn btn-primary mb-3 mt-3"
              @click="setContState(true)"
              >{{$t("excelUploadButton")}}</router-link
            >
          </div>
        </div>
    </div>

    <p></p>
    <modal-utils />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalUtils from "../components/utils/ModalsUtils.vue";
import i18n from "@/i18n";

export default {
  name: "HomeView",
  components: {
    ModalUtils,
  },
  computed: {
    ...mapGetters(["getIsGeneralDocu"]),
  },
  methods: {
    ...mapActions("ActSteps", ["setFileUpload"]),
    ...mapActions(["setEditing", "setIsGeneralDocu", "setIsPackageUnit"]),
    setContState(status, packageUnit = undefined) {
      this.$store.commit("setContainerState", status);
      this.$store.commit("setIsPackageUnit", packageUnit);
      if (!status) {
        this.$store.dispatch("ActSteps/setFileUpload", true);
      }
    },
    getZJDocuButtonClass(){
      if (this.getIsGeneralDocu == undefined || this.getIsGeneralDocu){
        return "btn btn-outline-primary btn-lg me-3 mb-3"
      } else {
        return "btn btn-success btn-lg me-3 mb-3"
      }
    },
    getGeneralDocuButtonClass(){
      if (this.getIsGeneralDocu == undefined || !this.getIsGeneralDocu){
        return "btn btn-outline-secondary btn-lg me-3 mb-3"
      } else {
        return "btn btn-success btn-lg me-3 mb-3"
      }
    },
    showmodal() {
      $("#learnMoreModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },
  },
  mounted(){
//     utag.view({
//     "page_name" : "Home",
//     "page_language" : this.$i18n.locale,
//     "page_identifier" : "Home",
//     "page_category" : "",                         
//     "page_subcategory" : "",
//     "page_type" : "",                                      
//     "page_country" : "global"

// });
  }
};
</script>
<style scoped>
.capitalize::first-letter {
  font-size: 1.5rem;
  font-weight: bold;
  color: #173c74;
}
.bottom-2 {
  margin-bottom: 2rem;
}
.card {
  max-width: 450px;
}

</style>
