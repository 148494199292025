<template>
  <div component_optional-data>
    <div class="card">
      <h3 class="card-header">{{$t("headerOptMetaData")}}:</h3>
      <div class="card-body">
        <form action="" id="ObjectType">
          <div v-for="(item, index) in getReferencedObject_model" :key="item">
            <div class="card border rounded mt-2 border-dark">
              <div class="card-body">
                <div class="row mb-3">
                  <label for="ObjectType" class="col-sm-3 col-form-label"
                    >{{$t("objIdOptTypeInfoTitle")}}:
                    <a href="#"
                      ><i
                        class="bi bi-info-circle"
                        data-toggle="modal"
                        data-target="#infoModal"
                        data-title="objIdOptTypeInfoTitle"
                        data-body="objIdOptTypeInfoBody"
                        @click.prevent="showmodal"
                      ></i></a
                  ></label>
                  <div class="col-sm-9">
                    <select
                      list="categoryProposals"
                      class="form-select"
                      id="ObjectType"
                      :value="getRefObjModelType(index)"
                      @change="setType(index, $event)"
                      @keydown.enter.prevent
                    >
                      <option
                        v-for="item in optionalData"
                        :key="item"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mb-3">
                  <label for="Value" class="col-sm-3 col-form-label"
                    >{{$t("objIdOptValueInfoTitle")}}:
                    <a href="#"
                      ><i
                        class="bi bi-info-circle"
                        data-toggle="modal"
                        data-target="#infoModal"
                        data-title="objIdOptValueInfoTitle"
                        data-body="objIdOptValueInfoBody"
                        @click.prevent="showmodal"
                      ></i
                    ></a>
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      id="Value"
                      :value="getRefObjModelValue(index)"
                      @input="setValue(index, $event)"
                      @keydown.enter.prevent
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col xxl-1 mt-3 mt-xxl-0" v-if="index > 0">
                    <button
                      class="btn btn-outline-danger"
                      style="width: 3rem"
                      @click.prevent="removeReferencedObject(index)"
                    >
                      <i class="bi-x-circle" style="font-size: 1rem"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col xxl-1 mt-3 mt-xxl-0">
              <button
                class="btn btn-outline-primary"
                style="width: 8rem"
                @click.prevent="addReferencedObject()"
              >
                <i class="bi-plus-circle" style="font-size: 1.5rem"></i>
              </button>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-8 align-left mt-2">
              <go-back-util />
            </div>
            <div class="col-sm-4 align-right mt-2">
              <button
                class="btn btn-success bi-caret-right"
                @click.prevent="pushToParty"
              >
                {{$t("next Step")}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal-utils />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoBackUtil from "../utils/GoBackUtil.vue";
import i18n from "@/i18n";
import ModalUtils from "../utils/ModalsUtils.vue";

export default {
  name: "OptionalDataRefObjCom",
  components: {
    GoBackUtil,
    ModalUtils,
  },
  data() {
    return {
      optionalData: ["EquipmentId", "ReferenceDesignation", "ProjectId"],
    };
  },
  methods: {
    ...mapActions("RefObj", [
      "setRefObjModelValue",
      "setRefObjModelType",
      "removeReferencedObject",
      "addReferencedObject",
    ]),
    ...mapActions("ActSteps", ["setRefObjParty"]),

    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },
    setValue(index, event) {
      let payload = {
        index: index,
        value: event.target.value,
      };
      this.$store.dispatch("RefObj/setRefObjModelValue", payload);
    },
    setType(index, event) {
      let payload = {
        index: index,
        type: event.target.value,
      };
      this.$store.dispatch("RefObj/setRefObjModelType", payload);
    },

    pushToParty() {
      this.$store.dispatch("ActSteps/setRefObjParty", true);
      this.$router.push("/metadata/refobject/party");
    },
  },
  computed: {
    ...mapGetters("RefObj", [
      "getReferencedObject_model",
      "getRefObjModelValue",
      "getRefObjModelType",
    ]),
    ...mapGetters(["getIsGeneralDocu"])
  },

};
</script>
