<template>
  <div>
    <nav>
      <router-link to="/metadata/files/fileupload" :class="{ rldisabled: !getFileUpload }" >
        <span>
          {{$t("routeFileUpload")}}
        </span>
      </router-link>

      <router-link to="/metadata/files/fileselection" :class="{ rldisabled: !getFileSelect }">
        <span>
           {{$t("routeFileSelect")}}
        </span>
      </router-link>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "SideMenuRefObCom",
  computed: {
    ...mapGetters("ActSteps", ["getFileUpload", "getFileSelect"])
  },
};
</script>
<style scoped src="@/assets/process_steps.css">

</style>

