export default {
    namespaced: true,
    state() {
      return {
        excelData: [],
        pdfCompleted: false,
        engTagSet: new Set(),
      }
    },

    getters: {
        getExcelData(state){
            return state.excelData
        },
        getPdfCompleted(state){
            return state.pdfCompleted
        },
        getEngTagSet(state){
            return state.engTagSet
        },
    },

    mutations: {
        setExcelData(state, payload){
            state.excelData = payload
        },
        setPdfCompleted(state, payload){
            state.pdfCompleted = payload
        },
        setEngTagSet(state, payload){
            state.engTagSet = payload
        },
        clearEngTagSet(state){
                state.engTagSet = new Set()       
        },
        addEngTagSetItem(state, payload){
            state.engTagSet.add(payload)
        }
    },

    actions: {
        setExcelData(context, payload){
            context.commit("setExcelData", payload)
        },
        setPdfCompleted(context, payload){
            context.commit("setPdfCompleted", payload)
        },
        setEngTagSet(context, payload){
            context.commit("setEngTagSet", payload)
        },
        clearEngTagSet(context){
            context.commit("clearEngTagSet")
        },
        addEngTagSetItem(context, payload){
            context.commit("addEngTagSetItem", payload)
        },
    }
}