// import { createStore } from "vuex";
import OrgDataTempl from "./modules/OrgDataTemplStore.js";
import DocIdTempl from "./modules/DocIdTemplStore";
import Files from "./modules/FileStore";
import Classification from "./modules/ClassificationStore";
import RefObj from "./modules/RefObjStore";
import DocVer from "./modules/DocVersionStore";
import ActivationSteps from "./modules/ActivationStepsStore";
import ViewerStore from "./modules/ViewerStore.js";
import Bulk from "./modules/BulkUploadStore.js"

import Vuex from "vuex";
import { createStore } from "vuex-extensions";

export default createStore(Vuex.Store, {
  modules: {
    OrgData: OrgDataTempl,
    DocId: DocIdTempl,
    Files: Files,
    Classification: Classification,
    RefObj: RefObj,
    DocVer: DocVer,
    ActSteps: ActivationSteps,
    ViewerStore: ViewerStore,
    Bulk: Bulk,
  },
  state() {
    return {
      user: '',
      isAuthenticated: false,
      isContainer: false,
      token: "",
      fileSelected: false,
      nextRound: false,
      filesConfirmed: false,
      documentationName: '',
      MainXmlDocIdArray: [],
      MainPdfDataArray: [],
      isInstance: undefined,
      isTpuDocumentation: false,
      editingFinished: false,
      isGeneralDocu: undefined,
      objIdLengthOptData: 9,
      isPackageUnit: false,
    };
  },
  getters: {
    getObjIdLengthOptData(state){
      return state.objIdLengthOptData
    },
    getIsPackageUnit(state){
      return state.isPackageUnit
    },
    getUser(state){
      return state.user
    },
    getIsGeneralDocu(state){
      return state.isGeneralDocu
    },
    getEditing(state){
      return state.editingFinished
    },
    getIsTpuDocumentation(state) {
      return state.isTpuDocumentation;
    },
    getIsAuthenticated(state) {
      return state.isAuthenticated;
    },
    getToken(state) {
      return state.token;
    },

    getMainXmlDocIdArray(state) {
      return state.MainXmlDocIdArray;
    },
    getMainDocumentId: (state) => (index) => {
      return state.MainXmlDocIdArray[index].DocumentId;
    },
    getMainDomainId: (state) => (index) => {
      return state.MainXmlDocIdArray[index].DomainId;
    },
    getMainIsPrimary: (state) => (index) => {
      return state.MainXmlDocIdArray[index].IsPrimary;
    },
    getMainDocumentDomainId: (state) => (index) => {
      return state.MainXmlDocIdArray[index].DocumentDomainId;
    },

    getMainPdfDataArray(state) {
      return state.MainPdfDataArray;
    },
    getIsContainer(state) {
      return state.isContainer;
    },
    getFileSelected(state) {
      return state.fileSelected;
    },
    getNextRound(state) {
      return state.nextRound;
    },
    getFilesConfirmed(state) {
      return state.filesConfirmed;
    },
    getDocumentIdTemplate(state) {
      return state.documentIdTemplate;
    },
    getDocumentationName(state) {
      return state.documentationName;
    },
    getIsInstance(state) {
      return state.isInstance;
    },
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("token")) {
        // @Test
        localStorage.removeItem("token");
        // state.token = localStorage.getItem("token");
        // state.isAuthenticated = true;
        state.token = "";
        state.isAuthenticated = false;
      } else {
        state.token = "";
        state.isAuthenticated = false;
      }
    },
    setIsGeneralDocu(state, payload){
      state.isGeneralDocu = payload
    },
    setIsPackageUnit(state, payload){
      state.isPackageUnit = payload
    },
    setObjIdLengthOptData(state, payload){
      state.objIdLengthOptData = payload
    },
    setEditing(state, payload) {
      state.editingFinished = payload;
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      state.token = "";
      state.isAuthenticated = false;
    },
    setContainerState(state, is_container) {
      state.isContainer = is_container;
    },
    setFileSelectionState(state, selected) {
      state.fileSelected = selected;
    },
    setNextRound(state, value) {
      state.nextRound = value;
    },
    setFilesConfirmed(state, value) {
      state.filesConfirmed = value;
    },
    setDocumentationName(state, docName) {
      state.documentationName = docName;
    },
    addMainXmlDocIdItem(state, payload) {
      state.MainXmlDocIdArray.push(payload);
    },
    addMainPdfDataArray(state, payload) {
      state.MainPdfDataArray.push(payload);
    },
    setIsInstance(state, payload) {
      state.isInstance = payload;
    },

    setIsTpuDocumentation(state) {
      state.isTpuDocumentation = !state.isTpuDocumentation;
    },
    setUser(state, payload){
      state.user = payload
    }
  },

  actions: {
    setContainerState(context, payload){
      context.commit("setContainerState", payload)
    },
    setEditing(context, payload){
      context.commit("setEditing", payload)
    },
    setIsPackageUnit(context, payload){
      context.commit("setIsPackageUnit", payload)
    },
    setObjIdLengthOptData(context, payload){
      context.commit("setObjIdLengthOptData", payload)
    },
    setIsGeneralDocu(context, payload){
      context.commit("setIsGeneralDocu", payload)
    },
    setFileSelectionState(context, payload) {
      context.commit("setFileSelectionState", payload);
    },
    setNextRound(context, payload) {
      context.commit("setNextRound", payload);
    },
    setFilesConfirmationState(context, payload) {
      context.commit("setFilesConfirmed", payload);
    },
    setDocumentationName(context, payload) {
      context.commit("setDocumentationName", payload);
    },
    addMainXmlDocIdItem(context, payload) {
      context.commit("addMainXmlDocIdItem", payload);
    },
    addMainPdfDataArray(context, payload) {
      context.commit("addMainPdfDataArray", payload);
    },
    setIsInstance(context, payload) {
      context.commit("setIsInstance", payload);
    },
    setToken(context, token) {
      context.commit("setToken", token);
    },
    setIsTpuDocumentation(context) {
      context.commit("setIsTpuDocumentation");
    },
    setUser(context, payload){
      context.commit("setUser", payload)
    }
  },
});
