<template>
  <div>
    <div class="card">
      <h3 class="card-header">{{$t("headerRefObjParty")}}:
        <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="headerRefObjParty"
                  data-body="headerRefObjPartyBody"
                  @click.prevent="showmodal"
                ></i></a
            >
      </h3>
      <div class="card-body">
        <form action="" id="RefObjParty">
          <div class="row mb-3">
            <label for="OrganizationId" class="col-sm-3 col-form-label"
              >{{$t("refObjPartyOrgIdInfoTitle")}}:*
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="refObjPartyOrgIdInfoTitle"
                  data-body="refObjPartyOrgIdInfoBody"
                  @click.prevent="showmodal"
                ></i></a
            ></label>
            <div class="col-sm-9">
              <input
                required
                type="text"
                class="form-control"
                id="OrganizationId"
                :value="getOrganizationId1"
                @input="setOrganizationId1($event.target.value)"
                @focusout="singleFault($event.target)"
                @keydown.enter.prevent
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-sm-3 col-form-label" for="roleListInput">
              {{$t("refObjPartyRoleInfoTitle")}}:*
              <!-- <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="refObjPartyRoleInfoTitle"
                  data-body="refObjPartyRoleInfoBody"
                  @click.prevent="showmodal"
                ></i
              ></a> -->
            </label>
            <div class="col-sm-9">
              <select
                name="roleListInput"
                id="roleList"
                class="form-select"
                :value="getRole1"
                @input="setRole1($event.target.value)"
                @keydown.enter.prevent
              >
                <option value="Manufacturer">Manufacturer</option>
                <!-- <option value="Supplier">Supplier</option> -->
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label for="OrganizationName" class="col-sm-3 col-form-label"
              >{{$t("refObjPartyOrgNameInfoTitle")}}:*
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="refObjPartyOrgNameInfoTitle"
                  data-body="refObjPartyOrgNameInfoBody"
                  @click.prevent="showmodal"
                ></i></a
            ></label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                id="OrganizationName"
                required
                :value="getOrganizationName1"
                @input="setOrganizationName1($event.target.value)"
                @focusout="singleFault($event.target)"
                @keydown.enter.prevent
              />
            </div>
          </div>
          <div class="row mb-3">
            <label
              for="OrganizationOfficialName"
              class="col-sm-3 col-form-label"
              >{{$t("refObjPartyOrgOffNInfoTitle")}}:*
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="refObjPartyOrgOffNInfoTitle"
                  data-body="refObjPartyOrgOffNInfoBody"
                  @click.prevent="showmodal"
                ></i></a
            ></label>
            <div class="col-sm-9">
              <input
                required
                type="text"
                class="form-control"
                id="OrganizationOfficialName"
                :value="getOrganizationOfficialName1"
                @input="setOrganizationOfficialName1($event.target.value)"
                @focusout="singleFault($event.target)"
                @keydown.enter.prevent
              />
            </div>
          </div>
          <!-- <div class="row mb-3">
            <div class="col gy-2">
              <button class="btn btn-outline-primary" style="width: 8rem">
                <i class="bi-plus-circle" style="font-size: 1.5rem"></i>
              </button>
            </div>
          </div> -->
          <div class="row mb-3">
            <div class="col-sm-8 align-left mt-2">
              <go-back-util />
            </div>
            <div class="col-sm-4 align-right mt-2">
              <button
                class="btn btn-success bi-caret-right"
                @click.prevent="pushToDescription"
              >
                {{$t("next Step")}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal-utils />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoBackUtil from "../utils/GoBackUtil.vue";
import Fault from "../utils/Fault";
import i18n from "@/i18n";
import ModalUtils from "../utils/ModalsUtils.vue";

export default {
  name: "PartyRefObjCom",

  components: {
    GoBackUtil,
    ModalUtils,
  },
  computed: {
    ...mapGetters("RefObj", [
      "getPartyType1_model",
      "getOrganizationId1",
      "getRole1",
      "getOrganizationName1",
      "getOrganizationOfficialName1",
    ]),
    ...mapGetters("OrgData", ["getPartyType_model"]),
    ...mapGetters(["getIsGeneralDocu"])
  },
  methods: {
    ...mapActions("RefObj", [
      "setPartyType1",
      "setOrganizationId1",
      "setOrganizationName1",
      "setOrganizationOfficialName1",
      "setRole1",
    ]),
    ...mapActions("ActSteps", [
      "setRefObjDesc",
      "setDocVersion",
      "setDocVersionParty",
    ]),

    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },

    pushToDescription() {
      if (Fault.faultControl(document)) {
        this.$store.dispatch("ActSteps/setDocVersion", true);
        this.$store.dispatch("ActSteps/setDocVersionParty", true);
        this.$store.dispatch("ActSteps/setRefObjDesc", true);
        this.$router.push("/metadata/refobject/description");
      } else alert(i18n.global.t("faultMandatory"));
    },
    singleFault(ele) {
      return Fault.singleFault(ele);
    },
  },
  created() {
    let defaultParty = this.getPartyType_model;
    this.setOrganizationId1(defaultParty.OrganizationId);
    this.setOrganizationName1(defaultParty.OrganizationName);
    this.setOrganizationOfficialName1(defaultParty.OrganizationOfficialName);
  },

};
</script>
