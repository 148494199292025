<template>
  <div>
    <div
      class="btn-toolbar mb-3 mt-4"
      role="toolbar"
      aria-label="Toolbar with button groups"
    >
      <div
        class="btn-group position-relative top-50 start-50 translate-middle"
        role="group"
        aria-label="First group"
      >
        <router-link
          v-if="getFilesConfirmed"
          to="/metadata/files/fileselection"
          class="btn btn-outline-secondary me-1"
          :class="{
            bankRed: false,
            bankYellow: false,
            rldisabled: !getFileUpload,
          }"
          >{{$t("navFiles")}}
        </router-link>
        <router-link
          v-if="!getFilesConfirmed"
          to="/metadata/files/fileupload"
          class="btn btn-outline-secondary me-1"
          :class="{
            bankRed: false,
            bankYellow: false,
            rldisabled: !getFileUpload,
          }"
          >{{$t("navFiles")}}
        </router-link>
        <router-link
          to="/metadata/defaultsettings"
          class="btn btn-outline-secondary me-1"
          :class="{ bankRed: false, bankYellow: false, rldisabled: !getOrgData }"
        >
          {{$t("navOrgData")}}
        </router-link>
        <router-link
          to="/metadata/classification"
          class="btn btn-outline-secondary me-1"
          :class="{
            bankRed: false,
            bankYellow: false,
            rldisabled: !getClassification,
          }"
          >{{$t("navClassification")}}
        </router-link>
        <router-link
          to="/metadata/refobject"
          class="btn btn-outline-secondary me-1"
          :class="{
            bankRed: false,
            bankYellow: false,
            rldisabled: !getRefObject,
          }"
          >{{$t("navRefObject")}}
        </router-link>
        <router-link
          to="/metadata/docversion"
          class="btn btn-outline-secondary me-1"
          :class="{
            bankRed: false,
            bankYellow: false,
            rldisabled: !getDocVersion,
          }"
          >{{$t("navDocVersion")}}
        </router-link>
      </div>
    </div>
    <div class="row text-center mb-3">
      <h5>{{ getDocumentationName }}</h5>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BadgeSuccessUtil from "./utils/BadgeSuccessUtil.vue";
export default {
  name: "MetadataNavigationCom",

  components: {
    BadgeSuccessUtil,
  },
  computed: {
    ...mapGetters([
      "getDocumentationName",
      "getIsContainer",
      "getFilesConfirmed",
    ]),
    ...mapGetters("ActSteps", [
      "getFileUpload",
      "getOrgData",
      "getClassification",
      "getRefObject",
      "getDocVersion",
    ]),
  },
};
</script>

<style>
a.router-link-active {
  background-color: #6c757d;
  border-color: #6c757d;
  color: white;
}

.triangleRed::after {
  content: "";
  border-top: 10px solid transparent;
  border-left: 10px solid red;
  border-bottom: 10px solid transparent;
  position: absolute;
  rotate: 45deg;
  opacity: 70%;
  bottom: -7px;
  right: -2px;
}

.btn-overflow {
  overflow: hidden;
}
.bi-check {
  font-size: 1rem;
}

.bankRed::after {
  content: "";
  width: 100%;
  height: 4px;
  background: rgb(112, 121, 139);
  position: absolute;
  opacity: 80%;
  bottom: -7px;
  right: 0%;
}

.bankYellow::after {
  content: "";
  width: 100%;
  height: 4px;
  background: orange;
  position: absolute;
  opacity: 90%;
  bottom: -7px;
  right: 0%;
}
</style>
