<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>
          {{ $t("headerDocTemplates") }}
          <a href="#"
            ><i
              class="bi bi-info-circle"
              data-toggle="modal"
              data-target="#infoModal"
              data-title="headerDocTemplates"
              data-body="docIdHeaderInfoBody"
              @click.prevent="showmodal"
            ></i
          ></a>
        </h4>
      </div>
      <div class="card-body">
        <form action="" id="DocumentTemplates" @submit.prevent>
          <div v-for="(item, index) in getDocumentIdType" :key="item">
            <div class="card-body border rounded mb-2">
              <div class="row mb-3">
                <div
                  class="mb-3 text-primary text-center"
                  v-if="!isDisabled(index)"
                >
                  {{ $t("Edit_Mode") }}
                </div>
                <label for="DomainID" class="col-sm-3 col-form-label"
                  >{{ $t("DomainID") }}:*
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="DomainID"
                      :data-body="
                        'docIdDomainIdInfoBody' +
                        (index < 1 && !getIsGeneralDocu ? '0' : '1')
                      "
                      @click.prevent="showmodal"
                    ></i></a
                ></label>
                <div class="col-sm-9">
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="DomainID"
                    :disabled="isDisabled(index)"
                    :value="getDomainId(index)"
                    @input="changeDomainId(index, $event)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-4">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :checked="getIsPrimary(index)"
                      id="domainId-primary"
                      :disabled="isDisabled(index)"
                      @input="changeIsPrimary(index, $event)"
                    />
                    <label class="form-check-label" for="domainId-primary">
                      is primary:*
                      <a href="#"
                        ><i
                          class="bi bi-info-circle"
                          data-toggle="modal"
                          data-target="#infoModal"
                          data-title="thisTitle"
                          data-body="thisBody"
                          @click.prevent="showmodal"
                        ></i
                      ></a>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label for="DocumentDomainId" class="col-sm-3 col-form-label"
                  >{{ $t("DocumentDomainId") }}:*
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="DocumentDomainId"
                      :data-body="
                        'docIdDocDomainIdInfoBody' +
                        (index < 1 && !getIsGeneralDocu ? '0' : '1')
                      "
                      @click.prevent="showmodal"
                    ></i></a
                ></label>
                <div class="col-sm-9">
                  <input
                    required
                    type="url"
                    class="form-control"
                    id="DocumentDomainId"
                    :value="getDocumentDomainId(index)"
                    :disabled="isDisabled(index)"
                    @input="changeDocumentDomainId(index, $event)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  />
                </div>
              </div>
              <div class="row mb-3">
                <label for="DocumentId" class="col-sm-3 col-form-label"
                  >{{ $t("DocumentID") }}:*
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="DocumentID"
                      :data-body="
                        'docIdDocIdInfoBody' +
                        (index < 1 && !getIsGeneralDocu ? '0' : '1')
                      "
                      @click.prevent="showmodal"
                    ></i></a
                ></label>
                <div class="col-sm-9">
                  <input
                    required
                    type="url"
                    class="form-control"
                    id="DocumentId"
                    :value="getDocumentId(index)"
                    @input="changeDocumentId(index, $event.target.value)"
                    @focusout="checkDocId(index, $event.target)"
                    @keydown.enter.prevent
                  />
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-6" v-if="!getIsGeneralDocu">
                  <p>
                    <a :href="getUrl()" target="_blank">{{
                      getExplanationName()
                    }}</a>
                  </p>
                  <p>
                    <a :href="getUrl2()" target="_blank">{{
                      $t("BIGClassSystem")
                    }}</a>
                  </p>
                </div>
                <div
                  class="col-sm-12 text-end mt-1"
                  v-if="
                    !(
                      getDocumentIdTypeId(index) == undefined ||
                      getDocumentIdTypeId(index) == ''
                    ) && !getNextRound
                  "
                >
                  <a href="#">
                    <i
                      class="bi bi-trash icon-secondary"
                      data-toggle="modal"
                      data-target="#confirmDeleteModal"
                      @click.prevent="showDeleteModal(index)"
                    ></i
                  ></a>
                  <span class="text-secondary">
                    {{ $t("delete_template") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              (getDocumentIdType.length == 1 ||
                getDocumentIdType.length == 0) &&
              !getNextRound
            "
          >
            <h5 v-if="getDocumentIdType.length == 1">
              {{ $t("headerAddDocId") }}
            </h5>
            <h5 v-else></h5>
            <div class="card-body border rounded mt-2 border-dark">
              <div class="row mb-3">
                <label for="Template" class="col-sm-3 col-form-label"
                  >{{ $t("chooseTemp") }}
                </label>
                <div class="col-sm-9">
                  <select
                    :required="
                      getDocumentIdType.length == 0 &&
                      getUserChooseTemplate == 0
                    "
                    class="form-select"
                    id="DocTemplate"
                    v-model="docTemplates.id"
                    @change.prevent="setDocTemplate(docTemplates.id)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  >
                    <option
                      v-for="item in docTemplates"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.DomainId }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mb-3">
                <div class="row mb-3">
                  <h3 class="text-primary">{{ $t("or") }}</h3>
                </div>
                <div class="col-lg-3 col-form-label">
                  {{ $t("createTemp") }}
                </div>
                <div class="col-lg-3">
                  <button
                    class="btn btn-outline-primary"
                    style="width: 8rem"
                    @click.prevent="addDocumentIdType"
                  >
                    <i class="bi-plus-circle" style="font-size: 1.5rem"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-8 align-left mt-2">
              <go-back-util />
            </div>
            <div class="col-sm-4 align-right mt-2">
              <button
                class="btn btn-success bi-caret-right"
                @click.prevent="createDocIdTemplate"
              >
                {{ $t("next Step") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal-utils />
    <div
      class="modal fade"
      id="confirmDeleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmDeleteModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmDeleteModalTitle"></h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary float-start"
              data-dismiss="modal"
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary float-end"
              data-dismiss="modal"
              @click="deleteTemplate(delIndex)"
            >
              {{ $t("delete_template") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GoBackUtil from "../utils/GoBackUtil.vue";
import { mapActions, mapGetters } from "vuex";
import Fault from "../utils/Fault";
import i18n from "@/i18n";
import ModalUtils from "../utils/ModalsUtils.vue";
// import JSZip from "jszip";
export default {
  name: "DocumentIdCom",
  data() {
    return {
      docTemplates: [],
      fail: [],
      delIndex: undefined,
    };
  },
  methods: {
    addFail(singleFail) {
      if (this.fail.find((item) => item == singleFail) == undefined) {
        this.fail.push(singleFail);
      }
    },
    getUrl() {
      if (process.env.NODE_ENV !== "production" && this.getIsTpuDocumentation) {
        return "http://localhost:8000/api/v1/dociddescription/?pk=1";
      } else if (
        process.env.NODE_ENV !== "production" &&
        !this.getIsTpuDocumentation
      ) {
        return "http://localhost:8000/api/v1/dociddescription/?pk=2";
      } else if (this.getIsTpuDocumentation) {
        return window.location.origin + "/api/v1/dociddescription/?pk=1";
      } else if (!this.getIsTpuDocumentation) {
        return window.location.origin + "/api/v1/dociddescription/?pk=2";
      }
    },
    getUrl2() {
      if (process.env.NODE_ENV !== "production") {
        return "http://localhost:8000/api/v1/dociddescription/?pk=3";
      } else {
        return window.location.origin + "/api/v1/dociddescription/?pk=3";
      }
    },
    getExplanationName() {
      if (this.getIsTpuDocumentation) {
        return i18n.global.t("doIdTPU");
      } else {
        return i18n.global.t("doIdNonTPU");
      }
    },
    checkDocId(index, ele) {
      const faultCode = this.singleFault(ele);
      if (!faultCode) {
        this.addFail(i18n.global.t("faultMandatory"));
      } else {
        this.deletePrimaryFault(i18n.global.t("faultMandatory"));
      }
      if (index == 0 && !this.getIsGeneralDocu) {
        // check BIG Spec
        let value = ele.value.trim();
        const invalidCharactersRegex =
          /(BIG_)(\w){2}(-)\D{1}\d{3}(-)\D{1}\d{2}(-VD)(-)\D{1}\d{3}(-)([\s\w]{1,20})(-R0_0){1}(-F-){1}\d{1,2}-([-_\s\w]{1,50})$/;
        const match = value.match(invalidCharactersRegex);
        if (!match) {
          ele.classList.add("border-danger");
          var listOfRegexToCheck = [
            /BIG_/,
            /\w{2}/,
            /-/,
            /\D{1}\d{3}/,
            /-/,
            /\D{1}\d{2}/,
            /-/,
            /VD/,
            /-/,
            /\D{1}\d{3}/,
            /-/,
            /[\s\w]{1,20}/,
            /-/,
            /R0_0{1}/,
            /-/,
            /F/,
            /-{1}/,
            /\d{1,2}/,
            /-/,
            /[-_\s\w]{1,50}$/,
          ];
          var regexSum = new RegExp();
          var attribute = "Start";
          for (let index in listOfRegexToCheck) {
            regexSum = new RegExp(
              regexSum.source + listOfRegexToCheck[index].source
            );
            var partialMatch = value.match(regexSum);
            if (partialMatch !== null) {
              try {
                attribute = partialMatch[0];
              } catch (error) {
                console.log(error)
              }
            } 
          }
          this.addFail(i18n.global.t("faultBigSpec", [attribute])); 
        } else {
          this.deletePrimaryFault(i18n.global.t("faultBigSpec"));
          ele.classList.remove("border-danger");
        }
      }
      this.pushAlert();
    },
    pushAlert() {
      if (this.fail.length > 0) {
        let Message = "";
        this.fail.forEach(function (item) {
          Message = Message + item + "\n";
        });
        alert(Message);
      }
    },
    singleFault(ele) {
      return Fault.singleFault(ele);
    },
    setUserActed(action) {
      this.$store.dispatch("DocId/setUserChooseTemplate", action);
    },
    setDocTemplate(key) {
      var docTemplate = this.docTemplates.find(function (element) {
        return element.id == key;
      });
      let payload = {
        index: this.getDocumentIdType.length,
        DocumentIdTyp: docTemplate,
      };
      this.$store.dispatch("DocId/addDocumentIdType");
      this.$store.dispatch("DocId/setDocumentIdType", payload);
      this.setUserActed(1);
    },
    isDisabled(index) {
      if ((index == 0 && !this.getIsGeneralDocu) || this.getNextRound) {
        return true;
      } else {
        return false;
      }
    },
    changeDocumentId(index, value) {
      const pdfVersionRegex =
        /(BIG_)(\w){2}(-)\D{1}\d{3}(-)\D{1}\d{2}(-VD)(-)\D{1}\d{3}([-\w]{0,21})?(-R0_0){1}(-F-){1}\d{1,2}/;
      const match = value.match(pdfVersionRegex);

      if (match) {
        var lastIndex = match[0].lastIndexOf("-");
        this.$store.dispatch(
          "DocVer/setDocVersionId4",
          match[0].substring(lastIndex + 1)
        );
      }
      let payload = {
        index: index,
        DocumentId: value,
      };
      this.$store.dispatch("DocId/setDocumentId", payload);
    },
    changeDomainId(index, event) {
      let payload = {
        index: index,
        DomainId: event.target.value,
      };
      this.$store.dispatch("DocId/setDomainId", payload);
    },
    changeDocumentDomainId(index, event) {
      let payload = {
        index: index,
        DocumentDomainId: event.target.value,
      };
      this.$store.dispatch("DocId/setDocumentDomainId", payload);
    },
    changeIsPrimary(index) {
      this.deletePrimaryFault(i18n.global.t("faultIsPrimary0"));
      this.deletePrimaryFault(i18n.global.t("faultIsPrimary2"));
      var primary = !this.getIsPrimary(index);
      let payload = {
        index: index,
        IsPrimary: primary,
      };
      this.$store.dispatch("DocId/setIsPrimary", payload);
      var isPrimary = this.checkIsPrimary();
      if (isPrimary != 1) {
        this.addFail(i18n.global.t("faultIsPrimary" + isPrimary));
        this.pushAlert();
      }
    },

    deletePrimaryFault(faultValue) {
        this.fail = this.fail.filter((x) => !x.includes(faultValue.substring(".")[0]))
    },
    checkIsPrimary() {
      var isPrimaryCounter = 0;
      this.getDocumentIdType.forEach((element) => {
        if (element.IsPrimary) {
          isPrimaryCounter++;
        }
      });
      return isPrimaryCounter;
    },
    async deleteTemplate(index) {
      await axios
        .delete("api/v1/doctemplate/" + this.getDocumentIdTypeId(index) + "/")
        .then(async (resp) => {
          this.removeDocumentIdType(index);
          if (
            (this.getDocumentIdType.length == 1 && !this.getIsGeneralDocu) ||
            this.getDocumentIdType.length == 0
          ) {
            this.setUserActed(0);
          }
          await axios
            .get("api/v1/doctemplate/")
            .then((resp) => {
              this.docTemplates = resp.data;
            })
            .catch((error) => {
              alert(error);
            });
          // alert("Template successfully deleted");
        })
        .catch((error) => {
          alert(error.request.responseText);
        });
    },
    async createDocIdTemplate() {
      axios.defaults.headers.common["Authorization"] = "Token " + this.getToken;
      this.deletePrimaryFault(i18n.global.t("faultMandatory"));
      if (!Fault.faultControl(document)) {
        this.addFail(i18n.global.t("faultMandatory"));
      }
      this.deletePrimaryFault(i18n.global.t("faultIsPrimary0"));
      this.deletePrimaryFault(i18n.global.t("faultIsPrimary2"));
      var isPrimary = this.checkIsPrimary();
      if (isPrimary != 1) {
        // this.fail.push(i18n.global.t("faultIsPrimary" + isPrimary));
        this.addFail(i18n.global.t("faultIsPrimary" + isPrimary));
      }
      if (this.fail.length == 0) {
        if (!this.getNextRound) {
          for (let i = 0; i < this.getDocumentIdType.length; i++) {
            if (this.getDocumentIdTypeId(i) !== undefined) {
              await axios
                .patch(
                  "api/v1/doctemplate/" + this.getDocumentIdTypeId(i) + "/",
                  this.getDocumentIdTypeInstance(i)
                )
                .then((resp) => {})
                .catch((error) => {
                  alert(error.request.responseText);
                });
            } else if (!(!this.getIsGeneralDocu && i == 0)) {
              await axios
                .post("api/v1/doctemplate/", this.getDocumentIdTypeInstance(i))
                .then((resp) => {})
                .catch((error) => {
                  alert(error.request.responseText);
                });
            }
          }
        }
        this.$router.push("/metadata/classification");
        this.$store.dispatch("ActSteps/setClassification", true);
      } else this.pushAlert();
    },

    ...mapActions("DocId", [
      "setUserChooseTemplate",
      "setDomainId",
      "setIsPrimary",
      "setDocumentDomainId",
      "setDocumentIdType",
      "setDocumentId",
      "addDocumentIdType",
      "removeDocumentIdType",
    ]),
    ...mapActions("ActSteps", ["setClassification"]),
    ...mapActions("DocVer", ["setDocVersionId4"]),
    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget);
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },
    showDeleteModal(index) {
      this.delIndex = index;
      $("#confirmDeleteModal").on("show.bs.modal", function (event) {
        // var button = $(event.relatedTarget); // Button that triggered the modal
        var title = "delete_template";
        var body = "reallyDelete";
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },
  },
  components: {
    GoBackUtil,
    ModalUtils,
  },
  computed: {
    ...mapGetters("DocId", [
      "getUserChooseTemplate",
      "getDomainId",
      "getIsPrimary",
      "getDocumentDomainId",
      "getDocumentIdType",
      "getDocumentId",
      "getDocumentIdTypeInstance",
      "getDocumentIdTypeId",
    ]),
    ...mapGetters([
      "getNextRound",
      "getToken",
      "getIsTpuDocumentation",
      "getIsGeneralDocu",
    ]),
    ...mapGetters("Files", ["getFileArray", "getPdfFile"]),
  },
  //
  async activated() {
    if (!this.getIsGeneralDocu) {
      if (this.getDocumentIdType.length == 0) {
        this.$store.dispatch("DocId/addDocumentIdType");
      }
      let payload = {
        index: 0,
        DomainId:
          "BIG_00-0000-000-PM-010-010 Site and Plant Structure and Eng Doc Codes",
      };
      this.$store.dispatch("DocId/setDomainId", payload);
      let payload1 = {
        index: 0,
        DocumentDomainId:
          "BIG_00-0000-000-PM-010-010 Site and Plant Structure and Eng Doc Codes",
      };
      this.$store.dispatch("DocId/setDocumentDomainId", payload1);
      let payload2 = {
        index: 0,
        IsPrimary: true,
      };
      this.$store.dispatch("DocId/setIsPrimary", payload2);
      // new
      let fileIndex = undefined;
      this.getFileArray.forEach((file, index) => {
        if (file.finished === "FILE_SELECTED") {
          fileIndex = index;
        }
      });
      try {
        const pdfFile = this.getPdfFile(fileIndex);
        var lastPdfIndex = pdfFile.name.lastIndexOf(".pdf");
        const documentName = pdfFile.name.substring(0, lastPdfIndex);
        const invalidCharactersRegex =
          /(BIG_)(\w){2}(-)\D{1}\d{3}(-)\D{1}\d{2}(-VD)(-)\D{1}\d{3}([-\w]{0,21})?(-R0_0){1}(-F-){1}\d{1,2}([-\s\w]{0,51})?$/;
        const matchFull = documentName.match(invalidCharactersRegex);

        if (
          matchFull &&
          (this.getDocumentId(0) == undefined || this.getDocumentId(0) == "")
        ) {
          this.changeDocumentId(0, documentName);
        }
      } catch (error) {
        // alert(i18n.global.t("unspecificError"));
      }
      // end new
    }
    await axios
      .get("api/v1/doctemplate/")
      .then((resp) => {
        this.docTemplates = resp.data;
      })
      .catch((error) => {
        alert(error);
      });
  },
};
</script>

<style scoped>
.col-form-label {
  padding-top: 0rem;
}
.icon-secondary {
  color: #6c757d;
  padding-right: 0.5rem;
}
</style>
