<template>
  <div>
    <div class="card">
      <h3 class="card-header">{{ $t("headerDocLifeCycle") }}:</h3>
      <div class="card-body">
        <form action="" id="RefObjParty">
          <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label"
              >{{ $t("docVersionOrgIdInfoTitle") }}:*
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="docVersionOrgIdInfoTitle"
                  data-body="docVersionOrgIdInfoBody"
                  @click.prevent="showmodal"
                ></i></a
            ></label>
            <div class="col-sm-9">
              <input
                required
                type="text"
                class="form-control"
                id=""
                :value="getPartyType7OrganizationId"
                @input="setOrganizationId7($event.target.value)"
                @focusout="singleFault($event.target)"
                @keydown.enter.prevent
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-sm-3 col-form-label" for="Responsible">
              {{ $t("docVersionRoleInfoTitle") }}:*
            </label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                id="Responsible"
                value="Responsible"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label for="lifeCycleOrgName" class="col-sm-3 col-form-label"
              >{{ $t("docVersionOrgNameInfoTitle") }}:*
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="docVersionOrgNameInfoTitle"
                  data-body="docVersionOrgNameInfoBody"
                  @click.prevent="showmodal"
                ></i></a
            ></label>
            <div class="col-sm-9">
              <input
                required
                type="text"
                class="form-control"
                id="lifeCycleOrgName"
                :value="getPartyType7OrganizationName"
                @input="setOrganizationName7($event.target.value)"
                @focusout="singleFault($event.target)"
                @keydown.enter.prevent
              />
            </div>
          </div>
          <div class="row mb-3">
            <label for="lifeCycOrgOfficial" class="col-sm-3 col-form-label"
              >{{ $t("docVersionOrgOffNameInfoTitle") }}:*
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="docVersionOrgOffNameInfoTitle"
                  data-body="docVersionOrgOffNameInfoBody"
                  @click.prevent="showmodal"
                ></i></a
            ></label>
            <div class="col-sm-9">
              <input
                required
                type="text"
                class="form-control"
                id="lifeCycOrganOfficial"
                :value="getPartyType7OrganizationOfficialName"
                @input="setOrganizationOfficialName7($event.target.value)"
                @focusout="singleFault($event.target)"
                @keydown.enter.prevent
              />
            </div>
          </div>

          <div class="card border rounded mt-2 border-dark">
            <div class="card-body">
              <!-- v-for div -->
              <div
                v-for="(comment, index) in getCommentsType_model"
                :key="index"
              >
                <div class="row mb-3">
                  <label for="Comment" class="col-sm-3 col-form-label"
                    >{{ $t("docVersionDocCommentInfoTitle") }}:
                    <a href="#"
                      ><i
                        class="bi bi-info-circle"
                        data-toggle="modal"
                        data-target="#infoModal"
                        data-title="docVersionDocCommentInfoTitle"
                        data-body="docVersionDocCommentInfoBody"
                        @click.prevent="showmodal"
                      ></i></a
                  ></label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      id="Comment"
                      :value="getComments(index)"
                      @input="setComm(index, $event)"
                      @keydown.enter.prevent
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-sm-3 col-form-label" for="commentlanguage">
                    {{ $t("docVersionDocLangInfoTitle") }}:
                  </label>
                  <div class="col-sm-4">
                    <select
                      class="form-control"
                      id="commentlanguage"
                      :value="getCommentsLanguage(index)"
                      @input="setCommLang(index, $event)"
                      @keydown.enter.prevent
                    >
                      <option
                        v-for="item in getLanguage()"
                        :key="item[0]"
                        :value="item[0]"
                      >
                        {{ item[0] }} - {{ item[1] }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-2 mt-2 mt-sm-0" v-if="index > 0">
                    <button
                      class="btn btn-outline-danger"
                      style="width: 3rem"
                      @click.prevent="removeComment(index)"
                    >
                      <i class="bi-x-circle" style="font-size: 1rem"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- ------------------ -->
              <div class="row mb-3">
                <div class="col sm-2">
                  <button
                    class="btn btn-outline-primary"
                    style="width: 4rem"
                    @click.prevent="addComment"
                  >
                    <i class="bi-plus-circle" style="font-size: 1rem"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3 mt-3">
            <label for="SetDate" class="col-sm-3 col-form-label"
              >{{ $t("docVersionDocDateInfoTitle") }}:
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="docVersionDocDateInfoTitle"
                  data-body="docVersionDocDateInfoBody"
                  @click.prevent="showmodal"
                ></i
              ></a>
            </label>
            <div class="col-sm-4">
              <input
                type="date"
                class="form-control"
                id="SetDate"
                :value="getLifeCycleSetDate"
                @input="setLifeCycleSetDate($event.target.value)"
                @keydown.enter.prevent
              />
            </div>
            <label class="col-sm-2 col-form-label" for="status">
              {{ $t("docVersionDocStatusInfoTitle") }}:*
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="docVersionDocStatusInfoTitle"
                  data-body="docVersionDocStatusInfoBody"
                  @click.prevent="showmodal"
                ></i
              ></a>
            </label>
            <div class="col-sm-3">
              <select
                name="status"
                id="status"
                class="form-select"
                :value="getLifeCycleStatusValue"
                @input="setLifeCycleStatusValue($event.target.value)"
                @keydown.enter.prevent
              >
                <option value="Released">Released</option>
                <option value="InReview">InReview</option>
              </select>
            </div>
          </div>
          <!-- really  reasonable to have party several times?-->
          <!-- <div class="row mb-3">
            <div class="col gy-2">
              <button class="btn btn-outline-primary" style="width: 8rem">
                <i class="bi-plus-circle" style="font-size: 1.5rem"></i>
              </button>
            </div>
          </div> -->
          <div class="row mb-3">
            <div class="col-sm-9 align-left mt-2">
              <go-back-util />
            </div>
            <div class="col-sm-3 align-right mt-2">
              <button
                class="btn btn-success bi-caret-right"
                @click.prevent="pushToDocRel"
              >
                <span v-if="documentationFinished()">{{
                  $t("buttonDownload")
                }}</span>
                <span v-else>{{ $t("buttonNextFile") }}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal-utils />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoBackUtil from "../utils/GoBackUtil.vue";
import createDocumentation from "../createDocumentation";
import createXML from "../createXML";
import Fault from "../utils/Fault";
import LangCodes from "../utils/LangCodes";
import i18n from "@/i18n";
import ModalUtils from "../utils/ModalsUtils.vue";
import axios from "axios";

export default {
  name: "LifeCycleDocVerCom",

  components: {
    GoBackUtil,
    ModalUtils,
  },
  methods: {
    ...mapActions("DocVer", [
      "setOrganizationId7",
      "setPartyType7",
      "setOrganizationName7",
      "setOrganizationOfficialName7",
      "addComment",
      "setComments",
      "setCommentLanguage",
      "setLifeCycleSetDate",
      "setLifeCycleStatusValue",
      "removeComment",
    ]),
    ...mapActions(["setToken", "setEditing", "setUser", "setIsGeneralDocu"]),

    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },

    singleFault(ele) {
      return Fault.singleFault(ele);
    },
    getLanguage() {
      return LangCodes.getLang();
    },
    async pushToDocRel() {
      // this.$router.push("/metadata/docversion/relationship/")
      if (Fault.faultControl(document)) {
        createDocumentation.generateDoc();
        if (this.documentationFinished()) {
          if (this.getIsContainer) {
            let documentsArray = [];
            for (let i = 0; i < this.getFileArray.length; i++) {
              documentsArray.push(
                await createDocumentation.createDocumentContainer(i)
              );
            }
            if (this.getZipFile(0) != "") {
              for (let i = 0; i < this.getZipArray.length; i++) {
                documentsArray.push([
                  this.getZipFile(i),
                  this.getZipFile(i).name,
                ]);
              }
            }
            // @ToDo alle Metadaten ersetzen, die zu ersetzen sind und XML austauschen
            let mainXML = createXML.createVdiMain();
            const mainPDF = await createXML.createMainPDF();
            let content = await createDocumentation.zipZip(
              documentsArray,
              mainXML,
              mainPDF
            );
            let contentArray = [content, this.getDocumentationName];
            this.downloadDocumentContainer(contentArray);
          } else {
            let docZip = await createDocumentation.createDocumentContainer(0);
            this.downloadDocumentContainer(docZip);
          }
          // add axios post for logging
          await axios
            .post("api/v1/logging/", {
              documentationName: this.getDocumentationName,
            })
            .then((resp) => {
              // do nothing
            })
            .catch((error) => {
              alert(error.request.responseText);
            });
          // end
          const token = this.getToken;
          const user = this.getUser;
          const generalDoku = this.getIsGeneralDocu;
          this.$store.reset();
          this.$store.dispatch("setToken", token);
          this.$store.dispatch("setUser", user);
          this.$store.dispatch("setEditing", true),
            this.$store.dispatch("setIsGeneralDocu", generalDoku);
          this.$router.push("/");
        } else {
          this.$router.push("/metadata/files/fileselection");
        }
      } else alert(i18n.global.t("faultMandatory"));
    },

    downloadDocumentContainer(contentArray) {
      const url = URL.createObjectURL(contentArray[0]);
      const link = document.createElement("a");
      link.href = url;
      link.download = contentArray[1];
      link.click();
    },

    documentationFinished() {
      let fileNotProcessed = this.getFileArray.findIndex(
        (file) => file.finished === "FILE_NOT_SELECTED"
      );
      if (fileNotProcessed < 0) {
        return true;
      } else return false;
    },
    setCommLang(index, event) {
      let payload = {
        index: index,
        language: event.target.value,
      };
      this.$store.dispatch("DocVer/setCommentLanguage", payload);
    },
    setComm(index, event) {
      let payload = {
        index: index,
        comment: event.target.value,
      };
      this.$store.dispatch("DocVer/setComments", payload);
    },
  },
  computed: {
    ...mapGetters("OrgData", ["getPartyType_model"]),
    ...mapGetters("DocVer", [
      "getPartyType7_model",
      "getPartyType7OrganizationId",
      "getPartyType7OrganizationName",
      "getPartyType7OrganizationOfficialName",
      "getLifeCycleSetDate",
      "getLifeCycleStatusValue",
      "getComments",
      "getCommentsLanguage",
      "getCommentsType_model",
      "getLifeCyleStatus_model",
    ]),
    ...mapGetters("Files", ["getFileArray", "getZipArray", "getZipFile"]),
    ...mapGetters([
      "getNextRound",
      "getIsContainer",
      "getDocumentationName",
      "getToken",
      "getUser",
      "getIsGeneralDocu",
    ]),
  },

  created() {
    let defaultParty = this.getPartyType_model;
    this.setOrganizationId7(defaultParty.OrganizationId);
    this.setOrganizationName7(defaultParty.OrganizationName);
    this.setOrganizationOfficialName7(defaultParty.OrganizationOfficialName);
  },
};
</script>
