<template>
  <div>
    <nav>
      <router-link to="/metadata/refobject/objectid" :class="{ rldisabled: !getRefObjObjId }">
        <span>
          {{$t("routeObjectId")}}
        </span></router-link
      >

      <router-link to="/metadata/refobject/optional-data" :class="{ rldisabled: !getRefObjOptData }">
        <span>
          {{$t("routeOptionalVendorMetadata")}}
        </span></router-link
      >

      <router-link to="/metadata/refobject/party" :class="{ rldisabled: !getRefObjParty }">
        <span>
          {{$t("routeHeaderRefObjParty")}}
        </span></router-link
      >

      <router-link to="/metadata/refobject/description" :class="{ rldisabled: !getRefObjDesc }">
        <span>
          {{$t("routeHeaderRefObjDesc")}}
        </span></router-link
      >
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "SideMenuRefObCom",
  computed: {
    ...mapGetters("ActSteps", ["getRefObjObjId", "getRefObjOptData", "getRefObjParty", "getRefObjDesc"])
  },
};
</script>
<style scoped src="@/assets/process_steps.css">

</style>
