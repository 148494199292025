<template>
  <div>
    <nav>
      <router-link to="/metadata/defaultsettings/documentIdDomain/" :class="{ rldisabled: !getOrgMetaData }">
        <span>
          {{$t("routeOrganizationMetadata")}}
        </span>
      </router-link>
      <router-link to="/metadata/defaultsettings/documentId/" :class="{ rldisabled: !getOrgDocDomain }">
        <span>
          {{$t("routeDocumentDomain")}}
        </span>
      </router-link>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "SideMenuOrgDataCom",
  computed: {
    ...mapGetters("ActSteps", ["getOrgDocDomain", "getOrgMetaData"])
  },
};
</script>

<style scoped src="@/assets/process_steps.css"></style>
