<template>
  <div>
    <div class="row">
      <!-- :class="getIsPackageUnit ? 'col-md-6' : 'col-md-8'" -->
      <div
        :class="
          $route.path != '/metadata/bulkupload' ? 'col-md-6' : 'col-md-12'
        "
        v-if="$route.path != '/vdi2770viewer/files'"
      >
        <div
          class="card mx-auto d-block"
          style="margin-top: 1rem"
          v-if="getIsContainer"
        >
          <div class="card-header">
            <div
              class="text-center d-flex align-items-center justify-content-around"
            >
              <h4>{{ $t("PDFFiles") }}</h4>
            </div>
            <div
              class="text-center d-flex align-items-center justify-content-around"
              v-if="$route.path != '/metadata/bulkupload'"
            >
              <div
                class="form-check form-switch ms-2"
                id="flexSwitchNativeFile"
              >
                <label
                  class="form-check-label"
                  for="flexSwitchNativeFileInput"
                  id="switchNativeFile"
                  ><h5>
                    {{ $t("inclNativeFile") }}
                    <a href="#"
                      ><i
                        class="bi bi-info-circle"
                        data-toggle="modal"
                        data-target="#infoModal"
                        data-title="inclNativeFile"
                        data-body="inclNativeFileInfoBody"
                        @click.prevent="showmodal"
                      ></i
                    ></a></h5
                ></label>

                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchNativeFileInput"
                  :checked="inclNativeFile"
                  @click="toggleInclNativeFile"
                />
              </div>
            </div>
          </div>

          <div class="card-body">
            <div
              id="drop_file_zone"
              @drop.stop.prevent="saveMultipleFiles('PDF', $event)"
              @dragover.prevent
              :class="getFilesConfirmed ? 'drop-disabled' : 'drop_file_zone'"
              v-if="!inclNativeFile && getIsContainer"
            >
              <span id="drop_text">{{ $t("dropPDF") }}</span>
              <ul id="drop_file_zone_ul">
                <div v-for="(item, index) in getFileArray" :key="index">
                  <li v-if="item.pdfFile">
                    <a
                      href="#"
                      class="bi bi-trash icon-secondary"
                      @click="removeTheFile(index)"
                      v-if="getPdfFileStatus(index) == 'FILE_NOT_SELECTED'"
                    ></a>
                    <a
                      :href="createUrl(index)"
                      target="_blank"
                      class="link-black"
                      >{{ item.pdfFile.name }}
                    </a>
                    <div
                      v-for="(nativeFile, index2) in item.nativeFiles"
                      :key="index2"
                      class="table table-sm table-very-small"
                    >
                      <tr class="table-very-small">
                        <td>{{ nativeFile.name }}</td>
                      </tr>
                    </div>
                  </li>
                </div>
                <div
                  id="drop_file_zone_bulk_li"
                  v-for="(item, index) in getMissingExcelData()"
                  :key="index"
                >
                  <!--   -->
                  <li class="missing">
                    {{ item[ColDefinitions.FILENAME] }}
                    <span class="missing-file"> {{ $t("missing") }} </span>
                  </li>
                </div>
              </ul>
            </div>
          </div>
          <!-- <div class="card-footer" id="card-pdf-drop-zone" ></div> -->
        </div>
        <div v-if="inclNativeFile || !getIsContainer">
          <div v-for="(item, index) in getFileArray" :key="item">
            <div class="card" style="margin-top: 1rem">
              <div class="card-body">
                <div class="card-title">
                  <div class="row">
                    <div class="col-lg-10">
                      <h5>
                        {{ $t("PDF File", [index + 1]) }}
                        <a href="#"
                          ><i
                            class="bi bi-info-circle"
                            data-toggle="modal"
                            data-target="#infoModal"
                            data-title="PDF File"
                            data-body="fileUploadPDFInfoBody"
                            @click.prevent="showmodal(index + 1)"
                          ></i
                        ></a>
                      </h5>
                    </div>
                    <div v-if="index > 0" class="col-lg-2">
                      <button
                        class="btn btn-outline-danger"
                        @click="removeTheFile(index)"
                      >
                        <i class="bi-x-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div v-if="getPdfFile(index) == '' || !getIsContainer">
                  <div class="row mt-2">
                    <div class="col-lg-10">
                      <input
                        type="file"
                        class="form-control"
                        ref="filePDF"
                        accept="application/pdf"
                        @change="savePdfFile(index)"
                        :disabled="getFilesConfirmed"
                        @keydown.enter.prevent
                      />
                    </div>
                    <div class="col-lg-2">
                      <i
                        class="bi-filetype-pdf"
                        style="font-size: 1.8rem; color: #bd0f07"
                      ></i>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div class="card">
                    <div class="card-body">
                      <div class="row mt-1">
                        <div class="col-lg-10">
                          <h6 class="card-title">
                            <a
                              :href="createUrl(index)"
                              target="_blank"
                              class="link-black"
                              >{{ item.pdfFile.name }}
                            </a>
                          </h6>

                          <div
                            v-for="(file, index) in item.nativeFiles"
                            :key="index"
                            class="table table-sm table-very-small"
                          >
                            <tr class="table-very-small">
                              <td>{{ file.name }}</td>
                            </tr>
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <i
                            class="bi-filetype-pdf"
                            style="font-size: 1.5rem; color: #bd0f07"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="getNativeFiles(index).length == 0 || !getIsContainer"
                  class="row mt-3"
                >
                  <div class="card-title">
                    <h5>
                      {{ $t("Native File(s)", [index + 1]) }}
                      <a href="#"
                        ><i
                          class="bi bi-info-circle"
                          data-toggle="modal"
                          data-target="#infoModal"
                          data-title="Native File(s)"
                          data-body="fileUploadNativeInfoBody"
                          @click.prevent="showmodal(index + 1)"
                        ></i
                      ></a>
                    </h5>
                  </div>
                  <div class="row mt-2">
                    <div class="col-lg-10">
                      <input
                        type="file"
                        multiple
                        class="form-control"
                        :ref="'fileNative' + index"
                        @change="saveNativeFile(index, $event)"
                        :disabled="getFilesConfirmed"
                        @keydown.enter.prevent
                      />
                    </div>
                    <div class="col-lg-2">
                      <i
                        class="bi-file-earmark-plus"
                        style="font-size: 1.8rem; color: #0d6efd"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="getIsContainer && !getFilesConfirmed">
            <div class="col gy-2">
              <button
                class="btn btn-outline-primary"
                @click="addFilePlaceholder"
                style="width: 8rem"
              >
                <i class="bi-plus-circle" style="font-size: 1.5rem"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row" v-if="$route.path != '/metadata/bulkupload'">
          <div class="col-sm-8 align-left mt-2">
            <go-back-util />
          </div>
          <div class="col sm-4 align-right mt-2">
            <button
              v-if="!getFilesConfirmed"
              class="btn btn-success bi-caret-right"
              @click.prevent="setFilesConfirmed(true)"
            >
              {{ $t("next Step") }}
            </button>
            <button
              v-else
              class="btn btn-outline-success bi-pencil-square"
              @click.prevent="setFilesConfirmed(false)"
            >
              {{ $t("Edit Files") }}
            </button>
          </div>
        </div>
        <p></p>
      </div>

      <!-- End PDF Upload -->
      <div
        class="col-md-4 mt-4"
        v-if="!getIsPackageUnit && $route.path != '/metadata/bulkupload'"
      >
        <h5>{{ $t("headerFileUploadDesc") }}</h5>
        <p>
          {{ $t("p1FileUploadDesc") }}
        </p>
        <p>
          {{ $t("p2FileUploadDesc") }}
        </p>
        <p>{{ $t("p3FileUploadDesc") }}</p>
        <div v-if="getIsContainer">
          <p>{{ $t("p4FileUploadDesc") }}</p>
          <p>{{ $t("p5FileUploadDesc") }}</p>
        </div>
      </div>

      <!--  -->
      <div
        :class="
          $route.path == '/vdi2770viewer/files' ? 'col-md-12' : 'col-md-6'
        "
        v-if="getIsPackageUnit"
      >
        <div class="card mx-auto d-block" style="margin-top: 1rem">
          <div class="card-header">
            <div
              class="text-center d-flex align-items-center justify-content-around"
            >
              <h4>{{ $t("VDI2270Container") }}</h4>
            </div>
            <h5 style="text-align: center">
              {{ $t("headerZipUploadDesc") }}
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="headerZipUploadDesc"
                  data-body="headerZipInfoBody"
                  @click.prevent="showmodal()"
                ></i
              ></a>
            </h5>
          </div>
          <div class="card-body">
            <div
              id="drop_file_zone"
              @drop.stop.prevent="saveMultipleFiles('XML', $event)"
              @dragover.prevent
              :class="getFilesConfirmed ? 'drop-disabled' : 'drop_file_zone'"
            >
              <span id="drop_text">
                {{ $t("dropContainer") }}
              </span>

              <ul>
                <div v-for="(item, index) in getZipArray" :key="index">
                  <li v-if="item.zipFile != ''">
                    <a
                      href="#"
                      class="bi bi-trash icon-secondary"
                      @click="funcRemoveZip(index)"
                      v-if="!getNextRound"
                    ></a>
                    {{ item.zipFile.name }}
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-utils />
  </div>
</template>

<script>
import { ZVSDefinitions } from "../../components/constants/zvsBulkUploadConst";
import { mapActions, mapGetters } from "vuex";
import GoBackUtil from "../utils/GoBackUtil.vue";
import ModalUtils from "../utils/ModalsUtils.vue";
import i18n from "@/i18n";
import processZip from "../utils/processZip";
// import ExcelBulkUpload from "../../components/utils/ExcelBulkUpload";
import Message from 'vue-m-message'
import 'vue-m-message/dist/style.css'
import { h } from 'vue'

export default {
  name: "FileUploadCom",
  components: {
    ModalUtils,
    GoBackUtil,
  },
  data() {
    return {
      inclNativeFile: false,
      fails: [],
      ColDefinitions: undefined,
      countMissingPDFs: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getIsContainer",
      "getFilesConfirmed",
      "getIsPackageUnit",
      "getNextRound",
      "getIsGeneralDocu",
    ]),
    ...mapGetters("Files", [
      "getPdfFile",
      "getNativeFiles",
      "getFileArray",
      "getZipFile",
      "getHasMainXML",
      "getZipArray",
      "getXmlToJson",
      "getPdfFileStatus",
    ]),
    ...mapGetters("Bulk", ["getExcelData"]),
  },
  methods: {
    // @BulkUpload
    getMissingExcelData() {
      let myArray = this.getExcelData;
      let pdfArray = [];
      for (let i = 0; i < this.getFileArray.length; i++) {
        pdfArray.push(this.getPdfFile(i).name);
        
      }
      myArray = myArray.filter((el) => {
        return !pdfArray.includes(el[this.ColDefinitions.FILENAME]);
      });
      //  avoid doubling messages
      if (myArray.length > 0 && this.countMissingPDFs != myArray.length ) {
        Message.error(
          () =>
            h("div", { id: 'message' }, [
              "Some files are missing",
            ]),
          {
            title: "Attention needed",
            duration: 3000,
            closable: true,
            position: 'center',
            // iconURL:
            //   "https://avatars.githubusercontent.com/u/11366654?s=40&v=4",
          }
        );
        this.$store.dispatch("Bulk/setPdfCompleted", false)
      }
      this.countMissingPDFs = myArray.length
      // && pdfArray[0] != undefined
      if (myArray.length == 0 && pdfArray.length > 0 && pdfArray[0] != undefined){
        Message.closeAll()
        this.$store.dispatch("Bulk/setPdfCompleted", true)
      }
      return myArray;
    },
    // End Bulkupload
    showmodal(index = undefined) {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title, [index]));
        modal.find(".modal-body").text(i18n.global.t(body, [index]));
      });
    },
    createUrl(index = 0) {
      if (this.getPdfFile(index)) {
        return URL.createObjectURL(this.getPdfFile(index));
      } else return "#";
    },
    toggleInclNativeFile() {
      this.removeEmptyFilePlaces();
      this.inclNativeFile = !this.inclNativeFile;
    },
    removeTheFile(index) {
      if (!this.getFilesConfirmed) {
        if (this.getPdfFileStatus(index) == "FILE_NOT_SELECTED") {
          this.$store.dispatch("Files/removeFile", index);
        } else alert(i18n.global.t("fileInPreparation"));
        if (this.getFileArray.length == 0) {
          this.$store.dispatch("Files/addFilePlaceholder");
        }
      } else alert(i18n.global.t("filesNotInEditMode"));
    },
    funcRemoveZip(index) {
      if (!this.getFilesConfirmed) {
        this.$store.dispatch("Files/removeZip", index);
        if (this.getZipArray.length == 0) {
          this.$store.dispatch("Files/addZipPlaceholder");
        }
      } else alert(i18n.global.t("filesNotInEditMode"));
    },
    async saveZip(index, cont) {
      try {
        const container = await processZip.loadZipContainer(
          cont
          // this.$refs.fileZip[index].files[0]
        );
        const hasXml = await processZip.checkVDI(container);
        if (hasXml) {
          const payload = {
            fileObj: cont, // this.$refs.fileZip[index].files[0],
            ind: index,
          };
          this.$store.dispatch("Files/setZipFile", payload);
          var isMainXml = false;
          if (hasXml == "VDI2770_Main.xml") {
            isMainXml = true;
          }
          let mainPayload = {
            hasMainXML: isMainXml,
            ind: index,
          };
          this.$store.dispatch("Files/setHasMainXML", mainPayload);
          const returnString = await processZip.readXmlFile(container);
          let jsonObj = processZip.convertToObject(returnString);
          processZip.addMainPdfData(jsonObj);
          processZip.addDocIdData(jsonObj);
          let payloadJson = {
            ind: index,
            xmlToJson: jsonObj,
          };
          this.$store.dispatch("Files/setXmlToJson", payloadJson);
          return true;
        } else {
          this.fails.push({ fault: "faultNotVDI2770", descr: cont.name });
          console.log(this.fails);

          alert(i18n.global.t("faultNotVDI2770"));
          return false;
        }
      } catch (error) {
        this.setZipToEmpty(index);
        alert("cantReadZip");
        return false;
      }
    },

    setFilesConfirmed(val) {
      this.removeEmptyFilePlaces();
      if (this.checkFileAvailable()) {
        this.$store.dispatch("setFilesConfirmationState", val);
        if (!val) {
          // add new placeholders
          this.$store.dispatch("Files/addFilePlaceholder");
          this.$store.dispatch("Files/addZipPlaceholder");
        }
      } else {
        alert(i18n.global.t("faultMissingPDF"));
      }
      if (this.getFilesConfirmed) {
        this.removeEmptyFilePlaces();
        this.$store.dispatch("ActSteps/setFileSelect", true);
        this.$router.push("/metadata/files/fileselection");
      }
    },
    checkFileAvailable() {
      var workingArray = this.getFileArray;
      for (let i = 0; i < workingArray.length; i++) {
        if (workingArray[i].pdfFile !== "") {
          return true;
        }
      }
      // @ToDo aktivieren wenn Flow ohne PDF geht
      // var workingArray = this.getZipArray;
      // for (let i = 0; i < workingArray.length; i++) {
      //   if (workingArray[i].zipFile != "") {
      //     return true
      //   }
      // }
      return false;
    },
    removeEmptyFilePlaces() {
      var workingArray = this.getFileArray;
      let i = 1;
      if (workingArray.length > 1) {
        i = 0;
      }
      for (i; i < workingArray.length; i++) {
        if (
          workingArray[i].pdfFile == "" ||
          workingArray[i].pdfFile == undefined
        ) {
          this.$store.dispatch("Files/removeFile", i);
        }
      }
      var workingArray = this.getZipArray;
      for (i = 1; i < workingArray.length; i++) {
        if (workingArray[i].zipFile == "") {
          this.$store.dispatch("Files/removeZip", i);
        }
      }
    },
    async savePdfFile(index) {
      processZip.savePdfType(
        this.$refs.filePDF[0].files[0],
        index,
        "Files/setPdfFile"
      );
    },
    async saveMultipleFiles(type, event) {
      if (!this.getFilesConfirmed) {
        const files = event.dataTransfer.files;
        if (type == "PDF") {
          if (this.getPdfFile(this.getFileArray.length - 1) != "") {
            this.$store.dispatch("Files/addFilePlaceholder");
          }
          var index = this.getFileArray.length - 1;
          for (let i = 0; i < files.length; i++) {
            this.$store.dispatch("Files/addFilePlaceholder");
          }
          for (let i = 0; i < files.length; i++) {
            await processZip.savePdfType(
              files[i],
              index + i,
              "Files/setPdfFile"
            );
          }
        } else if (type == "XML") {
          for (let i = 0; i < files.length; i++) {
            if (this.saveZip(this.getZipArray.length - 1, files[i])) {
              this.$store.dispatch("Files/addZipPlaceholder");
            }
          }
        }
      } else alert(i18n.global.t("filesNotInEditMode"));
    },
    saveNativeFile(index, event) {
      const payload = {
        fileList: event.target.files, // this.$refs.fileNative[0].files,
        ind: index,
      };
      this.$store.dispatch("Files/setNativeFiles", payload);
    },
    // submitFile() {
    // only testing
    // this.setFilesConfirmed(true);
    // const formData = new FormData();
    // formData.append("file", this.upload_files);
    // const headers = { "Content-Type": "multipart/form-data" };
    // axios.post("#", formData, { headers }).then((res) => {
    //   res.data.files; // binary representation of the file
    //   res.status; // HTTP status
    // });
    // },
    ...mapActions("Files", [
      "setFiles",
      "addFilePlaceholder",
      "setPdfFile",
      "setNativeFiles",
      "removeFile",
      "setZipFile",
      "setHasMainXML",
      "setXmlToJson",
      "addZipPlaceholder",
      "removeZip",
      "setAdjustable",
      "setZipToEmpty",
    ]),
    ...mapActions(["setFilesConfirmationState"]),
    ...mapActions("ActSteps", ["setFileSelect"]),
    ...mapActions("Bulk", ["setPdfCompleted"])
  },
  created() {
    if (this.getIsGeneralDocu) {
    } else {
      this.ColDefinitions = ZVSDefinitions;
      // this.optionsCheck = ZVSOptional;
      // this.mandatoryCheck = ZVSMandatory;
    }
  },
};
</script>
<style scoped>
.drop_file_zone {
  background-color: #f8f9fa;
  border: #0d6efd 3px dashed;
  border-radius: 6px;
  width: inherit;
  min-height: 200px;
  padding: 8px;
  text-align: center;
  background-image: var(--bs-gradient);
}
#drop_text {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-size: 22px;
  font-style: italic;
  opacity: 0.8;
}

ul {
  list-style-type: none;
  text-align: left;
}

.icon-secondary {
  color: #6c757d;
  padding-right: 1rem;
}
.drop-disabled {
  background-color: #e9ecef;
  border: #343a40 3px dashed;
  border-radius: 6px;
  width: inherit;
  min-height: 200px;
  padding: 8px;
  text-align: center;
  opacity: 0.7;
  background-image: var(--bs-gradient);
}
.table-very-small {
  font-size: 0.7rem;
  display: inline-block;
  line-height: 0.9rem;
  margin: 2px;
  padding-left: 1rem;
  border-collapse: collapse;
}
td {
  /* white-space: normal !important;  */
  word-wrap: break-word;
}
.link-black {
  color: black;
  opacity: 0.8;
}
.missing {
  color: darkolivegreen;
  opacity: 0.7;
}
.missing-file {
  color: #dc3545;
  /* opacity: 0.8; */
}
</style>
