import { createApp } from "vue";
import Message from 'vue-m-message'
// import 'vue-m-message/dist/style.css'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import i18n from "./i18n";
import LoadScript from "vue-plugin-load-script";

if (
  process.env.NODE_ENV !== "production" &&
  process.env.NODE_ENV !== "test" &&
  typeof console !== "undefined"
) {
  axios.defaults.baseURL = "http://localhost:8000";

} else {
  axios.defaults.baseURL = window.location.origin;
}

createApp(App).use(i18n).use(store).use(router, axios).use(LoadScript).use(Message).mount("#app");
