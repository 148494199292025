import store from "../../store/index";
import JSZip from "jszip";
import i18n from "@/i18n";
const { create } = require("xmlbuilder2");
const builder = require("xmlbuilder2");
const fr = new FileReader();

export default {
  async checkVDI(containerLoaded) {
    const mainFile = Object.keys(containerLoaded.files).find(
      (key) =>
        key.includes("VDI2770_Main.xml") || key.includes("VDI2770_Metadata.xml")
    );
    return mainFile;
  },
  async catchZipFile(containerLoaded, zipFileName) {
    const zipFile = Object.keys(containerLoaded.files).find((key) =>
      key.includes(zipFileName)
    );
    return zipFile;
  },
  async readZipFile(containerLoaded, zipFileName) {
    try {
      var zipFile = await containerLoaded.files[zipFileName];
      let fileContent = await zipFile
        .async("ArrayBuffer")
        .then(function (data) {
          // let utf8decoder = new TextDecoder();
          // const stringData = utf8decoder.decode(data);
          // return stringData;
          return fileContent;
        });
      // return readyString
    } catch (error) {
      alert(error);
      return undefined;
    }
  },

  addMainPdfData(jsonObj) {
    try {
      let data = {
        ClassId: jsonObj.Document[0].DocumentClassification[0].ClassId,
        DocumentId: jsonObj.Document[0].DocumentId[0]["#"],
        DocVer: jsonObj.Document[0].DocumentVersion[0].DocumentVersionId,
        lang: jsonObj.Document[0].DocumentVersion[0].Language,
        title:
          jsonObj.Document[0].DocumentVersion[0].DocumentDescription[0].Title,
      };
      store.dispatch("addMainPdfDataArray", data);
    } catch (error) {
      console.log(error);
    }
  },
  addDocIdData(jsonObj) {
    try {
      let payload = {
        DomainId: jsonObj.Document[0].DocumentId[0]["@DomainId"],
        DocumentId: jsonObj.Document[0].DocumentId[0]["#"],
        DocumentVersionId:
          jsonObj.Document[0].DocumentVersion[0].DocumentVersionId,
      };
      store.dispatch("addMainXmlDocIdItem", payload);
    } catch (error) {
      console.log(error);
    }
  },

  async savePdfType(fileObj, index, toDispatch) {
    if (fileObj.type == "application/pdf") {
      const pdfJS = await import("pdfjs-dist/build/pdf");
      pdfJS.GlobalWorkerOptions.workerSrc =
        window.location.origin + "/pdf.worker.min.js";
      var fr = new FileReader();
      fr.onload = async () => {
        var typedarray = new Uint8Array(fr.result);
        fr.onload = null;
        fr = null;
        const task = await pdfJS.getDocument(typedarray);
        let payload = {};
        await task.promise.then(
          (pdf) => {
            if (toDispatch == "Files/setPdfFile") {
              payload = {
                fileObj: fileObj,
                ind: index,
              };
            } else {
              payload = {
                uniqueId: index,
                loadedFile: fileObj,
                fileType: "application/pdf",
                xmlBlobData: undefined,
                numPages: pdf.numPages,
                size: fileObj.size,
              };
            }
            store.dispatch(toDispatch, payload);
          },
          function (reason) {
            console.log(reason.message);
            alert(i18n.global.t("faultNotReadablePDF"));
          }
        );
      };

      fr.readAsArrayBuffer(fileObj);
    } else alert(i18n.global.t("faultNotPdfType") + fileObj.name);
  },
  saveXmlType(XmlJson, index) {
    let payload = {
      uniqueId: index,
      loadedFile: undefined,
      fileType: "application/xml",
      xmlBlobData: XmlJson,
      numPages: undefined,
      size: undefined,
    };
    store.dispatch("ViewerStore/addViewerFileObj", payload);
  },

  async loadZipContainer(container) {
    var new_zip = new JSZip();
    const zipRepresentation = await new_zip
      .loadAsync(container)
      .then(function (zip) {
        return zip;
      });
    return zipRepresentation;
  },
  async readXmlFile(containerLoaded) {
    try {
      var xmlFile =
        (await containerLoaded.files["VDI2770_Metadata.xml"]) ||
        containerLoaded.files["VDI2770_Main.xml"];
      let readyString = await xmlFile
        .async("ArrayBuffer")
        .then(function (data) {
          let utf8decoder = new TextDecoder();
          const stringData = utf8decoder.decode(data);
          return stringData;
        });
      return readyString;
    } catch (error) {
      alert(str(error));
      return undefined;
    }
  },
  convertToObject(xmlString) {
    const doc = create(xmlString);
    return doc.end({ format: "object", verbose: true });
  },

  async exchangeXmlInZip(containerLoaded) {
    // const node = document.createElement("node");

    const xml = builder
      .create({ encoding: "utf-8" })
      .ele("FirstNode")
      .ele("child")
      .end({ prettyPrint: true });
    try {
      var xmlFile =
        (await containerLoaded.files["VDI2770_Metadata.xml"]) ||
        containerLoaded.files["VDI2770_Main.xml"];
      const blob = new Blob([xml], { type: "text/xml" });
      let varnewXmlFile = new File([blob], "newVDI2770_Metadata.xml", {
        type: "application/xml",
      });
      // funktioniert, aber ist vermutlich muss die Datei noch vorher gezippt werden.
      containerLoaded.files["VDI2770_Main.xml"] = varnewXmlFile;
    } catch (error) {
      console.log(error);
    }
  },
};
