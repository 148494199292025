<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>{{ $t("headerPartyTemp") }}</h4>
        {{ $t("partyOrgDataExplanation") }} {{ $t("descPartyTemp") }}
      </div>
      <div class="card-body">
        <form action="" id="OrganizationTemplates">
          <div
            class="row mb-3"
            v-if="getUserChooseTemplate <= 1 && !getNextRound"
          >
            <label for="Template" class="col-sm-3 col-form-label"
              >{{ $t("chooseTemp") }}
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="partyOrgDataInfoChoosTitle"
                  data-body="partyOrgDataInfoChoosBody"
                  @click.prevent="showmodal"
                ></i
              ></a>
            </label>
            <div class="col-sm-9">
              <!-- @focusout="singleFault($event.target)"   required-->
              <select
                required
                class="form-select"
                id="Template"
                v-model="templates.id"
                @change.prevent="setTemplate(templates.id)"
                @keydown.enter.prevent
              >
                <option
                  v-for="item in templates"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.OrganizationOfficialName }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mb-3" v-if="getUserChooseTemplate == 0">
            <div class="row mb-3">
              <h3 class="text-primary">{{ $t("or") }}</h3>
            </div>
            <div class="col-lg-3 col-form-label">
              {{ $t("createTemp") }}
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="partyOrgDataInfoTitle"
                  data-body="partyOrgDataInfoBody"
                  @click.prevent="showmodal"
                ></i
              ></a>
            </div>
            <div class="col-lg-3">
              <button
                class="btn btn-outline-primary"
                style="width: 8rem"
                @click.prevent="setUserActed(2)"
              >
                <i class="bi-plus-circle" style="font-size: 1.5rem"></i>
              </button>
            </div>
          </div>
          <div v-if="getUserChooseTemplate > 0">
            <div class="row mb-3">
              <div class="mb-3 text-primary text-center" v-if="!getNextRound">
                {{ $t("Edit_Mode") }}
              </div>
              <label for="OrganizationId" class="col-sm-3 col-form-label"
                >{{ $t("partyOrgDataOrgWebsInfoTitle") }}*:
                <a href="#"
                  ><i
                    class="bi bi-info-circle"
                    data-toggle="modal"
                    data-target="#infoModal"
                    data-title="partyOrgDataOrgWebsInfoTitle"
                    data-body="partyOrgDataOrgWebsInfoBody"
                    @click.prevent="showmodal"
                  ></i
                ></a>
              </label>
              <div class="col-sm-5">
                <input
                  required
                  type="text"
                  class="form-control"
                  id="OrganizationId"
                  :disabled="getNextRound"
                  :value="getOrganizationId"
                  @input="changeOrgId"
                  @keydown.enter.prevent
                />
              </div>
              <label class="col-sm-1 col-form-label" for="roleList">
                {{ $t("partyOrgDataRoleInfoTitle") }}:*
                <a href="#"
                  ><i
                    class="bi bi-info-circle"
                    data-toggle="modal"
                    data-target="#infoModal"
                    data-title="partyOrgDataRoleInfoTitle"
                    data-body="partyOrgDataRoleInfoBody"
                    @click.prevent="showmodal"
                  ></i
                ></a>
              </label>

              <div class="col-sm-3">
                <select
                  id="roleList"
                  class="form-select"
                  disabled
                  @input="changeResponsible"
                  @keydown.enter.prevent
                >
                  <option value="Responsible">Responsible</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label for="OrganizationName" class="col-sm-3 col-form-label"
                >{{ $t("partyOrgDataOrgNameInfoTitle") }}:*
                <a href="#"
                  ><i
                    class="bi bi-info-circle"
                    data-toggle="modal"
                    data-target="#infoModal"
                    data-title="partyOrgDataOrgNameInfoTitle"
                    data-body="partyOrgDataOrgNameInfoBody"
                    @click.prevent="showmodal"
                  ></i
                ></a>
              </label>
              <div class="col-sm-9">
                <input
                  required
                  type="text"
                  class="form-control"
                  id="OrganizationName"
                  :disabled="getNextRound"
                  :value="getOrganizationName"
                  @input="changeOrgName"
                  @focusout="singleFault($event.target)"
                  @keydown.enter.prevent
                />
              </div>
            </div>
            <div class="row mb-3">
              <label
                for="OrganizationOfficialName"
                class="col-sm-3 col-form-label"
                >{{ $t("partyOrgDataOrgOffNameInfoTitle") }}:*
                <a href="#"
                  ><i
                    class="bi bi-info-circle"
                    data-toggle="modal"
                    data-target="#infoModal"
                    data-title="partyOrgDataOrgOffNameInfoTitle"
                    data-body="partyOrgDataOrgOffNameInfoBody"
                    @click.prevent="showmodal"
                  ></i
                ></a>
              </label>
              <div class="col-sm-9">
                <!--  -->
                <input
                  required
                  type="text"
                  class="form-control"
                  id="OrganizationOfficialName"
                  :disabled="getNextRound"
                  :value="getOrganizationOfficialName"
                  @input="changeOrgOfficialName"
                  @focusout="singleFault($event.target)"
                  @keydown.enter.prevent
                />
              </div>
            </div>
            <div
              class="row mb-3"
              v-if="
                !(getPartyTempId == undefined || getPartyTempId == '') &&
                !getNextRound
              "
            >
              <div class="col-sm-12 text-end mt-1">
                <a href="#">
                  <i
                    class="bi bi-trash icon-secondary"
                    data-toggle="modal"
                    data-target="#confirmDeleteModal"
                    @click.prevent="showDeleteModal"
                  ></i
                ></a>
                <span class="text-secondary">
                  {{ $t("delete_template") }}
                </span>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-6 align-left mt-2">
              <go-back-util />
            </div>
            <div
              class="col-sm-3 align-right mt-2"
              v-if="getUserChooseTemplate === 1 && !getNextRound"
            >
              <button
                class="btn btn-outline-primary bi-pencil-square"
                @click.prevent="editTemplate"
              >
                {{ $t("newTemplate") }}
              </button>
            </div>
            <div class="col-sm-3 align-right mt-2">
              <button
                class="btn btn-success bi-caret-right"
                @click.prevent="createAndUpdateTemplate()"
              >
                {{ $t("next Step") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal-utils />
    <div
      class="modal fade"
      id="confirmDeleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmDeleteModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmDeleteModalTitle"></h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary float-start"
              data-dismiss="modal"
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary float-end"
              data-dismiss="modal"
              @click="deleteTemplate"
            >
              {{ $t("delete_template") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import GoBackUtil from "../utils/GoBackUtil.vue";
import Fault from "../utils/Fault";
import i18n from "@/i18n";
import ModalUtils from "../utils/ModalsUtils.vue";
export default {
  name: "PartyCom",
  data() {
    return {
      templates: [],
    };
  },
  methods: {
    singleFault(ele) {
      return Fault.singleFault(ele);
    },

    editTemplate() {
      this.$store.dispatch("OrgData/clearID");
      this.setUserActed(2);
    },
    setUserActed(action) {
      this.$store.dispatch("OrgData/setUserChooseTemplate", action);
    },
    setTemplate(key) {
      var template = this.templates.find(function (element) {
        return element.id == key;
      });
      this.$store.dispatch("OrgData/setPartyType", template);
      this.setUserActed(1);
    },

    changeOrgId(event) {
      this.$store.dispatch("OrgData/setOrganizationId", event.target.value);
    },
    changeResponsible(event) {
      this.$store.dispatch("OrgData/setResponsible", event.target.value);
    },
    changeOrgName(event) {
      this.$store.dispatch("OrgData/setOrganizationName", event.target.value);
    },
    changeOrgOfficialName(event) {
      this.$store.dispatch(
        "OrgData/setOrganizationOfficialName",
        event.target.value
      );
    },
    async deleteTemplate() {
      await axios
        .delete("api/v1/orgtemplate/" + this.getPartyTempId + "/")
        .then(async (resp) => {
          this.setUserActed(0);
          await axios
            .get("api/v1/orgtemplate/")
            .then((resp) => {
              this.templates = resp.data;
            })
            .catch((error) => {
              alert(error);
            });
          // alert("Template successfully deleted");
        })
        .catch((error) => {
          alert(error.request.responseText);
        });
    },
    async createAndUpdateTemplate() {
      axios.defaults.headers.common["Authorization"] = "Token " + this.getToken;
      axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
      axios.defaults.xsrfCookieName = "csrftoken";
      if (
        Fault.faultControl(document) &&
        this.getOrganizationId !== undefined
      ) {
        if (this.getPartyTempId == undefined || this.getPartyTempId == "") {
          await axios
            .post("api/v1/orgtemplate/", this.getPartyType_model)
            .then((resp) => {
              this.$store.dispatch("OrgData/setPartyType", resp.data);
              this.$router.push("/metadata/defaultsettings/documentId/");
            })
            .catch((error) => {
              alert(error.request.responseText);
            });
        } else if (this.getPartyTempId !== undefined) {
          await axios
            .patch(
              "api/v1/orgtemplate/" + this.getPartyTempId + "/",
              this.getPartyType_model
            )
            .then((resp) => {
              this.$router.push("/metadata/defaultsettings/documentId/");
            })
            .catch((error) => {
              alert(error.request.responseText);
            });
        } else {
          this.$router.push("/metadata/defaultsettings/documentId/");
        }
        this.$store.dispatch("ActSteps/setOrgDocDomain", true);
      } else alert(i18n.global.t("faultMandatory"));
    },
    ...mapActions("OrgData", [
      "setPartyType",
      "setOrganizationId",
      "setUserChooseTemplate",
      "setResponsible",
      "clearID",
    ]),
    ...mapActions(["setFileSelectionState"]),
    ...mapActions("ActSteps", ["setOrgDocDomain"]),
    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },
    showDeleteModal() {
      $("#confirmDeleteModal").on("show.bs.modal", function (event) {
        // var button = $(event.relatedTarget); // Button that triggered the modal
        var title = "delete_template";
        var body = "reallyDelete";
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },
  },

  computed: {
    ...mapGetters("OrgData", [
      "getOrganizationId",
      "getPartyType_model",
      "getResponsible",
      "getOrganizationName",
      "getOrganizationOfficialName",
      "getUserChooseTemplate",
      "getPartyTempId",
    ]),
    ...mapGetters(["getToken", "getNextRound", "getIsGeneralDocu"]),
  },
  components: {
    GoBackUtil,
    ModalUtils,
  },
  async activated() {
    axios.defaults.headers.common["Authorization"] = "Token " + this.getToken;
    await axios
      .get("api/v1/orgtemplate/")
      .then((resp) => {
        this.templates = resp.data;
      })
      .catch((error) => {
        alert(error);
      });
  },
};
</script>
<style scoped>
.icon-secondary {
  color: #6c757d;
  padding-right: 0.5rem;
}
</style>
