<template>
  <div>
    <div class="card">
      <h3 class="card-header">{{ $t("headerRefObjDesc") }}:</h3>
      <div class="card-body">
        <form action="" id="DescriptionRefObj">
          <div>
            <div
              class="card border rounded mt-2 border-dark"
              v-for="(obj, index) in getDescriptionType"
              :key="obj"
            >
              <div class="card-body">
                <div class="row mb-3">
                  <label for="Description" class="col-sm-3 col-form-label"
                    >{{ $t("refObjgDescDescInfoTitle") }}:
                    <a href="#"
                      ><i
                        class="bi bi-info-circle"
                        data-toggle="modal"
                        data-target="#infoModal"
                        data-title="refObjgDescDescInfoTitle"
                        data-body="refObjDescDescInfoBody"
                        @click.prevent="showmodal"
                      ></i></a
                  ></label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      id="Description"
                      :value="getDescriptionTypeDesc(index)"
                      @input="setDescTypDesc(index, $event)"
                      @keydown.enter.prevent
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <label for="language" class="col-sm-3 col-form-label"
                    >{{ $t("refObjgDescLangInfoTitle") }}:
                    <a href="#"
                      ><i
                        class="bi bi-info-circle"
                        data-toggle="modal"
                        data-target="#infoModal"
                        data-title="refObjgDescLangInfoTitle"
                        data-body="refObjDescLangInfoBody"
                        @click.prevent="showmodal"
                      ></i></a
                  ></label>
                  <div class="col-sm-3">
                    <select
                      name="Language"
                      class="form-select"
                      id="language"
                      :value="getDescriptionTypeLang(index)"
                      @input="setDescTypLang(index, $event)"
                      @keydown.enter.prevent
                    >
                      <option
                        v-for="item in getLanguage()"
                        :key="item[0]"
                        :value="item[0]"
                      >
                        {{ item[0] }} - {{ item[1] }}
                      </option>
                    </select>
                  </div>
                  <div class="col sm-1 mt-3 mt-sm-0" v-if="index >= 1">
                    <button
                      class="btn btn-outline-danger"
                      style="width: 3rem"
                      @click.prevent="removeDescription(index)"
                    >
                      <i class="bi-x-circle" style="font-size: 1rem"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col gy-2">
              <button
                class="btn btn-outline-primary"
                style="width: 8rem"
                @click.prevent="addDescription"
              >
                <i class="bi-plus-circle" style="font-size: 1.5rem"></i>
              </button>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-8 align-left mt-2">
              <go-back-util />
            </div>
            <div class="col-sm-4 align-right mt-2">
              <button
                class="btn btn-success bi-caret-right"
                @click.prevent="pushToDocVersion"
              >
                {{ $t("next Step") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal-utils />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoBackUtil from "../utils/GoBackUtil.vue";
import LangCodes from "../utils/LangCodes";
import i18n from "@/i18n";
import ModalUtils from "../utils/ModalsUtils.vue";
export default {
  name: "DescriptionRefObjCom",
  components: {
    GoBackUtil,
    ModalUtils,
  },
  methods: {
    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },
    getLanguage() {
      return LangCodes.getLang();
    },
    pushToDocVersion() {
      var pushForward = true;
      for (let i = 0; i < this.getDescriptionType.length; i++) {
        if (
          (this.getDescriptionTypeDesc(i) != "" &&
            this.getDescriptionTypeLang(i) != "") ||
          this.getDescriptionTypeDesc(i) == this.getDescriptionTypeLang(i)
        ) {
        } else {
          pushForward = false;
          this.$store.dispatch("ActSteps/setDocVersion", false);
          this.$store.dispatch("ActSteps/setDocVersionParty", false);
          alert(this.$t("fillAll"));
          break;
        }
      }
      if (pushForward) {
        this.$store.dispatch("ActSteps/setDocVersion", true);
        this.$store.dispatch("ActSteps/setDocVersionParty", true);
        this.$router.push("/metadata/docversion/documentversionid/");
      }
    },
    setDescTypDesc(index, event) {
      let payload = {
        index: index,
        description: event.target.value,
      };
      this.$store.dispatch("RefObj/setDescriptionTypeDesc", payload);
    },
    setDescTypLang(index, event) {
      let payload = {
        index: index,
        language: event.target.value,
      };
      this.$store.dispatch("RefObj/setDescriptionTypeLang", payload);
    },
    ...mapActions("ActSteps", ["setDocVersion", "setDocVersionParty"]),
    ...mapActions("RefObj", [
      "addDescription",
      "removeDescription",
      "setDescriptionTypeDesc",
      "setDescriptionTypeLang",
    ]),
  },
  computed: {
    ...mapGetters("RefObj", [
      "getDescriptionType",
      "getDescriptionTypeDesc",
      "getDescriptionTypeLang",
    ]),
    ...mapGetters(["getIsGeneralDocu"])
  },

};
</script>
