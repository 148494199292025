// import { mapGetters } from "vuex";
import store from "../store/index";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import axios from "axios";
import { v4 as uuidv4 } from "uuid";
const builder = require("xmlbuilder2");

export default {
  convertJsonToXml(jsonObj, rootElementName) {
    function convertDictToXml(parentElement, dictObj) {
      for (const [key, value] of Object.entries(dictObj)) {
        const childElement = document.createElement(key);
        parentElement.appendChild(childElement);
        if (typeof value === "object") {
          convertDictToXml(childElement, value);
        } else {
          childElement.textContent = value;
        }
      }
    }
    const rootElement = document.createElement(rootElementName);
    convertDictToXml(rootElement, jsonObj);
    return new XMLSerializer().serializeToString(rootElement);
  },

  sanitizeForXML(stringToSanitize) {
    if (stringToSanitize != undefined) {
      let stringSan = new String(stringToSanitize);
      var cleanString = stringSan.replaceAll("<", "&lt;");
      cleanString = cleanString.replaceAll(">", "&gt;");
      cleanString = cleanString.replaceAll("&", "&amp;");
      cleanString = cleanString.replaceAll('"', "&quot;");
      cleanString = cleanString.replaceAll("'", "&apos;");
      return cleanString;
    } else return stringToSanitize;
  },

  createVdiMetadata(index) {
    var feed = builder
      .create({ encoding: "utf-8" })
      .ele("Document")
      .att("xmlns", "http://www.vdi.de/schemas/vdi2770")
      .import(this.addDocId())
      .import(this.addDomainId())
      .import(this.addDocClassificationToXml())
      .import(this.addRefObj())
      .import(this.addDocVersion(index));

    return feed.end({ prettyPrint: true });
  },
  // addDocRelationship() {
  //   var fragment = builder.fragment();
  //   fragment
  //     .ele("DocumentRelationship")
  //     .att("Type",store.getters["DocVer/getDocumentRelationship_modelType"])
  //     .ele("DocumentId",store.getters["DocVer/getDocumentIdType10_modelDocumentId"])
  //     .att("DomainId",store.getters["DocVer/getDocumentIdType10_modelDomainId"])
  //     .ele("DocumentVersionId",
  //       store.getters["DocVer/getDocumentRelationship_modelDocumentVersionId"]
  //     )
  //     .import(this.addDocVerDesc11())

  //   return fragment;
  // },

  // addDocVerDesc11() {
  //   var fragment = builder.fragment();
  //   let getDesc = store.getters["DocVer/getDescriptionType11Description"];
  //   let getLang = store.getters["DocVer/getDescriptionType11Language"];
  //   for (
  //     let i = 0;
  //     i < store.getters["DocVer/getDescriptionType11_model"].length;
  //     i++
  //   ) {
  //     fragment.ele("Description", getDesc(i)).att("Language", getLang(i));
  //   }
  //   return fragment
  // },

  addDigitalFile(index) {
    var fragment = builder.fragment();
    let pdfFile = store.getters["Files/getPdfFile"];
    let nativeFile = store.getters["Files/getNativeFiles"];
    fragment
      .ele("DigitalFile")
      .att("FileFormat", pdfFile(index).type)
      .txt(this.sanitizeForXML(pdfFile(index).name));
    let nativeFileArray = nativeFile(index);
    for (let i = 0; i < nativeFileArray.length; i++) {
      fragment
        .ele("DigitalFile")
        .att("FileFormat", nativeFileArray[i].type)
        .txt(this.sanitizeForXML(nativeFileArray[i].name));
    }
    return fragment;
  },

  addDocVersion(index) {
    var fragment = builder.fragment();
    fragment
      .ele("DocumentVersion")
      .att("NumberOfPages", store.getters["DocVer/getNumPages4"])
      .ele("DocumentVersionId")
      .txt(this.sanitizeForXML(store.getters["DocVer/getDocVersionId4"]))
      .up()
      .import(this.addDocVerLanguage())
      .import(this.addDocVerParty())
      .import(this.addDocVerDesc())
      .ele("LifeCycleStatus")
      .att("StatusValue", store.getters["DocVer/getLifeCycleStatusValue"])
      .att("SetDate", store.getters["DocVer/getLifeCycleSetDate"])
      .ele("Party")
      .att("Role", "Responsible")
      .ele("Organization", {
        OrganizationId: store.getters["DocVer/getPartyType7OrganizationId"],
        OrganizationName: store.getters["DocVer/getPartyType7OrganizationName"],
        OrganizationOfficialName:
          store.getters["DocVer/getPartyType7OrganizationOfficialName"],
      })
      .up()
      .up()
      .import(this.addLifeCycleStatComments())
      .up()
      .import(this.addDigitalFile(index));

    return fragment;
  },
  addDocId(mainDoc = false) {
    let newMainDoc = mainDoc;
    var fragment = builder.fragment();
    let DocIds = store.getters["DocId/getDocumentIdType"];
    let domainId = store.getters["DocId/getDomainId"];
    let isPrimary = store.getters["DocId/getIsPrimary"];
    let documentId = store.getters["DocId/getDocumentId"];
    for (let i = 0; i < DocIds.length; i++) {
      fragment
        .ele("DocumentId", {
          DomainId: domainId(i),
          IsPrimary: isPrimary(i),
        })
        .txt(this.sanitizeForXML((newMainDoc = newMainDoc || documentId(i))))
        .up();
      newMainDoc = mainDoc;
    }
    return fragment;
  },
  addDomainId() {
    let DocIds = store.getters["DocId/getDocumentIdType"];
    let docDomainId = store.getters["DocId/getDocumentDomainId"];
    var fragment = builder.fragment();
    for (let i = 0; i < DocIds.length; i++) {
      fragment
        .ele("DocumentIdDomain", {
          DocumentDomainId: docDomainId(i),
        })
        .ele("Party", { Role: "Responsible" })
        .ele("Organization", {
          OrganizationId: store.getters["OrgData/getOrganizationId"],
          OrganizationName: store.getters["OrgData/getOrganizationName"],
          OrganizationOfficialName:
            store.getters["OrgData/getOrganizationOfficialName"],
        })
        .up()
        .up();
    }
    return fragment;
  },

  addDocVerLanguage() {
    var fragment = builder.fragment();
    let docVerLang = store.getters["DocVer/getPartyType4Language"];
    for (
      let i = 0;
      i < store.getters["DocVer/getPartyType4LanguagesArray"].length;
      i++
    ) {
      if (docVerLang(i)) {
        fragment.ele("Language").txt(this.sanitizeForXML(docVerLang(i)));
      }
    }
    return fragment;
  },

  addDocVerParty() {
    var fragment = builder.fragment();
    let docVerOrgId = store.getters["DocVer/getOrganizationId4"];
    let docVerOrgRole = store.getters["DocVer/getRole4"];
    let docVerOrgName = store.getters["DocVer/getOrganizationName4"];
    let docVerOrgNameOff = store.getters["DocVer/getOrganizationOfficialName4"];
    for (
      let i = 0;
      i < store.getters["DocVer/getPartyType4OrgDataArray"].length;
      i++
    ) {
      if (docVerOrgNameOff(i)) {
        fragment
          .ele("Party")
          .att("Role", docVerOrgRole(i))
          .ele("Organization", {
            OrganizationId: docVerOrgId(i),
            OrganizationName: docVerOrgName(i),
            OrganizationOfficialName: docVerOrgNameOff(i),
          })
          .up()
          .up();
      }
    }
    return fragment;
  },

  addDocVerDesc() {
    var fragment = builder.fragment();
    let getDesLang = store.getters["DocVer/getDocumentDescriptionLanguage"];
    let getDesTitle = store.getters["DocVer/getDocumentDescriptionTitle"];
    let getDesSubTitle = store.getters["DocVer/getDocumentDescriptionSubTitle"];
    let getDesSummary = store.getters["DocVer/getDocumentDescriptionSummary"];
    for (
      let i = 0;
      i < store.getters["DocVer/getDocumentDescription_model"].length;
      i++
    ) {
      if (getDesTitle(i)) {
        fragment
          .ele("DocumentDescription")
          .att("Language", getDesLang(i))
          .ele("Title")
          .txt(this.sanitizeForXML(getDesTitle(i)))
          .up()
          .ele("SubTitle")
          .txt(this.sanitizeForXML(getDesSubTitle(i)))
          .up()
          .ele("Summary")
          .txt(this.sanitizeForXML(getDesSummary(i)))
          .up()
          .import(this.getKeyword(i));
      }
    }
    return fragment;
  },
  addLifeCycleStatComments() {
    var fragment = builder.fragment();
    let docVerLifeCycleComm = store.getters["DocVer/getComments"];
    let docVerLifeCycleLang = store.getters["DocVer/getCommentsLanguage"];
    for (
      let i = 0;
      i < store.getters["DocVer/getCommentsType_model"].length;
      i++
    ) {
      if (docVerLifeCycleComm(i)) {
        fragment
          .ele("Comments")
          .att("Language", docVerLifeCycleLang(i))
          .txt(this.sanitizeForXML(docVerLifeCycleComm(i)));
      }
    }
    return fragment;
  },

  getKeyword(index) {
    var fragment = builder.fragment();
    let getKeyWordModel = store.getters["DocVer/getKeyWordsType_model"];
    let getTheKeyWord = store.getters["DocVer/getKeyWords"];
    var fragX = fragment;
    if (getTheKeyWord(index, 0)) {
      fragX = fragment.ele("KeyWords");
      for (let i = 0; i < getKeyWordModel(index).length; i++) {
        fragX
          .ele("KeyWord")
          .txt(this.sanitizeForXML(getTheKeyWord(index, i)))
          .up();
      }
    }
    return fragX;
  },

  addRefObj() {
    var fragment = builder.fragment();
    fragment
      .ele("ReferencedObject")
      .import(this.addRefObjObjectId())
      .import(this.addRefObjOptionalData())
      .ele("Party")
      .att("Role", store.getters["RefObj/getRole1"])
      .ele("Organization")
      .att(
        "OrganizationOfficialName",
        store.getters["RefObj/getOrganizationOfficialName1"]
      )
      .att("OrganizationName", store.getters["RefObj/getOrganizationName1"])
      .att("OrganizationId", store.getters["RefObj/getOrganizationId1"])
      .up()
      .up()
      .import(this.addDescriptionRefObj());
    return fragment;
  },
  addRefObjObjectId() {
    var fragment = builder.fragment();
    let getObjType = store.getters["RefObj/getObjectType"];
    let getObjRefType = store.getters["RefObj/getRefType"];
    let getObjBiunique = store.getters["RefObj/getIsGlobalBiUnique"];
    let getObjId = store.getters["RefObj/getObjectId"];
    for (
      let i = 0;
      i < store.getters["RefObj/getObjectIdType_model"].length;
      i++
    ) {
      if (getObjId(i)) {
        fragment
          .ele("ObjectId")
          .att("IsGloballyBiUnique", getObjBiunique(i))
          .att("RefType", getObjRefType(i))
          .att("ObjectType", getObjType(i))
          .txt(this.sanitizeForXML(getObjId(i)));
      }
    }
    return fragment;
  },
  addRefObjOptionalData() {
    var fragment = builder.fragment();
    fragment = this.orderRefObjOptData("ReferenceDesignation", fragment);
    fragment = this.orderRefObjOptData("EquipmentId", fragment);
    fragment = this.orderRefObjOptData("ProjectId", fragment);
    return fragment;
  },
  // ordering is necessary, sequenze in XSD
  orderRefObjOptData(criteria, fragment) {
    let getType = store.getters["RefObj/getRefObjModelType"];
    let getValue = store.getters["RefObj/getRefObjModelValue"];
    for (
      let i = 0;
      i < store.getters["RefObj/getReferencedObject_model"].length;
      i++
    ) {
      if (getValue(i)) {
        if (getType(i) === criteria) {
          fragment.ele(getType(i)).txt(this.sanitizeForXML(getValue(i)));
        }
      }
    }
    return fragment;
  },
  addDescriptionRefObj() {
    var fragment = builder.fragment();
    let getDescLang = store.getters["RefObj/getDescriptionTypeLang"];
    let getDescTxt = store.getters["RefObj/getDescriptionTypeDesc"];
    for (
      let i = 0;
      i < store.getters["RefObj/getDescriptionType"].length;
      i++
    ) {
      if (getDescTxt(i)) {
        fragment
          .ele("Description")
          .att("Language", getDescLang(i))
          .txt(this.sanitizeForXML(getDescTxt(i)));
      }
    }
    return fragment;
  },

  addDocClassificationToXml() {
    var fragment = builder.fragment();
    const classificationArray =
      store.getters["Classification/getDocumentClassification_model"];
    for (let i = 0; i < classificationArray.length; i++) {
      fragment
        .ele("DocumentClassification", {
          ClassificationSystem: classificationArray[i]["ClassificationSystem"],
        })

        .ele("ClassId")
        .txt(this.sanitizeForXML(classificationArray[i]["ClassId"]))
        .up()
        .import(this.getClassName(i, classificationArray));
    }
    return fragment;
  },
  getClassName(i, classificationArray) {
    var fragment = builder.fragment();
    for (
      let a = 0;
      a < classificationArray[i]["ClassNameType_model"].length;
      a++
    ) {
      fragment
        .ele("ClassName")
        .txt(
          this.sanitizeForXML(
            classificationArray[i]["ClassNameType_model"][a]["classname"]
          )
        )
        .att(
          "Language",
          classificationArray[i]["ClassNameType_model"][a]["language"]
        );
    }
    return fragment;
  },

  createVdiMain() {
    var feed = builder
      .create({ encoding: "utf-8" })
      .ele("Document")
      .att("xmlns", "http://www.vdi.de/schemas/vdi2770")
      .import(this.addDocId(uuidv4()))
      .import(this.addDomainId())
      .import(this.addDocClassificMainXml())
      .import(this.addRefObj())
      .import(this.addDocVerMainXml());

    return feed.end({ prettyPrint: true });
  },

  addDocVerMainXml() {
    var fragment = builder.fragment();
    fragment
      .ele("DocumentVersion")
      .att("NumberOfPages", "1")
      .ele("DocumentVersionId")
      .txt("1.0")
      .up()
      .ele("Language")
      .txt("en")
      .up()
      .ele("Party", { Role: "Author" })
      .ele("Organization", {
        OrganizationId: this.sanitizeForXML(store.getters["OrgData/getOrganizationId"]),
        OrganizationName: this.sanitizeForXML(store.getters["OrgData/getOrganizationName"]),
        OrganizationOfficialName:
        this.sanitizeForXML(store.getters["OrgData/getOrganizationOfficialName"]),
      })
      .up()
      .up()
      .import(this.addDocVerDescMainXml())
      .ele("LifeCycleStatus")
      .att("StatusValue", "Released")
      .att("SetDate", new Date().toJSON().slice(0, 10))
      .ele("Party")
      .att("Role", "Responsible")
      .ele("Organization", {
        OrganizationId: this.sanitizeForXML(store.getters["OrgData/getOrganizationId"]),
        OrganizationName: this.sanitizeForXML(store.getters["OrgData/getOrganizationName"]),
        OrganizationOfficialName:
        this.sanitizeForXML(store.getters["OrgData/getOrganizationOfficialName"]),
      })
      .up()
      .up()
      .up()
      .import(this.addRefersTo())
      // not needed
      // .import(this.addLifeCycleStatComments())
      // .up()
      // change
      .ele("DigitalFile")
      .att("FileFormat", "application/pdf")
      .txt("VDI2770_Main.pdf")
      .up();
    return fragment;
  },

  addRefersTo() {
    let mainXmlDocIds = store.getters["getMainXmlDocIdArray"];
    var fragment = builder.fragment();
    for (let i = 0; i < mainXmlDocIds.length; i++) {
      fragment
        .ele("DocumentRelationship")
        .att("Type", "RefersTo")
        .ele("DocumentId")
        .att("DomainId", mainXmlDocIds[i].DomainId)
        .txt(this.sanitizeForXML(mainXmlDocIds[i].DocumentId))
        .up()
        .ele("DocumentVersionId")
        .txt(this.sanitizeForXML(mainXmlDocIds[i].DocumentVersionId))
        .up()
        .up();
    }
    return fragment;
  },

  addDocVerDescMainXml() {
    var fragment = builder.fragment();
    fragment
      .ele("DocumentDescription")
      .att("Language", "en")
      .ele("Title")
      .txt("Main document")
      .up()
      .ele("Summary")
      .txt(
        "The main document is a listing of all documents of a level and the associated first subordinate level."
      )
      .up()
      .ele("KeyWords")
      .ele("KeyWord")
      .txt("main document")
      .up()
      .up();
    return fragment;
  },

  addDocClassificMainXml() {
    var fragment = builder.fragment();
    fragment
      .ele("DocumentClassification", {
        ClassificationSystem: "VDI2770:2018",
      })

      .ele("ClassId")
      .txt("01-01")
      .up()
      .ele("ClassName")
      .txt("identification")
      .att("Language", "en");
    return fragment;
  },

  async createMainPDF() {
    const file = this.generatePDF();
    return file;
    //  if we need to get PDF from Python
    // let arrayBuffer = undefined;
    // const formData = new FormData();
    // formData.append("file", file);

    // const response = await axios.post("/api/v1/pdfconvertion/", formData, {
    //   responseType: "arraybuffer",
    // });
    // return response.data;
  },

  generatePDF() {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: "a4",
      putOnlyUsedFonts: true,
    });
    doc.setFont("Helvetica");
    // doc.setFontType("bold");
    // doc.setTextColor('#FFFFFF')
    doc.setFontSize(16);
    doc.text("VDI2770 Main Document", 40, 40);

    doc.autoTable({
      head: [
        [
          "Document type",
          "DocumentId",
          "Document version",
          "Language",
          "Titel",
        ],
      ],
      body: this.generatePdfTable(),
      startY: 70,
      theme: "grid",
    });
    // doc.text("created at " + Date.now().toString() );
    // doc.setDocumentProperties('A')
    // doc.save("VDI2770_Main.pdf");

    return doc.output("blob");
  },

  generatePdfTable() {
    let newBody = [];
    let items = store.getters["getMainPdfDataArray"];
    for (let i = 0; i < items.length; i++) {
      let lineItems = [
        items[i].ClassId,
        items[i].DocumentId,
        items[i].DocVer,
        items[i].lang,
        items[i].title,
      ];
      newBody.push(lineItems);
    }
    return newBody;
  },
};
