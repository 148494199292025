<template>
  <div class="page-referenced-object">
    <div class="row">
      <div class="col-lg-12 gy-3 text-center">
        <h1 :class="getIsGeneralDocu ? 'hGen' : 'hZVS' ">{{$t("step4")}}</h1>
      </div>
      <metadata-navigation-com></metadata-navigation-com>
    </div>
    <div class="row mb-5">
      <side-menu-ref-ob-com />
    </div>
    <div class="row mt-3">
      <div class="col-lg-9">
        <h3 class="text-center" v-if="$route.path == '/metadata/refobject'">
          Please Navigate with the Menu on left side
        </h3>
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
        <div class="row mb-3" v-if="$route.path == '/metadata/refobject'">
          <div class="col-sm-9 align-left mt-2">
            <go-back-util />
          </div>
          <div class="col-sm-3 align-right mt-2">
            <button class="btn btn-success bi-caret-right">{{$t("next Step")}}</button>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <file-selection-com />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MetadataNavigationCom from "../../components/MetadataNavigationCom.vue";
import FileSelectionCom from "../../components/filerelated/FileSelectionCom.vue";
import SideMenuRefObCom from "../../components/referencedobject/SideMenuRefObCom.vue";
import GoBackUtil from "../../components/utils/GoBackUtil.vue";

export default {
  name: "ReferencedObjectView",
  components: {
    MetadataNavigationCom,
    FileSelectionCom,
    SideMenuRefObCom,
    GoBackUtil,
  },
  computed: {
    ...mapGetters(["getFileSelected", "getIsGeneralDocu"]),
  },
   mounted(){
//     const [empty, cat1, cat2, cat3] = new String(this.$route.path).split('/')
//     utag.view({
//     "page_name" : this.$route.path,
//     "page_language" : this.$i18n.locale,
//     "page_identifier" : "general_docu: " + this.getIsGeneralDocu,
//     "page_category" : cat1,                         
//     "page_subcategory" : cat2,
//     "page_sub_subcategory": cat3,
//     "page_type" : "",                                      
//     "page_country" : "global"

// });
  }
};
</script>
