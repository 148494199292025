<template>
  <div class="container-fluid" id="wrapper">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid">
          <router-link to="/" >
          <img
            src="./assets/BASFo_wh100db_3c.png"
            width="140"
            height="70"
            class="d-inline-block align-middle"
            alt="BASF Logo"
          />
          </router-link>|
        <a class="navbar-brand" href="#" style="padding-left: 10px"
          ><h3>{{$t('BASFVDI2770Creator')}}</h3></a
        >
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
                <router-link to="/vdi2770viewer/files" class="nav-link"
                @click="setIsPackageUnit(true);"
                  >{{$t('VDI2770Viewer')}}</router-link
                >
              </li>
            
            <li class="nav-item">
              <router-link to="/" class="nav-link">{{$t('Home')}}</router-link>|
            </li>
            <li class="nav-item pt-4">
              <LocaleSwitcher />
            </li>
            <li class="nav-item pt-4" v-if="getUser != ''">
              <a class="navbar-brand" href="#">{{$t('Hello')}} {{ getUser }}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <router-view></router-view>
      </div>
    </div>

    <!-- <footer class="footer mt-5 py-3 bg-light text-center">
      <div class="row">
        <div class="col"><h5 class="text-muted">Impressum</h5></div>
        <div class="col"><h5 class="text-muted">Privacy</h5></div>
        <div class="col"><h5 class="text-muted">Contact</h5></div>
      </div>
      <div class="row">
        <div class="col">
          <router-link to="/" class="nav-link">Footer Impressum</router-link>
        </div>
        <div class="col">
          <router-link to="/" class="nav-link">Footer Privacy</router-link>
        </div>
        <div class="col">
          <router-link to="/" class="nav-link">Footer Contact</router-link>
        </div>
      </div>
    </footer> -->
  </div>
</template>

<script>
import LocaleSwitcher from "./components/utils/LocaleSwitcher.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  components: { LocaleSwitcher},
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    ...mapActions(["setIsPackageUnit"])
  }

};
</script>

<style>
@import "../node_modules/bootstrap-icons";
.nav-item {
  padding-right: 10px;
  padding-block-start: 1rem;
}

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}
.hZVS {  
  text-decoration: underline  #0d6efd  2px;}  
.hGen {
  text-decoration: underline  #adb5bd   2px;
}
</style>
