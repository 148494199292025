import store from "../../store/index";
import i18n from "@/i18n";
import axios from "axios";
import {
  ZVSDefinitions,
  ZVSMandatory,
  ZVSOptional,
} from "../../components/constants/zvsBulkUploadConst";

export default {
  async getBulkUploadClassNames(ZvsClassId, ClassIdArray) {
    // console.log(ZvsClassId)
    var returnClassName = undefined
    try {
      let relatedclassNameId = ClassIdArray[0].find(
        (a) => a.ClassId == ZvsClassId
      ).id;
      await axios
        .get("api/v1/classname/?classnameId=" + relatedclassNameId)
        .then((resp) => {
          returnClassName = resp.data[0]["ClassName"]
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    return returnClassName
  },

  async getClassIdForClassSystem(ClassSystem, ClassIdArray){
    await axios
          .get("api/v1/classid/?ClassificationSystem=" + ClassSystem)
          .then((resp) => {
            ClassIdArray.push(resp.data);
          })
          .catch((error) => {
            console.log(error);
          });
          return await ClassIdArray
  },
  async getClassIdMatch(id){
    var relatedClassIdObject = undefined
    try {
      let relatedclassId = id
      await axios
        .get("api/v1/classmapping/?fromClassId=" + relatedclassId)
        .then((resp) => {
          relatedClassIdObject = resp.data;
        })
        .catch((error) => {
          alert(error);
        });
    } catch (error) {
      relatedClassIdObject = [
        {
          id: "",
          fromClassId_id: "",
          toClassId_id: "",
          ClassId: "",
        },
      ];
      
    }
    return await relatedClassIdObject
  },

  async getVdiClassName(id){
    try {
      // let relatedclassNameId = this.classIdProposals[index].find(
      //   (a) => a.ClassId == ClassId
      // ).id;
      var vdiClass = undefined
      await axios
        .get("api/v1/classname/?classnameId=" + id)
        .then((resp) => {
          // changed [index]
          vdiClass = resp.data;
        })
        .catch((error) => {
          alert(error);
        });
    } catch (error) {
      // changed [index]
      vdiClass = [
        {
          id: "",
          ClassId: "",
          language: "",
          ClassName: "",
          language: "",
        },
      ];
    }
    return await vdiClass
  },
};
