import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SingleSO from "../views/SingleSignOn.vue";
import KeepMeAliveView from "../views/KeepMeAliveView.vue";
import DefaultSettingsView from "../views/metadata/DefaultSettingsView.vue";
import ClassificationView from "../views/metadata/ClassificationView.vue";
import ReferencedObjectView from "../views/metadata/ReferencedObjectView.vue";
import FileView from "../views/metadata/FileView.vue";
import VdiViewer from "../views/VdiViewer.vue";
import DocumentVersionView from "../views/metadata/DocumentVersionView.vue";
import ObjectIdRefObjCom from "../components/referencedobject/ObjectIdRefObjCom.vue";
import OptionalDataRefObjCom from "../components/referencedobject/OptionalDataRefObjCom.vue";
import DescriptionRefObjCom from "../components/referencedobject/DescriptionRefObjCom.vue";
import PartyRefObjCom from "../components/referencedobject/PartyRefObjCom.vue";
import FileUploadCom from "../components/filerelated/FileUploadCom.vue";
import FileSelectionCom from "../components/filerelated/FileSelectionCom.vue";
import DocumentIdCom from "../components/organizationaldata/DocumentIdCom.vue";
import PartyCom from "../components/organizationaldata/PartyCom.vue";
import DocVersionPartyCom from "../components/documentversion/DocVersionPartyCom.vue";
import DocDescriptionDocVerCom from "../components/documentversion/DocDescriptionDocVerCom.vue";
import LifeCycleDocVerCom from "../components/documentversion/LifeCycleDocVerCom.vue";
import DocRelationshipDocVerCom from "../components/documentversion/DocRelationshipDocVerCom.vue";
import XmlPreview from "../components/documentversion/xmlPreview";
import BulkUploadView from "../views/metadata/BulkUploadView";
// import SignUp from "../views/SignUp.vue";
// import LogIn from "../views/LogIn.vue";

import store from "../store";

const routes = [
  // login view

  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requireLogin: true,
    },
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  {
    path: "/single-sign-on",
    name: "singlesignon",
    component: SingleSO,
  },
  {
    path: "/vdi2770viewer",
    name: "VdiViewer",
    component: VdiViewer,
    meta: {
      requireLogin: true,
    },
    children: [
      {
        path: "/vdi2770viewer/files",
        components: {
          default: FileUploadCom,

          second: FileSelectionCom,
        },
      },
    ],
  },
  // {
  //   path: "/log-in",
  //   name: "LogIn",
  //   component: LogIn,
  // },
  {
    path: "/metadata",
    name: "KeepMeAliveView",
    component: KeepMeAliveView,
    meta: {
      requireLogin: true,
    },
    children: [
      {
        path: "/metadata/defaultsettings",
        redirect: "/metadata/defaultsettings/documentIdDomain/",
        name: "DefaultSettingsView",
        component: DefaultSettingsView,
        meta: {
          requireLogin: true,
        },
        children: [
          {
            path: "/metadata/defaultsettings/documentId/",
            component: DocumentIdCom,
            props: true,
          },
          {
            path: "/metadata/defaultsettings/documentIdDomain/",
            component: PartyCom,
            // props: true,
          },
        ],
      },
      {
        path: "/metadata/classification",
        name: "ClassificationView",
        component: ClassificationView,
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "/metadata/refobject",
        redirect: "/metadata/refobject/objectid",
        name: "ReferencedObjectView",
        component: ReferencedObjectView,
        meta: {
          requireLogin: true,
        },
        children: [
          {
            path: "/metadata/refobject/objectid",
            component: ObjectIdRefObjCom,
          },
          {
            path: "/metadata/refobject/optional-data",
            component: OptionalDataRefObjCom,
          },
          { path: "/metadata/refobject/party", component: PartyRefObjCom },
          {
            path: "/metadata/refobject/description",
            component: DescriptionRefObjCom,
          },
        ],
      },
      {
        path: "/metadata/files",
        name: "/metadataFileView",
        // redirect: "/metadata/files/fileupload",
        component: FileView,

        meta: {
          requireLogin: true,
        },
        children: [
          { path: "/metadata/files/fileupload", component: FileUploadCom },
          {
            path: "/metadata/files/fileselection",
            component: FileSelectionCom,
          },
          {
            path: "/metadata/files/documentationname",
            component: FileView,
          },
        ],
      },
      {
        path: "/metadata/docversion",
        redirect: "/metadata/docversion/documentversionid/",
        name: "DocumentVersionView",
        component: DocumentVersionView,
        meta: {
          requireLogin: true,
        },
        children: [
          {
            path: "/metadata/docversion/DocumentVersionId",
            component: DocVersionPartyCom,
          },
          {
            path: "/metadata/docversion/documentdescription",
            component: DocDescriptionDocVerCom,
          },
          {
            path: "/metadata/docversion/lifecycle",
            component: LifeCycleDocVerCom,
          },
          // {
          //   path: "/metadata/docversion/relationship",
          //   component: DocRelationshipDocVerCom,
          // },
          {
            path: "/metadata/docversion/xmlpreview",
            component: XmlPreview,
          },
        ],
      },
      {
        path: "/metadata/bulkupload",
        name: "BulkUploadView",
        component: BulkUploadView,
        meta: {
          requireLogin: true,
        },
        children:[
          {
            path: "/metadata/bulkupload",
            component: FileUploadCom,
          }
        ]
      },
    ],
  },

  // alway as last path, catch all
  { path: "/:notFound(.*)", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requireLogin) &&
    !store.state.isAuthenticated
  ) {
    next("/single-sign-on");
  } else {
    // Continue with the regular navigation
    next();
  }
});

export default router;
