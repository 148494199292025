import store from "../../store/index";
import i18n from "@/i18n";
import {
  ZVSDefinitions,
  ZVSMandatory,
  ZVSOptional,
  ZVSRefObjTypes,
} from "../../components/constants/zvsBulkUploadConst";
import APIcallsBulkUpload from "./APIcallsBulkUpload";
import createDocumentation from "../createDocumentation";
// const fr = new FileReader()

export default {
  splitByCriteria(excelData, splitCriteriaSet, searchCriteria) {
    // var excelData = store.getters["Bulk/getExcelData"];
    var orderedExcelData = [];
    splitCriteriaSet.forEach((element) => {
      let dataArray = [];
      for (let i = 0; i < excelData.length; i++) {
        if (excelData[i][searchCriteria] == element) {
          dataArray.push(excelData[i]);
        }
      }
      orderedExcelData.push(dataArray);
    });
    return orderedExcelData;
  },
  // createXML.createVdiMetadata erwartet den index aus FileStore FileArray
  async fillDocumentContainerStore(VdiContainerData, ClassIdArray) {
    // Nach jedem Durchlauf muss der Store (ohne Files) resetet werden
    // const re = /\s*(?:;|,|\s|$)\s*/;
    // const langAbbr = VdiContainerData[ZVSDefinitions.LANG];
    // const splits = langAbbr.split(re);
    const splits = this.getSplittedLanguages(
      VdiContainerData[ZVSDefinitions.LANG]
    );
    this.setFileStatusSelected(VdiContainerData);
    this.setOrgData(VdiContainerData);
    this.setDocIdZvs(VdiContainerData);
    await this.setClassificationZvs(VdiContainerData, ClassIdArray);
    this.setRefObjData(VdiContainerData);
    this.setDescriptionData(VdiContainerData, splits);
    this.setManufacturerData(VdiContainerData);
    this.setDocVersionPartyData(VdiContainerData, splits);
    createDocumentation.generateDoc();
    // let funcGetPdfFileByIndex = store.getters["Files/getPdfFile"]
    // let funcGetXmlBlobByIndex = store.getters["Files/getXmlBlob"];
  },
  getSplittedLanguages(languages) {
    const re = /\s*(?:;|,|\s|$)\s*/;
    // const langAbbr = VdiContainerData[ZVSDefinitions.LANG];
    const splits = languages.split(re);
    return splits;
  },
  setFileStatusSelected(VdiContainerItem) {
    var statIndex = store.getters["Files/getFileArray"].findIndex(
      (file) => file.pdfFile.name == VdiContainerItem[ZVSDefinitions.FILENAME]
    );
    let fileStat = {
      index: statIndex,
      status: "FILE_SELECTED",
    };

    store.dispatch("Files/setFileStatus", fileStat);
  },

  setOrgData(VdiContainerItem) {
    let payload = {
      OrganizationId: VdiContainerItem[ZVSDefinitions.ORGID],
      OrganizationName: VdiContainerItem[ZVSDefinitions.ORGNAME],
      OrganizationOfficialName:
        VdiContainerItem[ZVSDefinitions.ORGOFFICIALNAME],
      Responsible: "Responsible",
    };
    store.dispatch("OrgData/setPartyType", payload);
  },
  //   only one predefined DocId currently possible
  setDocIdZvs(VdiContainerItem) {
    if (store.getters["DocId/getDocumentIdType"].length == 0) {
      store.dispatch("DocId/addDocumentIdType");
    }
    let payload = {
      index: 0,
      DomainId:
        "BIG_00-0000-000-PM-010-010 Site and Plant Structure and Eng Doc Codes",
    };
    store.dispatch("DocId/setDomainId", payload);
    let payload1 = {
      index: 0,
      DocumentDomainId:
        "BIG_00-0000-000-PM-010-010 Site and Plant Structure and Eng Doc Codes",
    };
    store.dispatch("DocId/setDocumentDomainId", payload1);
    let payload2 = {
      index: 0,
      IsPrimary: true,
    };
    store.dispatch("DocId/setIsPrimary", payload2);
    let payload3 = {
      index: 0,
      DocumentId: VdiContainerItem[ZVSDefinitions.DOCID],
    };
    store.dispatch("DocId/setDocumentId", payload3);
  },
  async setClassificationZvs(VdiContainerItem, ClassIdArray) {
    let value = VdiContainerItem[ZVSDefinitions.DOCID];
    const pdfVersionRegex =
      /(BIG_)(\w){2}(-)\D{1}\d{3}(-)\D{1}\d{2}(-VD)(-)\D{1}\d{3}/;
    const match = value.match(pdfVersionRegex);
    try {
      if (match) {
        var lastIndex = match[0].lastIndexOf("-");
        var catchedClassId = match[0].substring(lastIndex + 1);
        var classIdCheck = ClassIdArray[0].find(
          (item) => item["ClassId"] == catchedClassId
        );
        let payload = {
          index: 0,
          documentClassification_model_type: {
            ClassificationSystem:
              "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types",
            ClassId: await match[0].substring(lastIndex + 1),
            ClassNameType_model: [
              {
                language: "en",
                classname: await classIdCheck["classname"],
              },
            ],
          },
        };
        store.dispatch("Classification/setDocumentClassification", payload);
        var matchingClassId = await APIcallsBulkUpload.getClassIdMatch(
          classIdCheck["id"]
        );
        var vdiClass = await APIcallsBulkUpload.getVdiClassName(
          matchingClassId[0]["toClassId_id"]
        );
        vdiClass = vdiClass.map(({ id, ClassName, language }) => ({
          id: id,
          classname: ClassName,
          language: language,
        }));

        store.dispatch("Classification/addDocumentClassification");
        var classificationModel = {
          index: 1,
          documentClassification_model_type: {
            ClassificationSystem: "VDI2770:2018",
            ClassId: matchingClassId[0]["ClassId"],
            ClassNameType_model: vdiClass,
          },
        };
        store.dispatch(
          "Classification/setDocumentClassification",
          classificationModel
        );
      }
    } catch (error) {
      console.log(error);
      let payload = {
        index: 0,
        documentClassification_model_type: {
          ClassificationSystem:
            "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types",
          ClassId: "Not available",
          ClassNameType_model: [
            {
              language: "en",
              classname: "error",
            },
          ],
        },
      };
      store.dispatch("Classification/setDocumentClassification", payload);
    }
  },

  setRefObjData(VdiContainerItem) {
    for (let i = 0; i < ZVSRefObjTypes.length; i++) {
      let payload = {
        index: i,
        value: VdiContainerItem[ZVSRefObjTypes[i]],
      };
      store.dispatch("RefObj/setObjectId", payload);
    }
  },

  setDescriptionData(VdiContainerItem, languages) {
    for (let i = 0; i < languages.length; i++) {
      if (i > 0) {
        store.dispatch("RefObj/addDescription");
      }
      let payload = {
        index: i,
        description: VdiContainerItem[ZVSDefinitions.DESC],
      };
      store.dispatch("RefObj/setDescriptionTypeDesc", payload);
      let payload1 = {
        index: i,
        language: languages[i],
      };
      store.dispatch("RefObj/setDescriptionTypeLang", payload1);
    }
  },

  setManufacturerData(VdiContainerItem) {
    let PartyType1_model = {
      OrganizationId: VdiContainerItem[ZVSDefinitions.MANUFACT],
      OrganizationName: VdiContainerItem[ZVSDefinitions.MANUFACT],
      OrganizationOfficialName: VdiContainerItem[ZVSDefinitions.MANUFACT],
      Role: "Manufacturer",
      id: undefined,
    };
    store.dispatch("RefObj/setPartyType1", PartyType1_model);
  },

  setDocVersionPartyData(VdiContainerItem, languages) {
    let PartyType4_model = {
      DocVersionId: VdiContainerItem[ZVSDefinitions.REVISION],
      Party4OrgData: [
        {
          OrganizationId: VdiContainerItem[ZVSDefinitions.MANUFACT],
          OrganizationName: VdiContainerItem[ZVSDefinitions.MANUFACT],
          OrganizationOfficialName: VdiContainerItem[ZVSDefinitions.MANUFACT],
          Role: "Author",
        },
      ],
      NumPages: VdiContainerItem[ZVSDefinitions.NUMBEROFPDFPAGES],
      Language: languages,
      id: undefined,
    };

    store.dispatch("DocVer/setPartyType4", PartyType4_model);

    let PartyType7_model = {
      OrganizationId: VdiContainerItem[ZVSDefinitions.MANUFACT],
      OrganizationName: VdiContainerItem[ZVSDefinitions.MANUFACT],
      OrganizationOfficialName: VdiContainerItem[ZVSDefinitions.MANUFACT],
      Role: "Responsible",
    };
    store.dispatch("DocVer/setPartyType7", PartyType7_model);
    let DocumentDescription_model = [];
    var summary = VdiContainerItem[ZVSDefinitions.SUMMARY];
    if (
      VdiContainerItem[ZVSDefinitions.SUMMARY] == "" ||
      VdiContainerItem[ZVSDefinitions.SUMMARY] == undefined
    ) {
      summary = VdiContainerItem[ZVSDefinitions.DOCTITLE];
    }
    var keywordsModel = [];
    try {
      keywordsModel =
        VdiContainerItem[ZVSDefinitions.KEYWORDS].split(/\s*(?:;|,|$)\s*/); //(/[,;]/)
      if (VdiContainerItem[ZVSDefinitions.KEYWORDS].length == 0) {
        keywordsModel = [VdiContainerItem[ZVSDefinitions.DOCTITLE]];
      }
    } catch (error) {
        keywordsModel = [VdiContainerItem[ZVSDefinitions.DOCTITLE]];
    }

    languages.forEach((element) => {
      DocumentDescription_model.push({
        Language: element,
        Title: VdiContainerItem[ZVSDefinitions.DOCTITLE],
        SubTitle: undefined,
        Summary: summary,
        KeyWordsType_model: keywordsModel,
        id: undefined,
      });
    });
    store.dispatch(
      "DocVer/setDocumentDescription_model",
      DocumentDescription_model
    );
  },
};
