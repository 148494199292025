export default {
  "next Step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weiter"])},
  "Edit Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien bearbeiten"])},
  "go back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippen um zu suchen..."])},
  "routeObjectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt IDs"])},
  "routeOptionalVendorMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Lieferanten Metadaten"])},
  "routeHeaderRefObjParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beteiligte Partei"])},
  "routeHeaderRefObjDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "routeFileUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hochladen"])},
  "routeFileSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auswählen"])},
  "routeOrganizationMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisations Metadaten"])},
  "routeDocumentDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument Domain"])},
  "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 3: Metadaten Klassifizierung"])},
  "step5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 5: Metadaten Dokument Version"])},
  "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4: Metadaten Referenziertes Objekt"])},
  "navFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
  "navOrgData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisatorische Daten"])},
  "navClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassifizierung"])},
  "navRefObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenziertes Objekt"])},
  "navDocVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument Version"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen zum BASF VDI 2770 Ersteller"])},
  "homeSubTitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei ein Teil der digitalen Transformation und bringe deine Dokumentation auf das nächste Level."])},
  "homeParaOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDI 2770 Web Editor ermöglicht dir Dokumentationen in digitaler Form bereitzustellen, die dem DIN Standard 2770 entsprechen und die BASF-Regularien for die digitale Bereitstellung einhalten."])},
  "homeParaTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du möchtest einen Dokumentencontainer für ein einzelnes Dokument oder eine komplette Dokumentation für eine ganze Einheit erstellen? Starte hier mit deiner Auswahl und lass dich von uns durch den gesamten Prozess führen. Am Ende erhältst du einen ZIP-Container, den du digital mit der BASF teilen kannst."])},
  "homeParaThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In wenigen Minuten ist deine Dokumentation erstellt, starte jetzt!"])},
  "titleLearMoreSimpleDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfacher Dokument Container"])},
  "bodyLearMoreSimpleDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle einen Dokumenten-Container für eine bestimmte Datei. Dieser Dokumenten-Container kann in einem Dokumentations-Container verwendet werden. Er kann mehrfach in verschiedenen Dokumentationen wiederverwendet werden."])},
  "titleLearMoreDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentations Container"])},
  "bodyLearMoreDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle einen vollständigen Dokumentationscontainer für ein einzelnes Equipment oder eine komplette Unit. Mit dieser Funktionalität kannst du in einem Schritt alle notwendigen Dokumenten-Container erstellen und zu einem Dokumentationscontainer zusammenfassen."])},
  "SimpleDocumentContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfacher Dokumenten Container"])},
  "createDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzeuge ein Dokument"])},
  "documentationContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentations-Container"])},
  "createFullDocumentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzeuge eine vollständige Dokumentation"])},
  "PackageUnitDocumentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Unit Dokumentation"])},
  "createPackageUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erzeuge eine Package Unit"])},
  "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "thisTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel Titel für Info Modal"])},
  "thisBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel Text für Info Modal"])},
  "Step 1: Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 1: Dateien"])},
  "cardHeaderFileView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellst du eine Modelldokumentation oder eine Dokumentation für eine bestimmte Instanz?"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
  "modelInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine modellspezifische Dokumentation wird bei einem Bezug von Modell-ID oder Baureihe zur Dokumentation gewählt."])},
  "instance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instanz"])},
  "instanceInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine instanzspezifische Dokumentation wird bei einem eindeutigen Bezug von einer Instanz zur Dokumentation gewählt. Eine eindeutige Instanz kann beispielsweise durch die Kombination von Hersteller, Modellreihe und Seriennummer oder durch eine IEC 61406 Id vorgegeben werden."])},
  "documentationNameHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib deiner Dokumentation einen aussagekräftigen Namen:"])},
  "documentationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Dokumentation:"])},
  "documentationNameHelpBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Dokumentationsname ist die Benennung vom erstellten VDI 2770 Dokumentationscontainer."])},
  "TPU-Plant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentation für TPU"])},
  "TPU-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liefern Sie eine Dokumentation für die TPU-Anlage?"])},
  "TPUInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPU-Dokumentationen erfordern ein zusätzliches Klassifizierungssystem. Wir werden dies als obligatorisch hinzufügen, damit Sie nicht vergessen, dieses Klassensystem zu pflegen"])},
  "headerZipInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some intelligent explanation for document container upload"])},
  "fileUploadPDFInfoBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hier wird das ", _interpolate(_list(0)), ". Dokument des Dokumentationscontainers in PDF/A Format ausgewählt."])},
  "fileUploadZipInfoBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hier wird der ", _interpolate(_list(0)), ". Dokumentationscontainers in Zip Format ausgewählt."])},
  "fileUploadNativeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier werden die zusätzlichen Native Files (z.B. Zeichnung im cad-Format, Stückliste im xlsx-Format) des zugehörigen Dokuments des Dokumentationscontainers ausgewählt. Die Native Files werden gemeinsam mit dem PDF/A Dokument in einem Dokumentencontainer gespeichert und haben die identischen Dokument-Metadaten."])},
  "headerFileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte lade alle Dateien für deine Dokumentation hoch"])},
  "headerZipUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte verwende nur konforme Container"])},
  "p1FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Upload-Funktionalität ist in zwei Teile gegliedert. Das erste Upload Feld ist für Ihre PDF(A)-Datei. Hier ist ein einzelnes PDF erforderlich."])},
  "p2FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das zweite Feld ermöglicht den Upload mehrerer Dateien und kann zum Hochladen aller zugehörigen nativen Dateien, wie Zeichnungen, Word, Excel und aller anderen Dateiformate verwendet werden."])},
  "p3FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natürlich wird auch Drag & Drop unterstützt."])},
  "p4FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du auf die blaue Plus-Taste klickst, erscheinen neue Zeilen für die nächsten Dokumente. Füge so viele Dokumente hinzu, wie du benötigst."])},
  "p5FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du zu viele Zeilen ausgewählt hast oder ein Dateipaar löschen möchtest, klicke auf das rote Kreuz."])},
  "headerFileSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle eine Datei um die Metadaten zu kreieren:"])},
  "PDF File": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["PDF Datei ", _interpolate(_list(0)), " upload"])},
  "Zip File": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zip Datei ", _interpolate(_list(0)), " upload"])},
  "Native File(s)": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Native Datei(en) ", _interpolate(_list(0)), " upload"])},
  "headerSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewähltes Dokument:"])},
  "headerFileSelectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du dein nächstes Dokument zur Bearbeitung auswählen"])},
  "textFileSelectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du deine Dokumentation für diese Datei abgeschlossen hast, komme zurück und wähle die nächste Datei."])},
  "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 2: Metadaten der Organisation"])},
  "headerPartyTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen Organisations-Daten:"])},
  "descPartyTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit diesen Daten werden die Felder vorausgefüllt. Du kannst sie jederzeit überschreiben."])},
  "chooseTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eine Vorlage:"])},
  "createTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzeuge eine neue Vorlage:"])},
  "newTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Vorlage"])},
  "partyOrgDataInfoChoosBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können bereits gespeicherte Templates Ihres Unternehmens aus vorausgegangen Projekten ausgewählt werden."])},
  "partyOrgDataInfoChoosTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eine Vorlage"])},
  "partyOrgDataInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du ein neues Template für die Organisationsmetadaten erstellen und speichern. Das gespeicherte Template kann projektübergreifend verwendet werden."])},
  "partyOrgDataInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzeuge eine neue Vorlage"])},
  "partyOrgDataOrgWebsInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Id"])},
  "partyOrgDataOrgWebsInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindeutige ID für die Organisation, die für die Erstellung der Dokumentation verantwortlich ist und an den Kunden liefert. Beispielsweise, die offzielle Website des Unternehmens."])},
  "partyOrgDataRoleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "partyOrgDataRoleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier ist die Rolle 'Responsible' standardmäßig gesetzt und kann nicht verändert werden."])},
  "partyOrgDataOrgNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Name"])},
  "partyOrgDataOrgNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gebräuchliche Bezeichnung des Unternehmens ('Kurzform ohne Angabe der Rechtsform'), dass für die Erstellung der Dokumentation verantwortlich ist und an den Kunden liefert. Beispiel: Unternehmen ABC."])},
  "partyOrgDataOrgOffNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Official Name"])},
  "partyOrgDataOrgOffNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der offizielle Name des Unternehmens ('Langform mit Angabe der Rechtsform'), dass für die Erstellung der Dokumentation verantwortlich ist und an den Kunden liefert. Beispiel: Unternehmen ABC GmbH & Co. KG."])},
  "partyOrgDataExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation, die für den Inhalt dieses Dokuments verantwortlich ist."])},
  "DomainID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain ID"])},
  "DocumentDomainId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document DomainId"])},
  "DocumentID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DocumentId"])},
  "headerDocTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen Document Domain Ids:"])},
  "headerAddDocId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional kann eine weitere Document Domain Id hinzugefügt werden:"])},
  "docIdHeaderInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können bereits gespeicherte Templates Ihres Unternehmens aus vorausgegangen Projekten ausgewählt werden oder neue Templates erstellt werden."])},
  "docIdDomainIdInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verpflichtende DomainID für Zhanjiang Verbund Site Projekt"])},
  "docIdDomainIdInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennung oder Kennzeichen einer Domäne, in der eine Dokumenten-ID eineindeutig ist"])},
  "docIdDocDomainIdInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verpflichtende DocumentDomainID für Zhanjiang Verbund Site Projekt"])},
  "docIdDocDomainIdInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennung oder Kennzeichen einer Domäne; es können z. B. Namen von Organisationen verwendet werden."])},
  "docIdDocIdInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Document File-Naming Syntax laut Spezifikation 'BIG_00-0000-000-PM-010-010-R34-Site and Plant Structure and Eng Doc Codes'"])},
  "docIdDocIdInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer eines Dokuments"])},
  "headerClassData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflege die Klassifizierungsdaten"])},
  "ClassificationSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassifizierungs-System"])},
  "classSysHeaderInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflege die Klassifizierungsdaten"])},
  "classSysHeaderInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nutzer kann beliebig viele Klassifikationssysteme angeben. Es müssen jedoch zwingend die Klassifizierungssysteme 'BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types' und 'VDI 2770:2018' ausgefüllt werden."])},
  "classSysGeneralHeaderInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nutzer kann beliebig viele Klassifikationssysteme angeben. Es soll jedoch mindestens das Klassifizierungssystem 'VDI 2770:2018' ausgefüllt werden."])},
  "classSysInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassifizierungs-System"])},
  "classSysInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier wird das jeweilige Klassifikationssystem ausgewählt."])},
  "classIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Id"])},
  "classIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier ist dem Dokument zugehörige Dokumentkategorie nach dem gewählten Klassifikationssystem anzugeben. Das Dokument kann sich in seltenen Fällen auch auf mehrere Dokumentkategorien beziehen."])},
  "classNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ClassName"])},
  "classNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der ClassName wird automatisch anhand der ausgewählten ClassID gesetzt, falls ein vorgegebenes Klassensystem gewählt wurde."])},
  "classLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ClassLanguage"])},
  "classLangInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sprache vom Wert ClassName. Hier muss mindestens die Sprache Englisch angegeben werden."])},
  "headerRefObjId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten 'ObjectId' für Zhanjiang Verbund:"])},
  "headerGeneralRefObjId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten 'Object Id':"])},
  "toogleHeaderMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verpflichtend für Zhanjiang Verbund: (Klicken zum Umschalten)"])},
  "toogleHeaderOptional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls die Daten für Zhanjiang Verbund vorhanden sind: (Klicken zum Umschalten)"])},
  "additionalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere ObjectIDs "])},
  "additionalDataBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können noch weitere dem Objekt zugewiesene ObjectIDs vom Hersteller eingetragen werden."])},
  "objIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Id"])},
  "objIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of usage 'ObjectId'"])},
  "objIdRefTypeInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RefType"])},
  "objIdRefTypeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of usage 'RefType'"])},
  "objIdRefTypeInfoTitle0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf:ProjectId"])},
  "objIdRefTypeInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Projektnummer von BASF ist eine identifizierende Nummer und Kennungen eines Projektes in den BASF IT Systemen."])},
  "objIdRefTypeInfoTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf:purchase order number"])},
  "objIdRefTypeInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellnummer von BASF ist eine identifizierende Nummer und Kennungen für die Bestellung eines oder mehrerer Objekte, die in den IT-Systemen von BASF verwendet wird und bei der Bestellung dem Hersteller übermittelt wird."])},
  "objIdRefTypeInfoTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product type"])},
  "objIdRefTypeInfoBody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Produktbezeichnung (Modell, Baureihe, Produkttyp) kann als Komponente in einer oder mehreren Baueinheiten Verwendung finden. Die Produktbezeichnung ist innerhalb eines Herstellers eindeutig."])},
  "objIdRefTypeInfoTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instance of object uri (IEC 61406 ID)"])},
  "objIdRefTypeInfoBody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global eindeutige Identifikation des Objektes nach IEC 61406 (Vorgängernorm: DIN SPEC 91406) vom OEM."])},
  "objIdRefTypeInfoTitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECLASS number"])},
  "objIdRefTypeInfoBody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECLASS ist ein Datenstandard für die Klassifizierung von Produkten."])},
  "objIdRefTypeInfoTitle5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf: engineering tag number"])},
  "objIdRefTypeInfoBody5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die BASF Engineering Tag number (Apparatenummer, Messstellennummer) ist eine identifizierende Kennung des Einbauortes in der Produktionsanlage, dass durch den Betreiber an den Hersteller in den meisten Fällen bei der Bestellung übermittelt wird."])},
  "objIdRefTypeInfoTitle6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf: equipment number"])},
  "objIdRefTypeInfoBody6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die BASF Equipment Nummer ist eine eindeutige, identifierende Kennung einer Komponente in der Produktionsanlage. Die BASF Equipment Nummer muss vom Betreiber an den Hersteller übermittelt werden."])},
  "objIdRefTypeInfoTitle7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf: ReferencedDesignation"])},
  "objIdRefTypeInfoBody7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Referenzkennzeichnung ist eine identifizierende Nummer und Kennungen für ein Objekt (BASF Technischer Platz). Die BASF Technische Platz muss vom Betreiber an den Hersteller übermittelt werden."])},
  "objIdRefTypeInfoTitle8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serial number"])},
  "objIdRefTypeInfoBody8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eindeutige Identifikationsnummer vom Hersteller für ein individuelles Objektes. Innerhalb einer Produktbezeichnung (Modell, Baureihe, Produkttyp) von einem Hersteller ist die Seriennummer eindeutig."])},
  "objIdRefTypeInfoTitle9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vendor sales order conformation number"])},
  "objIdRefTypeInfoBody9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine eindeutige Kundenauftragsnummer, die vom ERP-System des Lieferanten nach der Vergabe der Bestellung erstellt wurde."])},
  "objIdObjectTypeInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["object type:*"])},
  "objIdObjectTypeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of usage 'Object Type'"])},
  "objIdBiuniqueInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is globally biunique"])},
  "objIdBiuniqueInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of usage 'is globally biunique'"])},
  "headerOptMetaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional zusätzliche Object-Metadaten"])},
  "objIdOptTypeInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "objIdOptTypeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Referenzen können für interne Bezüge in den IT-Systemen des Herstellers oder Lieferanten verwendet werden."])},
  "objIdOptValueInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "objIdOptValueInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der entsprechende Wert zu der gewählten Referenz."])},
  "headerRefObjParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten Partei"])},
  "headerRefObjPartyBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier werden standardmäßig die Organisationsmetadaten aus Schritt 2 eingefügt. Die Daten können bei Bedarf händisch angepasst werden."])},
  "refObjPartyOrgIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation Website"])},
  "refObjPartyOrgIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindeutige ID für die Organisation. Beispielsweise, die offzielle Website des Unternehmens."])},
  "refObjPartyRoleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "refObjPartyRoleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kann zwischen den folgenden Rollen gewählt werden: Manufacturer (Hersteller), Supplier (Zulieferer)."])},
  "refObjPartyOrgNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Name"])},
  "refObjPartyOrgNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gebräuchliche Bezeichnung des Unternehmens (Kurzform ohne Angabe der Rechtsform). Beispiel: Unternehmen ABC"])},
  "refObjPartyOrgOffNInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Official Name"])},
  "refObjPartyOrgOffNInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der offizielle Name des Unternehmens (Langform mit Angabe der Rechtsform), dass die Dokumentation erstellt und an den Kunden liefert. Beispiel: Unternehmen ABC GmbH & Co. KG"])},
  "headerRefObjDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung des Referenzierten Objektes"])},
  "refObjgDescDescInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "refObjDescDescInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrsprachige Beschreibung zum ReferencedObject. Je Sprache sollte es nur eine Beschreibung zum ReferencedObject geben."])},
  "refObjgDescLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "refObjDescLangInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angabe der Sprache der Beschreibung vom ReferencedObject."])},
  "headerDocVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten Dokument Version"])},
  "docVersionDocVerIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Version Id"])},
  "docVersionDocVerIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die aktuelle Version des Dokuments."])},
  "docVersionNumPagesInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Pages"])},
  "docVersionNumPagesInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Seiten des Dokuments bezogen auf das PDF/A Dateiformat."])},
  "docVersionLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "docVersionLangInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auflistung der im Dokument verwendeten Sprachen."])},
  "docVersionPartyHeadInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument Version Partei"])},
  "docVersionPartyHeadInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die für das Dokument zuständige Partei."])},
  "docVersionOrgIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Website"])},
  "docVersionOrgIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindeutige ID für die Organisation. Beispielsweise, die offzielle Website des Unternehmens."])},
  "docVersionOrgNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Name"])},
  "docVersionOrgNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gebräuchliche Bezeichnung des Unternehmens (Kurzform ohne Angabe der Rechtsform). Beispiel: Unternehmen ABC"])},
  "docVersionRoleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "docVersionRoleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier muss mindestens die Rolle 'Author' gesetzt werden."])},
  "docVersionOrgOffNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Official Name"])},
  "docVersionOrgOffNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der offizielle Name des Unternehmens (Langform mit Angabe der Rechtsform), dass die Dokumentation erstellt und an den Kunden liefert. Beispiel: Unternehmen ABC GmbH & Co. KG"])},
  "headerDocDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten Dokument Beschreibung"])},
  "docVersionDocTitleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Title"])},
  "docVersionDocTitleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel des ausgewählten Dokument."])},
  "docVersionDocSubTitleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtitle"])},
  "docVersionDocSubTitleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untertitel des Dokuments (Optionales Feld)"])},
  "docVersionDocSummaryInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
  "docVersionDocSummaryInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für jede in der Dokumentenversion verwendete Sprache muss eine DocumentDescription angegeben sein. Es ist möglich, z. B. zu einer deutschen Dokumentenversion zusätzlich eine englische Beschreibung anzugeben, obwohl der Dokumenteninhalt nicht mehrsprachig ist."])},
  "docVersionDocKeyWordInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
  "docVersionDocKeyWordInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsselwörter vom ausgewählten Dokument. Beispielsweise kann für eine Instandhaltungsanleitung die Schlüsselwörter 'Inspektion', 'Instandhaltung', 'Wartung' gesetzt werden."])},
  "docVersionDocLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "headerDocLifeCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebenszyklus Status"])},
  "docVersionDocCommentInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "docVersionDocCommentInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste von mehrsprachigen Bemerkungen und Anmerkungen zum Status."])},
  "docVersionDocDateInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "docVersionDocDateInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum, an dem der Status festgelegt wurde."])},
  "docVersionDocStatusInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "docVersionDocStatusInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Statuswert des Dokumentes im Dokumentenlebenszyklus. Es kann zwischen zwei Statuswerten unterschienden werden: InReview (in Prüfung) und Released (freigegeben)."])},
  "buttonDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentation herunter laden"])},
  "buttonNextFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nächstes Datei"])},
  "routeDocVerParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument Version Partei"])},
  "routeDocDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument Beschreibung"])},
  "routeDocLifeCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebenszyklus Status"])},
  "faultMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte alle Pflichtfelder ausfüllen"])},
  "faultDocumentationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen Namen für die Dokumentation vergeben."])},
  "faultInvalidCharDoc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Der Dokumentationsname enthält ungültige Zeichen, beginnend bei Position ", _interpolate(_list(0))])},
  "faultDocNameToLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name der Dokumentation darf nicht länger als 150 Zeichen sein."])},
  "faultMissingPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte mindestens eine PDF-Datei hinzufügen."])},
  "faultNoFileSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Datei auswählen"])},
  "faultBigSpec": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die Dokument Id muss gemäß der BIG-Spezifikation strukturiert sein. Fehler nach: ", _interpolate(_list(0))])},
  "faultMissingDocId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Dokumenten-ID"])},
  "faultDomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die ", _interpolate(_list(0)), " muss eine gültige Domäne sein."])},
  "faultPath": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die ", _interpolate(_list(0)), " muss einen Pfad enthalten."])},
  "faultDomainUpper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Domänenteil muss klein geschrieben werden."])},
  "faultPunyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Url darf keinen Punycode enthalten."])},
  "faultUrlLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Url darf nicht länger als 255 Zeichen sein."])},
  "faultInvalidCharURL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die Url enthält ungültige Zeichen ab Position ", _interpolate(_list(0)), "."])},
  "faultPoNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellnummer darf nur Ziffern enthalten."])},
  "faultEclassNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eclass kann nur Zahlen enthalten."])},
  "faultEclassLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eclass muss genau 8 Zeichen haben."])},
  "faultIndividual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens eines vom Typ 'indivdual' ist erforderlich."])},
  "faultNoIndividual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Dokumentencontainer ist Typ 'indivdual' nicht erlaubt."])},
  "faultAtLeastOneEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens ein Feld 'Object Id' muss befüllt werden"])},
  "faultNotReadablePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF ist nicht lesbar"])},
  "unspecificError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten, bitte überprüfen Sie Ihre Eingaben."])},
  "doIdTPU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DocumentId Erläuterungen für TPU"])},
  "doIdNonTPU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DocumentID Erläuterungen"])},
  "generalDocu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Dokumentation <br> (Nicht Zhanjiang)"])},
  "zhanjiangDocu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentation für <br> Zhanjiang Verbund"])},
  "wantToLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Eingaben gehen verloren. Willst du die Seite wirklich verlassen?"])},
  "VDI2770Container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDI2770 Container"])},
  "faultNotVDI2770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein gültiger VDI2770 Container. Bitte wähle einen gültigen Container."])},
  "inclNativeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native (xlsx, docx ...) Dateien benötigt?"])},
  "inclNativeFileInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls du keine nativen Dateien benötigst, kannst du deine PDFs alle auf einmal in das Upload Feld ziehen. Mit nativen Dateien it das leider nicht möglich, da dann die Zuordnung zwischen PDF und nativen Dateien nicht hergestellt werden kann."])},
  "VDI2270Container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDI-2770 Container"])},
  "PDFFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF-Dateien"])},
  "dropPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziehe deine PDF-Dateien hier her."])},
  "dropContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziehe deine VDI2770 Container hier her."])},
  "cantReadZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konnte Datei nicht lesen. Bist du sicher es war eine ZIP-Datei?"])},
  "fileInPreparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen nicht möglich, Datei bereits in einem Bearbeitungsschritt."])},
  "filesNotInEditMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Upload ist nicht im Bearbeitungsmodus."])},
  "faultNotPdfType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei ist nicht vom Typ PDF."])},
  "faultInvalidClassId": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die ClassID in deiner Dokument id scheint ungültig zu sein, bitte überprüfe und ändere die ClassId ", _interpolate(_list(0))])},
  "BIGClassSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne das BIG-Klassifizierungssystem"])},
  "Edit_Mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Bearbeitungsmodus)"])},
  "delete_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template löschen"])},
  "reallyDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template wirklich löschen?"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "faultIsPrimary0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für genau eine DocumentDomainId muss 'isPrimary' angegeben sein."])},
  "faultIsPrimary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für genau eine DocumentDomainId muss 'isPrimary' angegeben sein. Bitte eines entfernen"])},
  "numPdfFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl PDF-Dateien:"])},
  "pdfpart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteil PDFs an Gesamtdateien / Anteil XML und native Dateien"])},
  "Metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seiten: "])},
  "pdfFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF Dateien"])},
  "BacktoCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Editor"])},
  "BacktoViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Dateiauswahl"])},
  "headerFileViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke auf einen Link um den Container-Inhalt zu begutachten"])},
  "VDI2770Viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDI2770 Betrachter"])},
  "BASFVDI2770Creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BASF VDI 2770 Creator and Viewer"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo"])},
  "Expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Knoten öffnen"])},
  "Collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Knoten schließen"])},
  "attentionRAMsize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachten: Welche VDI-Containergröße verarbeitbar ist, hängt von ihrem verfügbaren Arbeitsspeicher ab."])},
  "Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe: "])},
  "HidePDFlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige Metadaten"])},
  "ShowPDFlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige PDF Liste"])},
  "fillAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es muss jeweils ein Wert für Description und Language angegeben sein oder beide leer sein"])},
  "filterResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zeige alle Filter-Ergebnisse"])},
  "include1ZipLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beziehe 1. Zip-Level mit ein"])},
  "expertMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experten Modus, wählen Sie einen XML-Dateilink in der Baumansicht"])},
  "noExpertMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailansicht"])},
  "filterMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Metadaten"])},
  "FullXMLRep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständige XML Darstellung"])},
  "clickButtonToExpand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klick auf die Buttons um alles darunterliegende auszuklappen."])},
  "clickLineToExpand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klick in die Zeile um eine einzelne Zeile auszuklappen."])},
  "ExpandAllChilds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles unterhalb ausklappen"])},
  "excelUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellung von massenweise Dokumentationen mit Excel"])},
  "excelUploadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starte die Massenerstellung"])},
  "bulkUploadZVS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel-Upload VDI2770-Erzeugung für ZVS Projekt"])},
  "bulkUploadGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel-Upload VDI2770-Erzeugung allgemein"])},
  "subHeaderBulkCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie ist Excel-Upload zu verwenden?"])},
  "descriptionBulkCreation_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Excel Bulk-Upload Job ermöglicht Ihnen das einfache Erstellen von mehreren VDI 2770 konformen Dokumentationscontainern auf Grundlage der eingetragenen Informationen in einem Excel Template."])},
  "excelTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leere Excel-Upload-Vorlage herunterladen"])},
  "headerTemplateUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie Ihre ausgefüllte Excel-Vorlage hier hoch:"])},
  "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Datei fehlt)"])},
  "checkExcelInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfe den Inhalt der Excel Datei"])},
  "createContainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzeuge VDI2770 Container"])},
  "downloadPreFilledExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade Excel Vorlage mit vorausgefüllten Dateinamen herunter."])},
  "descriptionBulkCreation_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wird für jeden im Excel Template eingetragenen BASF Engineering Tag ein eigenständiger VDI 2770 Dokumentationscontainer erstellt. Die Benennung des Dokumentationscontainers erfolgt über den eingetragenen BASF Engineering Tag."])},
  "descriptionBulkCreation_Step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 1: "])},
  "descriptionBulkCreation_Step_1_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sämtliche Dateien per drag & drop in das PDF - Upload Feld ziehen. "])},
  "descriptionBulkCreation_Step_2_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf den Button „Lade Excel Vorlage mit vorausgefüllten Dateinamen herunter“ klicken, um das mit den Dateinamen vorausgefüllte Excel Template herunterzuladen."])},
  "descriptionBulkCreation_Step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 2: "])},
  "descriptionBulkCreation_Step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 3: "])},
  "descriptionBulkCreation_Step_3_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausfüllen vom Excel Template. Die grün markierten Spaltenköpfe zeigen die Pflichteinträge, die anderen Spalten sind optionale Einträge."])},
  "descriptionBulkCreation_Step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt 4: "])},
  "descriptionBulkCreation_Step_4_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen des ausgefüllten Excel Templates."])},
  "descriptionBulkCreation_hint_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Das Excel Template kann nicht verändert werden, somit können keine weiteren Spalten hinzugefügt oder Spalten gelöscht werden."])},
  "descriptionBulkCreation_hint_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Während des Ausfüllens des Excel Templates kann die Website auch geschlossen werden. Bei erneutem Öffnen der Seite kann das ausgefüllte Excel Template hochgeladen werden. Die im Excel Template aufgeführten Dateien werden Ihnen daraufhin im PDF – Upload Feld aufgelistet."])},
  "excelBulkCommentIPclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schutzeinstufung des Dokumentes vom Hersteller. Mögliche Einträge: public, internal, confidential."])},
  "excelBulkCommentLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auflistung der im Dokument verwendeten Sprachen nach ISO 639-1 (Sprachcodes). Bei mehrsprachigen Dokumenten werden die Sprachcodes mit einem Komma separiert."])}
}