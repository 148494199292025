export default {
  namespaced: true,
  state() {
    return {
      ObjectIdType_model: [
        {
          // id: undefined,
          ObjectType: "Type",
          RefType: "basf:ProjectId",
          IsGloballyBiUnique: false,
          ObjectId: undefined,
        },
        {
          // id: undefined,
          ObjectType: "Type",
          RefType: "basf:purchase order number",
          IsGloballyBiUnique: false,
          ObjectId: undefined,
        },
        {
          // id: undefined,
          ObjectType: "Type",
          RefType: "product type",
          IsGloballyBiUnique: false,
          ObjectId: undefined,
        },
        {
          // id: undefined,
          ObjectType: "Individual",
          RefType: "instance of object uri (IEC 61406 ID)",
          IsGloballyBiUnique: true,
          ObjectId: undefined,
        },
        {
          // id: undefined,
          ObjectType: "Type",
          RefType: "ECLASS number",
          IsGloballyBiUnique: false,
          ObjectId: undefined,
        },
        {
          // id: undefined,
          ObjectType: "Individual",
          RefType: "basf:engineering tag number",
          IsGloballyBiUnique: false,
          ObjectId: undefined,
        },
        {
          // id: undefined,
          ObjectType: "Individual",
          RefType: "basf:equipment number",
          IsGloballyBiUnique: false,
          ObjectId: undefined,
        },
        {
          // id: undefined,
          ObjectType: "Individual",
          RefType: "basf:ReferencedDesignation",
          IsGloballyBiUnique: false,
          ObjectId: undefined,
        },
        {
          // id: undefined,
          ObjectType: "Individual",
          RefType: "serial number",
          IsGloballyBiUnique: false,
          ObjectId: undefined,
        },
        {
          // id: undefined,
          ObjectType: "Type",
          RefType: "vendor sales order confirmation number",
          IsGloballyBiUnique: false,
          ObjectId: undefined,
        },
      ],
      //  @ToDo according to current models implementation, not sure if this works
      // @ToDo add ForeignKeys, if neccessary
      ReferencedObject_model: [
        {
          type: "ReferenceDesignation",
          value: undefined,
        },
        {
          type: "EquipmentId",
          value: undefined,
        },
        {
          type: "ProjectId",
          value: undefined,
        },
      ],
      PartyType1_model: {
        OrganizationId: undefined,
        OrganizationName: undefined,
        OrganizationOfficialName: undefined,
        Role: "Manufacturer",
        id: undefined,
      },
      DescriptionType_model: [
        {
          Language: '',
          Description: '',
        },
      ],
    };
  },
  getters: {
    //   DescriptionType_model ---------------
    getDescriptionType(state) {
      return state.DescriptionType_model;
    },
    getDescriptionTypeDesc: (state) => (index) => {
      return state.DescriptionType_model[index].Description;
    },
    getDescriptionTypeLang: (state) => (index) => {
      return state.DescriptionType_model[index].Language;
    },
    // PartyType1_model ------------
    getPartyType1_model(state) {
      return state.PartyType1_model;
    },
    getOrganizationId1(state) {
      return state.PartyType1_model.OrganizationId;
    },
    getRole1(state) {
      return state.PartyType1_model.Role;
    },
    getOrganizationName1(state) {
      return state.PartyType1_model.OrganizationName;
    },
    getOrganizationOfficialName1(state) {
      return state.PartyType1_model.OrganizationOfficialName;
    },
    // ObjectIdType_model --------------
    getObjectType: (state) => (index) => {
      return state.ObjectIdType_model[index].ObjectType;
    },
    getRefType: (state) => (index) => {
      return state.ObjectIdType_model[index].RefType;
    },

    getIsGlobalBiUnique: (state) => (index) => {
      return state.ObjectIdType_model[index].IsGloballyBiUnique;
    },
    getObjectId: (state) => (index) => {
      return state.ObjectIdType_model[index].ObjectId;
    },
    getObjectIdType_model(state) {
      return state.ObjectIdType_model;
    },
    // ReferencedObject_model -------------
    getReferencedObject_model(state) {
      return state.ReferencedObject_model;
    },
    getRefObjModelValue: (state) => (index) => {
      return state.ReferencedObject_model[index].value;
    },
    getRefObjModelType: (state) => (index) => {
      return state.ReferencedObject_model[index].type;
    },
  },
  mutations: {
    //   DescriptionType_model ---------------
    addDescription(state) {
      state.DescriptionType_model.push({
        Language: '',
        Description: '',
      });
    },
    removeDescription(state, index) {
      state.DescriptionType_model.splice(index, 1);
    },
    setDescriptionTypeDesc(state, payload) {
      state.DescriptionType_model[payload.index].Description =
        payload.description;
    },
    setDescriptionTypeLang(state, payload) {
      state.DescriptionType_model[payload.index].Language = payload.language;
    },
    // PartyType1_model ------------
    setPartyType1(state, DataObject) {
      state.PartyType1_model = DataObject;
    },
    setOrganizationId1(state, OrgId) {
      state.PartyType1_model.OrganizationId = OrgId;
    },
    setOrganizationName1(state, payload) {
      state.PartyType1_model.OrganizationName = payload;
    },
    setOrganizationOfficialName1(state, payload) {
      state.PartyType1_model.OrganizationOfficialName = payload;
    },
    setRole1(state, payload) {
      state.PartyType1_model.Role = payload;
    },
    // ObjectIdType_model  ---------------
    setObjectType(state, payload) {
      state.ObjectIdType_model[payload.index].ObjectType = payload.value;
    },
    setRefType(state, payload) {
      state.ObjectIdType_model[payload.index].RefType = payload.value;
    },
    setIsGlobalBiUnique(state, payload) {
      state.ObjectIdType_model[payload.index].IsGloballyBiUnique =
        payload.value;
    },
    setObjectId(state, payload) {
      state.ObjectIdType_model[payload.index].ObjectId = payload.value;
    },
    addObjectIdType(state) {
      state.ObjectIdType_model.push({
        ObjectType: "Type",
        RefType: undefined,
        IsGloballyBiUnique: false,
        ObjectId: undefined,
      });
    },
    // setEmptyObjectIdType(state) {
    //   state.ObjectIdType_model = [
    //     {
    //       ObjectType: "Type",
    //       RefType: undefined,
    //       IsGloballyBiUnique: false,
    //       ObjectId: undefined,
    //     },
    //   ];
    // },
    setEmptyObjectIdType(state) {
      state.ObjectIdType_model = [
        {
          ObjectType: "Individual",
          RefType: "instance of object uri",
          IsGloballyBiUnique: true,
          ObjectId: undefined,
        },
        {
          ObjectType: "Individual",
          RefType: "serial number",
          IsGloballyBiUnique: false,
          ObjectId: undefined,
        },
        {
          ObjectType: "Type",
          RefType: "product type",
          IsGloballyBiUnique: false,
          ObjectId: undefined,
        },
      ];
    },
    removeObjectIdType(state, payload) {
      state.ObjectIdType_model.splice(payload, 1);
    },

    // ReferencedObject_model -------------
    setRefObjModelValue(state, payload) {
      state.ReferencedObject_model[payload.index].value = payload.value;
    },
    setRefObjModelType(state, payload) {
      state.ReferencedObject_model[payload.index].type = payload.type;
    },
    addReferencedObject(state) {
      state.ReferencedObject_model.push({
        type: undefined,
        value: undefined,
      });
    },
    removeReferencedObject(state, index) {
      state.ReferencedObject_model.splice(index, 1);
    },
  },
  actions: {
    //   DescriptionType_model ---------------
    addDescription(context) {
      context.commit("addDescription");
    },
    removeDescription(context, index) {
      context.commit("removeDescription", index);
    },
    setDescriptionTypeDesc(context, payload) {
      context.commit("setDescriptionTypeDesc", payload);
    },
    setDescriptionTypeLang(context, payload) {
      context.commit("setDescriptionTypeLang", payload);
    },
    // PartyType1_model ------------
    setPartyType1(context, payload) {
      context.commit("setPartyType1", payload);
    },
    setOrganizationId1(context, payload) {
      context.commit("setOrganizationId1", payload);
    },
    setOrganizationName1(context, payload) {
      context.commit("setOrganizationName1", payload);
    },
    setOrganizationOfficialName1(context, payload) {
      context.commit("setOrganizationOfficialName1", payload);
    },
    setRole1(context, payload) {
      context.commit("setRole1", payload);
    },
    // ObjectIdType_model  --------
    setEmptyObjectIdType(context){
      context.commit("setEmptyObjectIdType")
    },
    setObjectType(context, payload) {
      context.commit("setObjectType", payload);
    },
    setRefType(context, payload) {
      context.commit("setRefType", payload);
    },
    setIsGlobalBiUnique(context, payload) {
      context.commit("setIsGlobalBiUnique", payload);
    },
    setObjectId(context, payload) {
      context.commit("setObjectId", payload);
    },
    addObjectIdType(context) {
      context.commit("addObjectIdType");
    },
    removeObjectIdType(context, payload) {
      context.commit("removeObjectIdType", payload);
    },
    // ReferencedObject_model -------------
    setRefObjModelValue(context, payload) {
      context.commit("setRefObjModelValue", payload);
    },
    setRefObjModelType(context, payload) {
      context.commit("setRefObjModelType", payload);
    },
    addReferencedObject(context) {
      context.commit("addReferencedObject");
    },
    removeReferencedObject(context, payload) {
      context.commit("removeReferencedObject", payload);
    },
  },
};
