export default {
  namespaced: true,
  state() {
    return {
      PartyType4_model: {
        DocVersionId: undefined,
        Party4OrgData: [
          {
            OrganizationId: undefined,
            OrganizationName: undefined,
            OrganizationOfficialName: undefined,
            Role: "Author",
          },
        ],
        NumPages: undefined,
        Language: [undefined],
        id: undefined,
      },
      DocumentDescription_model: [
        {
          Language: undefined,
          Title: undefined,
          SubTitle: undefined,
          Summary: undefined,
          KeyWordsType_model: [undefined],
          id: undefined,
        },
      ],
      LifeCycleStatus_model: {
        SetDate: undefined,
        StatusValue: "Released",
      },
      PartyType7_model: {
        OrganizationId: undefined,
        OrganizationName: undefined,
        OrganizationOfficialName: undefined,
        Role: "Responsible",
        // id: undefined,
      },
      CommentsType_model: [
        {
          Language: undefined,
          Comments: undefined,
        },
      ],
      DescriptionType11_model: [
        {
          Language: undefined,
          Description: undefined,
          id: undefined,
        },
      ],
      DocumentRelationship_model: {
        Type: undefined,
        DocumentVersionId: undefined,
        id: undefined,
      },
      DocumentIdType10_model: {
        DocumentId: undefined,
        DomainId: undefined,
        id: undefined,
      },
    };
  },
  getters: {
    // DescriptionType11_model -----------
    getDescriptionType11_model(state) {
      return state.DescriptionType11_model;
    },
    getDescriptionType11Description: (state) => (index) => {
      return state.DescriptionType11_model[index].Description;
    },

    getDescriptionType11Language: (state) => (index) => {
      return state.DescriptionType11_model[index].Language;
    },

    // DocumentRelationship_model ---------
    getDocumentRelationship_model(state) {
      return state.DocumentRelationship_model;
    },
    getDocumentRelationship_modelDocumentVersionId(state) {
      return state.DocumentRelationship_model.DocumentVersionId;
    },
    getDocumentRelationship_modelType(state) {
      return state.DocumentRelationship_model.Type;
    },

    // DocumentIdType10_model -------------
    getDocumentIdType10_model(state) {
      return state.DocumentIdType10_model;
    },
    getDocumentIdType10_modelDocumentId(state) {
      return state.DocumentIdType10_model.DocumentId;
    },
    getDocumentIdType10_modelDomainId(state) {
      return state.DocumentIdType10_model.DomainId;
    },

    // CommentsType_model ----------------------
    getComments: (state) => (index) => {
      return state.CommentsType_model[index].Comments;
    },
    getCommentsLanguage: (state) => (index) => {
      return state.CommentsType_model[index].Language;
    },
    getCommentsType_model(state) {
      return state.CommentsType_model;
    },

    // PartyType7_model -----------------------
    getPartyType7_model(state) {
      return state.PartyType7_model;
    },
    getPartyType7OrganizationId(state) {
      return state.PartyType7_model.OrganizationId;
    },
    getPartyType7OrganizationName(state) {
      return state.PartyType7_model.OrganizationName;
    },
    getPartyType7OrganizationOfficialName(state) {
      return state.PartyType7_model.OrganizationOfficialName;
    },

    // LifeCycleStatus_model ---------------
    getLifeCyleStatus_model(state) {
      return state.LifeCycleStatus_model;
    },
    getLifeCycleSetDate(state) {
      return state.LifeCycleStatus_model.SetDate;
    },
    getLifeCycleStatusValue(state) {
      return state.LifeCycleStatus_model.StatusValue;
    },

    // DocumentDescription_model -----------
    getDocumentDescription_model(state) {
      return state.DocumentDescription_model;
    },
    getDocumentDescriptionLanguage: (state) => (index) => {
      return state.DocumentDescription_model[index].Language;
    },
    getDocumentDescriptionSummary: (state) => (index) => {
      return state.DocumentDescription_model[index].Summary;
    },
    getDocumentDescriptionTitle: (state) => (index) => {
      return state.DocumentDescription_model[index].Title;
    },
    getDocumentDescriptionSubTitle: (state) => (index) => {
      return state.DocumentDescription_model[index].SubTitle;
    },
    getKeyWordsType_model: (state) => (index) => {
      return state.DocumentDescription_model[index].KeyWordsType_model;
    },
    getKeyWords: (state) => (index, keyWindex) => {
      return state.DocumentDescription_model[index].KeyWordsType_model[
        keyWindex
      ];
    },
    
    // PartyType4_model ------------
    getDocVersionId4(state) {
      return state.PartyType4_model.DocVersionId;
    },
    getNumPages4(state) {
      return state.PartyType4_model.NumPages;
    },
    getPartyType4Language: (state) => (index) => {
      return state.PartyType4_model.Language[index];
    },
    getPartyType4_model(state) {
      return state.PartyType4_model;
    },
    getOrganizationId4: (state) => (index) => {
      return state.PartyType4_model.Party4OrgData[index].OrganizationId;
    },
    getRole4: (state) => (index) => {
      return state.PartyType4_model.Party4OrgData[index].Role;
    },
    getOrganizationName4: (state) => (index) => {
      return state.PartyType4_model.Party4OrgData[index].OrganizationName;
    },
    getOrganizationOfficialName4: (state) => (index) => {
      return state.PartyType4_model.Party4OrgData[index]
        .OrganizationOfficialName;
    },
    getPartyType4OrgDataArray(state) {
      return state.PartyType4_model.Party4OrgData;
    },
    getPartyType4LanguagesArray(state) {
      return state.PartyType4_model.Language;
    },
  },
  mutations: {
    // DescriptionType11_model -----------
    addDescriptionType11(state) {
      state.DescriptionType11_model.push({
        Language: undefined,
        Description: undefined,
      });
    },
    removeDescriptionType11(state, index) {
      state.DescriptionType11_model.splice(index, 1);
    },
    setDescriptionType11Description(state, payload) {
      state.DescriptionType11_model[payload.index].Description =
        payload.description;
    },

    setDescriptionType11Language(state, payload) {
      state.DescriptionType11_model[payload.index].Language = payload.language;
    },
    // DocumentRelationship_model ---------
    setDocumentRelationship_modelDocumentVersionId(state, versionId) {
      state.DocumentRelationship_model.DocumentVersionId = versionId;
    },
    setDocumentRelationship_modelType(state, type) {
      state.DocumentRelationship_model.Type = type;
    },

    // DocumentIdType10_model -------------
    setDocumentIdType10_modelDocumentId(state, docId) {
      state.DocumentIdType10_model.DocumentId = docId;
    },
    setDocumentIdType10_modelDomainId(state, domainId) {
      state.DocumentIdType10_model.DomainId = domainId;
    },

    // CommentsType_model ----------------------
    addComment(state) {
      state.CommentsType_model.push({
        Language: undefined,
        Comments: undefined,
      });
    },
    removeComment(state, index) {
      state.CommentsType_model.splice(index, 1);
    },
    setComments(state, payload) {
      state.CommentsType_model[payload.index].Comments = payload.comment;
    },
    setCommentLanguage(state, payload) {
      state.CommentsType_model[payload.index].Language = payload.language;
    },
    clearCommentsType_model(state){
      state.CommentsType_model =[
        {
          Language: undefined,
          Comments: undefined,
        },
      ];
    },

    // PartyType7_model -----------------------
    setPartyType7(state, DataObject) {
      state.PartyType7_model = DataObject;
    },
    setOrganizationId7(state, OrgId) {
      state.PartyType7_model.OrganizationId = OrgId;
    },
    setOrganizationName7(state, payload) {
      state.PartyType7_model.OrganizationName = payload;
    },
    setOrganizationOfficialName7(state, payload) {
      state.PartyType7_model.OrganizationOfficialName = payload;
    },

    // LifeCycleStatus_model ---------------
    setLifeCycleSetDate(state, date) {
      state.LifeCycleStatus_model.SetDate = date;
    },
    setLifeCycleStatusValue(state, status) {
      state.LifeCycleStatus_model.StatusValue = status;
    },

    // DocumentDescription_model -----------
    setDocumentDescription_model(state, payload) {
      state.DocumentDescription_model = payload
    },
    setDocumentDescriptionLanguage(state, payload) {
      state.DocumentDescription_model[payload.descIndex].Language =
        payload.Language;
    },
    setDocumentDescriptionSummary(state, payload) {
      state.DocumentDescription_model[payload.descIndex].Summary =
        payload.Summary;
    },
    setDocumentDescriptionTitle(state, payload) {
      state.DocumentDescription_model[payload.descIndex].Title = payload.Title;
    },
    setDocumentDescriptionSubTitle(state, payload) {
      state.DocumentDescription_model[payload.descIndex].SubTitle =
        payload.SubTitle;
    },
    setDocumentDescriptionKeyWords(state, payload) {
      state.DocumentDescription_model[payload.descIndex].KeyWordsType_model[
        payload.keyWindex
      ] = payload.KeyWord;
    },
    addDocumentDescription(state) {
      state.DocumentDescription_model.push({
        Language: undefined,
        Title: undefined,
        SubTitle: undefined,
        Summary: undefined,
        KeyWordsType_model: [undefined],
      });
    },
    removeDocumentDescription(state, index) {
      state.DocumentDescription_model.splice(index, 1);
    },
    addKeyWord(state, descIndex) {
      state.DocumentDescription_model[descIndex].KeyWordsType_model.push(
        undefined
      );
    },
    removeKeyWord(state, payload) {
      state.DocumentDescription_model[
        payload.descIndex
      ].KeyWordsType_model.splice(payload.keyWindex, 1);
    },
    clearDocumentDescription_model(state) {
      state.DocumentDescription_model = [
        {
          Language: undefined,
          Title: undefined,
          SubTitle: undefined,
          Summary: undefined,
          KeyWordsType_model: [undefined],
          id: undefined,
        },
      ];
    },
    // PartyType4_model ------------
    setPartyType4(state, DataObject) {
      state.PartyType4_model = DataObject;
    },
    setOrganizationId4(state, payload) {
      state.PartyType4_model.Party4OrgData[payload.index].OrganizationId =
        payload.value;
    },
    setOrganizationName4(state, payload) {
      state.PartyType4_model.Party4OrgData[payload.index].OrganizationName =
        payload.value;
    },
    setOrganizationOfficialName4(state, payload) {
      state.PartyType4_model.Party4OrgData[
        payload.index
      ].OrganizationOfficialName = payload.value;
    },
    setRole4(state, payload) {
      state.PartyType4_model.Party4OrgData[payload.index].Role = payload.value;
    },
    setDocVersionId4(state, id) {
      state.PartyType4_model.DocVersionId = id;
    },
    setNumPages4(state, number) {
      state.PartyType4_model.NumPages = number;
    },
    setPartyType4Language(state, payload) {
      state.PartyType4_model.Language[payload.index] = payload.language;
    },
    clearPartyType4Language(state) {
      state.PartyType4_model.Language = [undefined];
    },
    addPartyType4LanguageItem(state) {
      state.PartyType4_model.Language.push(undefined);
    },
    removeLanguage(state, index) {
      state.PartyType4_model.Language.splice(index, 1);
    },
    addPartyType4OrgDataItem(state) {
      state.PartyType4_model.Party4OrgData.push({
        OrganizationId: undefined,
        OrganizationName: undefined,
        OrganizationOfficialName: undefined,
        Role: "Responsible",
      });
    },
    removePartyType4OrgDataItem(state, index) {
      state.PartyType4_model.Party4OrgData.splice(index, 1);
    },
  },
  actions: {
    // DescriptionType11_model -----------
    addDescriptionType11(context) {
      context.commit("addDescriptionType11");
    },
    removeDescriptionType11(context, payload) {
      context.commit("removeDescriptionType11", payload);
    },
    setDescriptionType11Description(context, payload) {
      context.commit("setDescriptionType11Description", payload);
    },
    setDescriptionType11Language(context, payload) {
      context.commit("setDescriptionType11Language", payload);
    },
    // DocumentRelationship_model ---------
    setDocumentRelationship_modelDocumentVersionId(context, versionId) {
      context.commit(
        "setDocumentRelationship_modelDocumentVersionId",
        versionId
      );
    },
    setDocumentRelationship_modelType(context, type) {
      context.commit("setDocumentRelationship_modelType", type);
    },

    // DocumentIdType10_model -------------
    setDocumentIdType10_modelDocumentId(context, docId) {
      context.commit("setDocumentIdType10_modelDocumentId", docId);
    },
    setDocumentIdType10_modelDomainId(context, domainId) {
      context.commit("setDocumentIdType10_modelDomainId", domainId);
    },

    // CommentsType_model ----------------------
    addComment(context) {
      context.commit("addComment");
    },
    removeComment(context, index) {
      context.commit("removeComment", index);
    },
    setCommentLanguage(context, payload) {
      context.commit("setCommentLanguage", payload);
    },
    setComments(context, payload) {
      context.commit("setComments", payload);
    },
    clearCommentsType_model(context){
      context.commit("clearCommentsType_model")
    },

    // PartyType7_model -----------------------
    setPartyType7(context, payload) {
      context.commit("setPartyType7", payload);
    },
    setOrganizationId7(context, payload) {
      context.commit("setOrganizationId7", payload);
    },
    setOrganizationName7(context, payload) {
      context.commit("setOrganizationName7", payload);
    },
    setOrganizationOfficialName7(context, payload) {
      context.commit("setOrganizationOfficialName7", payload);
    },

    // LifeCycleStatus_model ---------------
    setLifeCycleSetDate(context, date) {
      context.commit("setLifeCycleSetDate", date);
    },
    setLifeCycleStatusValue(context, status) {
      context.commit("setLifeCycleStatusValue", status);
    },

    // DocumentDescription_model -----------
    setDocumentDescription_model(context, payload){
      context.commit("setDocumentDescription_model", payload)
    },
    setDocumentDescriptionLanguage(context, payload) {
      context.commit("setDocumentDescriptionLanguage", payload);
    },
    setDocumentDescriptionSummary(context, payload) {
      context.commit("setDocumentDescriptionSummary", payload);
    },
    setDocumentDescriptionTitle(context, payload) {
      context.commit("setDocumentDescriptionTitle", payload);
    },
    setDocumentDescriptionSubTitle(context, payload) {
      context.commit("setDocumentDescriptionSubTitle", payload);
    },
    setDocumentDescriptionKeyWords(context, payload) {
      context.commit("setDocumentDescriptionKeyWords", payload);
    },
    addDocumentDescription(context) {
      context.commit("addDocumentDescription");
    },
    removeDocumentDescription(context, index) {
      context.commit("removeDocumentDescription", index);
    },
    addKeyWord(context, payload) {
      context.commit("addKeyWord", payload);
    },
    removeKeyWord(context, payload) {
      context.commit("removeKeyWord", payload);
    },
    clearDocumentDescription_model(context){
      context.commit("clearDocumentDescription_model")
    },
    // PartyType4_model ------------
    setPartyType4(context, payload) {
      context.commit("setPartyType4", payload);
    },
    setOrganizationId4(context, payload) {
      context.commit("setOrganizationId4", payload);
    },
    setOrganizationName4(context, payload) {
      context.commit("setOrganizationName4", payload);
    },
    setOrganizationOfficialName4(context, payload) {
      context.commit("setOrganizationOfficialName4", payload);
    },
    setRole4(context, payload) {
      context.commit("setRole4", payload);
    },
    setDocVersionId4(context, id) {
      context.commit("setDocVersionId4", id);
    },
    setNumPages4(context, number) {
      context.commit("setNumPages4", number);
    },
    setPartyType4Language(context, payload) {
      context.commit("setPartyType4Language", payload);
    },
    clearPartyType4Language(context) {
      context.commit("clearPartyType4Language");
    },
    addPartyType4LanguageItem(context) {
      context.commit("addPartyType4LanguageItem");
    },
    removeLanguage(context, index) {
      context.commit("removeLanguage", index);
    },
    addPartyType4OrgDataItem(context) {
      context.commit("addPartyType4OrgDataItem");
    },
    removePartyType4OrgDataItem(context, index) {
      context.commit("removePartyType4OrgDataItem", index);
    },
  },
};
