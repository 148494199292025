<template>
  <div id="file-selection ">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header" v-if="!getFileSelected">
            <h4>
              {{
                $route.path == "/vdi2770viewer/files"
                  ? $t("headerFileViewer")
                  : $t("headerFileSelect")
              }}
            </h4>
          </div>
          <div class="card-header" v-else>
            <h4>{{ $t("headerSelected") }}</h4>
          </div>

          <div class="card-body">
            <div class="card-text">
              <div v-for="(file, ind) in getFileArray" :key="ind">
                <div v-if="getPdfFile(ind) !== ''">
                  <div class="row border rounded file-choose bg-light">
                    <span
                      :class="
                        file.finished === 'FILE_SELECTED'
                          ? 'border border-success rounded'
                          : file.finished === 'FINISHED'
                          ? 'border border-success rounded bg-ligthgreen'
                          : 'border border-primary rounded'
                      "
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="file"
                          @change="setFile(ind)"
                          :disabled="
                            file.finished === 'FILE_SELECTED'
                              ? true
                              : file.finished === 'FINISHED'
                              ? true
                              : getFileSelected
                          "
                          :checked="
                            file.finished === 'FILE_SELECTED'
                              ? true
                              : file.finished === 'FINISHED'
                              ? true
                              : false
                          "
                        />

                        <label
                          class="form-check-label text-muted"
                          v-if="file.pdfFile"
                        >
                          <h6 class="card-title">
                            <a
                              :href="createUrl(ind)"
                              class="link-primary"
                              target="_blank"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Click to show PDF in new tab"
                              >{{ file.pdfFile.name }}
                            </a>
                          </h6>
                          <div
                            v-for="(file, index) in file.nativeFiles"
                            :key="index"
                            class="table table-sm table-very-small"
                          >
                            <tr class="table-very-small">
                              <td>{{ file.name }}</td>
                            </tr>
                          </div>
                        </label>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <!--  -->
              <div v-if="getIsPackageUnit">
                <div class="card-header">
                  <h4>{{ $t("VDI2770Container") }}</h4>
                  <!-- ---new search  -->
                  <div v-if="$route.path == '/vdi2770viewer/files'">
                    <div class="input-group mb-3">
                      <input
                        class="form-control border rounded"
                        id="filter-input"
                        v-model="filterCriteria"
                        placeholder="Filter..."
                        @keyup="getFilterFeedback"
                      />
                      <div class="row">
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <i class="bi bi-funnel-fill"></i>
                            <input
                              class="form-check-input form-check-inline ms-3"
                              type="checkbox"
                              v-model="applyFilterInZip"
                              id="applyFilterInZip"
                              @change="getFilterFeedback"
                            />
                            <label
                              class="form-check-label"
                              for="applyFilterInZip"
                            >
                              {{ $t("include1ZipLevel") }}
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="getFilterCriteria() != ''">
                      <div class="row border rounded file-choose bg-light">
                        <span
                          class="border border-success rounded bg-ligthgreen"
                        >
                          <div class="form-check">
                            <label class="form-check-label text-muted">
                              <!--  -->
                              <h6 class="card-title">
                                <a
                                  href="#"
                                  class="link-primary"
                                  data-toggle="modal"
                                  data-target="#staticBackdrop"
                                  data-title="Filter Result"
                                  data-body="headerZipInfoBody"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Click to show all filter results in Metadata Viewer"
                                  @click.prevent="showAllFiltered()"
                                  >{{ $t("filterResult") }}</a
                                >
                              </h6>
                            </label>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- ---end search -->
                </div>
                <div v-for="(file, ind) in getZipArray" :key="ind">
                  <div
                    class="row border rounded file-choose bg-light"
                    v-if="getPromiseValue(ind) == true && file.zipFile.name"
                  >
                    <span class="border border-success rounded bg-ligthgreen">
                      <div class="form-check">
                        <label class="form-check-label text-muted">
                          <!--  -->
                          <h6 class="card-title">
                            <a
                              href="#"
                              class="link-primary"
                              data-toggle="modal"
                              data-target="#staticBackdrop"
                              :data-title="file.zipFile.name"
                              data-body="headerZipInfoBody"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Click to show Container in Metadata Viewer"
                              @click.prevent="showmodal('', file.zipFile.name)"
                              >{{ file.zipFile.name }}
                            </a>
                          </h6>
                        </label>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="row mt-3"
                v-if="$route.path == '/metadata/files/fileselection'"
              >
                <div class="col-sm-8 align-left mt-2">
                  <go-back-util />
                </div>
                <div class="col-sm-4 align-right mt-2">
                  <button
                    class="btn btn-success bi-caret-right"
                    id="btnSelectFile"
                    @click.prevent="pushNextStep"
                  >
                    {{ $t("next Step") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-lg-3 mt-2"
        v-if="$route.path == '/metadata/files/fileselection'"
      >
        <h5>{{ $t("headerFileSelectDescription") }}</h5>
        {{ $t("textFileSelectDescription") }}
      </div>
    </div>
<!-- @FreeVersion New -->
    <zip-viewer-comp v-bind:fr="fr"/>
    <!-- <zip-viewer-comp /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoBackUtil from "../utils/GoBackUtil.vue";
import i18n from "@/i18n";
import ZipViewerComp from "./ZipViewerComp.vue";
import processZip from "../utils/processZip";
import JSZip from "jszip";
import { v4 as uuidv4 } from "uuid";
import TreeViewNavigation from "../utils/TreeViewNavigator";

export default {
  name: "FileSelectionCom",
  data() {
    return {
      filterCriteria: "",
      applyFilterInZip: false,
      filteredResultForHTML: undefined,
      promiseArray: [],
      fr: new FileReader(),
    };
  },
  components: {
    ZipViewerComp,
  },

  methods: {
    ...mapActions("Files", ["setFileStatus"]),
    ...mapActions("DocVer", [
      "setNumPages4",
      "setDocumentDescriptionSummary",
      "setDocumentDescriptionTitle",
      "setDocumentDescriptionKeyWords",
    ]),
    ...mapActions(["setFileSelectionState"]),
    ...mapActions("ActSteps", ["setOrgData", "setOrgMetaData"]),
    ...mapActions("ViewerStore", ["addViewerFileObj", "deleteViewerFiles"]),

    createFileFromBlob(file) {
      let newFile = File;
      newFile = new File([file], "Result.zip", {
        lastModified: new Date(),
        type: "application/zip",
      });
      this.filteredResultForHTML = {
        zipFile: newFile,
        hasMainXML: undefined,
        xmlToJson: undefined,
        adjustable: undefined,
      };
      return true;
    },

    getFilterCriteria() {
      return this.filterCriteria;
    },
    async showAllFiltered() {
      await this.createFilterResultZip();
      this.showmodal("", "Filter Result", this.filteredResultForHTML);
    },

    async createFilterResultZip() {
      const zip = new JSZip();
      for (let i = 0; i < this.getZipArray.length; i++) {
        if (this.getPromiseValue(i)) {
          zip.file(
            this.getZipArray[i].zipFile.name,
            this.getZipArray[i].zipFile
          );
        }
      }
      var content = await zip.generateAsync({
        type: "blob",
      });
      let fileContent = this.createFileFromBlob(content);
      return fileContent;
    },

    getPromiseValue(index) {
      if (
        this.promiseArray.length < this.getZipArray.length &&
        this.getFilterCriteria() == ""
      ) {
        this.getFilterFeedback();
      }
      var criteriaFound = false;
      for (let i = 0; i < this.promiseArray.length; i++) {
        if (
          this.promiseArray[i][0] == index &&
          this.promiseArray[i][1] == true
        ) {
          criteriaFound = true;
        }
      }
      return criteriaFound;
    },

    async getFilterFeedback() {
      this.promiseArray = [];
      if (this.getFilterCriteria() != "") {
        for (let index = 0; index < this.getZipArray.length; index++) {
          if (
            this.getZipArray[index].zipFile.name != undefined &&
            String(this.getZipArray[index].zipFile.name)
              .toLowerCase()
              .includes(String(this.getFilterCriteria()).toLowerCase())
          ) {
            this.promiseArray.push([index, true]);
          } else if (this.applyFilterInZip) {
            if (this.getZipArray[index].zipFile) {
              var zipRepresentation = await processZip.loadZipContainer(
                this.getZipArray[index].zipFile
              );
              zipRepresentation.forEach(async (relativePath, file) => {
                var found = false;
                // @FreeVersion
                const returnString = await processZip.readXmlFile(
                  zipRepresentation
                );
                // @FreeVersion
                if (
                  String(file.name)
                    .toLowerCase()
                    .includes(String(this.getFilterCriteria()).toLowerCase()) ||
                  returnString
                    .toLowerCase()
                    .includes(String(this.getFilterCriteria()).toLowerCase())
                ) {
                  found = true;
                }

                if (found) {
                  this.promiseArray.push([index, true]);
                }
              });
            } else {
              this.promiseArray.push([index, false]);
            }
          }
        }
      } else {
        for (let index = 0; index < this.getZipArray.length; index++) {
          this.promiseArray.push([index, true]);
        }
      }
    },

    async showmodal(index = undefined, fileName, zipFile = undefined) {
      this.deleteViewerFiles();
      let titleContent = document.getElementById("modalInjectedTitle");
      titleContent.innerHTML = " VDI2770 Metadata Viewer for: " + fileName;
      let zipContent = document.getElementById("zipContent");
      zipContent.innerHTML = "";
      await this.createZipMenuContent(fileName, zipFile);
    },

    async createZipMenuContent(zipFileName, zipFile) {
      let htmlString =
        '<nav aria-label="ContainerTree"><ul class="treeview-navigation" role="tree" aria-label="ContainerTree" id="' +
        zipFileName +
        '"> </ul></nav>';
      let zipContent = document.getElementById("zipContent");
      zipContent.innerHTML += htmlString;
      if (zipFile == undefined) {
        await this.createLiExpandableItem(
          this.getZipFileByName(zipFileName),
          zipFileName
        );
      } else {
        await this.createLiExpandableItem(zipFile, zipFileName);
      }
      return htmlString;
    },

    async createLiExpandableItem(
      zipFile,
      zipFileName,
      loadContainer = true,
      id = ""
    ) {
      const splittedFileName = zipFileName.split(".");
      let liHtmlString =
        '<li role="none" id="Expand' +
        zipFileName +
        '"> <a class="expandable" role="treeitem" aria-expanded="false" aria-owns="id-' +
        splittedFileName[0] +
        '-subtree" href="#' +
        zipFileName +
        '"> <span class="label"> <span class="icon"> <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10"> <polygon points="2 1, 12 1, 7 9"></polygon></svg></span>' +
        zipFileName +
        " </span> </a> </li>";
      let zipContent = document.getElementById("id-" + id + "-subtree");
      if (zipContent == null) {
        zipContent = document.getElementById(zipFileName);
      }
      zipContent.innerHTML += liHtmlString;
      let loopArray = await this.createUlItem(
        zipFile,
        splittedFileName[0],
        zipFileName,
        loadContainer
      );
      for (let i = 0; i < loopArray[1].length; i++) {
        await this.createLiExpandableItem(
          loopArray[1][i][0],
          loopArray[1][i][1],
          loopArray[1][i][2],
          splittedFileName[0]
        )[0];
      }
      let trees = document
        .getElementById("staticBackdrop")
        .querySelectorAll('nav [role="tree"]');
      for (let i = 0; i < trees.length; i++) {
        new TreeViewNavigation(trees[i]);
      }
    },
    createLiItem(fileName, zipFileName, id) {
      let liHtmlString =
        '<li role="nonexpand"> <a role="treeitem"  href="#' +
        fileName +
        '" id="' +
        id +
        '"> <span class="label">' +
        fileName +
        " </span> </a> </li>";
      let zipContent = document.getElementById(
        "id-" + zipFileName + "-subtree"
      );
      zipContent.innerHTML += liHtmlString;
      return liHtmlString;
    },
    async createUlItem(zipFile, splittedFileName, zipFileName, loadContainer) {
      let test = [];
      let ulHtmlString =
        '<ul id="id-' +
        splittedFileName +
        '-subtree" role="group" aria-label="' +
        zipFileName +
        '" >' +
        " </ul>";

      let zipContent = document.getElementById("Expand" + zipFileName);
      zipContent.innerHTML += ulHtmlString;
      let zipRepresentation = zipFile;
      if (loadContainer) {
        zipRepresentation = await processZip.loadZipContainer(zipFile.zipFile);
      }

      for (let includeFile in zipRepresentation.files) {
        if (includeFile.endsWith(".zip")) {
          await zipRepresentation
            .file(includeFile)
            .async("blob")
            .then(JSZip.loadAsync)
            .then(async (innerZip) => {
              test.push([await innerZip, includeFile, false]);
            });
        } else {
          let id = uuidv4();
          ulHtmlString =
            ulHtmlString + this.createLiItem(includeFile, splittedFileName, id);
          try {
            await this.saveFileForViewer(zipRepresentation, includeFile, id);
          } catch (error) {
            console.log(error);
          }
        }
      }
      return [ulHtmlString, test];
    },

    async saveFileForViewer(zipRepresentation, includeFile, id) {
      try {
        await zipRepresentation
          .file(includeFile)
          .async("blob")
          .then(async (innerZip) => {
            let mimeType = "";
            if (includeFile.toLowerCase().endsWith(".pdf")) {
              mimeType = "application/pdf";
              let file = new File([innerZip], includeFile, { type: mimeType });
              processZip.savePdfType(file, id, "ViewerStore/addViewerFileObj");
            } else if (includeFile.endsWith(".xml")) {
              mimeType = "application/xml";
              let file = new File([innerZip], includeFile, { type: mimeType });
              file
                .text()
                .then((text) => {
                  processZip.saveXmlType(processZip.convertToObject(text), id);
                })
                .catch((error) => alert(error));
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    getZipFileByName(zipName) {
      let fileToExplore = this.getZipArray.find(
        (item) => item.zipFile.name == zipName
      );
      return fileToExplore;
    },

    checkSelected(file) {
      return file.finished === "FILE_SELECTED";
    },
    createUrl(index = 0) {
      if (this.getPdfFile(index)) {
        return URL.createObjectURL(this.getPdfFile(index));
      } else return "#";
    },
    pushNextStep() {
      if (this.getFileArray.find(this.checkSelected)) {
        this.$store.dispatch("ActSteps/setOrgData", true);
        this.$store.dispatch("ActSteps/setOrgMetaData", true);
        this.$store.dispatch("setFileSelectionState", true);
        if (this.getNextRound) {
          this.$store.dispatch("ActSteps/setOrgDocDomain", true);
          this.$router.push("/metadata/defaultsettings/documentId/");
        } else {
          this.$router.push("/metadata/defaultsettings/documentIdDomain/");
        }
      } else alert(i18n.global.t("faultNoFileSelected"));
    },
    async setFile(ind) {
      this.getFileArray.forEach((file, index) => {
        if (file.finished === "FILE_SELECTED") {
          this.$store.dispatch("Files/setFileStatus", {
            index: index,
            status: "FILE_NOT_SELECTED",
          });
        }
      });
      let payload = { index: ind, status: "FILE_SELECTED" };
      try {
        const pdfJS = await import("pdfjs-dist/build/pdf");
        pdfJS.GlobalWorkerOptions.workerSrc =
          window.location.origin + "/pdf.worker.min.js";
        this.fr.onload = async () => {
          let typedarray = new Uint8Array(this.fr.result);
          const task = await pdfJS.getDocument(typedarray);
          task.promise.then((pdf) => {
            this.$store.dispatch("DocVer/setNumPages4", pdf.numPages);
            pdf.getMetadata().then((stuff) => {
              this.$store.dispatch("DocVer/setDocumentDescriptionTitle", {
                descIndex: 0,
                Title: stuff.info.Title,
              });
              this.$store.dispatch("DocVer/setDocumentDescriptionKeyWords", {
                descIndex: 0,
                keyWindex: 0,
                KeyWord: stuff.info.Keywords,
              });
              this.$store.dispatch("DocVer/setDocumentDescriptionSummary", {
                descIndex: 0,
                Summary: stuff.info.Subject,
              });
            });
          });
        };
        this.fr.readAsArrayBuffer(this.getPdfFile(ind));
      } catch (error) {
        alert(error);
      }
      this.$store.dispatch("Files/setFileStatus", payload);
    },
  },

  computed: {
    ...mapGetters([
      "getFileSelected",
      "getNextRound",
      "getFilesConfirmed",
      "getIsPackageUnit",
      "getIsGeneralDocu",
    ]),
    ...mapGetters("Files", [
      "getFileArray",
      "getZipArray",
      "getZipFile",
      "getPdfFile",
    ]),
    ...mapGetters("DocVer", ["getDocumentDescription_model"]),
    ...mapGetters("ViewerStore", [
      "getFileByUniqueID",
      "getXmlBlobByUniqueID",
      "getViewerFileObjByUniqueID",
      "getViewerFileObjsByType",
      "getViewerFilesArray",
    ]),
  },
  components: {
    GoBackUtil,
    ZipViewerComp,
  },
};
</script>

<style scoped>
.file-choose {
  padding: 0.5rem;
  margin: 4px;
}
.border-primary {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  padding-right: 0.5rem;
}

.table-very-small {
  font-size: 0.7rem;
  display: inline-block;
  line-height: 0.9rem;
  margin: 2px;
  padding-left: 1rem;
  border-collapse: collapse;
}
td {
  /* white-space: normal !important;  */
  word-wrap: break-word;
}
.border-success {
  border-width: small !important;
}
.form-check {
  overflow: hidden;
}
.bg-ligthgreen {
  background-color: rgb(232, 247, 242);
}
</style>
