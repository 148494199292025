<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>{{$t("headerDocVersion")}}:</h4>
      </div>
      <div class="card-body">
        <form action="" id="OrganizationTemplates">
          <div class="row mb-3">
            <label for="DocVersionId" class="col-sm-3 col-form-label"
              >{{$t("docVersionDocVerIdInfoTitle")}}:*
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="docVersionDocVerIdInfoTitle"
                  data-body="docVersionDocVerIdInfoBody"
                  @click.prevent="showmodal"
                ></i></a
            ></label>
            <div class="col-sm-2">
              <input
                required
                type="number"
                min="0"
                class="form-control"
                id="DocVersionId"
                :value="getDocVersionId4"
                @input="setDocVersionId4($event.target.value)"
                @focusout="singleFault($event.target)"
                @keydown.enter.prevent
              />
            </div>

            <label for="NumberOfPages" class="col-sm-3 col-form-label"
              >{{$t("docVersionNumPagesInfoTitle")}}:*
              <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="docVersionNumPagesInfoTitle"
                  data-body="docVersionNumPagesInfoBody"
                  @click.prevent="showmodal"
                ></i></a
            ></label>
            <div class="col-sm-2">
              <input
                required
                type="number"
                min="1"
                class="form-control"
                id="NumberOfPages"
                :value="getNumPages4"
                @input="setNumPages4($event.target.value)"
                @focusout="singleFault($event.target)"
                @keydown.enter.prevent
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12 card border rounded border-dark">
              <div
                class="row mt-2"
                v-for="(item, index) in getPartyType4LanguagesArray"
                :key="index"
              >
                <label
                  class="col-sm-3 col-form-label text-center"
                  for="DocVersionLang"
                >
                  {{$t("docVersionLangInfoTitle")}}:*
                  <a href="#"
                ><i
                  class="bi bi-info-circle"
                  data-toggle="modal"
                  data-target="#infoModal"
                  data-title="docVersionLangInfoTitle"
                  data-body="docVersionLangInfoBody"
                  @click.prevent="showmodal"
                ></i></a
            >
                </label>

                <div class="col-sm-4 mb-4">
                  <select
                    required
                    class="form-control"
                    id="DocVersionLang"
                    :value="getPartyType4Language(index)"
                    @input="setPT4Language(index, $event)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  >
                    <option
                      v-for="item in getLanguage()"
                      :key="item[0]"
                      :value="item[0]"
                    >
                      {{ item[0] }} - {{ item[1] }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4 mb-1" v-if="index > 0">
                  <button
                    class="btn btn-outline-danger"
                    style="width: 3rem"
                    @click.prevent="removeLang(index)"
                  >
                    <i class="bi-x-circle" style="font-size: 1rem"></i>
                  </button>
                </div>
                <div class="col-sm-4 mb-1" v-if="index === 0">
                  <button
                    class="btn btn-outline-primary"
                    style="width: 3rem"
                    @click.prevent="addPartyType4LangIt()"
                  >
                    <i class="bi-plus-circle" style="font-size: 1rem"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="card border rounded mt-2 border-dark">
            <div class="card-header">
              <h4>
                {{$t("docVersionPartyHeadInfoTitle")}}
                <a href="#"
                  ><i
                    class="bi bi-info-circle"
                    data-toggle="modal"
                    data-target="#infoModal"
                    data-title="docVersionPartyHeadInfoTitle"
                    data-body="docVersionPartyHeadInfoBody"
                    @click.prevent="showmodal"
                  ></i
                ></a>
              </h4>
            </div>
            <div
              class="card-body"
              v-for="(item, index) in getPartyType4OrgDataArray"
              :key="index"
            >
              <div class="row mb-3">
                <label for="DocVersionParty" class="col-sm-3 col-form-label"
                  >{{$t("docVersionOrgIdInfoTitle")}}:*
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="docVersionOrgIdInfoTitle"
                      data-body="docVersionOrgIdInfoBody"
                      @click.prevent="showmodal"
                    ></i></a
                ></label>
                <div class="col-sm-5">
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="DocVersionParty"
                    :value="getOrganizationId4(index)"
                    @input="setOrgId4(index, $event)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  />
                </div>
                <label class="col-sm-1 col-form-label" for="roleDocVersion">
                  {{$t("docVersionRoleInfoTitle")}}:*
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="docVersionRoleInfoTitle"
                      data-body="docVersionRoleInfoBody"
                      @click.prevent="showmodal"
                    ></i
                  ></a>
                </label>
                <div class="col-sm-3">
                  <select
                    name="roleDocVersion"
                    id="roleDocVersion"
                    class="form-select"
                    :value="getRole4(index)"
                    :disabled="index == 0"
                    @input="setRole4Local(index, $event)"
                    @keydown.enter.prevent
                  >
                    <option value="Responsible">Responsible</option>
                    <option value="Author">Author</option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <label
                  for="OrganizationDocVersion"
                  class="col-sm-3 col-form-label"
                  >{{$t("docVersionOrgNameInfoTitle")}}:*
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="docVersionOrgNameInfoTitle"
                      data-body="docVersionOrgNameInfoBody"
                      @click.prevent="showmodal"
                    ></i></a
                ></label>
                <div class="col-sm-9">
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="OrganizationDocVersion"
                    :value="getOrganizationName4(index)"
                    @input="setOrgName4(index, $event)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  />
                </div>
              </div>
              <div class="row mb-3">
                <label
                  for="OfficialNameDocVersion"
                  class="col-sm-3 col-form-label"
                  >{{$t("docVersionOrgOffNameInfoTitle")}}:*
                  <a href="#"
                    ><i
                      class="bi bi-info-circle"
                      data-toggle="modal"
                      data-target="#infoModal"
                      data-title="docVersionOrgOffNameInfoTitle"
                      data-body="docVersionOrgOffNameInfoBody"
                      @click.prevent="showmodal"
                    ></i></a
                ></label>
                <div class="col-sm-9">
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="OfficialNameDocVersion"
                    :value="getOrganizationOfficialName4(index)"
                    @input="setOrgNameOff4(index, $event)"
                    @focusout="singleFault($event.target)"
                    @keydown.enter.prevent
                  />
                </div>
              </div>

              <div class="col-sm-4 mb-1" v-if="index > 0">
                <button
                  class="btn btn-outline-danger"
                  style="width: 3rem"
                  @click.prevent="removePartyType4OrgDataItem(index)"
                >
                  <i class="bi-x-circle" style="font-size: 1rem"></i>
                </button>
              </div>
              <div
                class="col-sm-4 mb-1"
                v-else-if="getPartyType4OrgDataArray.length < 2"
              >
                <button
                  class="btn btn-outline-primary"
                  style="width: 3rem"
                  @click.prevent="addPartyType4OrgDataItem()"
                >
                  <i class="bi-plus-circle" style="font-size: 1rem"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-9 align-left mt-2">
              <go-back-util />
            </div>
            <div class="col-sm-3 align-right mt-2">
              <button
                class="btn btn-success bi-caret-right"
                @click.prevent="pusToDocDescription"
              >
                {{$t("next Step")}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <modal-utils />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoBackUtil from "../utils/GoBackUtil.vue";
import Fault from "../utils/Fault";
import LangCodes from "../utils/LangCodes";
import i18n from "@/i18n";
import ModalUtils from "../utils/ModalsUtils.vue";

export default {
  name: "DocVersionPartyCom",
  components: {
    GoBackUtil,
    ModalUtils,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("OrgData", ["getPartyType_model"]),
    ...mapGetters("DocVer", [
      "getPartyType4_model",
      "getOrganizationId4",
      "getRole4",
      "getOrganizationName4",
      "getOrganizationOfficialName4",
      "getDocVersionId4",
      "getNumPages4",
      "getPartyType4Language",
      "getPartyType4LanguagesArray",
      "getPartyType4OrgDataArray",
    ]),
    ...mapGetters("RefObj", ["getDescriptionType"]),
    ...mapGetters(["getIsGeneralDocu"])
  },
  methods: {
    ...mapActions("DocVer", [
      "setOrganizationId4",
      "setOrganizationName4",
      "setOrganizationOfficialName4",
      "setRole4",
      "setPartyType4Language",
      "setNumPages4",
      "setDocVersionId4",
      "addPartyType4LanguageItem",
      "removeLanguage",
      "addPartyType4OrgDataItem",
      "removePartyType4OrgDataItem",
      "addDocumentDescription",
      "removeDocumentDescription",
      "setDocumentDescriptionLanguage",
    ]),
    ...mapActions("ActSteps", ["setDocVersionDocDesc"]),

    showmodal() {
      $("#infoModal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var title = button.data("title");
        var body = button.data("body");
        var modal = $(this);
        modal.find(".modal-title").text(i18n.global.t(title));
        modal.find(".modal-body").text(i18n.global.t(body));
      });
    },

    singleFault(ele) {
      return Fault.singleFault(ele);
    },
    getLanguage() {
      return LangCodes.getLang();
    },
    addPartyType4LangIt() {
      this.$store.dispatch("DocVer/addPartyType4LanguageItem");
      this.$store.dispatch("DocVer/addDocumentDescription");
    },
    removeLang(index) {
      this.$store.dispatch("DocVer/removeLanguage", index);
      this.$store.dispatch("DocVer/removeDocumentDescription", index);
    },
    setOrgId4(index, event) {
      let payload = {
        index: index,
        value: event.target.value,
      };
      this.$store.dispatch("DocVer/setOrganizationId4", payload);
    },
    setRole4Local(index, event) {
      let payload = {
        index: index,
        value: event.target.value,
      };
      this.$store.dispatch("DocVer/setRole4", payload);
    },
    setOrgName4(index, event) {
      let payload = {
        index: index,
        value: event.target.value,
      };
      this.$store.dispatch("DocVer/setOrganizationName4", payload);
    },
    setOrgNameOff4(index, event) {
      let payload = {
        index: index,
        value: event.target.value,
      };
      this.$store.dispatch("DocVer/setOrganizationOfficialName4", payload);
    },
    setPT4Language(index, event) {
      let payload = {
        index: index,
        language: event.target.value,
      };
      let payload2 = {
        descIndex: index,
        Language: event.target.value,
      };
      this.$store.dispatch("DocVer/setPartyType4Language", payload);
      this.$store.dispatch("DocVer/setDocumentDescriptionLanguage", payload2);
    },
    pusToDocDescription() {
      if (Fault.faultControl(document)) {
        this.$store.dispatch("ActSteps/setDocVersionDocDesc", true);
        this.$router.push("/metadata/docversion/documentdescription/");
      } else alert(i18n.global.t("faultMandatory"));
    },
  },

  created() {
    let defaultParty = this.getPartyType_model;
    let payloadOrgId = {
      index: 0,
      value: defaultParty.OrganizationId,
    };
    this.setOrganizationId4(payloadOrgId);
    let payloadOrgName = {
      index: 0,
      value: defaultParty.OrganizationName,
    };
    this.setOrganizationName4(payloadOrgName);
    let payloadOrgOffName = {
      index: 0,
      value: defaultParty.OrganizationOfficialName,
    };
    this.setOrganizationOfficialName4(payloadOrgOffName);
  },

};
</script>
