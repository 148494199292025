export default {
  namespaced: true,
  state() {
    return {
      fileArray: [
        {
          pdfFile: "",
          nativeFiles: [],
          finished: "FILE_NOT_SELECTED",
          xmlBlob: undefined,
        },
      ],
      docuArray: [
        {
          zipFile: "",
          hasMainXML: undefined,
          xmlToJson: undefined,
          adjustable: undefined,
        },
      ],
    };
  },

  getters: {
    getZipFile: (state) => (index) => {
      return state.docuArray[index].zipFile;
    },
    getAdjustable: (state) => (index) => {
      return state.docuArray[index].adjustable;
    },
    getHasMainXML: (state) => (index) => {
      return state.docuArray[index].hasMainXML;
    },
    getXmlToJson: (state) => (index) => {
      return state.docuArray[index].xmlToJson;
    },
    getPdfFile: (state) => (index) => {
      return state.fileArray[index].pdfFile;
    },
    getPdfFileStatus: (state) => (index) => {
      return state.fileArray[index].finished;
    },
    getNativeFiles: (state) => (index) => {
      return state.fileArray[index].nativeFiles;
    },
    getXmlBlob: (state) => (index) => {
      return state.fileArray[index].xmlBlob;
    },
    getFileArray(state) {
      return state.fileArray;
    },
    getZipArray(state) {
      return state.docuArray;
    },
  },
  mutations: {
    setFileArray(state, payload){
      state.fileArray = payload
    },
    setFiles(state, payload) {
      state.fileArray[payload.index] = {
        pdfFile: payload.pdfFile,
        nativeFiles: payload.arrNativeFile,
        finished: "FILE_NOT_SELECTED",
        xmlBlob: undefined,
      };
    },
    addFilePlaceholder(state) {
      state.fileArray.push({
        pdfFile: "",
        nativeFiles: [],
        finished: "FILE_NOT_SELECTED",
        xmlBlob: undefined,
      });
    },
    addZipPlaceholder(state) {
      state.docuArray.push({
        zipFile: "",
        hasMainXML: undefined,
        xmlToJson: undefined,
        adjustable: undefined,
      });
    },
    setZipToEmpty(state, index) {
      (state.docuArray[index].zipFile = ""),
        (state.docuArray[index].hasMainXML = undefined),
        (state.docuArray[index].xmlToJson = undefined),
        (state.docuArray[index].adjustable = undefined);
    },
    setZipFile(state, payload) {
      state.docuArray[payload.ind].zipFile = payload.fileObj;
    },
    setHasMainXML(state, payload) {
      state.docuArray[payload.ind].hasMainXML = payload.hasMainXML;
    },
    setAdjustable(state, payload) {
      state.docuArray[payload.ind].adjustable = payload.adjustable;
    },
    setXmlToJson(state, payload) {
      state.docuArray[payload.ind].xmlToJson = payload.xmlToJson;
    },
    removeZip(state, index) {
      state.docuArray.splice(index, 1);
    },
    setPdfFile(state, payload) {
      state.fileArray[payload.ind].pdfFile = payload.fileObj;
    },
    setNativeFiles(state, payload) {
      state.fileArray[payload.ind].nativeFiles = payload.fileList;
    },
    setFileStatus(state, payload) {
      state.fileArray[payload.index].finished = payload.status;
    },
    setXmlBlob(state, payload) {
      state.fileArray[payload.index].xmlBlob = payload.xmlBlob;
    },
    removeFile(state, index) {
      state.fileArray.splice(index, 1);
    },
  },
  actions: {
    setFileArray(context, payload){
      context.commit("setFileArray", payload)
    },
    setZipToEmpty(context, index){
      context.commit("setZipToEmpty", index)
    },
    setZipFile(context, payload) {
      context.commit("setZipFile", payload);
    },
    setHasMainXML(context, payload) {
      context.commit("setHasMainXML", payload);
    },
    setAdjustable(context, payload) {
      context.commit("setAdjustable", payload);
    },
    setXmlToJson(context, payload) {
      context.commit("setXmlToJson", payload);
    },
    addZipPlaceholder(context) {
      context.commit("addZipPlaceholder");
    },
    removeZip(context, index) {
      context.commit("removeZip", index);
    },
    setFiles(context, payload) {
      // pdfFile, arrNativeFile, index
      context.commit("setFiles", payload);
    },
    addFilePlaceholder(context) {
      context.commit("addFilePlaceholder");
    },
    setPdfFile(context, payload) {
      context.commit("setPdfFile", payload);
    },
    setNativeFiles(context, payload) {
      context.commit("setNativeFiles", payload);
    },
    setFileStatus(context, payload) {
      context.commit("setFileStatus", payload);
    },
    setXmlBlob(context, payload) {
      context.commit("setXmlBlob", payload);
    },
    removeFile(context, payload) {
      context.commit("removeFile", payload);
    },
  },
};
