<template>
  <div class="page-bulk-upload">
    <div class="col-lg-12 my-3 text-center">
      <h1 :class="getIsGeneralDocu ? 'hGen' : 'hZVS'">
        <div v-if="getIsGeneralDocu">
          {{ $t("bulkUploadGeneral") }}
        </div>
        <div v-else>
          {{ $t("bulkUploadZVS") }}
        </div>
      </h1>
    </div>
    <div class="row mt-5">
      <h4>{{ $t("subHeaderBulkCreation") }}</h4>
      <p>
        {{ $t("descriptionBulkCreation_1") }}
      </p>
      <p>
        {{ $t("descriptionBulkCreation_2") }}
      </p>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-1 text-primary">
          <b>{{ $t("descriptionBulkCreation_Step_1") }} </b>
        </div>
        <div class="col-9">{{ $t("descriptionBulkCreation_Step_1_txt") }}</div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-1 text-primary">
          <b>{{ $t("descriptionBulkCreation_Step_2") }} </b>
        </div>
        <div class="col-9">{{ $t("descriptionBulkCreation_Step_2_txt") }}</div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-1 text-primary">
          <b>{{ $t("descriptionBulkCreation_Step_3") }} </b>
        </div>
        <div class="col-9">{{ $t("descriptionBulkCreation_Step_3_txt") }}</div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-1 text-primary"></div>
        <div class="col-9">
          <small class="text-muted">{{
            $t("descriptionBulkCreation_hint_1")
          }}</small>
        </div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-1 text-primary">
          <b>{{ $t("descriptionBulkCreation_Step_4") }} </b>
        </div>
        <div class="col-9">{{ $t("descriptionBulkCreation_Step_4_txt") }}</div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-1 text-primary"></div>
        <div class="col-9">
          <small class="text-muted">{{
            $t("descriptionBulkCreation_hint_2")
          }}</small>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <a href="#" @click.prevent="prepareTemplate(false)">{{
        $t("excelTemplate")
      }}</a>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <router-view></router-view>
      </div>
      <div class="col-lg-6 mt-3">
        <h5>{{ $t("headerTemplateUpload") }}</h5>
        <input
          type="file"
          class="form-control"
          ref="fileExcelTmplate"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="readExcelFile($event)"
          @keydown.enter.prevent
        />
        <div class="row mt-3 justify-content-md-center" v-if="excelLoaded">
          <div class="col-lg-6">
            <button
              type="button"
              class="btn btn-outline-primary"
              @click.prevent="checkExcelFields"
            >
              {{ $t("checkExcelInput") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row mt-3 mb-3 justify-content-md-center"
      v-if="getPdfFile(0) != ''"
    >
      <div class="col-lg-6">
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="prepareTemplate(true)"
        >
          {{ $t("downloadPreFilledExcel") }}
        </button>
      </div>
    </div>
    <div
      class="row mt-3 mb-3 justify-content-md-center"
      v-if="successfullChecks == countOfChecks && getPdfCompleted"
    >
      <div class="col-lg-6">
        <button
          type="button"
          class="btn btn-success"
          @click.prevent="createVDIContainers"
        >
          {{ $t("createContainers") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ZVSDefinitions,
  ZVSMandatory,
  ZVSOptional,
} from "../../components/constants/zvsBulkUploadConst";
import ExcelBulkUpload from "../../components/utils/ExcelBulkUpload";
import APIcallsBulkUpload from "../../components/utils/APIcallsBulkUpload";
import LangCodes from "../../components/utils/LangCodes";
import createDocumentation from "../../components/createDocumentation";
import createXML from "../../components/createXML";
import { read, utils, writeFile, writeFileXLSX } from "xlsx-js-style";
import XLSX from "xlsx-js-style";
import { mapActions, mapGetters } from "vuex";
import FileUploadCom from "../../components/filerelated/FileUploadCom.vue";
import i18n from "@/i18n";
import Message from "vue-m-message";
import "vue-m-message/dist/style.css";
import { h } from "vue";
import axios from "axios";

// from which line the excel file should be read; starts at 0
const STARTROW = 1;
const STARTCOL = 0;
const CHECKSUCCEED = 1;
const CHECKFAIL = 0;
const COUNTOFCHECKS = 5;

export default {
  name: "BulkUploadView",
  components: {
    FileUploadCom,
  },
  data() {
    return {
      optionsCheck: undefined,
      mandatoryCheck: undefined,
      excelLoaded: false,
      successfullChecks: 0,
      countOfChecks: COUNTOFCHECKS,
      reader: new FileReader(),
      ColDefinitions: undefined,
      ClassIdPosibilitys: [],
      // fr: new FileReader(),
      pages: "Pages",
      title: "title",
      keywords: "keywords",
      subject: "subject",
    };
  },

  methods: {
    ...mapActions([
      "setIsPackageUnit",
      "setToken",
      "setUser",
      "setDocumentationName",
      "setContainerState",
    ]),
    ...mapActions("Bulk", [
      "setExcelData",
      "setEngTagSet",
      "clearEngTagSet",
      "addEngTagSetItem",
      "setPdfCompleted",
    ]),
    ...mapActions("Files", [
      "setZipFile",
      "removeFile",
      "setFileStatus",
      "setFileArray",
    ]),

    // create VDI2770 Containers
    async createVDIContainers() {
      // -------------------------
      //  Message.loading(
      //   () =>
      //     h("div", { id: "messageDownloadVDI" }, ["Preparing your VDI2770 containers"]),
      //   {
      //     title: "Preparing VDI2770 Documentation",
      //     duration: -1,
      //     closable: false,
      //     position: "center",
      //   }
      // );
      // ------------------------------
      this.removeEmptyFilePlaces();
      this.$store.dispatch("Bulk/clearEngTagSet");
      for (let i = 0; i < this.getExcelData.length; i++) {
        this.$store.dispatch(
          "Bulk/addEngTagSetItem",
          this.getExcelData[i][ZVSDefinitions.TAG]
        );
      }
      var separatedTagArray = ExcelBulkUpload.splitByCriteria(
        this.getExcelData,
        this.getEngTagSet,
        ZVSDefinitions.TAG
      );
      for (let z = 0; z < separatedTagArray.length; z++) {
        this.$store.dispatch(
          "setDocumentationName",
          separatedTagArray[z][0][ZVSDefinitions.TAG]
        );
        for (let y = 0; y < separatedTagArray[z].length; y++) {
          await ExcelBulkUpload.fillDocumentContainerStore(
            separatedTagArray[z][y],
            this.ClassIdPosibilitys
          );
        }
        await this.zipDocuContainer();
        // add axios post for logging
          await axios
            .post("api/v1/logging/", {
              documentationName: "#Bulk# " + this.getDocumentationName,
            })
            .then((resp) => {
              // do nothing
            })
            .catch((error) => {
              alert(error.request.responseText);
            });
          // end
        this.setFileStoreToNotSelected();
        this.resetStore();
      }
      this.deleteFiles();
      // Message.closeAll();
      this.$router.push("/");
    },
    removeEmptyFilePlaces() {
      var workingArray = this.getFileArray;
      let i = 1;
      if (workingArray.length > 1) {
        i = 0;
      }
      for (i; i < workingArray.length; i++) {
        if (
          workingArray[i].pdfFile == "" ||
          workingArray[i].pdfFile == undefined
        ) {
          this.$store.dispatch("Files/removeFile", i);
        }
      }
    },
    setFileStoreToNotSelected() {
      this.getFileArray.forEach((file, index) => {
        this.$store.dispatch("Files/setFileStatus", {
          index: index,
          status: "FILE_NOT_SELECTED",
        });
        // }
      });
    },

    resetStore() {
      const token = this.getToken;
      const user = this.getUser;
      const generalDoku = this.getIsGeneralDocu;
      const theFiles = this.getFileArray;
      this.$store.reset();
      this.$store.dispatch("Bulk/setEngTagSet", new Set());
      this.$store.dispatch("setToken", token);
      this.$store.dispatch("setUser", user);
      this.$store.dispatch("setEditing", true);
      this.$store.dispatch("setIsGeneralDocu", generalDoku);
      this.$store.dispatch("Files/setFileArray", theFiles);
      this.$store.dispatch("setContainerState", true);
      this.$store.dispatch("setIsPackageUnit", false);
    },
    deleteFiles() {
      this.successfullChecks = 0;
      this.$store.dispatch("Bulk/setPdfCompleted", false);
      this.$store.dispatch("Files/setFileArray", [
        {
          pdfFile: "",
          nativeFiles: [],
          finished: "FILE_NOT_SELECTED",
          xmlBlob: undefined,
        },
      ]);
    },
    // DokumentationsContainer erzeugen Start
    async zipDocuContainer() {
      let documentsArray = [];
      for (let i = 0; i < this.getFileArray.length; i++) {
        if (this.getFileArray[i].finished === "FINISHED") {
          documentsArray.push(
            await createDocumentation.createDocumentContainer(i)
          );
        }
      }
      // Nur wenn Zip Container mit hochgeladen und mit verarbeitet werden
      // if (this.getZipFile(0) != "") {
      //   for (let i = 0; i < this.getZipArray.length; i++) {
      //     documentsArray.push([this.getZipFile(i), this.getZipFile(i).name]);
      //   }
      // }
      let mainXML = createXML.createVdiMain();
      const mainPDF = await createXML.createMainPDF();
      let content = await createDocumentation.zipZip(
        documentsArray,
        mainXML,
        mainPDF
      );
      let contentArray = [content, this.getDocumentationName];
      this.downloadDocumentContainer(contentArray);
    },
    // DokumentationsContainer erzeugen Ende
    downloadDocumentContainer(contentArray) {
      const url = URL.createObjectURL(contentArray[0]);
      const link = document.createElement("a");
      link.href = url;
      link.download = contentArray[1];
      link.click();
    },

    // where to start reading data
    update_sheet_range(ws, rowStart = undefined, colStart = undefined) {
      var range = { s: { r: Infinity, c: Infinity }, e: { r: 0, c: 0 } };
      Object.keys(ws)
        .filter(function (x) {
          return x.charAt(0) != "!";
        })
        .map(utils.decode_cell)
        .forEach(function (x) {
          range.s.c = colStart ? colStart : Math.min(range.s.c, x.c);
          range.s.r = rowStart ? rowStart : Math.min(range.s.r, x.r);
          range.e.c = Math.max(range.e.c, x.c);
          range.e.r = Math.max(range.e.r, x.r);
        });
      ws["!ref"] = utils.encode_range(range);
    },

    prepareTemplate(addPdfNames = false) {
      /* generate worksheet from state */

      var AoA = [
        [
          "",
          "",
          i18n.global.t("partyOrgDataOrgWebsInfoBody"),
          i18n.global.t("partyOrgDataOrgNameInfoBody"),
          i18n.global.t("partyOrgDataOrgOffNameInfoBody"),
          i18n.global.t("docVersionDocTitleInfoBody"),
          "",
          i18n.global.t("docIdDocIdInfoBody0"),
          i18n.global.t("excelBulkCommentLang"),
          i18n.global.t("docVersionDocSummaryInfoTitle"),
          i18n.global.t("docVersionDocKeyWordInfoBody"),
          i18n.global.t("objIdRefTypeInfoBody0"),
          i18n.global.t("objIdRefTypeInfoBody1"),
          i18n.global.t("objIdRefTypeInfoBody5"),
          i18n.global.t("refObjDescDescInfoBody"),
          i18n.global.t("objIdRefTypeInfoBody6"),
          i18n.global.t("objIdRefTypeInfoBody3"),
          i18n.global.t("objIdRefTypeInfoBody2"),
          i18n.global.t("objIdRefTypeInfoBody4"),
          i18n.global.t("objIdRefTypeInfoBody7"),
          i18n.global.t("objIdRefTypeInfoBody9"),
          "",
          i18n.global.t("objIdRefTypeInfoBody8"),
          i18n.global.t("excelBulkCommentIPclass"),
        ],
      ];
      // this.removeEmptyFilePlaces();
      AoA.push(this.ColDefinitions);
      Message.loading(
        () =>
          h("div", { id: "messageExcelLoading" }, ["Reading Files Metadata"]),
        {
          title: "Prepare your template",
          duration: -1,
          closable: true,
          position: "center",
        }
      );
      if (addPdfNames) {
        var promises = [];

        this.getFileArray.forEach(async (element) => {
          if (element.pdfFile == "" || element.pdfFile == undefined) {
          } else {
            promises.push(
              this.getPdfMetadata(element.pdfFile, AoA, element.pdfFile.name)
            );
          }
        });

        Promise.all(promises).then((values) => {
          // last promise is to slow
          setTimeout(() => {
            this.createDownloadLink(values[promises.length - 1]);
          }, 500);
        });
      } else {
        this.createDownloadLink(AoA);
      }
    },

    createDownloadLink(AoA) {
      var ws = utils.aoa_to_sheet(AoA, { origin: "A1" });
      // add styling
      this.addStyleToWs(ws);
      /* create workbook and append worksheet */
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      /* export to XLSX */
      const excelBuffer = XLSX.write(wb, {
        type: "buffer",
        cellStyles: true,
      });
      const content = new Blob([excelBuffer]);

      const encodedUri = window.URL.createObjectURL(content);
      const link = document.createElement("a");

      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "VDI2770_massupload_template.xlsx");
      link.click();
      Message.closeAll();
      Message.success(
        () =>
          h("div", { id: "messageExcelLoad" }, [
            "Template successfull downloaded",
          ]),
        {
          title: "Prepare your template",
          duration: 1500,
          closable: true,
          position: "center",
        }
      );
    },

    addStyleToWs(ws) {
      // , color: { rgb: '9e9e9e' }   , wrapText: true
      const DEF_MANDATORY = {
        font: { name: "Calibri", sz: 12, bold: true },
        alignment: { vertical: "center", horizontal: "center", wrapText: true },
        fill: { fgColor: { rgb: "AFE1AF" } }, // 5ebd3e  9FE2BF AFE1AF
      };
      const DEF_OPTIONAL = {
        font: { name: "Calibri", sz: 12, bold: true },
        alignment: { vertical: "center", horizontal: "center", wrapText: true },
        fill: { fgColor: {rgb: "f7f7f7"}}
      };
      const DEF_HELP = {
        font: { name: "Calibri", sz: 9, bold: false, italic: true },
        alignment: { wrapText: true },
        fill: { fgColor: { rgb: "efeeeb" } },
      };
      const BORDER_ALL = {
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
        },
      };
      const range = utils.decode_range(ws["!ref"] ?? "");
      // const rowCount = range.e.r;
      const rowCount = STARTROW + 1; //range.e.r; //1;
      const columnCount = range.e.c;
      ws["!cols"] = [];
      for (let i = 0; i < this.ColDefinitions.length; i++) {
        // ws["!cols"].push({ width: 1.2 * this.ColDefinitions[i].length });
        if (i == 1 || i == 6 ) {
          ws["!cols"].push({ width: 20 });
        } else {
          ws["!cols"].push({ width: 60 });
        }
      }
      for (let row = 0; row < rowCount; row++) {
        for (let col = 0; col <= columnCount; col++) {
          const cellRef = utils.encode_cell({ r: row, c: col });
          if (cellRef) {
            if (
              this.mandatoryCheck.find((element) => element == ws[cellRef].v)
            ) {
              ws[cellRef].s = {
                ...DEF_MANDATORY,
                ...BORDER_ALL, 
              };
            } else if (row == 1) {
              ws[cellRef].s = {
                ...DEF_OPTIONAL,
                ...BORDER_ALL,
              };
            } else if (row == 0) {
              ws[cellRef].s = {
                ...DEF_HELP,
                ...BORDER_ALL,
              };
            }
          }
        }
      }
    },
    async getPdfMetadata(pdfFile, AoA, filename) {
      var convertPromise = new Promise(async (resolve, reject) => {
        if (pdfFile) {
          try {
            const pdfJS = await import("pdfjs-dist/build/pdf");
            pdfJS.GlobalWorkerOptions.workerSrc =
              window.location.origin + "/pdf.worker.min.js";
            const fre = new FileReader();
            fre.onload = async () => {
              let typedarray = new Uint8Array(fre.result);
              const task = await pdfJS.getDocument(typedarray);
              task.promise
                .then((pdf) => {
                  pdf.getMetadata().then((stuff) => {
                    let metadata = Array(
                      Object.keys(ZVSDefinitions).length
                    ).fill("");
                    const index = this.ColDefinitions.findIndex((object) => {
                      return object == ZVSDefinitions.FILENAME;
                    });
                    const index1 = this.ColDefinitions.findIndex((object) => {
                      return object == ZVSDefinitions.NUMBEROFPDFPAGES;
                    });
                    const index2 = this.ColDefinitions.findIndex((object) => {
                      return object === ZVSDefinitions.DOCTITLE;
                    });
                    const index3 = this.ColDefinitions.findIndex((object) => {
                      return object === ZVSDefinitions.SUMMARY;
                    });
                    const index4 = this.ColDefinitions.findIndex((object) => {
                      return object === ZVSDefinitions.KEYWORDS;
                    });
                    metadata[index] = filename;
                    metadata[index1] = pdf.numPages;
                    metadata[index2] = stuff.info.Title;
                    metadata[index3] = stuff.info.Subject;
                    metadata[index4] = stuff.info.Keywords;
                    AoA.push(metadata);
                  });
                })
                .then(() => {
                  resolve(AoA);
                });
            };

            fre.readAsArrayBuffer(pdfFile);
          } catch (error) {
            console.log(error);
          }
        }
      });
      return convertPromise;
    },

    async readExcelFile(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        // const reader = new FileReader();

        this.reader.onload = (e) => {
          const bstr = e.target.result;
          const wb = read(bstr, { type: "binary" });
          const wsnames = wb.SheetNames;
          const ws = wb.Sheets[wsnames[0]];
          this.update_sheet_range(ws, STARTROW, STARTCOL);
          const data = utils.sheet_to_json(ws);
          this.$store.dispatch("Bulk/setExcelData", data);
          this.checkExcelFields();
          this.excelLoaded = true;
        };

        this.reader.readAsBinaryString(this.file);
      }
    },
    checkExcelFields() {
      this.successfullChecks = 0;
      this.successfullChecks += this.checkMandatoryFields();
      // this.successfullChecks += this.checkOptionsFields();
      this.successfullChecks += this.checkBIGClassNames();
      this.successfullChecks += this.checkIecId();
      this.successfullChecks += this.checkLanguageAbbr();
      this.successfullChecks += this.checkPoNumber();

      if (this.successfullChecks == this.countOfChecks) {
        // this.excelCompleted = true;
        Message.success(
          () =>
            h("div", { id: "messageExcelComplete" }, [
              "Congratulations! Your Excel-Sheet is fine!",
            ]),
          {
            title: "Excel completely filled",
            duration: 2000,
            closable: true,
            position: "center",
          }
        );
      }
    },
    checkOptionsFields() {
      var optionsRowFail = "";
      var hasOptKeys = undefined;
      this.getExcelData.forEach((element) => {
        hasOptKeys = this.optionsCheck.some((item) =>
          element.hasOwnProperty(item)
        );
        if (!hasOptKeys) {
          var row = element.__rowNum__ + 1;
          optionsRowFail += " " + row + ";";
        }
      });
      if (optionsRowFail.length > 0) {
        Message.error(
          () =>
            h("div", { id: "messageExcelOptionsIncomplete" }, [
              "The Excel template is missing at least one optional entry in the row(s):" +
                optionsRowFail,
            ]),
          {
            title: "Please complete your Excel-Sheet",
            duration: -1,
            closable: true,
            position: "center",
          }
        );
        return CHECKFAIL;
      } else return CHECKSUCCEED;
    },
    checkMandatoryFields() {
      var rowFail = "";
      var hasAllKeys = undefined;
      // ändern, da immer nur der letzte Wert angezeigt wird. Array von Fehlern oder so
      this.getExcelData.forEach((element) => {
        hasAllKeys = this.mandatoryCheck.every((item) =>
          element.hasOwnProperty(item)
        );
        if (!hasAllKeys) {
          var row = element.__rowNum__ + 1;
          rowFail += " " + row + ";";
        }
      });
      if (rowFail.length > 0) {
        Message.error(
          () =>
            h("div", { id: "messageExcelIncomplete" }, [
              "In the Excel template, mandatory information is missing in the row(s):" +
                rowFail,
            ]),
          {
            title: "Please complete your Excel-Sheet",
            duration: -1,
            closable: true,
            position: "center",
          }
        );
        return CHECKFAIL;
      } else {
        return CHECKSUCCEED;
      }
    },
    checkBIGClassNames() {
      var rowFail = [];
      this.getExcelData.forEach((element) => {
        var value = element[ZVSDefinitions.DOCID];
        if (value != undefined) {
          const pdfVersionRegex =
            /(BIG_)(\w){2}(-)\D{1}\d{3}(-)\D{1}\d{2}(-VD)(-)\D{1}\d{3}/;
          const match = value.match(pdfVersionRegex);
          if (match) {
            var lastIndex = match[0].lastIndexOf("-");
            var catchedClassId = match[0].substring(lastIndex + 1);
            var classIdCheck = this.ClassIdPosibilitys[0].find(
              (item) => item["ClassId"] == catchedClassId
            );
          }
          if (!match || classIdCheck == undefined) {
            var row = element.__rowNum__ + 1;
            if (catchedClassId == undefined) {
              // catchedClassId = "ZVSP Document Number invalid Syntax"
              var messageText =
                "no. " + row + ": ZVSP Document Number invalid Syntax;";
            } else {
              var messageText =
                "no. " + row + ": invalid ClassId = " + catchedClassId + "; ";
            }
            rowFail.push(
              h("div", {
                class: "col-md-12",
                innerHTML: messageText,
              })
            );
          }
        }
      });
      if (rowFail.length > 0) {
        Message.error(
          () =>
            h("div", { id: "messageClassIdFail", "^width": "100" }, [
              "No valid ClassId found in row(s):",
              rowFail,
            ]),
          {
            title: "Please check " + ZVSDefinitions.DOCID,
            duration: -1,
            closable: true,
            position: "center",
            // supportHTML: true,
          }
        );
        return CHECKFAIL;
      } else {
        return CHECKSUCCEED;
      }
    },
    checkIecId() {
      var rowFail = [];
      var fails = "";
      this.getExcelData.forEach((element) => {
        var value = element[ZVSDefinitions.IECID];
        if (value != undefined && value.trim() !== "") {
          if (!value.includes(".")) {
            fails += i18n.global.t("faultDomain", [value]) + " ";
          }

          if (!value.includes("/")) {
            fails += i18n.global.t("faultPath", [value]) + " ";
          }

          const domain = value.split("://").pop().split("/")[0];

          if (domain.toLowerCase() !== domain) {
            fails += i18n.global.t("faultDomainUpper") + " ";
          }

          if (value.includes("xn--")) {
            fails += i18n.global.t("faultPunyCode") + " ";
          }

          if (value.length > 255) {
            fails += i18n.global.t("faultUrlLength") + " ";
          }

          const invalidCharactersRegex =
            /[^A-Za-z0-9#,$&'()*+\-.\/~\[\]=?:;!_@]/;
          const match = value.match(invalidCharactersRegex);

          if (match) {
            fails +=
              i18n.global.t("faultInvalidCharURL", [match.index + 1]) + " ";
          }
        }
        if (fails != "") {
          var row = element.__rowNum__ + 1;
          rowFail.push(
            h("div", {
              class: "col-md-12",
              innerHTML: "no. " + row + ": " + fails,
            })
          );
          fails = "";
        }
      });
      if (rowFail.length > 0) {
        Message.error(
          () =>
            h("div", { id: "messageIecIdFail", "^width": "100" }, [
              "IEC61406 ID invalid in row(s):",
              rowFail,
            ]),
          {
            title: "Please check " + ZVSDefinitions.IECID,
            duration: -1,
            closable: true,
            position: "center",
            // supportHTML: true,
          }
        );
        return CHECKFAIL;
      } else {
        return CHECKSUCCEED;
      }
    },

    checkLanguageAbbr() {
      var rowFail = [];
      var langAbbr = [];
      // ändern, da immer nur der letzte Wert angezeigt wird. Array von Fehlern oder so
      this.getExcelData.forEach((element) => {
        var languages = ExcelBulkUpload.getSplittedLanguages(
          element[ZVSDefinitions.LANG]
        );
        for (let z = 0; z < LangCodes.getLang().length; z++) {
          langAbbr.push(LangCodes.getLang()[z][0]);
        }
        for (let i = 0; i < languages.length; i++) {
          if (!langAbbr.includes(languages[i])) {
            var row = element.__rowNum__ + 1;
            var fails = " Invalid Language = '" + languages[i] + "'";
            rowFail.push(
              h("div", {
                class: "col-md-12",
                innerHTML: "no. " + row + ": " + fails,
              })
            );
          }
        }
      });
      if (rowFail.length > 0) {
        Message.error(
          () =>
            h("div", { id: "messageExcelInvalidLang" }, [
              "Invalid language code in row(s):",
              rowFail,
            ]),
          {
            title:
              "Please check your " + ZVSDefinitions.LANG + " Abbreviations",
            duration: -1,
            closable: true,
            position: "center",
          }
        );
        return CHECKFAIL;
      } else {
        return CHECKSUCCEED;
      }
    },
    checkPoNumber() {
      var rowFail = [];
      this.getExcelData.forEach((element) => {
        const invalidPoCharRegex = /[^0-9]/;
        const match = element[ZVSDefinitions.PO]
          .toString()
          .match(invalidPoCharRegex);
        if (match) {
          var row = element.__rowNum__ + 1;
          var fails =
            " Invalid Purchase Order = '" + element[ZVSDefinitions.PO] + "'";
          rowFail.push(
            h("div", {
              class: "col-md-12",
              innerHTML: "no. " + row + ": " + fails,
            })
          );
        }
      });
      if (rowFail.length > 0) {
        Message.error(
          () =>
            h("div", { id: "messageExcelinvalidPo" }, [
              "Invalid purchase order in row(s):",
              rowFail,
            ]),
          {
            title: "Please check your " + ZVSDefinitions.PO,
            duration: -1,
            closable: true,
            position: "center",
          }
        );
        return CHECKFAIL;
      } else {
        return CHECKSUCCEED;
      }
    },
  },

  computed: {
    ...mapGetters([
      "getIsGeneralDocu",
      "getToken",
      "getUser",
      "getDocumentationName",
    ]),
    ...mapGetters("Files", [
      "getPdfFile",
      "getFileArray",
      "getZipArray",
      "getZipFile",
    ]),
    ...mapGetters("Bulk", ["getExcelData", "getPdfCompleted", "getEngTagSet"]),
  },
  async created() {
    if (this.getIsGeneralDocu) {
    } else {
      this.ColDefinitions = Object.values(ZVSDefinitions);
      this.optionsCheck = ZVSOptional;
      this.mandatoryCheck = ZVSMandatory;
      this.ClassIdPosibilitys =
        await APIcallsBulkUpload.getClassIdForClassSystem(
          "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types",
          this.ClassIdPosibilitys
        );
    }
  },
  unmounted() {
    const token = this.getToken;
    const user = this.getUser;
    this.$store.reset();
    this.$store.dispatch("setToken", token);
    this.$store.dispatch("setUser", user);
    this.$store.dispatch("setIsPackageUnit", true);
  },
};
</script>
