export default {
  "next Step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
  "Edit Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑文件"])},
  "go back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["键入以搜索..."])},
  "routeObjectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对象 ID"])},
  "routeOptionalVendorMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选供应商元数据"])},
  "routeHeaderRefObjParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司"])},
  "routeHeaderRefObjDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "routeFileUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件上传"])},
  "routeFileSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择文件"])},
  "routeOrganizationMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织元数据"])},
  "routeDocumentDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件域"])},
  "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 3 步：元数据分类"])},
  "step5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 5 步：元数据文件版本"])},
  "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤 4：元数据引用对象"])},
  "navFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件"])},
  "navOrgData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织数据"])},
  "navClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分类"])},
  "navRefObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引用的对象"])},
  "navDocVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件版本"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎使用 BASF VDI 2770 Creator"])},
  "homeSubTitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成为数字化转型的一部分，让您的文档更上一层楼。"])},
  "homeParaOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDI 2770 Web Creator 允许您轻松创建符合德国工业标准 2770 和巴斯夫数字化交付规则的数字格式文档。"])},
  "homeParaTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是要为单个文件创建文档容器，还是为整个单元创建完整文档？从这里开始您的选择，让我们指导您完成整个过程。最后，通过您将收到一个ZIP文件包，您可以与巴斯夫进行数字共享。"])},
  "homeParaThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即开始并创建您的文档。"])},
  "titleLearMoreSimpleDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简单文件容器"])},
  "bodyLearMoreSimpleDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为一个特定文件创建文件容器。此文件容器可用于文档容器。它可以在不同的文档中多次重复使用。"])},
  "titleLearMoreDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档容器"])},
  "bodyLearMoreDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为设备或单元创建完整的文档容器。使用此功能，您可以在一个步骤中创建所有必要的文件容器，并将它们组合到一个文档容器中。"])},
  "SimpleDocumentContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简单文件容器"])},
  "createDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建文件"])},
  "documentationContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档容器"])},
  "createFullDocumentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建完整文档"])},
  "PackageUnitDocumentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单元文档包"])},
  "createPackageUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建包装单元"])},
  "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多信息"])},
  "thisTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info Modal 的示例标题"])},
  "thisBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info Modal 的示例正文"])},
  "Step 1: Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第 1 步：文件"])},
  "cardHeaderFileView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是为类型还是为特定实例创建文档？"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
  "modelInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择产品类型 ID 或产品系列，您将为类型创建文档。"])},
  "instance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实例"])},
  "instanceInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当实例对文档有唯一引用时，将选择特定于实例的文档。例如，可以通过制造商、型号和序列号的组合或 IEC 61406 ID 来指定唯一实例。"])},
  "documentationNameHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为您的文档指定一个有意义的完整名称："])},
  "documentationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档名称："])},
  "documentationNameHelpBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档名称是创建VDI 2770 文档容器的名称。"])},
  "TPU-Plant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPU 文档"])},
  "TPU-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是否提供 TPU 的文档？"])},
  "TPUInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPU 文档需要额外的分类系统。我们将添加此作为必填项，因此您不会忘记维护此类系统"])},
  "headerZipInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档容器上传的一些解释"])},
  "fileUploadPDFInfoBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ".此处选择PDF/A格式文件的文档容器。"])},
  "fileUploadZipInfoBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ".此处选择 Zip 格式的文档容器。"])},
  "fileUploadNativeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此处选择了文档容器相关其他初始文件（例如，CAD格式的图纸，xlsx格式的零件清单）。初始文件与 PDF/A 文件一起保存在文档容器中，并具有相同的文件元数据。"])},
  "headerFileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请上传所有文件作为您的文档"])},
  "headerZipUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在此处仅使用符合要求的容器"])},
  "p1FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传功能分为两部分。第一个上传区域用于您的 PDF（A） 文件。这必需是个单一PDF。"])},
  "p2FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第二个区域支持多个文件上传，您可以使用它来上传所有相关的初始文件，如图纸、word、excel 和所有其他文件格式。"])},
  "p3FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当然，支持拖放。"])},
  "p4FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过单击蓝色加号按钮，可新增下一个文件。根据需要任意添加文件的数量。"])},
  "p5FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您选择了太多行或想要删除一些文件，请单击红叉。"])},
  "headerFileSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择一个文件以创建其元数据："])},
  "PDF File": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["PDF 文件 ", _interpolate(_list(0)), " 上传"])},
  "Zip File": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zip 文件 ", _interpolate(_list(0)), " 上传"])},
  "Native File(s)": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["初始文件 ", _interpolate(_list(0)), " 上传"])},
  "headerSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选文件："])},
  "headerFileSelectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里，您可以选择接下来要处理的文件"])},
  "textFileSelectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您完成了此文档的文件，请返回并选择另一个文件。"])},
  "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第二步：组织元数据"])},
  "headerPartyTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织模板："])},
  "descPartyTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用这些数据，将预先填充区域。您可以随时更改它们。"])},
  "chooseTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择模板："])},
  "createTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新模板："])},
  "newTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新模板"])},
  "partyOrgDataInfoChoosBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里，可以选择以前已经保存的项目模板。"])},
  "partyOrgDataInfoChoosTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择模板"])},
  "partyOrgDataInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在此处创建并保存组织元数据的新模板。保存的模板可以跨项目使用。"])},
  "partyOrgDataInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新模板"])},
  "partyOrgDataOrgWebsInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织 ID"])},
  "partyOrgDataOrgWebsInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织的唯一 ID，负责创建文档并将其交付给客户。例如，公司的官方网站。"])},
  "partyOrgDataRoleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])},
  "partyOrgDataRoleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此处，“责任人”的角色是默认设置，无法更改。"])},
  "partyOrgDataOrgNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织名称"])},
  "partyOrgDataOrgNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负责准备文件并将其交付给客户的公司通用名称（“没有法律形式的简称”）。示例：ABC。"])},
  "partyOrgDataOrgOffNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织正式名称"])},
  "partyOrgDataOrgOffNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["负责准备文件并将其交付给客户的公司正式名称（“带有法律形式指示的长格式”）。示例：ABC股份有限公司。"])},
  "partyOrgDataExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对本文件内容负责的组织。"])},
  "DomainID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["域 ID"])},
  "DocumentDomainId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件DomainId"])},
  "DocumentID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件Id"])},
  "headerDocTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件DomainId模板："])},
  "headerAddDocId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（可选）可以添加另一个文件DomainId："])},
  "docIdHeaderInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里，您可以从以前的项目中选择已保存的公司模板或创建新模板。"])},
  "docIdDomainIdInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["湛江一体化基地项目的必需的域 ID"])},
  "docIdDomainIdInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档 ID 为双唯一属性域的 ID 或指示器"])},
  "docIdDocDomainIdInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["湛江一体化基地项目必需的文件DomainId"])},
  "docIdDocDomainIdInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID或指示器的域；例如，可以使用组织的名称。"])},
  "docIdDocIdInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商文件命名语法据规范“BIG_00-0000-000-PM-010-010-R34-Site and Plant Structure and Eng Doc Codes”"])},
  "docIdDocIdInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分配给文件的标识号"])},
  "headerClassData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["维护分类数据"])},
  "ClassificationSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分类系统"])},
  "classSysHeaderInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["维护分类数据"])},
  "classSysHeaderInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户可以指定任意数量的分类系统。但是，必须据分类系统“BIG_00-0000-000-PM-010-010 附录 3 VD 文档类型”和“VDI 2770：2018”完成。"])},
  "classSysGeneralHeaderInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户可以指定任意数量的分类系统。但是，须据分类系统“VDI 2770：2018”完成。"])},
  "classSysInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分类系统"])},
  "classSysInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此处选择相应的分类系统。"])},
  "classIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别ID"])},
  "classIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将根据所选分类系统，与文件相关的文件类别在此处标明。 在某些情况下，该文件可能涉及多个文件类别。"])},
  "classNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别名"])},
  "classNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果选择了预定义的类系统，则会根据选定的类ID自动设置类别名称。"])},
  "classLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别语言"])},
  "classLangInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别名称值的语言。至少必须在此处指定英语语言。"])},
  "headerRefObjId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["湛江一体化基地的元数据“Object Id”："])},
  "headerGeneralRefObjId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元数据“Object Id”："])},
  "toogleHeaderMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["湛江一体化基地必填：（点击切换）"])},
  "toogleHeaderOptional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果湛江一体化基地有数据：（点击切换）"])},
  "additionalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他Object Id"])},
  "additionalDataBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以在此处输入制造商分配给对象的其他Object Id。"])},
  "objIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Id"])},
  "objIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用法说明“Object Id”"])},
  "objIdRefTypeInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RefType"])},
  "objIdRefTypeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用法说明 'RefType'"])},
  "objIdRefTypeInfoTitle0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf: ProjectId"])},
  "objIdRefTypeInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴斯夫项目编号是巴斯夫IT系统中项目的识别号和标识符。"])},
  "objIdRefTypeInfoTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf: 采购订单号"])},
  "objIdRefTypeInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴斯夫的采购订单号是识别号和用于标识订单的一个或多个对象。它用于巴斯夫的IT系统，并在下订单时提供给制造商。"])},
  "objIdRefTypeInfoTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品类型"])},
  "objIdRefTypeInfoBody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品名称（型号、序列号、产品类型）可用作一个或多个装配单元中的组件。产品名称在制造商中是唯一的。"])},
  "objIdRefTypeInfoTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对象 uri 的实例 （IEC 61406 ID）"])},
  "objIdRefTypeInfoBody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OEM 根据 IEC 61406（前身标准：DIN SPEC 91406）对对象进行全球唯一标识。"])},
  "objIdRefTypeInfoTitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECLASS 号"])},
  "objIdRefTypeInfoBody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECLASS是产品分类的数据标准。"])},
  "objIdRefTypeInfoTitle5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf: 工程位号"])},
  "objIdRefTypeInfoBody5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴斯夫工程位号（设备编号）是生产工厂安装位置的识别代码，在大多数情况下，在下采购订单时，操作员会将其传输给制造商。"])},
  "objIdRefTypeInfoTitle6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf: 设备编号"])},
  "objIdRefTypeInfoBody6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴斯夫设备编号是生产工厂中组件的唯一标识符。巴斯夫设备编号必须由操作员传送给制造商。"])},
  "objIdRefTypeInfoTitle7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf: 参考名称"])},
  "objIdRefTypeInfoBody7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考名称是对象的标识号和用于标识对象（巴斯夫功能位置）。巴斯夫的功能位置必须由运营商提供给制造商。"])},
  "objIdRefTypeInfoTitle8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["序号"])},
  "objIdRefTypeInfoBody8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制造商为单个对象提供的唯一标识号。在制造商的产品名称（型号、序列号、产品类型）中，序列号是唯一的。"])},
  "objIdRefTypeInfoTitle9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商销售订单编号"])},
  "objIdRefTypeInfoBody9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由供应商的ERP系统在授予采购订单后创建的唯一销售订单号。"])},
  "objIdObjectTypeInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对象类型：*"])},
  "objIdObjectTypeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用法说明“对象类型”"])},
  "objIdBiuniqueInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是全球唯一的"])},
  "objIdBiuniqueInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用法说明“全球唯一”"])},
  "headerOptMetaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对象可选的附加元数据"])},
  "objIdOptTypeInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
  "objIdOptTypeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这些引用可用于制造商或供应商IT系统中的内部引用。"])},
  "objIdOptValueInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["值"])},
  "objIdOptValueInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选引用的对应值。"])},
  "headerRefObjParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元数据方"])},
  "headerRefObjPartyBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认情况下，将插入步骤2中的组织元数据。如果需要，可以手动调整数据。"])},
  "refObjPartyOrgIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织网站"])},
  "refObjPartyOrgIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织的唯一标识。例如公司的官方网站。"])},
  "refObjPartyRoleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])},
  "refObjPartyRoleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据指南VDI 2770:2018，您可以在以下角色之间进行选择：制造商，供应商"])},
  "refObjPartyOrgNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织名称"])},
  "refObjPartyOrgNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司的通用名称(不含法定名称的缩写)。例子：ABC公司"])},
  "refObjPartyOrgOffNInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织的正式名称"])},
  "refObjPartyOrgOffNInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建文档并将其交付给客户的公司的正式名称(带有法律格式的长格式)。例如：ABC有限责任公司"])},
  "headerRefObjDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引用对象描述"])},
  "refObjgDescDescInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "refObjDescDescInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ReferencedObject的多语言描述。每种语言都应该只有一个ReferencedObject的描述。"])},
  "refObjgDescLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
  "refObjDescLangInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指示ReferencedObject的描述语言。"])},
  "headerDocVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元数据文档版本"])},
  "docVersionDocVerIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档版本号"])},
  "docVersionDocVerIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件的当前版本。"])},
  "docVersionNumPagesInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页数"])},
  "docVersionNumPagesInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档中与PDF/A文件格式相关的页数。"])},
  "docVersionLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
  "docVersionLangInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档中使用的语言列表。"])},
  "docVersionPartyHeadInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件版本方"])},
  "docVersionPartyHeadInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件版本的责任方。"])},
  "docVersionOrgIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织网站"])},
  "docVersionOrgIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织的唯一标识。例如公司的官方网站。"])},
  "docVersionOrgNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织名称"])},
  "docVersionOrgNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司的通用名称(不含法定名称的缩写)。例子：ABC公司"])},
  "docVersionRoleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])},
  "docVersionRoleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这里至少必须设置` Author `角色。"])},
  "docVersionOrgOffNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织的正式名称"])},
  "docVersionOrgOffNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建文档并将其交付给客户的公司的正式名称(带有法律格式的长格式)。例如：ABC有限责任公司"])},
  "headerDocDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元数据文档说明"])},
  "docVersionDocTitleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档标题"])},
  "docVersionDocTitleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选文档的标题。"])},
  "docVersionDocSubTitleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["副标题"])},
  "docVersionDocSubTitleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档的副标题(可选字段)"])},
  "docVersionDocSummaryInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总结"])},
  "docVersionDocSummaryInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档版本中使用的每种语言都必须指定DocumentDescription。此外如果可能的话，例如，也可以为德语文档版本额外指定一个英语描述，尽管文档内容不是多语言的。"])},
  "docVersionDocKeyWordInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关键字"])},
  "docVersionDocKeyWordInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选文档的关键字。例如，可以为维护手册设置关键字“检查”、“维护”、“维修”。"])},
  "docVersionDocLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
  "headerDocLifeCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生命周期方"])},
  "docVersionDocCommentInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "docVersionDocCommentInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对状态的多语言评论和说明的列表。"])},
  "docVersionDocDateInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
  "docVersionDocDateInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定义状态的日期。"])},
  "docVersionDocStatusInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "docVersionDocStatusInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是文档生命周期中文档的status值。两个状态值为nReview和release。"])},
  "buttonDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载文档"])},
  "buttonNextFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个文件"])},
  "routeDocVerParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件版本"])},
  "routeDocDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件描述"])},
  "routeDocLifeCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生命周期状态"])},
  "faultMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写所有必填字段"])},
  "faultDocumentationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档名缺失"])},
  "faultInvalidCharDoc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["文档名称包含以pos ", _interpolate(_list(0)), "开始的无效字符"])},
  "faultDocNameToLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档名称不能超过150个字符。"])},
  "faultMissingPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请添加至少一个PDF"])},
  "faultNoFileSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择文件"])},
  "faultBigSpec": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["文档Id必须按照BIG规范构建。", _interpolate(_list(0)), "后有错误"])},
  "faultMissingDocId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少文档id"])},
  "faultDomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "必须是一个有效的域名。"])},
  "faultPath": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "必须包含一个路径。"])},
  "faultDomainUpper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["域部分必须小写。"])},
  "faultPunyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url中不能包含punycode。"])},
  "faultUrlLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url不能超过255个字符。"])},
  "faultInvalidCharURL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["url包含从pos ", _interpolate(_list(0)), "开始的无效字符"])},
  "faultPoNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["采购订单号码只能包含数字"])},
  "faultEclassNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eclass只能包含数字"])},
  "faultEclassLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eclass必须为8个字符"])},
  "faultIndividual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少需要一个' individual '类型"])},
  "faultNoIndividual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档容器中不允许' individual '类型。"])},
  "faultAtLeastOneEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至少有一个字段` Object Id `是必需的"])},
  "faultNotReadablePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF不可读"])},
  "unspecificError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发生错误，请检查输入。"])},
  "doIdTPU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DocumentId 解释(TPU)"])},
  "doIdNonTPU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DocumentID解释"])},
  "generalDocu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建一个通用文档<br>（非湛江）"])},
  "zhanjiangDocu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档<br>湛江一体化基地"])},
  "wantToLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有输入都将丢失。您真的想离开吗?"])},
  "VDI2770Container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDI2770容器"])},
  "faultNotVDI2770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有有效的VDI2770容器。请选择一个有效的容器。"])},
  "inclNativeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要初始文件（xlsx, docx ...）？"])},
  "inclNativeFileInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你不需要初始文件，你可以一次性将所有PDF文件拖到upload区域。遗憾的是，对于初始文件这是不可能的，因为这样就无法在PDF和初始文件之间建立关联。"])},
  "VDI2270Container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["符合VDI-2770的容器"])},
  "PDFFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF文件"])},
  "dropContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里放置VDI2770容器"])},
  "dropPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里放置PDF文件"])},
  "cantReadZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法读取文件。您确定这是zip文件吗?"])},
  "fileInPreparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可删除，正在处理文件。"])},
  "filesNotInEditMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件上传不处于编辑模式。"])},
  "faultNotPdfType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件不是PDF格式"])},
  "faultInvalidClassId": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["您的文档id中的ClassID似乎无效，请检查并更改ClassId ", _interpolate(_list(0))])},
  "BIGClassSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开BIG分类系统"])},
  "Edit_Mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（编辑模式）"])},
  "delete_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除您的模板"])},
  "reallyDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您真的要删除此模板吗?"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "faultIsPrimary0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有且只有一个DocumentDomainId可指定为isPrimary。"])},
  "faultIsPrimary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有且只有一个DocumentDomainId可指定为isPrimary。请移除多余的。"])},
  "numPdfFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF文件数量"])},
  "pdfpart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF文件占总文件的百分比 / XML和初始文件的百分比"])},
  "Metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元数据"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页面："])},
  "pdfFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF文件"])},
  "BacktoCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回生成器"])},
  "BacktoViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回文件选择"])},
  "headerFileViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击链接以检查容器内容"])},
  "BASFVDI2770Creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["巴斯夫VDI2770生成器和查看器"])},
  "VDI2770Viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDI2770查看器"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您好"])},
  "Expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展开所有"])},
  "Collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折叠所有"])},
  "attentionRAMsize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请注意:可以处理的VDI容器大小取决于可用的RAM。"])},
  "Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大小："])},
  "HidePDFlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示元数据"])},
  "ShowPDFlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示PDF列表"])},
  "fillAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述和语言必须有值，否则两者必须为空"])},
  "filterResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示完整的筛选结果"])},
  "include1ZipLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含第一层子压缩包"])},
  "expertMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专家模式，在树视图中选择一个XML文件链接"])},
  "noExpertMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细视图"])},
  "filterMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索元数据"])},
  "FullXMLRep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成XML表示"])},
  "clickButtonToExpand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击此按钮以展开所有"])},
  "clickLineToExpand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击行以展开一行"])},
  "ExpandAllChilds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展开所有"])},
  "excelUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用excel模板创建VDI 2770文档容器"])},
  "excelUploadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始批量创建"])},
  "bulkUploadZVS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建湛江一体化基地项目的VDI2770文档容器"])},
  "bulkUploadGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建通用的VDI2770文档容器"])},
  "subHeaderBulkCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如何使用EXCEL上传？"])},
  "descriptionBulkCreation_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel批量上传作业允许您根据Excel模板中输入的信息轻松创建多个符合VDI 2770的文档容器。"])},
  "excelTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载空的Excel上传模板"])},
  "headerTemplateUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里上传填好的Excel模板:"])},
  "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（文件丢失）"])},
  "checkExcelInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查excel表格的内容"])},
  "createContainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建VDI 2770文档容器"])},
  "downloadPreFilledExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载预填文件名的Excel模板。"])},
  "descriptionBulkCreation_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel模板中输入的每个BASF Engineering Tag都会创建一个单独的VDI 2770文档容器。文档容器使用输入的BASF Engineering Tag命名。"])},
  "descriptionBulkCreation_Step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤1："])},
  "descriptionBulkCreation_Step_1_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拖拽所有文件到PDF上传字段。"])},
  "descriptionBulkCreation_Step_2_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击“下载预填文件名的Excel模板”按钮，下载预填文件名的Excel模板。"])},
  "descriptionBulkCreation_Step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤2："])},
  "descriptionBulkCreation_Step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤3："])},
  "descriptionBulkCreation_Step_3_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填写Excel模板。列名标记为绿色的为必填项，其他列为可选项。"])},
  "descriptionBulkCreation_Step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["步骤4："])},
  "descriptionBulkCreation_Step_4_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传填写完成的Excel模板。"])},
  "descriptionBulkCreation_hint_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请注意：Excel模板不能更改,因此不能添加或删除其他列。"])},
  "descriptionBulkCreation_hint_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请注意：填写Excel模板时，本网站是可以关闭的。您可以再次打开网页，以上传填写完成的Excel模板。Excel模板中列出的文件将在'已上传PDF'区域中列出。"])},
  "excelBulkCommentIPclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制造商对文件的保护等级。可能的选项：公开的、内部的、机密的。"])},
  "excelBulkCommentLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据ISO 639-1（语言代码）列出文件中所使用的语言。对于多语言文档，语言代码由逗号分隔。"])}
}