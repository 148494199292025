export default {
  "next Step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next Step"])},
  "Edit Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Files"])},
  "go back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go back"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type to search..."])},
  "routeObjectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Ids"])},
  "routeOptionalVendorMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional Vendor Metadata"])},
  "routeHeaderRefObjParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Party"])},
  "routeHeaderRefObjDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "routeFileUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Upload "])},
  "routeFileSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select document "])},
  "routeOrganizationMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Metadata "])},
  "routeDocumentDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Domain"])},
  "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3: Metadata Classification"])},
  "step5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 5: Metadata Document Version"])},
  "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4: Metadata Referenced Object"])},
  "navFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "navOrgData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational Data"])},
  "navClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification"])},
  "navRefObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenced Object"])},
  "navDocVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Version"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to BASF VDI 2770 Creator"])},
  "homeSubTitel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be a part of the digital transformation and take your documentation to the next level."])},
  "homeParaOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDI 2770 Web Creator allow you to easily create documentation in digital format that complies with the German Industrial Standard 2770 and the BASF rules for digital handover."])},
  "homeParaTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create a document container for a single document or a complete documentation for an entire unit? Start here with your selection and let us guide you through the entire process. At the end you will receive a ZIP container that you can share digitally with BASF."])},
  "homeParaThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instantly start and create your documentation now."])},
  "titleLearMoreSimpleDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple Document Container"])},
  "bodyLearMoreSimpleDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a document-container for one specific file. This document container can be used in a documentation container. It can be reused multiple times in different documentations."])},
  "titleLearMoreDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation Container"])},
  "bodyLearMoreDocContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a full documentaion container for an equipment or Unit. With this function you can create in one step all necessary doucment container and combine them to a documentation container."])},
  "SimpleDocumentContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple Document Container"])},
  "createDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create document"])},
  "documentationContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation Container"])},
  "createFullDocumentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create full documentation"])},
  "PackageUnitDocumentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Unit Documentation"])},
  "createPackageUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create package unit"])},
  "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
  "thisTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Title for Info Modal"])},
  "thisBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Body for Info Modal"])},
  "Step 1: Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1: Files"])},
  "cardHeaderFileView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you create a model-documentation or an documentation for a specific instance?"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
  "modelInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A model-specific documentation is selected when referring to model ID or series for documentation."])},
  "instance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instance"])},
  "instanceInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An instance-specific documentation is selected when there is a unique reference from an instance to the documentation. A unique instance can be specified, for example, by the combination of manufacturer, model series and serial number or by an IEC 61406 Id."])},
  "documentationNameHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give your documentation a meaningfull name:"])},
  "documentationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation name:"])},
  "documentationNameHelpBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The documentation name is the name of the created VDI 2770 documentation container."])},
  "TPU-Plant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation for TPU"])},
  "TPU-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you provide a documentation for TPU?"])},
  "TPUInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPU documentations require additional classification system. We will add this as mandatory, so you don't forget to maintain this class-system"])},
  "headerZipInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some intelligent explanation for document container upload"])},
  "fileUploadPDFInfoBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The ", _interpolate(_list(0)), ". document of the documentation container in PDF/A format is selected here."])},
  "fileUploadZipInfoBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The ", _interpolate(_list(0)), ". documentation container in Zip format is selected here."])},
  "fileUploadNativeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The additional native files (e.g. drawing in cad format, parts list in xlsx format) of the related document of the documentation container are selected here. The native files are saved together with the PDF/A document in a document container and have the identical document metadata."])},
  "headerFileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Upload all files for your documentation"])},
  "headerZipUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use only conform containers here"])},
  "p1FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The upload functionality is divided in two parts. The first upload field is for your PDF(A) file. Here is a single PDF required."])},
  "p2FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The second field enables multiple file upload and you can use it for uploading all related native files, like drawings, word, excel and all other file formats."])},
  "p3FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of course, drag and drop is supported."])},
  "p4FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking the blue plus-button, new rows appears for the next documents. Add as many documents as you need."])},
  "p5FileUploadDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have selected too many rows or like to delete a pair of files, click on the red cross."])},
  "headerFileSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a document to create its metadata:"])},
  "PDF File": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["PDF File ", _interpolate(_list(0)), " upload"])},
  "Zip File": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Zip File ", _interpolate(_list(0)), " upload"])},
  "Native File(s)": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Native File(s) ", _interpolate(_list(0)), " upload"])},
  "headerSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected document:"])},
  "headerFileSelectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can select the document you like to work on next"])},
  "textFileSelectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you finished your documentation for this document, come back and select another one."])},
  "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2: Metadata Organization"])},
  "headerPartyTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization templates:"])},
  "descPartyTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With these data, fields are prefilled. You can overwrite them at any time."])},
  "chooseTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose template:"])},
  "createTemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new template:"])},
  "newTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Template"])},
  "partyOrgDataInfoChoosBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here, already saved templates of your company from previous projects can be selected."])},
  "partyOrgDataInfoChoosTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose template"])},
  "partyOrgDataInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can create and save a new template for the organisation metadata. The saved template can be used across projects."])},
  "partyOrgDataInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new template"])},
  "partyOrgDataOrgWebsInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Id"])},
  "partyOrgDataOrgWebsInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique ID for the organization, which is responsible for creating the documentation and delivering it to the customer. For example the official website of the company."])},
  "partyOrgDataRoleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "partyOrgDataRoleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the role 'Responsible' is set by default and cannot be changed."])},
  "partyOrgDataOrgNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Name"])},
  "partyOrgDataOrgNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The common designation of the company ('short form without indication of legal form') that is responsible for the preparation of the documentation and delivers it to the customer. Example: Company ABC."])},
  "partyOrgDataOrgOffNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Official Name"])},
  "partyOrgDataOrgOffNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The official name of the company ('long form with indication of legal form') that is responsible for the preparation of the documentation and delivers it to the customer. Example: Company ABC GmbH & Co. KG."])},
  "partyOrgDataExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization who is responsible for the content of this document."])},
  "DomainID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain ID"])},
  "DocumentDomainId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document DomainId"])},
  "DocumentID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DocumentId"])},
  "headerDocTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates Document Domain Ids:"])},
  "headerAddDocId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionally another Document Domain Id can be added:"])},
  "docIdHeaderInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can select already saved templates of your company from previous projects or create new templates."])},
  "docIdDomainIdInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory DomainID for Zhanjiang Verbund Site Project"])},
  "docIdDomainIdInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID or indicator for a domain in which a document ID is biunique"])},
  "docIdDocDomainIdInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory DocumentDomainID for Zhanjiang Verbund Site Project"])},
  "docIdDocDomainIdInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID or indicator for a domain; Names of organizations can for example be used."])},
  "docIdDocIdInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Document File-Naming Syntax regarding Specification 'BIG_00-0000-000-PM-010-010-R34-Site and Plant Structure and Eng Doc Codes'"])},
  "docIdDocIdInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification number assigned to a document"])},
  "headerClassData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintain the Classification Data"])},
  "ClassificationSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification System"])},
  "classSysHeaderInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintain the Classification Data"])},
  "classSysHeaderInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user can specify any number of classification systems. However, the classification systems 'BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types' and 'VDI 2770:2018' must be completed."])},
  "classSysGeneralHeaderInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user can specify any number of classification systems. However, the classification system 'VDI 2770:2018' shall be completed."])},
  "classSysInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification System"])},
  "classSysInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The respective classification system is selected here."])},
  "classIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class Id"])},
  "classIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document category associated with the document according to the selected classification system is to be indicated here. In some cases, the document could be refer to several document categories."])},
  "classNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ClassName"])},
  "classNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ClassName is automatically set based on the selected ClassID, if a predefined class system was choosen."])},
  "classLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ClassLanguage"])},
  "classLangInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The language of the ClassName value. At least the language English must be specified here."])},
  "headerRefObjId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata 'Object Id' for Zhanjiang VS:"])},
  "headerGeneralRefObjId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata 'Object Id':"])},
  "toogleHeaderMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory for Zhanjiang Verbund Site: (Click to Toggle)"])},
  "toogleHeaderOptional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If data are available for Zhanjiang Verbund Site: (Click to Toggle)"])},
  "additionalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional ObjectIDs "])},
  "additionalDataBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further ObjectIDs assigned to the object by the manufacturer could be entered here."])},
  "objIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Id"])},
  "objIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of usage 'ObjectId'"])},
  "objIdRefTypeInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RefType"])},
  "objIdRefTypeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of usage 'RefType'"])},
  "objIdRefTypeInfoTitle0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf:ProjectId"])},
  "objIdRefTypeInfoBody0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The BASF project number is an identifying number and identifier of a project in the BASF IT systems."])},
  "objIdRefTypeInfoTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf:purchase order number"])},
  "objIdRefTypeInfoBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The purchase order number of BASF is an identifying number and identifiers for the order of one or more objects, which is used in IT systems of BASF and is given to the manufacturer when the purchased order is placed."])},
  "objIdRefTypeInfoTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product type"])},
  "objIdRefTypeInfoBody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product designation (model ID, series, product type) can be used as a component in one or more assembly units. The product designation is unique within a manufacturer."])},
  "objIdRefTypeInfoTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instance of object uri (IEC 61406 ID)"])},
  "objIdRefTypeInfoBody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globally unique identification of the object according to IEC 61406 (predecessor standard: DIN SPEC 91406) by the OEM."])},
  "objIdRefTypeInfoTitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECLASS number"])},
  "objIdRefTypeInfoBody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECLASS is a data standard for the classification of products."])},
  "objIdRefTypeInfoTitle5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf: engineering tag number"])},
  "objIdRefTypeInfoBody5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The BASF engineering tag number (Apparatus number) is an identifying code for the installation location in the production plant that is transmitted by the operator to the manufacturer in most cases when the purchase order is placed."])},
  "objIdRefTypeInfoTitle6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf: equipment number"])},
  "objIdRefTypeInfoBody6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The BASF Equipment Number is a unique identifier of a component in the production plant. The BASF Equipment Number must be transmitted by the operator to the manufacturer."])},
  "objIdRefTypeInfoTitle7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basf: ReferencedDesignation"])},
  "objIdRefTypeInfoBody7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reference designation is an identifying number and identifiers for an object (BASF functional location). The BASF functional location must be given by the operator to the manufacturer."])},
  "objIdRefTypeInfoTitle8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serial number"])},
  "objIdRefTypeInfoBody8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The unique identification number from the manufacturer for an individual object. Within a product designation (model, series, product type) from a manufacturer, the serial number is unique."])},
  "objIdRefTypeInfoTitle9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vendor sales order conformation number"])},
  "objIdRefTypeInfoBody9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A unique sales order number which created by vendor's ERP system after PO awarded."])},
  "objIdObjectTypeInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["object type:*"])},
  "objIdObjectTypeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of usage 'Object Type'"])},
  "objIdBiuniqueInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is globally biunique"])},
  "objIdBiuniqueInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of usage 'is globally biunique'"])},
  "headerOptMetaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional additional Object-Metadata"])},
  "objIdOptTypeInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "objIdOptTypeInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These references can be used for internal references in the IT systems of the manufacturer or supplier."])},
  "objIdOptValueInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "objIdOptValueInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The corresponding value to the selected reference."])},
  "headerRefObjParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata Party"])},
  "headerRefObjPartyBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The organisational metadata from step 2 is inserted here by default. The data can be adjusted manually if necessary."])},
  "refObjPartyOrgIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation Website"])},
  "refObjPartyOrgIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique ID for the organization. For example the official website of the company."])},
  "refObjPartyRoleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "refObjPartyRoleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can choose between the following roles according to guideline VDI 2770:2018:  Manufacturer, Supplier"])},
  "refObjPartyOrgNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Name"])},
  "refObjPartyOrgNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The common name of the company (short form without the legal form). Example: Company ABC"])},
  "refObjPartyOrgOffNInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Official Name"])},
  "refObjPartyOrgOffNInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The official name of the company (long form with the legal form) that creates the documentation and delivers it to the customer. Example: Company ABC GmbH & Co. KG"])},
  "headerRefObjDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of Referenced Object"])},
  "refObjgDescDescInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "refObjDescDescInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multilingual description of the ReferencedObject. There should be only one description of the ReferencedObject per language."])},
  "refObjgDescLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "refObjDescLangInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indication of the language of the description of ReferencedObject."])},
  "headerDocVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata Document Version"])},
  "docVersionDocVerIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Version Id"])},
  "docVersionDocVerIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current version of the document."])},
  "docVersionNumPagesInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Pages"])},
  "docVersionNumPagesInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of pages in the document in relation to the PDF/A file format."])},
  "docVersionLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "docVersionLangInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of languages used in the document."])},
  "docVersionPartyHeadInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Version Party"])},
  "docVersionPartyHeadInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible Party for the document version."])},
  "docVersionOrgIdInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Website"])},
  "docVersionOrgIdInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique ID for the organization. For example the official website of the company."])},
  "docVersionOrgNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Name"])},
  "docVersionOrgNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The common name of the company (short form without the legal form). Example: Company ABC"])},
  "docVersionRoleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "docVersionRoleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least the role 'Author' must be set here."])},
  "docVersionOrgOffNameInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Official Name"])},
  "docVersionOrgOffNameInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The official name of the company (long form with the legal form) that creates the documentation and delivers it to the customer. Example: Company ABC GmbH & Co. KG"])},
  "headerDocDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata Document Description"])},
  "docVersionDocTitleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Title"])},
  "docVersionDocTitleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title of the selected document."])},
  "docVersionDocSubTitleInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtitle"])},
  "docVersionDocSubTitleInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtitle of the document (optional field)"])},
  "docVersionDocSummaryInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
  "docVersionDocSummaryInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A DocumentDescription must be specified for each language used in the document version. It is possible, for example, to additionally specify an English description for a German document version, although the document content is not multilingual."])},
  "docVersionDocKeyWordInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
  "docVersionDocKeyWordInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords from the selected document. For example, the keywords 'inspection', 'maintenance', 'repair' can be set for a maintenance manual."])},
  "docVersionDocLangInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "headerDocLifeCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Life Cycle Party"])},
  "docVersionDocCommentInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "docVersionDocCommentInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of multilingual comments and notes on the status."])},
  "docVersionDocDateInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "docVersionDocDateInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date on which the status was defined."])},
  "docVersionDocStatusInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "docVersionDocStatusInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the status value of the document in the document life cycle. Two status values can be distinguished: InReview and Released."])},
  "buttonDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download Documentation"])},
  "buttonNextFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next File"])},
  "routeDocVerParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Version Party"])},
  "routeDocDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Description"])},
  "routeDocLifeCycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Life Cycle Status"])},
  "faultMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill all mandatory fields"])},
  "faultDocumentationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing documentation name"])},
  "faultInvalidCharDoc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The documentation name contains invalid characters starting at pos ", _interpolate(_list(0))])},
  "faultDocNameToLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The documentation name must not exceed 150 characters."])},
  "faultMissingPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add at least one PDF"])},
  "faultNoFileSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select a file"])},
  "faultBigSpec": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The document Id must be structured according to the BIG specification. Fault after: ", _interpolate(_list(0))])},
  "faultMissingDocId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing document-id"])},
  "faultDomain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The ", _interpolate(_list(0)), " must be a valid domain."])},
  "faultPath": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The ", _interpolate(_list(0)), " must contain a path."])},
  "faultDomainUpper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The domain part must be lowercase."])},
  "faultPunyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The url must not contain punycode."])},
  "faultUrlLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The url must not exceed 255 characters."])},
  "faultInvalidCharURL": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The url contains invalid characters starting at pos ", _interpolate(_list(0))])},
  "faultPoNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase order number may only contain numbers"])},
  "faultEclassNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eclass may only contain numbers"])},
  "faultEclassLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eclass must have exactly 8 characters"])},
  "faultIndividual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one of Type 'indivdual' is required"])},
  "faultNoIndividual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type 'indivdual' is not allowed in the document container."])},
  "faultAtLeastOneEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one field 'Object Id' is required"])},
  "faultNotReadablePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF is not readable"])},
  "unspecificError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Error has occured, please check your inputs."])},
  "doIdTPU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DocumentId Explanation TPU"])},
  "doIdNonTPU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DocumentID Explanation"])},
  "generalDocu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a General Documentation <br>  (Not Zhanjiang)"])},
  "zhanjiangDocu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation <br> Zhanjiang Verbundsite"])},
  "wantToLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All inputs will be lost. Do you really want to leave?"])},
  "VDI2770Container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDI2770 Container"])},
  "faultNotVDI2770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No valid VDI2770 container. Please select a valid container."])},
  "inclNativeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native (xlsx, docx ...) files required?"])},
  "inclNativeFileInfoBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don't need native files, you can drag your PDFs all at once into the upload field. With native files it is unfortunately not possible, because then the assignment between PDF and native files can not be made."])},
  "VDI2270Container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDI-2770 conform containers"])},
  "PDFFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF-Files"])},
  "dropContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop VDI2770 Container(s) here"])},
  "dropPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop PDF-file(s) here"])},
  "cantReadZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't read file. Are you sure this was a zip file?"])},
  "fileInPreparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete not possible, file already in an processing step."])},
  "filesNotInEditMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Upload is not in edit mode."])},
  "faultNotPdfType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File is not of type PDF."])},
  "faultInvalidClassId": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The ClassID in your document id seems to be invalid, please check and change ClassId ", _interpolate(_list(0))])},
  "BIGClassSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open BIG-Classificationsystem"])},
  "Edit_Mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Edit Mode)"])},
  "delete_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete your template"])},
  "reallyDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do you really want to delete this template?"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "faultIsPrimary0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exactly one DocumentDomainId must have 'isPrimary' specified."])},
  "faultIsPrimary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exactly one DocumentDomainId must have 'isPrimary' specified. Please remove one."])},
  "numPdfFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of PDF files: "])},
  "pdfpart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage of PDFs in total files / Percentage of XML and native files "])},
  "Metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata"])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages: "])},
  "pdfFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF files"])},
  "BacktoCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Creator"])},
  "BacktoViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to file selection"])},
  "headerFileViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on a link to examine the container contents"])},
  "BASFVDI2770Creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BASF VDI 2770 Creator and Viewer"])},
  "VDI2770Viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDI2770 Viewer"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
  "Expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand all nodes"])},
  "Collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse all nodes"])},
  "attentionRAMsize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note: The VDI container size that can be processed depends on your available RAM."])},
  "Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size: "])},
  "HidePDFlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Metadata"])},
  "ShowPDFlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show PDF list"])},
  "fillAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There must be a value for Description and Language or both must be empty"])},
  "filterResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show complete Filter Result"])},
  "include1ZipLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["include first zip-level"])},
  "expertMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert Mode, choose a XML-file link in the tree view"])},
  "noExpertMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailview"])},
  "filterMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for Metadata"])},
  "FullXMLRep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete XML representation"])},
  "clickButtonToExpand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click on the buttons to expand all children"])},
  "clickLineToExpand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click in the line to expand only one line-item"])},
  "ExpandAllChilds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand all childs"])},
  "excelUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating documentation in bulk with Excel upload"])},
  "excelUploadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start bulk creation"])},
  "bulkUploadZVS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk upload VDI2770 creation for ZVS project"])},
  "bulkUploadGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk upload common VDI2770 creation"])},
  "subHeaderBulkCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use Excel upload?"])},
  "descriptionBulkCreation_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Excel bulk upload job allows you to easily create multiple VDI 2770 compliant documentation containers based on the information entered in an Excel template."])},
  "excelTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donwload empty Excel-upload-template"])},
  "headerTemplateUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your filled Excel template here:"])},
  "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(File missing)"])},
  "checkExcelInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check content of excelsheet"])},
  "createContainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create VDI2770 containers"])},
  "downloadPreFilledExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Excel template with prefilled file names"])},
  "descriptionBulkCreation_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A separate VDI 2770 documentation container is created for each BASF Engineering Tag entered in the Excel template. The documentation container is named using the BASF Engineering Tag entered."])},
  "descriptionBulkCreation_Step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1: "])},
  "descriptionBulkCreation_Step_1_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop all files into the PDF upload field."])},
  "descriptionBulkCreation_Step_2_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the 'Download Excel template with prefilled file names' button to download the Excel template prefilled with the file names."])},
  "descriptionBulkCreation_Step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2: "])},
  "descriptionBulkCreation_Step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3: "])},
  "descriptionBulkCreation_Step_3_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filling out the Excel template. The column headers marked in green show the mandatory entries, the other columns are optional entries."])},
  "descriptionBulkCreation_Step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4: "])},
  "descriptionBulkCreation_Step_4_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload the completed Excel template."])},
  "descriptionBulkCreation_hint_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note:  - The Excel template cannot be changed, so no additional columns can be added or columns deleted."])},
  "descriptionBulkCreation_hint_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note:  - The website can also be closed while the Excel template is being filled out. The completed Excel template can be uploaded when the page is reopened. The files listed in the Excel template will then be listed in the PDF upload field."])},
  "excelBulkCommentIPclass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection classification of the document by the manufacturer. Possible entries: public, internal, confidential."])},
  "excelBulkCommentLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of the languages used in the document according to ISO 639-1 (language codes). For multilingual documents, the language codes are separated by a comma."])}
}