import createXML from "./createXML";
// import "jspdf-autotable";
import JSZip from "jszip";
import store from "../store/index";

export default {
  generateDoc() {
    var index = store.getters["Files/getFileArray"].findIndex(
      (file) => file.finished === "FILE_SELECTED"
    );
    //  create MetadataXML
    let xmlBlob = this.generateMetadataXMLBlob(index);
    // save MetadataXML for zip
    this.addBlobToStore(xmlBlob, index);
    //   save DocIds for MainXML
    let docIdArray = store.getters["DocId/getDocumentIdType"];
    for (let i = 0; i < docIdArray.length; i++) {
      store.dispatch("addMainXmlDocIdItem", this.getDocId(i));
    }
    //   save DocInformation for MainPDF
    store.dispatch("addMainPdfDataArray", this.getMainPdfData());

    //   everything done, set File as FINISHED
    this.setFileFinished(index);
    // enable next File selection
    store.dispatch("setFileSelectionState", false);
    // set Variable for selecting Views in next round
    store.dispatch("setNextRound", true);
    // clear all Data, which are not used in the next run
    this.clearStore();
    this.resetActivation();
  },

  generateMetadataXMLBlob(index) {
    const content = createXML.createVdiMetadata(index);
    const blob = new Blob([content], { type: "text/xml" });
    return blob;
  },

  addBlobToStore(blob, index) {
    let payload = {
      index: index,
      xmlBlob: blob,
    };
    store.dispatch("Files/setXmlBlob", payload);
  },
  getDocId(i) {
    // @ToDo change DocId and all other occurences
    let domainIdFunc = store.getters["DocId/getDomainId"];
    let docIdFunc = store.getters["DocId/getDocumentId"];
    let payload = {
      DomainId: domainIdFunc(i),
      DocumentId: docIdFunc(i),
      DocumentVersionId: store.getters["DocVer/getDocVersionId4"],
    };
    return payload;
  },

  setFileFinished(index) {
    let payload = {
      index: index,
      status: "FINISHED",
    };
    store.dispatch("Files/setFileStatus", payload);
  },
  clearStore() {
    var classSystem = "BIG_00-0000-000-PM-010-010 Annex 3 VD Doc Types";
    if (store.getters["getIsTpuDocumentation"] == true) {
      classSystem = "Documentation Checklist CIM Database structure";
    }
    if (store.getters["getIsGeneralDocu"] == true) {
      classSystem = "VDI2770:2018";
    }
    store.dispatch("Classification/clearStore", classSystem);
    store.dispatch("DocVer/setNumPages4", undefined);
    store.dispatch("DocVer/setDocVersionId4", undefined);
    store.dispatch("DocVer/clearPartyType4Language");
    store.dispatch("DocVer/clearDocumentDescription_model");
    store.dispatch("DocVer/setLifeCycleStatusValue", "Released");
    store.dispatch("DocVer/setLifeCycleSetDate", undefined);
    store.dispatch("DocVer/clearCommentsType_model");
    // @ToDo clear DocIds
    let docIdArray = store.getters["DocId/getDocumentIdType"];
    for (let i = 0; i < docIdArray.length; i++) {
      let payload = {
        index: i,
        DocumentId: undefined,
      };
      store.dispatch("DocId/setDocumentId", payload);
    }
    // store.dispatch("DocId/setDocumentId", { index: 0, DocumentId: undefined });
    // store.dispatch("DocId/setDocumentId", { index: 1, DocumentId: undefined });
  },
  resetActivation() {
    store.dispatch("ActSteps/setOrgData", false);
    store.dispatch("ActSteps/setOrgMetaData", false);
    store.dispatch("ActSteps/setOrgDocDomain", false);
    store.dispatch("ActSteps/setClassification", false);
    store.dispatch("ActSteps/setRefObject", false);
    store.dispatch("ActSteps/setRefObjObjId", false);
    store.dispatch("ActSteps/setRefObjOptData", false);
    store.dispatch("ActSteps/setRefObjDesc", false);
    store.dispatch("ActSteps/setDocVersion", false);
    store.dispatch("ActSteps/setDocVersionParty", false);
    store.dispatch("ActSteps/setDocVersionDocDesc", false);
    store.dispatch("ActSteps/setDocVersionLifeCycle", false);
    store.dispatch("ActSteps/setRefObjParty", false);
  },
  async createDocumentContainer(index) {
    let pdfFile = store.getters["Files/getPdfFile"];
    let nativeFile = store.getters["Files/getNativeFiles"];
    let xmlBlob = store.getters["Files/getXmlBlob"];
    let nativeFileArray = nativeFile(index);
    const zip = new JSZip();
    for (const file of nativeFileArray) {
      const content = await file.arrayBuffer();
      zip.file(file.name, content);
    }
    const pdfContent = await pdfFile(index).arrayBuffer();
    zip.file(pdfFile(index).name, pdfContent);
    zip.file("VDI2770_Metadata.xml", xmlBlob(index));
    const content = await zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 5,
      },
    });
    // this.getZipName(pdfFile(index).name);
    return [content, this.getZipName(pdfFile(index).name)];
  },

  getZipName(fileName) {
    let zipName = fileName.split(".pdf");
    return zipName[0] + ".zip";
  },
  async zipZip(zipArray, XMLfile, PDFFile) {
    const zip = new JSZip();
    for (let i = 0; i < zipArray.length; i++) {
      zip.file(zipArray[i][1], zipArray[i][0]);
    }
    zip.file("VDI2770_Main.xml", XMLfile);
    zip.file("VDI2770_Main.pdf", PDFFile);
    const content = await zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 5,
      },
    });
    return content;
  },

  getMainPdfData() {
    let getClassId =
      store.getters["Classification/getDocumentClassificationClassId"];
    let getLang = store.getters["DocVer/getDocumentDescriptionLanguage"];
    let getTitle = store.getters["DocVer/getDocumentDescriptionTitle"];
    let docId = store.getters["DocId/getDocumentId"];
    let data = {
      ClassId: getClassId(0),
      DocumentId: docId(0),
      DocVer: store.getters["DocVer/getDocVersionId4"],
      lang: getLang(0),
      title: getTitle(0),
    };
    return data;
  },
};
