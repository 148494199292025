<template>
  <div id="zip-viewer">
    <!-- Modal -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header bg-dark text-white">
            <div class="modal-title d-inline" id="staticBackdropLabel">
              <img
                src="../../assets/BASFo_wh100db_3c.png"
                width="140"
                height="70"
                class="align-middle"
                alt="BASF Logo"
              />
              <div id="modalInjectedTitle" class="d-inline pt-2"></div>
            </div>

            <button
              type="button"
              class="btn-close bg-white"
              data-dismiss="modal"
              aria-label="Close"
              @click="resetModalContent"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row my-3 text-primary">
                <h6 class="text-center">{{ $t("attentionRAMsize") }}</h6>
              </div>
              <div class="row justify-content-md-center mb-3">
                <div class="col-3">
                  <button
                    class="btn btn-light rounded border"
                    @click.prevent="togglePDFFunction"
                  >
                    {{
                      getShowListOfPdfs == true
                        ? $t("HidePDFlist")
                        : $t("ShowPDFlist")
                    }}
                  </button>
                </div>
                <div class="col-6">
                  <!-- Start Filter @FreeVersion -->
                  <div class="input-group mb-3">
                    <input
                      class="form-control border rounded ms-3"
                      id="filter-input"
                      v-model="filterCriteria"
                      placeholder="Filter..."
                      @keyup="getFilterFeedback"
                    />
                    <div class="row ms-3">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="bi bi-funnel-fill"></i>
                          <label class="form-check-label" for="filter-input">
                            {{ $t("filterMetadata") }}
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- End Filter -->
                </div>
              </div>
              <div class="row word-break">
                <div
                  class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-4 ms-auto border border-primary border-2 rounded mb-3"
                >
                  <div class="d-flex flex-row justify-content-xl-around mt-3">
                    <div class="p-2">
                      <h6 class="d-inline py-2">
                        {{ $t("VDI2770Container") }}
                      </h6>
                    </div>
                    <div class="p-2">
                      <div class="d-inline py-2">
                        {{ $t("numPdfFiles") }} {{ showPdfList()[1] }}
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    {{ $t("pdfpart") }}
                  </div>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="{
                        width:
                          Math.round(
                            (showPdfList()[1] / getViewerFilesArray.length) *
                              100
                          ) + '%',
                      }"
                    >
                      {{
                        Math.round(
                          (showPdfList()[1] / getViewerFilesArray.length) * 100
                        )
                      }}
                      &#037;
                    </div>
                    <!-- @Free Version New Version -->
                    <div
                      class="progress-bar bg-info"
                      role="progressbar"
                      :style="{
                        width:
                          Math.round(
                            100 -
                              (showPdfList()[1] / getViewerFilesArray.length) *
                                100
                          ) + '%',
                      }"
                    >
                      {{
                        Math.round(
                          100 -
                            (showPdfList()[1] / getViewerFilesArray.length) *
                              100
                        )
                      }}
                      &#037;
                    </div>
                  </div>
                  <div class="row justify-content-md-start mb-2 mt-2">
                    <div class="col-6">
                      <button
                        class="btn btn-sm btn-light rounded border"
                        @click.prevent="toogleTreeExpand"
                      >
                        {{ expanded == false ? $t("Expand") : $t("Collapse") }}
                      </button>
                    </div>
                    <div class="col-3"></div>
                  </div>
                  <div class="row mb-3 mt-3" id="zipContent"></div>
                </div>
                <!-- PDF files div -->
                <div
                  class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7 ms-auto border border-primary border-2 rounded mb-3"
                >
                  <div v-if="getShowListOfPdfs">
                    <h6 class="py-3">{{ $t("pdfFiles") }}</h6>
                    <div class="row mb-2 ps-3">
                      <ol class="ps-4">
                        <li
                          v-for="(files, index) in showPdfList()[0]"
                          :key="index"
                        >
                          <div
                            class="row pdflistrow"
                            :id="files.uniqueId + 'metaId'"
                            role="pdflistitem"
                          >
                            <div class="col-10 word-break">
                              <a
                                :href="createUrlByFile(files.loadedFile)"
                                class="link-dark pdf"
                                target="_blank"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Click to show PDF in new tab"
                                @click="addClickStyle($event)"
                                >{{ files.loadedFile.name }}
                              </a>
                            </div>
                            <div class="col-2">
                              {{ $t("Size") }}
                              {{ (files.loadedFile.size / 1e6).toFixed(3) }} Mb
                            </div>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <!-- Start Metadata -->
                  <div v-else id="MetadataTable">
                    <h6 class="py-3">{{ $t("Metadata") }}</h6>

                    <table class="table table-sm">
                      <!-- getDetailViewDocumentId -->
                      <thead>
                        <tr>
                          <th scope="col" colspan="4">Document Id</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(docId, index) in getDetailViewDocumentId(
                          getXmlFileBlob()
                        )"
                        :key="index"
                      >
                        <tr>
                          <!-- <td>Document Id:</td> -->
                          <td colspan="4">
                            <span class="text-primary"> {{ docId }}</span>
                          </td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th scope="col" class="pt-3" colspan="4">Party</th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th scope="col" class="bg-light fw-little-bold">
                            Id (Website):
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            Name:
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            Official name:
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            Type:
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(party, index) in getDetailViewParties(
                          getXmlFileBlob()
                        )"
                        :key="index"
                      >
                        <tr>
                          <td>
                            <!-- Id (Website): -->
                            <span class="text-primary"> {{ party[1] }}</span>
                          </td>
                          <td>
                            <!-- Name: -->
                            <span class="text-primary"> {{ party[2] }}</span>
                          </td>
                          <td>
                            <!-- Official name: -->
                            <span class="text-primary"> {{ party[3] }}</span>
                          </td>
                          <td>
                            <!-- Type: -->
                            <span class="text-primary"> {{ party[0] }}</span>
                          </td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th scope="col" class="pt-3" colspan="4">
                            Classification
                          </th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th scope="col" class="bg-light fw-little-bold">
                            System:
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            Class ID:
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            Class name:
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            Class language:
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(classes, index) in getDetailViewClassSystem(
                          getXmlFileBlob()
                        )"
                        :key="index"
                      >
                        <tr>
                          <td>
                            <!-- System: -->
                            <span class="text-primary"> {{ classes[0] }}</span>
                          </td>
                          <td>
                            <!-- Class ID: -->
                            <span class="text-primary"> {{ classes[1] }}</span>
                          </td>
                          <td>
                            <tr v-for="(lang, ind) in classes[2]" :key="ind">
                              <td>
                                <!-- Class name: -->
                                <span class="text-primary"> {{ lang[0] }}</span>
                              </td>
                            </tr>
                          </td>

                          <td>
                            <tr
                              v-for="(lang, ind) in classes[2]"
                              :key="ind"
                              class="align-bottom"
                            >
                              <td>
                                <!-- language: -->
                                <span class="text-primary"> {{ lang[1] }}</span>
                              </td>
                            </tr>
                          </td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th scope="col" class="pt-3" colspan="4">
                            Referenced Object
                          </th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th scope="col" class="bg-light fw-little-bold">
                            Type:
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            Value:
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            Object Type:
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            global bi-unique:
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(refObj, index) in getDetailViewRefObj(
                          getXmlFileBlob()
                        )"
                        :key="index"
                      >
                        <tr>
                          <td>
                            <!-- Typ: -->
                            <span class="text-primary"> {{ refObj[0] }}</span>
                          </td>
                          <td>
                            <!-- Value: -->
                            <span class="text-primary"> {{ refObj[1] }}</span>
                          </td>
                          <td>
                            <!-- Object Type: -->
                            <span class="text-primary"> {{ refObj[2] }}</span>
                          </td>
                          <td>
                            <!-- global bi-unique: -->
                            <span class="text-primary"> {{ refObj[3] }}</span>
                          </td>
                        </tr>
                      </tbody>
                      <thead
                        v-if="
                          getDetailViewRefObjDefault(getXmlFileBlob()).length >
                          0
                        "
                      >
                        <tr>
                          <th colspan="4" class="pt-3">
                            Referenced Object Default Datatypes
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(
                          refObjDefault, index
                        ) in getDetailViewRefObjDefault(getXmlFileBlob())"
                        :key="index"
                      >
                        <tr>
                          <td colspan="1" class="fw-little-bold">
                            {{ refObjDefault[0] }}:
                          </td>
                          <td colspan="3">
                            <span class="text-primary">
                              {{ refObjDefault[1] }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th scope="col" class="pt-3" colspan="4">
                            Description
                          </th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th scope="col" class="bg-light fw-little-bold">
                            Title:
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            Summary:
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            Keyword:
                          </th>
                          <th scope="col" class="bg-light fw-little-bold">
                            language:
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(desc, index) in getDetailViewDescription(
                          getXmlFileBlob()
                        )"
                        :key="index"
                      >
                        <tr>
                          <td>
                            <!-- Title: -->
                            <span class="text-primary"> {{ desc[0] }}</span>
                          </td>
                          <td>
                            <!-- Summary: -->
                            <span class="text-primary"> {{ desc[1] }}</span>
                          </td>
                          <td>
                            <tr
                              v-for="(keyword, index) in desc[3]"
                              :key="index"
                            >
                              <td>
                                <!-- Keyword: -->
                                <span class="text-primary"> {{ keyword }}</span>
                              </td>
                            </tr>
                          </td>
                          <td>
                            <!-- language: -->
                            <span class="text-primary"> {{ desc[2] }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- End Metadata -->
                </div>
                <!-- End PDF files div -->
                <div
                  class="col align-self-end col-xxl-7 ms-auto border border-secondary border-2 rounded pt-2 pb-3"
                >
                  <div class="p-2">
                    <h6 class="d-inline py-2">
                      {{ $t("expertMode") }}
                    </h6>
                    <p class="small">
                      <em>
                        {{ $t("clickButtonToExpand") }}
                        <br />{{ $t("clickLineToExpand") }}
                      </em>
                    </p>
                  </div>
                  <div id="output">{{ renderJsonXML(getXmlFileBlob()) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="resetModalContent"
            >
              {{
                $route.path == "/vdi2770viewer/files"
                  ? $t("BacktoViewer")
                  : $t("BacktoCreator")
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import visualizer from "../utils/visualizer";
export default {
  name: "ZipViewerCom",
  // @FreeVersion New
  props: ["fr"],
  data() {
    return {
      expanded: false,
      filterCriteria: "",
    };
  },
  components: {},
  computed: {
    ...mapGetters("ViewerStore", [
      "getFileByUniqueID",
      "getXmlBlobByUniqueID",
      "getViewerFileObjByUniqueID",
      "getViewerFileObjsByType",
      "getViewerFilesArray",
      "getShowListOfPdfs",
      "getSelectedFileId",
      "getExpertMode",
    ]),
  },
  methods: {
    resetModalContent() {
      this.expanded = false;
      this.$store.dispatch("ViewerStore/setSelectedFileId", undefined);
      this.$store.dispatch("ViewerStore/deleteViewerFiles");
      // @FreeVersion
      this.$store.dispatch("ViewerStore/setShowListOfPdfsState", true);
      this.filterCriteria = "";
      this.fr.abort();
    },
    toogleTreeExpand() {
      this.expanded = !this.expanded;
      let allExpandNodes = document.getElementsByClassName("expandable");
      for (let i = 0; i < allExpandNodes.length; i++) {
        allExpandNodes[i].setAttribute("aria-expanded", this.expanded);
      }
    },

    createUrlByFile(file) {
      if (file) {
        return URL.createObjectURL(file);
      } else return "#";
    },
    viewerAlert() {
      alert("ViewerAlert");
    },
    showPdfList() {
      let pdfArray = [];
      let numberOfFiles = 0;
      this.getViewerFilesArray.forEach((file) => {
        if (file.loadedFile != undefined) {
          if (
            file.loadedFile.name != "VDI2770_Main.pdf" &&
            file.fileType == "application/pdf"
          ) {
            pdfArray.push(file);
            numberOfFiles += 1;
          }
        }
      });
      return [pdfArray, numberOfFiles];
    },
    // @FreeVersion
    getFilterFeedback() {
      this.removeClass("metadata-search");
      if (
        this.getFilterCriteria() != "" &&
        this.getViewerFileObjsByType("application/xml")
      ) {
        if (!this.expanded) {
          this.toogleTreeExpand();
        }
        for (let i = 0; i < this.getViewerFilesArray.length; i++) {
          if (
            this.getViewerFilesArray[i].fileType == "application/xml" &&
            JSON.stringify(this.getViewerFilesArray[i].xmlBlobData)
              .toLowerCase()
              .includes(this.getFilterCriteria())
          ) {
            let eleToColor = document.getElementById(
              this.getViewerFilesArray[i].uniqueId
            );

            eleToColor.classList.add("metadata-search");
            let loopArray = $(eleToColor).parent().siblings();
            for (let j = 0; j < loopArray.length; j++) {
              try {
                let siblingId = loopArray[j].children[0].id;
                $("#" + siblingId + "metaId")
                  .parent()
                  .addClass("metadata-search");
              } catch (error) {}
            }
          }
        }
      }
    },

    removeClass(class_to_remove) {
      let classElements = document.querySelectorAll("." + class_to_remove);
      for (var i = 0; i < classElements.length; i++) {
        classElements[i].classList.remove(class_to_remove);
      }
    },
    // @FreeVersion 2
    addClickStyle(e) {
      e.target.setAttribute("class", "link-clicked");
      for (const a of document.querySelectorAll("a")) {
        if (a.textContent.includes(e.target.innerText)) {
          a.setAttribute("class", "link-clicked");
        }
      }
    },
    togglePDFFunction() {
      this.toggleShowListOfPdfsState();
      this.$nextTick(function () {
        for (const a of document.getElementsByClassName("link-dark pdf")) {
          for (const lc of document.getElementsByClassName("link-clicked")) {
            if (
              a.textContent.includes(lc.innerText) &&
              !lc.innerText.endsWith(".xml")
            ) {
              a.setAttribute("class", "link-clicked");
            }
          }
        }
      });
    },
    // @FreeVersion
    getFilterCriteria() {
      return String(this.filterCriteria.trim()).toLowerCase();
    },
    // @FreeVersion
    getDetailViewDescription(xmlBlob) {
      let descriptions = [];
      let language = undefined;
      let summary = undefined;
      let title = undefined;
      let keyword = undefined;
      let keywords = [];
      try {
        for (
          let i = 0;
          i < xmlBlob.Document[0].DocumentVersion[0].DocumentDescription.length;
          i++
        ) {
          try {
            language =
              xmlBlob.Document[0].DocumentVersion[0].DocumentDescription[i][
                "@Language"
              ];
            summary =
              xmlBlob.Document[0].DocumentVersion[0].DocumentDescription[i]
                .Summary[0];
            title =
              xmlBlob.Document[0].DocumentVersion[0].DocumentDescription[i]
                .Title[0];
            keywords = [];
            for (
              let j = 0;
              j <
              xmlBlob.Document[0].DocumentVersion[0].DocumentDescription[i]
                .KeyWords[0].KeyWord.length;
              j++
            ) {
              keyword =
                xmlBlob.Document[0].DocumentVersion[0].DocumentDescription[i]
                  .KeyWords[0].KeyWord[j];
              keywords.push(keyword);
            }
            descriptions.push([title, summary, language, keywords]);
          } catch (error) {}
        }
        return descriptions;
      } catch (error) {
        descriptions.push(["---", "---", "---", ["---"]]);
        return descriptions;
      }
    },
    // @FreeVersion
    getDetailViewRefObjDefault(xmlBlob) {
      let defaultRefObj = [];
      try {
        for (
          let i = 0;
          i < xmlBlob.Document[0].ReferencedObject[0].ProjectId.length;
          i++
        ) {
          let projectId = xmlBlob.Document[0].ReferencedObject[0].ProjectId[i];
          defaultRefObj.push(["ProjectId", projectId]);
        }
      } catch (error) {}
      try {
        for (
          let i = 0;
          i < xmlBlob.Document[0].ReferencedObject[0].EquipmentId.length;
          i++
        ) {
          let equiId = xmlBlob.Document[0].ReferencedObject[0].EquipmentId[i];
          defaultRefObj.push(["EquipmentId", equiId]);
        }
      } catch (error) {}
      try {
        for (
          let i = 0;
          i <
          xmlBlob.Document[0].ReferencedObject[0].ReferenceDesignation.length;
          i++
        ) {
          let refDesignation =
            xmlBlob.Document[0].ReferencedObject[0].ReferenceDesignation[i];
          defaultRefObj.push(["ReferenceDesignation", refDesignation]);
        }
      } catch (error) {}
      return defaultRefObj;
    },
    // @FreeVersion
    getDetailViewRefObj(xmlBlob) {
      let refobjects = [];
      try {
        for (
          let i = 0;
          i < xmlBlob.Document[0].ReferencedObject[0].ObjectId.length;
          i++
        ) {
          let refType =
            xmlBlob.Document[0].ReferencedObject[0].ObjectId[i]["@RefType"];
          let refValue =
            xmlBlob.Document[0].ReferencedObject[0].ObjectId[i]["#"];
          let refTypeType =
            xmlBlob.Document[0].ReferencedObject[0].ObjectId[i]["@ObjectType"];
          let refGbiUnique =
            xmlBlob.Document[0].ReferencedObject[0].ObjectId[i][
              "@IsGloballyBiUnique"
            ];
          refobjects.push([refType, refValue, refTypeType, refGbiUnique]);
        }
        return refobjects;
      } catch (error) {
        refobjects.push(["---", "---", "---", "---"]);
        return refobjects;
      }
    },
    // @FreeVersion
    getDetailViewDocumentId(xmlBlob) {
      let docIds = [];
      let docId = undefined;
      try {
        for (let i = 0; i < xmlBlob.Document[0].DocumentId.length; i++) {
          docId = xmlBlob.Document[0].DocumentId[i]["#"];
          docIds.push(docId);
        }
        return docIds;
      } catch (error) {
        docIds.push("---");
        return docIds;
      }
    },
    // @FreeVersion
    getDetailViewParties(xmlBlob) {
      let parties = [];
      try {
        for (let i = 0; i < xmlBlob.Document[0].DocumentIdDomain.length; i++) {
          let responsible =
            xmlBlob.Document[0].DocumentIdDomain[i].Party[0]["@Role"];
          let orgID =
            xmlBlob.Document[0].DocumentIdDomain[i].Party[0].Organization[0][
              "@OrganizationId"
            ];
          let orgName =
            xmlBlob.Document[0].DocumentIdDomain[i].Party[0].Organization[0][
              "@OrganizationName"
            ];
          let officialName =
            xmlBlob.Document[0].DocumentIdDomain[i].Party[0].Organization[0][
              "@OrganizationOfficialName"
            ];
          parties.push([responsible, orgID, orgName, officialName]);
          // }
        }
        for (
          let i = 0;
          i < xmlBlob.Document[0].ReferencedObject[0].Party.length;
          i++
        ) {
          let responsible =
            xmlBlob.Document[0].ReferencedObject[0].Party[i]["@Role"];
          let orgID =
            xmlBlob.Document[0].ReferencedObject[0].Party[i].Organization[0][
              "@OrganizationId"
            ];
          let orgName =
            xmlBlob.Document[0].ReferencedObject[0].Party[i].Organization[0][
              "@OrganizationName"
            ];
          let officialName =
            xmlBlob.Document[0].ReferencedObject[0].Party[i].Organization[0][
              "@OrganizationOfficialName"
            ];
          parties.push([responsible, orgID, orgName, officialName]);
        }
        return parties;
      } catch (error) {
        parties.push(["---", "---", "---", "---"]);
        return parties;
      }
    },
    // @FreeVersion
    getDetailViewClassSystem(xmlBlob) {
      let classification = [];
      try {
        for (
          let i = 0;
          i < xmlBlob.Document[0].DocumentClassification.length;
          i++
        ) {
          let system = undefined;
          try {
            system =
              xmlBlob.Document[0].DocumentClassification[i][
                "@ClassificationSystem"
              ];
          } catch (error) {}

          let classId = undefined;
          try {
            classId = xmlBlob.Document[0].DocumentClassification[i].ClassId[0];
          } catch (error) {}

          let languages = [];
          let className = undefined;
          let language = undefined;
          try {
            for (
              let j = 0;
              j <
              xmlBlob.Document[0].DocumentClassification[i].ClassName.length;
              j++
            ) {
              try {
                className =
                  xmlBlob.Document[0].DocumentClassification[i].ClassName[j][
                    "#"
                  ];
              } catch (error) {}
              try {
                language =
                  xmlBlob.Document[0].DocumentClassification[i].ClassName[j][
                    "@Language"
                  ];
              } catch (error) {}

              languages.push([className, language]);
            }
          } catch (error) {}

          classification.push([system, classId, languages]);
        }
        return classification;
      } catch (error) {
        classification.push(["---", "---", [["---", "---"]]]);
        return classification;
      }
    },
    // @FreeVersion
    getXmlFileBlob() {
      let selectedViewerFileRep = this.getViewerFileObjByUniqueID(
        this.getSelectedFileId
      );
      if (selectedViewerFileRep) {
        return selectedViewerFileRep.xmlBlobData;
      }
    },
    // @FreeVersion
    renderJsonXML(json) {
      var _visualizer = new visualizer($("#output"));
      _visualizer.visualize(json);
    },
    ...mapActions("ViewerStore", [
      "addViewerFileObj",
      "deleteViewerFiles",
      "toggleShowListOfPdfsState",
      "setShowListOfPdfsState",
      "setSelectedFileId",
      // @FreeVersion
      "setExpertMode",
    ]),
  },
};
</script>
<style>
.pdfLinkBackground {
  background-color: burlywood;
}
.pdf {
  font-weight: 550;
}
#modalInjectedTitle {
  color: white;
  font-size: 1.5em;
}
.word-break {
  word-break: keep-all;
  word-wrap: break-word;
}
.page header {
  border: #005a9c solid 2px;
  background: #005a9c;
  color: white;
  text-align: center;
}

.page header .title {
  font-size: 2.5em;
  font-weight: bold;
  font-family: serif;
}

.page header .tagline {
  font-style: italic;
}

.page footer {
  border: #005a9c solid 2px;
  background: #005a9c;
  font-family: serif;
  color: white;
  font-style: italic;
  padding-left: 1em;
}

.page .body {
  display: grid;
  grid-template-columns: auto auto;
  border: #eee solid 2px;
}

.page .body nav {
  margin: 0;
  padding: 6px;
  width: 17em;
  height: 60em;
  background: #eee;
}

.page .body nav.focus {
  padding: 4px;
  border: 2px solid #005a9c;
}

.page .body .page {
  margin: 0.25em;
  padding: 0.25em;
  height: 30em;
}

.page .body .page h1 {
  margin: 0;
  padding: 0;
}

.page .main {
  padding: 1em;
}

.treeview-navigation ul,
.treeview-navigation li {
  margin: 0;
  padding: 0;
}

.treeview-navigation li li span.label {
  padding-left: 1em;
}

.treeview-navigation li li li span.label {
  padding-left: 2em;
}

.treeview-navigation li li li li span.label {
  padding-left: 3em;
}
.treeview-navigation li li li li li span.label {
  padding-left: 4em;
}
.treeview-navigation li li li li li li span.label {
  padding-left: 5em;
}
.treeview-navigation li li li li li li li span.label {
  padding-left: 6em;
}

.treeview-navigation[role="tree"] {
  margin: 0;
  padding: 0;
  list-style: none;
}

.treeview-navigation[role="tree"] li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.treeview-navigation a[role="treeitem"] ul {
  margin: 0;
  padding: 0;
}

.treeview-navigation
  a[role="treeitem"][aria-expanded="false"]
  + [role="group"] {
  display: none;
}

.treeview-navigation a[role="treeitem"][aria-expanded="true"] + [role="group"] {
  display: block;
}

.treeview-navigation a[role="treeitem"] > span svg {
  transform: translate(0, 0);
}

.treeview-navigation a[role="treeitem"][aria-expanded="false"] > span svg {
  transform: rotate(270deg) translate(2px, 2px);
}

.treeview-navigation a[role="treeitem"] {
  margin: 0;
  padding: 4px;
  padding-left: 9px;
  text-decoration: none;
  color: #005a9c;
  border: none;
  display: block;
}

.treeview-navigation a[role="treeitem"][aria-current] {
  border-left: 5px solid #005a9c;
  padding-left: 4px;
  background-color: #ddd;
}

.treeview-navigation a[role="treeitem"] span.icon svg polygon {
  stroke-width: 2px;
  fill: currentcolor;
  stroke: transparent;
}

/* disable default keyboard focus styling for treeitems
   Keyboard focus is styled with the following CSS */

.treeview-navigation a[role="treeitem"]:focus {
  outline: 0;
  padding: 2px;
  padding-left: 7px;
  border: 2px #005a9c solid;
}

.treeview-navigation a[role="treeitem"][aria-current]:focus {
  padding-left: 4px;
  border-left-width: 5px;
}

.treeview-navigation a[role="treeitem"]:hover {
  background-color: #adddff;
  text-decoration: underline;
  padding-left: 4px;
  border-left: 5px solid #333;
}

.treeview-navigation a[role="treeitem"] span.icon:hover {
  color: #333;
}

.treeview-navigation a[role="treeitem"] span.icon svg polygon:hover {
  stroke: currentcolor;
}

.visual-package {
  padding: 3px;
  border-radius: 2px;
  margin-top: 3px;
}
.visual-header {
  cursor: pointer;
}

.visual-name {
  color: gray;
}

.visual-array {
  background-color: whitesmoke;
  border: thin solid #343a40;
  border-radius: 5px;
}
.visual-object {
  background-color: white;
  border: thin solid #7da2ce;
  border-radius: 5px;
}
.visual-string {
  color: #0d6efd;
}
.visual-number {
  color: blue;
}
.visual-function {
  color: #198754;
}

.visual-open .visual-children {
  display: block;
}
.visual-closed .visual-children {
  display: none;
}

.visual-arrow {
  background-image: url("d.png");
  background-repeat: no-repeat;
  background-color: transparent;
  height: 15px;
  width: 15px;
  display: inline-block;
}

.visual-open .visual-arrow {
  background-position: -20px 0;
}
.visual-closed .visual-arrow {
  background-position: 0 0;
}

.visual-type {
  color: #6c757d;
  font-size: 8pt;
  float: right;
  border: thin solid #343a40;
  border-radius: 5px;
}

.hide {
  display: none !important;
}
.fw-little-bold {
  font-weight: 500;
}
.metadata-search {
  background-color: #ffd556;
  padding: 2px !important;
  border: 2px #005a9c solid !important;
}
.metadata-active {
  background-color: #dee2e6;
  padding: 2px !important;
  border: 2px #005a9c solid !important;
}
.link-clicked {
  color: #5cb85c !important;
}
</style>
